import React, { Component } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateDeviceToken } from '../actions/login'
import { storePin } from '../actions/users'
import PIN from '../components/securePIN/PIN'
import { setCurrentScreen } from '../actions/session'
import { getUserStudySite } from '../selectors/studyMetaData'
import { getSubject, getUser } from '../selectors/commonSelector'
import PropTypes from 'prop-types'
import { updatePinValidated } from '../actions/storeAppStatus'

class PinSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appPin: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { setCurrentScreen } = this.props
    setCurrentScreen('PIN')
  }

  changeLoading = () => {
    const { loading } = this.state
    this.setState({
      loading: !loading,
    })
  }

  render() {
    const {
      navigation,
      screenProps,
      isSubjectRegistered,
      updateDeviceToken: updateSubjectDeviceToken,
      subject,
      clientID,
      storePin,
      loggedInUserId,
      appType,
      updatePinValidated: updatePinValidationStatus
    } = this.props
    const { loading } = this.state
    const changePin = navigation.getParam('changePin') || false
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator size="large" color="#d7dadb" overlayColor="#d7dadb" />
          </View>
        )}
        <PIN
          navigation={navigation}
          changeLoading={this.changeLoading}
          mode="choose"
          changePin={changePin}
          screenProps={screenProps}
          isSubjectRegistered={isSubjectRegistered}
          updateDeviceToken={updateSubjectDeviceToken}
          subject={subject}
          clientID={clientID}
          storePin={storePin}
          loggedInUserId = {loggedInUserId}
          appType = {appType}
          updatePinValidated = {updatePinValidationStatus} 
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubjectRegistered: getUser(state)?.isSubjectRegistered,
  subject: getSubject(state),
  clientID: getUserStudySite(state)?.client?.id,
  loggedInUserId: getUser(state)?.phoneNo,
  appType: state.appStatus.appType
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
      updateDeviceToken,
      storePin,
      updatePinValidated
    },
    dispatch
  )


  PinSetup.defaultProps = {
    setCurrentScreen:()=> null,
    updateDeviceToken:()=> null,
    storePin:()=> null,  
  }
  
  PinSetup.propTypes = {
    setCurrentScreen: PropTypes.func,
    updateDeviceToken: PropTypes.func,
    storePin: PropTypes.func, 
    updatePinValidated: PropTypes.func.isRequired,
    appType: PropTypes.string.isRequired,
  } 
  

export default connect(mapStateToProps, mapDispatchToProps)(PinSetup)
