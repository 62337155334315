import React, { PureComponent } from 'react'
import { View, Text,  StyleSheet } from 'react-native'

class OfflineNotice extends PureComponent {
  render() {
    const { isConnected, isInternetReachable, t } = this.props
    return (
      <View
        style={[
          styles.offlineView,
          {
            backgroundColor: isConnected == true && isInternetReachable == true ? '#66bb6a' : '#ef5350',
          },
        ]}
      >
        <Text style={styles.offlineText}>
          {isConnected == true && isInternetReachable == true ? t('Online') : t('Offline')}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  offlineView: {
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  offlineText: {
    color: '#fff',
  },
})

export default OfflineNotice
