import React , { useMemo } from 'react'
import { View,Platform,Alert} from 'react-native'
import WebAlert from '../utils/WebAlert'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { updateSpaceAcknowledgement } from '../actions/storeAppStatus'
import { getDeviceStatus } from '../actions/users'
import PropTypes from 'prop-types'

const NoSpacePopUp =  (props) => useMemo(() => {
  const { t,  acknowledgeSpaceRequirement, buttons } = props
  return (
    <View>
    {Platform.OS === 'web' && (
      <WebAlert
      headerText={''}
      hideModal={() => {}}
      t={t}
      action={acknowledgeSpaceRequirement}
      message={t('DownloadImageFailedNoEnoughSpace')}
      buttons = {buttons}
    />
   )}
   {Platform.OS !== 'web' && (
       Alert.alert(
        '',
        t('DownloadImageFailedNoEnoughSpace'),
        [
            {
            text: t('OK'),
            onPress: async () =>  acknowledgeSpaceRequirement(),
            },
        ],
        { cancelable: false }
        )
    )
   }
 </View>
  );
}, [props?.isSpaceAlertAcknowledged, props?.isStorageAvailable, props?.currentScreen])

const NoEnoughDiskSpaceAlert = (props) => {
    const { t, isStorageAvailable, isSpaceAlertAcknowledged, currentScreen} = props
    const dispatch = useDispatch()
    const acknowledgeSpaceRequirement = () => {
        dispatch(updateSpaceAcknowledgement(true))
    }
    const buttons = [ 
      { 'title': 'OK', 'action': () => acknowledgeSpaceRequirement() }, 
  ]

  //Showing this popup only in offline when devicestorage is full, current screen is not PIN and not initialscreen(FALSE) 
  //Added isBoolean conditions to arrest undefined or null values (not using isEmpty because isEmpty(boolean) gives false)
  return ( 
    _.isBoolean(isStorageAvailable) && _.isBoolean(isSpaceAlertAcknowledged) && !isStorageAvailable && !isSpaceAlertAcknowledged && !getDeviceStatus() && !_.isEqual(currentScreen, 'PIN')  && 
    <NoSpacePopUp
         t ={t}
         acknowledgeSpaceRequirement = {acknowledgeSpaceRequirement}
         buttons={buttons}
         isStorageAvailable={isStorageAvailable}
         isSpaceAlertAcknowledged={isSpaceAlertAcknowledged}
         currentScreen={currentScreen}
         />)
   
}


NoEnoughDiskSpaceAlert.defaultProps = {
  t: () => null,
  isStorageAvailable: true,
  isSpaceAlertAcknowledged: false,
  currentScreen: ''
}

NoEnoughDiskSpaceAlert.propTypes = {
  t: PropTypes.func,
  isStorageAvailable: PropTypes.bool,
  isSpaceAlertAcknowledged: PropTypes.bool,
  currentScreen: PropTypes.string
}

export default NoEnoughDiskSpaceAlert