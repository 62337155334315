import React, { useEffect } from 'react'
import { View } from 'react-native'

function PrintFail(props) {
  return (
    <React.Fragment>
      <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <h2>Failed to Print the Form</h2>{' '}
      </View>
    </React.Fragment>
  )
}

export default PrintFail
