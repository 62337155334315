import { Button, Input } from 'native-base'
import React, { useState } from 'react'
import {
  Image,
  View,
  Text,
  Platform,
  ActivityIndicator,
  Dimensions,
  KeyboardAvoidingView,
} from 'react-native'
import { styles } from '../../styles/loginStyles'
import customStyles from '../../../styles'
import { validatePasscode } from '../../../actions/site'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AutoHeightImage from '../../AutoHeightImage'
import { buttonBackgroundColor } from '../../../containers/NavigationScreens'
import _ from 'lodash'
import tryalLogoBox from '../../../../assets/base64/tryalLogoBox'
import RenderHtml from 'react-native-render-html'
import CustomTextInput from '../../common/CustomTextInput'

var { height } = Dimensions.get('window')

const Passcode = (props) => {
  const { screenProps, loading, navigation, validate, showErrorMessage } = props
  const { t } = screenProps

  const [passcode, setPasscode] = useState('')

  const handleClick = async () => {
    validate(passcode, true)
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: '#fff',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {loading && (
        <View style={{ position: 'absolute', alignItems: 'center', alignSelf: 'center' }}>
          <ActivityIndicator
            size="large"
            color={buttonBackgroundColor}
            overlayColor="rgba(0, 0, 0, 0.07)"
          />
        </View>
      )}
      <View style={{ margin: 60 }}>
        {Platform.OS !== 'web' ? (
          <AutoHeightImage
            source={{
              uri: `data:image/png;base64,${tryalLogoBox}`,
            }}
            width={300}
          />
        ) : (
          <Image
            source={{
              uri: `data:image/png;base64,${tryalLogoBox}`,
            }}
            resizeMode="contain"
            style={[styles.image]}
          />
        )}
      </View>
      <View
        style={{
          flex: 1,
          marginHorizontal: 10,
          maxWidth: 500,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <View style={{ width: '90%', alignSelf: 'center', maxWidth: 500 }}>
          <CustomTextInput
            keyboardType="default"
            placeholder={t('EnterPasscode')}
            placeholderTextColor="#bdbdbd"
            style={styles.textInputStyle}
            onChangeText={(val) => setPasscode(val)}
          />
          {showErrorMessage ? (
            <Text style={{ color: 'red' }}>{t('InvPasscode')}</Text>
          ) : (
            <Text></Text>
          )}
        </View>
        <View style={{ flex: 1 }}>
          <Button
            style={customStyles.passcodeSubmitButton}
            onPress={handleClick}
            testID="passcode-button"
          >
            <Text
              style={[
                { color: 'white', alignSelf: 'center', textTransform: 'uppercase' },
                customStyles.fontStyle,
              ]}
            >
              {t('OK')}
            </Text>
          </Button>
        </View>
        <View style={{ alignSelf: 'center', flex: 1 }}>
          <Text style={{ textAlign: 'center' }}>
            <RenderHtml source={{ html: t('SiteIdentifierInfo') }} />
          </Text>
        </View>
      </View>
    </KeyboardAvoidingView>
  )
}

const mapStateToProps = (state) => ({
  loading: state.site.loading,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validatePasscode,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Passcode)
