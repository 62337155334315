import React from 'react';
import AutoHeightImage from '../../AutoHeightImage';
import PropTypes from 'prop-types';

const CustomImage = (props) => {
    const { uri } = props;
    return (
        <AutoHeightImage
                source={{
                  uri: `${uri}`,
                }}
                {...props}
              />
    )
}

CustomImage.propTypes = {
  uri: PropTypes.string.isRequired,
}
export default CustomImage;