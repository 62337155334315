import React from 'react';
import PropTypes from 'prop-types';
import DateModalLayout from './DateModalLayout';
import constants from '../../constants/constants'
import moment from 'moment';

const {dateFormats} = constants
const Datepicker = ({
  showTimeInput,
  timeFormat,
  placeHolder,
  valueFormat,
  isInitialValue,
  shortTimezone,
  disabled,
  testID,
  locale,
  t,
  mode,
  onChange,
  date
}) => {
  return (
    <DateModalLayout
      showTimeInput={showTimeInput}
      timeFormat={timeFormat}
      placeHolder={placeHolder}
      valueFormat={valueFormat}
      isInitialValue={isInitialValue}
      shortTimezone={shortTimezone}
      disabled={disabled}
      testID={testID}
      locale={locale}
      t={t}
      mode={mode}
      onChange={onChange}
      date={date}
    />
  );
};


Datepicker.defaultProps = {
  showTimeInput: false,
  timeFormat: "hh:mm a",
  placeHolder: "Select Date",
  valueFormat: dateFormats.DATE,
  isInitialValue: false,
  shortTimezone: moment.tz.guess(),
  disabled: false,
  testID: "DateTime12-Field-view",
  locale: constants.locale.english,
  mode: "datetime12",
  date: new Date(),
  t: (key) => key,
  onChange: () => {},
};


Datepicker.propTypes = {
  showTimeInput: PropTypes.bool,
  timeFormat: PropTypes.string,
  placeHolder: PropTypes.string,
  valueFormat: PropTypes.string,
  isInitialValue: PropTypes.bool,
  shortTimezone: PropTypes.string,
  disabled: PropTypes.bool,
  testID: PropTypes.string,
  locale: PropTypes.string,
  t: PropTypes.func,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date)
};

export default Datepicker;
