import React from "react";
import PropTypes from 'prop-types'
import ScalableScale from "./ScalableScale";
import { alignments } from "../alignments";

const NRSHorizontalScale = (props) => {
    const { start, end, step, value, disabled, id, isPercentage, onNumberChange, orientation, fontSize } = props
    return (
      <ScalableScale
        start={start}
        end={end}
        step={step}
        value={value}
        disabled={disabled}
        id={id}
        isPercentage={isPercentage}
        onNumberChange={onNumberChange}
        orientation={orientation} 
        fontSize={fontSize}
      />
    )
}

NRSHorizontalScale.defaultProps = {
    isPercentage: false,
    start: 0,
    end: 10,
    step: 1,
    value: null,
    onNumberChange: () => null,
    disabled: false,
    id: null,
    orientation: alignments.HORIZONTAL,
  }
  
  NRSHorizontalScale.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onNumberChange: PropTypes.func,
    isPercentage: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    orientation: PropTypes.string,
    fontSize: PropTypes.number.isRequired
  }

export default NRSHorizontalScale