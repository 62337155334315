import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import constants from '../constants/constants'
import { getUserId } from '../selectors/user'

export const RETRIEVE_ALL_CRF_DATA_REQUEST = createActionType('RETRIEVE_ALL_CRF_DATA_REQUEST')
export const RETRIEVE_ALL_CRF_DATA_SUCCESS = createActionType('RETRIEVE_ALL_CRF_DATA_SUCCESS')
export const RETRIEVE_ALL_CRF_DATA_FAILURE = createActionType('RETRIEVE_ALL_CRF_DATA_FAILURE')
export const UPDATE_FIELD_ANSWER = createActionType('UPDATE_FIELD_ANSWER')

export const retrieveCrfData = (requestProps) => async(dispatch, getState) => {
  const {isPrint} = requestProps
  const userId = !isPrint ? getUserId(getState()) : constants.AppType.PRINT
  dispatch({ type: RETRIEVE_ALL_CRF_DATA_REQUEST, userId })
  let crfData = {}
  try {
    if(!isPrint){
     crfData = await retrieveSubjectCrfData(requestProps)
    }else{
     crfData = await retrieveSubjectCrfDataBySvfId(requestProps)
    }
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_SUCCESS, formAnswers: crfData , userId })
  }catch (error) {
    dispatch({ type: RETRIEVE_ALL_CRF_DATA_FAILURE, userId })
    console.error(error)
  }

}
const retrieveSubjectCrfData = async(requestProps) => {
  const {subjectId} = requestProps
  const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/crfData`)
  const crfDataGroupBySvfId = _.groupBy(res.data, 'subjectVisitForm.id')
  return processCrfDataByFieldOidAndGroupBySvf(crfDataGroupBySvfId)
}

const retrieveSubjectCrfDataBySvfId = async(requestProps) => {
  const {primaryOrgCode, studyId, siteId, subjectId, subjectVisitId, svfId} = requestProps
  const res = await api.get(`/${primaryOrgCode}/studies/${studyId}/sites/${siteId}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms/${svfId}/crfData`)
  const crfDataGroupBySvfId = {[svfId]: res.data}
  return processCrfDataByFieldOidAndGroupBySvf(crfDataGroupBySvfId)
}

const processCrfDataByFieldOidAndGroupBySvf = (crfData) => {
  let svfsCrfData = {}
  _.forEach(crfData, (svfCrfDataList, svfId) => {
      let svfCrfData = {}
      _.forEach(svfCrfDataList, (crfData) => (svfCrfData[crfData.field.fieldOid] = crfData))
      svfsCrfData[svfId] = svfCrfData
  })
  return svfsCrfData
}

export const updateFieldAnswer = (selectedField, svfId) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_FIELD_ANSWER,
    selectedField: selectedField,
    svfId: svfId,
    userId: getUserId(getState())
  })
}

