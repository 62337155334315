import React, { Component } from 'react'
import {  View, ActivityIndicator } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import { getPin } from '../../utils/secureStorageUtils'
import PIN from './PIN'
import { connect } from 'react-redux'
import { setCurrentScreen } from '../../actions/session'
import { getUser } from '../../selectors/commonSelector'
import { bindActionCreators } from 'redux'
import { updatePinValidated } from '../../actions/storeAppStatus'
import PropTypes from 'prop-types'

class PinChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    setCurrentScreen('PIN')
  }
  
  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //   title: t('AppLock'),
  // })

  retrieveAppPin = async () => {
    const { loggedInUserId } = this.props;
    const appPin = getPin(loggedInUserId)
    this.setState({
      appPin,
      loading: false,
    })
  }

  render() {
    const { loading, appPin, loggedInUserId } = this.state
    const { navigation, screenProps, updatePinValidated: updatePinValidationStatus } = this.props
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator size="large" color="#d7dadb" overlayColor="#d7dadb" />
          </View>
        )}
        <NavigationEvents
          onWillFocus={() => {
            this.retrieveAppPin()
          }}
        />
        {!loading && (
          <PIN
            appPin={appPin}
            mode="validate"
            navigation={navigation}
            changePin
            screenProps={screenProps}
            loggedInUserId = {loggedInUserId}
            updatePinValidated = {updatePinValidationStatus}
          />
        )}
      </View>
    )
  }
}

PinChange.propTypes = {
  updatePinValidated: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
  screenProps: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  loggedInUserId: getUser(state)?.phoneNo
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
      updatePinValidated
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PinChange)
