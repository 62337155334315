import React from "react"
import TrackMarkComponent from "./TrackMarkComponent"
import { View } from "react-native"
import TrackMarkNumbers from "./TrackMarkNumbers"
import constants from "./constants"
import PropTypes from "prop-types"

const { SCALE_POSITION } = constants;
const VerticalTrackMarkContainer = (props) => {
    const { scaleVerticalAlignment } = props
  
    return (
        <View style = {{ flexDirection: 'row'}}>
            {/* ----------marks------------ */}
            <TrackMarkComponent {...props} />

            {/* ----------indication numbers------------ */}
            <View style = {{ justifyContent: 'flex-end', marginTop: 50, marginBottom: scaleVerticalAlignment === SCALE_POSITION.RIGHT_SIDE_OF_THE_PAGE ? 5 : 0}}>
            <TrackMarkNumbers {...props} />
            </View>
        </View>
    )
}

VerticalTrackMarkContainer.defaultProps = {
    scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE,
}
VerticalTrackMarkContainer.propTypes = {
    scaleVerticalAlignment: PropTypes.string,
}
export default VerticalTrackMarkContainer