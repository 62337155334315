export default {
    "Africa/Abidjan": "Afrika/Abidjan",
"Africa/Accra": "Afrika/Akra",
"Africa/Addis_Ababa": "Afrika/Addis_Ababa",
"Africa/Algiers": "Afrika/Alžir",
"Africa/Asmara": "Afrika/Asmara",
"Africa/Asmera": "Afrika/Asmera",
"Africa/Bamako": "Afrika/Bamako",
"Africa/Bangui": "Afrika/Bangui",
"Africa/Banjul": "Afrika/Banjul",
"Africa/Bissau": "Afrika/Bisau",
"Africa/Blantyre": "Afrika/Blantyre",
"Africa/Brazzaville": "Afrika/Brazzaville",
"Africa/Bujumbura": "Afrika/Bujumbura",
"Africa/Cairo": "Afrika/Kairo",
"Africa/Casablanca": "Afrika/Casablanca",
"Africa/Ceuta": "Afrika/Ceuta",
"Africa/Conakry": "Afrika/Conakry",
"Africa/Dakar": "Afrika/Dakar",
"Africa/Dar_es_Salaam": "Afrika/Dar_es_Salaam",
"Africa/Djibouti": "Afrika/Džibuti",
"Africa/Douala": "Afrika/Douala",
"Africa/El_Aaiun": "Afrika/El_Aaiun",
"Africa/Freetown": "Afrika/Freetown",
"Africa/Gaborone": "Afrika/Gaborone",
"Africa/Harare": "Afrika/Harare",
"Africa/Johannesburg": "Afrika/Johannesburg",
"Africa/Juba": "Afrika/Juba",
"Africa/Kampala": "Afrika/Kampala",
"Africa/Khartoum": "Afrika/Kartum",
"Africa/Kigali": "Afrika/Kigali",
"Africa/Kinshasa": "Afrika/Kinšasa",
"Africa/Lagos": "Afrika/Lagos",
"Africa/Libreville": "Afrika/Libreville",
"Africa/Lome": "Afrika/Lome",
"Africa/Luanda": "Afrika/Luanda",
"Africa/Lubumbashi": "Afrika/Lubumbashi",
"Africa/Lusaka": "Afrika/Lusaka",
"Africa/Malabo": "Afrika/Malabo",
"Africa/Maputo": "Afrika/Maputo",
"Africa/Maseru": "Afrika/Maseru",
"Africa/Mbabane": "Afrika/Mbabane",
"Africa/Mogadishu": "Afrika/Mogadishu",
"Africa/Monrovia": "Afrika/Monrovija",
"Africa/Nairobi": "Afrika/Nairobi",
"Africa/Ndjamena": "Afrika/Ndjamena",
"Africa/Niamey": "Afrika/Niamey",
"Africa/Nouakchott": "Afrika/Nouakchott",
"Africa/Ouagadougou": "Afrika/Ouagadougou",
"Africa/Porto-Novo": "Afrika/Porto-Novo",
"Africa/Sao_Tome": "Afrika/Sao_Tome",
"Africa/Timbuktu": "Afrika/Timbuktu",
"Africa/Tripoli": "Afrika/Tripoli",
"Africa/Tunis": "Afrika/Tunis",
"Africa/Windhoek": "Afrika/Windhoek",
"America/Adak": "Amerika/Adak",
"America/Anchorage": "Amerika/Sidrište",
"America/Anguilla": "Amerika/Angvila",
"America/Antigua": "Amerika/Antigva",
"America/Araguaina": "Amerika/Araguaina",
"America/Argentina/Buenos_Aires": "Amerika/Argentina/Buenos_Aires",
"America/Argentina/Catamarca": "Amerika/Argentina/Katamarka",
"America/Argentina/ComodRivadavia": "Amerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba": "Amerika/Argentina/Cordoba",
"America/Argentina/Jujuy": "Amerika/Argentina/Jujuy",
"America/Argentina/La_Rioja": "Amerika/Argentina/La_Rioja",
"America/Argentina/Mendoza": "Amerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos": "Amerika/Argentina/Rio_Gallegos",
"America/Argentina/Salta": "Amerika/Argentina/Salta",
"America/Argentina/San_Juan": "Amerika/Argentina/San_Juan",
"America/Argentina/San_Luis": "Amerika/Argentina/San_Luis",
"America/Argentina/Tucuman": "Amerika/Argentina/Tucuman",
"America/Argentina/Ushuaia": "Amerika/Argentina/Ushuaia",
"America/Aruba": "Amerika/Aruba",
"America/Asuncion": "Amerika/Asuncion",
"America/Atikokan": "Amerika/Atikokan",
"America/Atka": "Amerika/Atka",
"America/Bahia": "Amerika/Bahia",
"America/Bahia_Banderas": "Amerika/Bahia_Banderas",
"America/Barbados": "Amerika/Barbados",
"America/Belem": "Amerika/Belem",
"America/Belize": "Amerika/Belize",
"America/Blanc-Sablon": "Amerika/Blanc-Sablon",
"America/Boa_Vista": "Amerika/Boa_Vista",
"America/Bogota": "Amerika/Bogota",
"America/Boise": "Amerika/Boise",
"America/Buenos_Aires": "Amerika/Buenos_Aires",
"America/Cambridge_Bay": "Amerika/Cambridge_Bay",
"America/Campo_Grande": "Amerika/Campo_Grande",
"America/Cancun": "Amerika/Cancun",
"America/Caracas": "Amerika/Karakas",
"America/Catamarca": "Amerika/Katamarka",
"America/Cayenne": "Amerika/Cayenne",
"America/Cayman": "Amerika/Kajman",
"America/Chicago": "Amerika/Čikago",
"America/Chihuahua": "Amerika/Chihuahua",
"America/Ciudad_Juarez": "Amerika/Ciudad_Juarez",
"America/Coral_Harbour": "Amerika/Coral_Harbour",
"America/Cordoba": "Amerika/Cordoba",
"America/Costa_Rica": "Amerika/Costa_Rica",
"America/Creston": "Amerika/Creston",
"America/Cuiaba": "Amerika/Cuiaba",
"America/Curacao": "Amerika/Curacao",
"America/Danmarkshavn": "Amerika/Danmarkshavn",
"America/Dawson": "Amerika/Dawson",
"America/Dawson_Creek": "Amerika/Dawson_Creek",
"America/Denver": "Amerika/Denver",
"America/Detroit": "Amerika/Detroit",
"America/Dominica": "Amerika/Dominika",
"America/Edmonton": "Amerika/Edmonton",
"America/Eirunepe": "Amerika/Eirunepe",
"America/El_Salvador": "Amerika/El_Salvador",
"America/Ensenada": "Amerika/Ensenada",
"America/Fort_Nelson": "Amerika/Fort_Nelson",
"America/Fort_Wayne": "Amerika/Fort_Wayne",
"America/Fortaleza": "Amerika/Fortaleza",
"America/Glace_Bay": "Amerika/Glace_Bay",
"America/Godthab": "Amerika/Godthab",
"America/Goose_Bay": "Amerika/Goose_Bay",
"America/Grand_Turk": "Amerika/Grand_Turk",
"America/Grenada": "Amerika/Grenada",
"America/Guadeloupe": "Amerika/Guadeloupe",
"America/Guatemala": "Amerika/Gvatemala",
"America/Guayaquil": "Amerika/Guayaquil",
"America/Guyana": "Amerika/Gvajana",
"America/Halifax": "Amerika/Halifax",
"America/Havana": "Amerika/Havana",
"America/Hermosillo": "Amerika/Hermosillo",
"America/Indiana/Indianapolis": "Amerika/Indiana/Indianapolis",
"America/Indiana/Knox": "Amerika/Indijana/Knox",
"America/Indiana/Marengo": "Amerika/Indijana/Marengo",
"America/Indiana/Petersburg": "Amerika/Indijana/Petersburg",
"America/Indiana/Tell_City": "Amerika/Indijana/Tell_City",
"America/Indiana/Vevay": "Amerika/Indijana/Vevay",
"America/Indiana/Vincennes": "Amerika/Indijana/Vincennes",
"America/Indiana/Winamac": "Amerika/Indijana/Winamac",
"America/Indianapolis": "Amerika/Indianapolis",
"America/Inuvik": "Amerika/Inuvik",
"America/Iqaluit": "Amerika/Iqaluit",
"America/Jamaica": "Amerika/Jamajka",
"America/Jujuy": "Amerika/Jujuy",
"America/Juneau": "Amerika/Juneau",
"America/Kentucky/Louisville": "Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello": "Amerika/Kentucky/Monticello",
"America/Knox_IN": "Amerika/Knox_in",
"America/Kralendijk": "Amerika/Kralendijk",
"America/La_Paz": "Amerika/La_Paz",
"America/Lima": "Amerika/Lima",
"America/Los_Angeles": "Amerika/Los_Angeles",
"America/Louisville": "Amerika/Louisville",
"America/Lower_Princes": "Amerika/Lower_Princes",
"America/Maceio": "Amerika/Maceio",
"America/Managua": "Amerika/Managua",
"America/Manaus": "Amerika/Manaus",
"America/Marigot": "Amerika/Marigot",
"America/Martinique": "Amerika/Martinik",
"America/Matamoros": "Amerika/Matamoros",
"America/Mazatlan": "Amerika/Mazatlan",
"America/Mendoza": "Amerika/Mendoza",
"America/Menominee": "Amerika/Menominee",
"America/Merida": "Amerika/Merida",
"America/Metlakatla": "Amerika/Metlakatla",
"America/Mexico_City": "Amerika/Meksiko_Grad",
"America/Miquelon": "Amerika/Mikelon",
"America/Moncton": "Amerika/Moncton",
"America/Monterrey": "Amerika/Monterrey",
"America/Montevideo": "Amerika/Montevideo",
"America/Montreal": "Amerika/Montreal",
"America/Montserrat": "Amerika/Montserrat",
"America/Nassau": "Amerika/Nassau",
"America/New_York": "Amerika/New York",
"America/Nipigon": "Amerika/Nipigon",
"America/Nome": "Amerika/Ime",
"America/Noronha": "Amerika/Noronha",
"America/North_Dakota/Beulah": "Amerika/Sjeverna_Dakota/Beulah",
"America/North_Dakota/Center": "Amerika/Sjeverna_Dakota/Centar",
"America/North_Dakota/New_Salem": "Amerika/Sjeverna_Dakota/New_Salem",
"America/Nuuk": "Amerika/Novi",
"America/Ojinaga": "Amerika/Ojinaga",
"America/Panama": "Amerika/Panama",
"America/Pangnirtung": "Amerika/Pangnirtung",
"America/Paramaribo": "Amerika/Paramaribo",
"America/Phoenix": "Amerika/Feniks",
"America/Port-au-Prince": "Amerika/Port-au-Prince",
"America/Port_of_Spain": "Amerika/Port_of_Španjolske",
"America/Porto_Acre": "Amerika/Porto_Acre",
"America/Porto_Velho": "Amerika/Porto_Velho",
"America/Puerto_Rico": "Amerika/Puerto_Rico",
"America/Punta_Arenas": "Amerika/Punta_Arenas",
"America/Rainy_River": "Amerika/Rainy_River",
"America/Rankin_Inlet": "Amerika/Rankin_Inlet",
"America/Recife": "Amerika/Recife",
"America/Regina": "Amerika/Regina",
"America/Resolute": "Amerika/Odlučna",
"America/Rio_Branco": "Amerika/Rio_Branco",
"America/Rosario": "Amerika/Rosario",
"America/Santa_Isabel": "Amerika/Santa_Isabel",
"America/Santarem": "Amerika/Santarem",
"America/Santiago": "Amerika/Santiago",
"America/Santo_Domingo": "Amerika/Santo_Domingo",
"America/Sao_Paulo": "Amerika/Sao_Paulo",
"America/Scoresbysund": "Amerika/Scoresbysund",
"America/Shiprock": "Amerika/Shiprock",
"America/Sitka": "Amerika/Sitka",
"America/St_Barthelemy": "Amerika/St_Barthelemy",
"America/St_Johns": "Amerika/St_Johns",
"America/St_Kitts": "Amerika/St_Kitts",
"America/St_Lucia": "Amerika/St_Lucia",
"America/St_Thomas": "Amerika/St_Thomas",
"America/St_Vincent": "Amerika/St_Vincent",
"America/Swift_Current": "Amerika/Swift_Current",
"America/Tegucigalpa": "Amerika/Tegucigalpa",
"America/Thule": "Amerika/Thule",
"America/Thunder_Bay": "Amerika/Thunder_Bay",
"America/Tijuana": "Amerika/Tijuana",
"America/Toronto": "Amerika/Toronto",
"America/Tortola": "Amerika/Tortola",
"America/Vancouver": "Amerika/Vancouver",
"America/Virgin": "Amerika/Djevica",
"America/Whitehorse": "Amerika/Bijeli konj",
"America/Winnipeg": "Amerika/Winnipeg",
"America/Yakutat": "Amerika/Yakutat",
"America/Yellowknife": "Amerika/Yellowknife",
"Antarctica/Casey": "Antarktika/Casey",
"Antarctica/Davis": "Antarktika/Davis",
"Antarctica/DumontDUrville": "Antarktika/Dumontdurville",
"Antarctica/Macquarie": "Antarktika/Macquarie",
"Antarctica/Mawson": "Antarktika/Mawson",
"Antarctica/McMurdo": "Antarktika/McMurdo",
"Antarctica/Palmer": "Antarktika/Palmer",
"Antarctica/Rothera": "Antarktika/Rothera",
"Antarctica/South_Pole": "Antarktika/Južni Pol",
"Antarctica/Syowa": "Antarktika/Syowa",
"Antarctica/Troll": "Antarktika/Troll",
"Antarctica/Vostok": "Antarktika/Vostok",
"Arctic/Longyearbyen": "Arktik/Longyearbyen",
"Asia/Aden": "Azija/Aden",
"Asia/Almaty": "Azija/Almati",
"Asia/Amman": "Azija/Amman",
"Asia/Anadyr": "Azija/Anadyr",
"Asia/Aqtau": "Azija/Aqtau",
"Asia/Aqtobe": "Azija/Aqtobe",
"Asia/Ashgabat": "Azija/Ašgabat",
"Asia/Ashkhabad": "Azija/Ashkhabad",
"Asia/Atyrau": "Azija/Atyrau",
"Asia/Baghdad": "Azija/Bagdad",
"Asia/Bahrain": "Azija/Bahrein",
"Asia/Baku": "Azija/Baku",
"Asia/Bangkok": "Azija/Bangkok",
"Asia/Barnaul": "Azija/Barnaul",
"Asia/Beirut": "Azija/Bejrut",
"Asia/Bishkek": "Azija/Biškek",
"Asia/Brunei": "Azija/Brunej",
"Asia/Calcutta": "Azija/Kalkuta",
"Asia/Chita": "Azija/Čita",
"Asia/Choibalsan": "Azija/Choibalsan",
"Asia/Chongqing": "Azija/Chongqing",
"Asia/Chungking": "Azija/Chungking",
"Asia/Colombo": "Azija/Colombo",
"Asia/Dacca": "Azija/Dakka",
"Asia/Damascus": "Azija/Damask",
"Asia/Dhaka": "Azija/Dhaka",
"Asia/Dili": "Azija/Dili",
"Asia/Dubai": "Azija/Dubai",
"Asia/Dushanbe": "Azija/Dušanbe",
"Asia/Famagusta": "Azija/Famagusta",
"Asia/Gaza": "Azija/Gaza",
"Asia/Harbin": "Azija/Harbin",
"Asia/Hebron": "Azija/Hebron",
"Asia/Ho_Chi_Minh": "Azija/Ho_Chi_Minh",
"Asia/Hong_Kong": "Azija/Hong_Kong",
"Asia/Hovd": "Azija/Hovd",
"Asia/Irkutsk": "Azija/Irkutsk",
"Asia/Istanbul": "Azija/Istanbul",
"Asia/Jakarta": "Azija/Džakarta",
"Asia/Jayapura": "Azija/Jayapura",
"Asia/Jerusalem": "Azija/Jeruzalem",
"Asia/Kabul": "Azija/Kabul",
"Asia/Kamchatka": "Azija/Kamčatka",
"Asia/Karachi": "Azija/Karachi",
"Asia/Kashgar": "Azija/Kašgar",
"Asia/Kathmandu": "Azija/Katmandu",
"Asia/Katmandu": "Azija/Katmandu",
"Asia/Khandyga": "Azija/Khandyga",
"Asia/Kolkata": "Azija/Kalkuta",
"Asia/Krasnoyarsk": "Azija/Krasnojarsk",
"Asia/Kuala_Lumpur": "Azija/Kuala_Lumpur",
"Asia/Kuching": "Azija/Kuching",
"Asia/Kuwait": "Azija/Kuvajt",
"Asia/Macao": "Azija/Makao",
"Asia/Macau": "Azija/Makao",
"Asia/Magadan": "Azija/Magadan",
"Asia/Makassar": "Azija/Makassar",
"Asia/Manila": "Azija/Manila",
"Asia/Muscat": "Azija/Muškat",
"Asia/Nicosia": "Azija/Nikozija",
"Asia/Novokuznetsk": "Azija/Novokuznetsk",
"Asia/Novosibirsk": "Azija/Novosibirsk",
"Asia/Omsk": "Azija/Omsk",
"Asia/Oral": "Azija/Oralno",
"Asia/Phnom_Penh": "Azija/Phnom_Penh",
"Asia/Pontianak": "Azija/Pontianak",
"Asia/Pyongyang": "Azija/Pjongjang",
"Asia/Qatar": "Azija/Katar",
"Asia/Qostanay": "Azija/Qostanay",
"Asia/Qyzylorda": "Azija/Qyzylorda",
"Asia/Rangoon": "Azija/Rangun",
"Asia/Riyadh": "Azija/Rijad",
"Asia/Saigon": "Azija/Sajgon",
"Asia/Sakhalin": "Azija/Sahalin",
"Asia/Samarkand": "Azija/Samarkand",
"Asia/Seoul": "Azija/Seul",
"Asia/Shanghai": "Azija/Šangaj",
"Asia/Singapore": "Azija/Singapur",
"Asia/Srednekolymsk": "Azija/Srednekolymsk",
"Asia/Taipei": "Azija/Tajpej",
"Asia/Tashkent": "Azija/Taškent",
"Asia/Tbilisi": "Azija/Tbilisi",
"Asia/Tehran": "Azija/Teheran",
"Asia/Tel_Aviv": "Azija/Tel_Aviv",
"Asia/Thimbu": "Azija/Thimbu",
"Asia/Thimphu": "Azija/Thimphu",
"Asia/Tokyo": "Azija/Tokio",
"Asia/Tomsk": "Azija/Tomsk",
"Asia/Ujung_Pandang": "Azija/Ujung_Pandang",
"Asia/Ulaanbaatar": "Azija/Ulaanbaatar",
"Asia/Ulan_Bator": "Azija/Ulan_Bator",
"Asia/Urumqi": "Azija/Urumqi",
"Asia/Ust-Nera": "Azija/Ust-Nera",
"Asia/Vientiane": "Azija/Vientiane",
"Asia/Vladivostok": "Azija/Vladivostok",
"Asia/Yakutsk": "Azija/Jakutsk",
"Asia/Yangon": "Azija/Yangon",
"Asia/Yekaterinburg": "Azija/Jekaterinburg",
"Asia/Yerevan": "Azija/Erevan",
"Atlantic/Azores": "Atlantik/Azori",
"Atlantic/Bermuda": "Atlantik/Bermudi",
"Atlantic/Canary": "Atlantik/Kanarski",
"Atlantic/Cape_Verde": "Atlantik/Cape Verde",
"Atlantic/Faeroe": "Atlantik/Ferska Republika",
"Atlantic/Faroe": "Atlantik/Farski",
"Atlantic/Jan_Mayen": "Atlantik/Jan_Mayen",
"Atlantic/Madeira": "Atlantik/Madeira",
"Atlantic/Reykjavik": "Atlantik/Reykjavik",
"Atlantic/South_Georgia": "Atlantik/Južna Džordžija",
"Atlantic/St_Helena": "Atlantik/Sveta Helena",
"Atlantic/Stanley": "Atlantik/Stanley",
"Australia/ACT": "Australija/ACT",
"Australia/Adelaide": "Australija/Adelaide",
"Australia/Brisbane": "Australija/Brisbane",
"Australia/Broken_Hill": "Australija/Broken_Hill",
"Australia/Canberra": "Australija/Canberra",
"Australia/Currie": "Australija/Currie",
"Australia/Darwin": "Australija/Darwin",
"Australia/Eucla": "Australija/Eucla",
"Australia/Hobart": "Australija/Hobart",
"Australia/LHI": "Australija/LHI",
"Australia/Lindeman": "Australija/Lindeman",
"Australia/Lord_Howe": "Australija/Lord_Howe",
"Australia/Melbourne": "Australija/Melbourne",
"Australia/NSW": "Australija/NSW",
"Australia/North": "Australija/Sjever",
"Australia/Perth": "Australija/Perth",
"Australia/Queensland": "Australija/Queensland",
"Australia/South": "Australija/Jug",
"Australia/Sydney": "Australija/Sydney",
"Australia/Tasmania": "Australija/Tasmanija",
"Australia/Victoria": "Australija/Viktorija",
"Australia/West": "Australija/Zapad",
"Australia/Yancowinna": "Australija/Yancowinna",
"Brazil/Acre": "Brazil/Acre",
"Brazil/DeNoronha": "Brazil/Denoronha",
"Brazil/East": "Brazil/Istok",
"Brazil/West": "Brazil/Zapad",
"CET": "OVO",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Kanada/Atlantik",
"Canada/Central": "Kanada/Central",
"Canada/Eastern": "Kanada/Istočna",
"Canada/Mountain": "Kanada/Planina",
"Canada/Newfoundland": "Kanada/Newfoundland",
"Canada/Pacific": "Kanada/Pacifik",
"Canada/Saskatchewan": "Kanada/Saskatchewan",
"Canada/Yukon": "Kanada/Yukon",
"Chile/Continental": "Čile/kontinentalni",
"Chile/EasterIsland": "Čile/Istočno ostrvo",
"Cuba": "Kuba",
"EET": "JESTI",
"EST": "EST",
"EST5EDT": "ESTA5",
"Egypt": "Egipat",
"Eire": "Ear",
"Etc/GMT": "ITC/GMT",
"Etc/GMT+0": "ITC/GMT+0",
"Etc/GMT+1": "ITC/GMT+1",
"Etc/GMT+10": "ITC/GMT+10",
"Etc/GMT+11": "ITC/GMT+11",
"Etc/GMT+12": "ITC/GMT+12",
"Etc/GMT+2": "ITC/GMT+2",
"Etc/GMT+3": "ITC/GMT+3",
"Etc/GMT+4": "ITC/GMT+4",
"Etc/GMT+5": "ITC/GMT+5",
"Etc/GMT+6": "ITC/GMT+6",
"Etc/GMT+7": "ITC/GMT+7",
"Etc/GMT+8": "ITC/GMT+8",
"Etc/GMT+9": "ITC/GMT+9",
"Etc/GMT-0": "ITC/GMT-0",
"Etc/GMT-1": "ITC/GMT-1",
"Etc/GMT-10": "ITC/GMT-10",
"Etc/GMT-11": "ITC/GMT-11",
"Etc/GMT-12": "ITC/GMT-12",
"Etc/GMT-13": "ITC/GMT-13",
"Etc/GMT-14": "ITC/GMT-14",
"Etc/GMT-2": "ITC/GMT-2",
"Etc/GMT-3": "ITC/GMT-3",
"Etc/GMT-4": "ITC/GMT-4",
"Etc/GMT-5": "ITC/GMT-5",
"Etc/GMT-6": "ITC/GMT-6",
"Etc/GMT-7": "ITC/GMT-7",
"Etc/GMT-8": "ITC/GMT-8",
"Etc/GMT-9": "ITC/GMT-9",
"Etc/GMT0": "ITC/GMT0",
"Etc/Greenwich": "Itc/Greenwich",
"Etc/UCT": "ITC/UCT",
"Etc/UTC": "ITC/UTC",
"Etc/Universal": "Etc/Univerzalno",
"Etc/Zulu": "Etc/Zulu",
"Europe/Amsterdam": "Evropa/Amsterdam",
"Europe/Andorra": "Europa/Andora",
"Europe/Astrakhan": "Europa/Astrahan",
"Europe/Athens": "Evropa/Atena",
"Europe/Belfast": "Europa/Belfast",
"Europe/Belgrade": "Evropa/Beograd",
"Europe/Berlin": "Evropa/Berlin",
"Europe/Bratislava": "Europa/Bratislava",
"Europe/Brussels": "Europa/Brisel",
"Europe/Bucharest": "Europa/Bukurešt",
"Europe/Budapest": "Evropa/Budimpešta",
"Europe/Busingen": "Europa/Busingen",
"Europe/Chisinau": "Europa/Kišinjev",
"Europe/Copenhagen": "Europa/Kopenhagen",
"Europe/Dublin": "Evropa/Dublin",
"Europe/Gibraltar": "Evropa/Gibraltar",
"Europe/Guernsey": "Europa/Guernsey",
"Europe/Helsinki": "Evropa/Helsinki",
"Europe/Isle_of_Man": "Evropa/Otoči_čovjeka",
"Europe/Istanbul": "Evropa/Istanbul",
"Europe/Jersey": "Evropa/Jersey",
"Europe/Kaliningrad": "Europa/Kalinjingrad",
"Europe/Kiev": "Europa/Kijev",
"Europe/Kirov": "Europa/Kirov",
"Europe/Kyiv": "Europa/Kijev",
"Europe/Lisbon": "Europa/Lisabon",
"Europe/Ljubljana": "Europa/Ljubljana",
"Europe/London": "Evropa/London",
"Europe/Luxembourg": "Europa/Luksemburg",
"Europe/Madrid": "Evropa/Madrid",
"Europe/Malta": "Europa/Malta",
"Europe/Mariehamn": "Europa/Mariehamn",
"Europe/Minsk": "Europa/Minsk",
"Europe/Monaco": "Europa/Monako",
"Europe/Moscow": "Europa/Moskva",
"Europe/Nicosia": "Europa/Nikozija",
"Europe/Oslo": "Europa/Oslo",
"Europe/Paris": "Europa/Pariz",
"Europe/Podgorica": "Europa/Podgorica",
"Europe/Prague": "Europa/Prag",
"Europe/Riga": "Europa/Riga",
"Europe/Rome": "Evropa/Rim",
"Europe/Samara": "Evropa/Samara",
"Europe/San_Marino": "Evropa/San_Marino",
"Europe/Sarajevo": "Evropa/Sarajevo",
"Europe/Saratov": "Europa/Saratov",
"Europe/Simferopol": "Europa/Simferopol",
"Europe/Skopje": "Evropa/Skoplje",
"Europe/Sofia": "Europa/Sofija",
"Europe/Stockholm": "Europa/Stockholm",
"Europe/Tallinn": "Europa/Tallinn",
"Europe/Tirane": "Europa/Tirana",
"Europe/Tiraspol": "Europa/Tiraspol",
"Europe/Ulyanovsk": "Europa/Uljanovsk",
"Europe/Uzhgorod": "Europa/Uzhgorod",
"Europe/Vaduz": "Europa/Vaduz",
"Europe/Vatican": "Evropa/Vatikan",
"Europe/Vienna": "Europa/Beč",
"Europe/Vilnius": "Europa/Vilnius",
"Europe/Volgograd": "Europa/Volgograd",
"Europe/Warsaw": "Evropa/Varšava",
"Europe/Zagreb": "Evropa/Zagreb",
"Europe/Zaporozhye": "Europa/Zaporožje",
"Europe/Zurich": "Europa/Zürich",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HST",
"Hongkong": "Hong Kong",
"Iceland": "Island",
"Indian/Antananarivo": "Indijski/Antananarivo",
"Indian/Chagos": "Indijski/Chagos",
"Indian/Christmas": "Indijski/Božić",
"Indian/Cocos": "Indijski/Kokos",
"Indian/Comoro": "Indijski/Komorski",
"Indian/Kerguelen": "Indijski/Kerguelen",
"Indian/Mahe": "Indijski/Mahe",
"Indian/Maldives": "Indijski/Maldivi",
"Indian/Mauritius": "Indijski/Mauricijus",
"Indian/Mayotte": "Indijski/Mayotte",
"Indian/Reunion": "Indijanski/ponovno okupljanje",
"Iran": "Iran",
"Israel": "Izrael",
"Jamaica": "Jamajka",
"Japan": "Japan",
"Kwajalein": "Kvajalein",
"Libya": "Libija",
"MET": "UPOZNALI",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Meksiko/Bajanorte",
"Mexico/BajaSur": "Meksiko/Bajasur",
"Mexico/General": "Meksiko/Općenito",
"NZ": "NZ",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacifik/Apia",
"Pacific/Auckland": "Pacifik/Auckland",
"Pacific/Bougainville": "Pacifik/Bougainville",
"Pacific/Chatham": "Pacifik/Chatham",
"Pacific/Chuuk": "Pacifik/Chuuk",
"Pacific/Easter": "Pacifik/Uskrs",
"Pacific/Efate": "Pacifik/Efate",
"Pacific/Enderbury": "Pacifik/Enderbury",
"Pacific/Fakaofo": "Pacifik/Fakaofo",
"Pacific/Fiji": "Pacifik/Fidži",
"Pacific/Funafuti": "Pacifik/Funafuti",
"Pacific/Galapagos": "Pacifik/Galapagos",
"Pacific/Gambier": "Pacifik/Gambier",
"Pacific/Guadalcanal": "Pacifik/Guadalcanal",
"Pacific/Guam": "Pacifik/Guam",
"Pacific/Honolulu": "Pacifik/Honolulu",
"Pacific/Johnston": "Pacifik/Johnston",
"Pacific/Kanton": "Pacifik/Kanton",
"Pacific/Kiritimati": "Pacifik/Kiritimati",
"Pacific/Kosrae": "Pacifik/Kosrae",
"Pacific/Kwajalein": "Pacifik/Kwajalein",
"Pacific/Majuro": "Pacific/Major",
"Pacific/Marquesas": "Pacifik/Marquesas",
"Pacific/Midway": "Pacifik/Midway",
"Pacific/Nauru": "Pacifik/Nauru",
"Pacific/Niue": "Pacifik/Niue",
"Pacific/Norfolk": "Pacifik/Norfolk",
"Pacific/Noumea": "Pacifik/Noumea",
"Pacific/Pago_Pago": "Pacifik/Pago_Pago",
"Pacific/Palau": "Pacifik/Palau",
"Pacific/Pitcairn": "Pacifik/Pitcairn",
"Pacific/Pohnpei": "Pacifik/Pohnpei",
"Pacific/Ponape": "Pacifik/Ponape",
"Pacific/Port_Moresby": "Pacifik/Port_Moresby",
"Pacific/Rarotonga": "Pacifik/Rarotonga",
"Pacific/Saipan": "Pacifik/Saipan",
"Pacific/Samoa": "Pacifik/Samoa",
"Pacific/Tahiti": "Pacifik/Tahiti",
"Pacific/Tarawa": "Pacifik/Tarawa",
"Pacific/Tongatapu": "Pacifik/Tongatapu",
"Pacific/Truk": "Pacific/Truk",
"Pacific/Wake": "Pacifik/Probudite se",
"Pacific/Wallis": "Pacifik/Wallis",
"Pacific/Yap": "Pacific/Yap",
"Poland": "Poljska",
"Portugal": "Portugal",
"ROC": "ROC",
"ROK": "ROK",
"Singapore": "Singapur",
"Turkey": "Turska",
"UCT": "UCT",
"US/Alaska": "SAD/Aljaska",
"US/Aleutian": "SAD/Aleutski",
"US/Arizona": "SAD/Arizona",
"US/Central": "SAD/Central",
"US/East-Indiana": "SAD/Istočna Indiana",
"US/Eastern": "SAD/Istočni",
"US/Hawaii": "SAD/Havaji",
"US/Indiana-Starke": "SAD/Indiana-Starke",
"US/Michigan": "SAD/Michigan",
"US/Mountain": "US/Planina",
"US/Pacific": "SAD/Pacifik",
"US/Samoa": "SAD/Samoa",
"UTC": "UTC",
"Universal": "Univerzalno",
"W-SU": "W-SU",
"WET": "MOKRO",
"Zulu": "Zulu",
}