import React from 'react'
import { Item } from 'native-base'
import CustomTextInput from '../common/CustomTextInput'

class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { value, onChange, textInputProps, style } = this.props
    return (
      <Item>
        <CustomTextInput
          // style={[styles.input]}
          selectionColor="#263238"
          value={value || ''}
          onChangeText={onChange}
          {...textInputProps}
          placeholder=""
          style={{ ...style }}
        />
      </Item>
    )
  }
}

export default PhoneNumberInput
