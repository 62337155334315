import { Container, Content, List, ListItem } from 'native-base'
import React, { Component } from 'react'
import { Text } from 'react-native'
import styles from './sideBar/styles'

class ChangeLanguage extends Component {
  state = {}

  render() {
    const {
      navigation,
      changeLanguage,
      selectedLang,
      screenProps,
      setLanguage,
      handleModalVisibility,
    } = this.props
    const { setLocale } = screenProps
    const fromLogin = navigation.getParam('fromLogin')
    const method = navigation.getParam('selectLanguage')
    let data = [
      {
        label: 'English',
        value: 'en_US',
      },
      {
        label: 'Français',
        value: 'fr_FR',
      },
      {
        label: 'Español',
        value: 'es_ES',
      },
      {
        label: '日本語',
        value: 'ja_JP',
      },
      {
        label: 'русский',
        value: 'ru_RU',
      },
    ]
    // const selectedLanguage = navigation.getParam('')
    return (
      <Container style={{ marginTop: fromLogin ? 0 : 0 }}>
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <List>
            {data.map((data) => (
              <ListItem
                selected={selectedLang === data.value ? true : false}
                style={{ height: 60 }}
                onPress={() => {
                  setTimeout(() => {
                    if (changeLanguage && method && method) {
                      changeLanguage(data.value, setLocale)
                      method(data.label)
                      navigation.goBack()
                    } else {
                      setLanguage(data.value, data.label)
                      handleModalVisibility()
                    }
                  }, 0)
                }}
              >
                <Text style={styles.text}>{data.label}</Text>
              </ListItem>
            ))}
          </List>
        </Content>
      </Container>
    )
  }
}

export default ChangeLanguage
