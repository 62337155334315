import React, { Component } from 'react'
import { Container, Content } from 'native-base'
import SubjectDetails from '../components/subjectDetails'
import StudyDetails from '../components/StudyDetails'
import VersionDetails from '../components/VersionDetails'

class SubjectDetailsPage extends Component {
  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //   title: t('myProfile'),
  // })

  render() {
    const { navigation, subject, studyInfo, versionInfo, screenProps } = this.props
    // console.log('Props in user data page::', subject)
    return (
      <Container>
        <Content>
          <SubjectDetails navigation={navigation} screenProps={screenProps} />

          <StudyDetails navigation={navigation} screenProps={screenProps} />

          <VersionDetails navigation={navigation} screenProps={screenProps} />
        </Content>
      </Container>
    )
  }
}

export default SubjectDetailsPage
