export default {"Africa/Abidjan": "Африка/Абиджан",
"Africa/Accra": "Африка/Акра",
"Africa/Addis_Ababa": "Африка/Адис_Абеба",
"Africa/Algiers": "Африка/Алжир",
"Africa/Asmara": "Африка/Асмара",
"Africa/Asmera": "Африка/Асмера",
"Africa/Bamako": "Африка/Бамако",
"Africa/Bangui": "Африка/Банги",
"Africa/Banjul": "Африка/Банджул",
"Africa/Bissau": "Африка/Бисау",
"Africa/Blantyre": "Африка/Блантайр",
"Africa/Brazzaville": "Африка/Бразавил",
"Africa/Bujumbura": "Африка/Бужумбура",
"Africa/Cairo": "Африка/Кайро",
"Africa/Casablanca": "Африка/Казабланка",
"Africa/Ceuta": "Африка/Сеута",
"Africa/Conakry": "Африка/Конакри",
"Africa/Dakar": "Африка/Дакар",
"Africa/Dar_es_Salaam": "Африка/Дар_ес_Салаам",
"Africa/Djibouti": "Африка/Джибути",
"Africa/Douala": "Африка/Дуала",
"Africa/El_Aaiun": "Африка/Ел_Аиун",
"Africa/Freetown": "Африка/Фрийтаун",
"Africa/Gaborone": "Африка/Габороне",
"Africa/Harare": "Африка/Хараре",
"Africa/Johannesburg": "Африка/Йоханесбург",
"Africa/Juba": "Африка/Джуба",
"Africa/Kampala": "Африка/Кампала",
"Africa/Khartoum": "Африка/Хартум",
"Africa/Kigali": "Африка/Кигали",
"Africa/Kinshasa": "Африка/Киншаса",
"Africa/Lagos": "Африка/Лагос",
"Africa/Libreville": "Африка/Либревил",
"Africa/Lome": "Африка/Ломе",
"Africa/Luanda": "Африка/Луанда",
"Africa/Lubumbashi": "Африка/Любумбаши",
"Africa/Lusaka": "Африка/Лусака",
"Africa/Malabo": "Африка/Малабо",
"Africa/Maputo": "Африка/Мапуто",
"Africa/Maseru": "Африка/Масеру",
"Africa/Mbabane": "Африка/Мбабане",
"Africa/Mogadishu": "Африка/Могадишу",
"Africa/Monrovia": "Африка/Монровия",
"Africa/Nairobi": "Африка/Найроби",
"Africa/Ndjamena": "Африка/Нджамена",
"Africa/Niamey": "Африка/Ниамей",
"Africa/Nouakchott": "Африка/Нуакшот",
"Africa/Ouagadougou": "Африка/Уагадугу",
"Africa/Porto-Novo": "Африка/Порто-Ново",
"Africa/Sao_Tome": "Африка/Сао_Томе",
"Africa/Timbuktu": "Африка/Тимбукту",
"Africa/Tripoli": "Африка/Триполи",
"Africa/Tunis": "Африка/Тунис",
"Africa/Windhoek": "Африка/Уиндхук",
"America/Adak": "Америка/Адак",
"America/Anchorage": "Америка/Анкоридж",
"America/Anguilla": "Америка/Ангила",
"America/Antigua": "Америка/Антигуа",
"America/Araguaina": "Америка/Арагуайна",
"America/Argentina/Buenos_Aires": "Америка/Аржентина/Буенос_Айрес",
"America/Argentina/Catamarca": "Америка/Аржентина/Катамарка",
"America/Argentina/ComodRivadavia": "Америка/Аржентина/Комодривадавия",
"America/Argentina/Cordoba": "Америка/Аржентина/Кордоба",
"America/Argentina/Jujuy": "Америка/Аржентина/Джухуй",
"America/Argentina/La_Rioja": "Америка/Аржентина/Ла_Риоха",
"America/Argentina/Mendoza": "Америка/Аржентина/Мендоса",
"America/Argentina/Rio_Gallegos": "Америка/Аржентина/Рио_Галегос",
"America/Argentina/Salta": "Америка/Аржентина/Салта",
"America/Argentina/San_Juan": "Америка/Аржентина/Сан_Хуан",
"America/Argentina/San_Luis": "Америка/Аржентина/Сан_Луис",
"America/Argentina/Tucuman": "Америка/Аржентина/Тукуман",
"America/Argentina/Ushuaia": "Америка/Аржентина/Ушуая",
"America/Aruba": "Америка/Аруба",
"America/Asuncion": "Америка/Асунсион",
"America/Atikokan": "Америка/Атикокан",
"America/Atka": "Америка/Атка",
"America/Bahia": "Америка/Баия",
"America/Bahia_Banderas": "Америка/Бахиа_Бандерас",
"America/Barbados": "Америка/Барбадос",
"America/Belem": "Америка/Белем",
"America/Belize": "Америка/Белиз",
"America/Blanc-Sablon": "Америка/Бланк-Саблон",
"America/Boa_Vista": "Америка/Боа_Виста",
"America/Bogota": "Америка/Богота",
"America/Boise": "Америка/Бойс",
"America/Buenos_Aires": "Америка/Буенос_Айрес",
"America/Cambridge_Bay": "Америка/Кеймбридж_Бей",
"America/Campo_Grande": "Америка/Кампо_Гранде",
"America/Cancun": "Америка/Канкун",
"America/Caracas": "Америка/Каракас",
"America/Catamarca": "Америка/Катамарка",
"America/Cayenne": "Америка/Кайен",
"America/Cayman": "Америка/Кайман",
"America/Chicago": "Америка/Чикаго",
"America/Chihuahua": "Америка/Чихуахуа",
"America/Ciudad_Juarez": "Америка/Сиудад_Хуарес",
"America/Coral_Harbour": "Америка/Корал_Харбър",
"America/Cordoba": "Америка/Кордоба",
"America/Costa_Rica": "Америка/Коста-Рика",
"America/Creston": "Америка/Крестън",
"America/Cuiaba": "Америка/Куяба",
"America/Curacao": "Америка/Кюрасао",
"America/Danmarkshavn": "Америка/Дания",
"America/Dawson": "Америка/Доусън",
"America/Dawson_Creek": "Америка/Доусън_Крийк",
"America/Denver": "Америка/Денвър",
"America/Detroit": "Америка/Детройт",
"America/Dominica": "Америка/Доминика",
"America/Edmonton": "Америка/Едмънтън",
"America/Eirunepe": "Америка/Ейрунепе",
"America/El_Salvador": "Америка/Ел_Салвадор",
"America/Ensenada": "Америка/Енсенада",
"America/Fort_Nelson": "Америка/Форт_Нелсън",
"America/Fort_Wayne": "Америка/Форт_Уейн",
"America/Fortaleza": "Америка/Форталеза",
"America/Glace_Bay": "Америка/Глейс_Бей",
"America/Godthab": "Америка/Годтаб",
"America/Goose_Bay": "Америка/Гоз_Бей",
"America/Grand_Turk": "Америка/Гранд_Турк",
"America/Grenada": "Америка/Гренада",
"America/Guadeloupe": "Америка/Гваделупа",
"America/Guatemala": "Америка/Гватемала",
"America/Guayaquil": "Америка/Гуаякил",
"America/Guyana": "Америка/Гвиана",
"America/Halifax": "Америка/Халифакс",
"America/Havana": "Америка/Хавана",
"America/Hermosillo": "Америка/Ермосило",
"America/Indiana/Indianapolis": "Америка/Индиана/Индианаполис",
"America/Indiana/Knox": "Америка/Индиана/Нокс",
"America/Indiana/Marengo": "Америка/Индиана/Маренго",
"America/Indiana/Petersburg": "Америка/Индиана/Петербург",
"America/Indiana/Tell_City": "Америка/Индиана/Тел_Сити",
"America/Indiana/Vevay": "Америка/Индиана/Вевей",
"America/Indiana/Vincennes": "Америка/Индиана/Винсен",
"America/Indiana/Winamac": "Америка/Индиана/Уинамак",
"America/Indianapolis": "Америка/Индианаполис",
"America/Inuvik": "Америка/Инувик",
"America/Iqaluit": "Америка/Икалуит",
"America/Jamaica": "Америка/Ямайка",
"America/Jujuy": "Америка/Джуюй",
"America/Juneau": "Америка/Джуно",
"America/Kentucky/Louisville": "Америка/Кентъки/Луисвил",
"America/Kentucky/Monticello": "Америка/Кентъки/Монтичело",
"America/Knox_IN": "Америка/Нокс_ин",
"America/Kralendijk": "Америка/Кралендайк",
"America/La_Paz": "Америка/Ла_Пас",
"America/Lima": "Америка/Лима",
"America/Los_Angeles": "Америка/Лос_Анджелис",
"America/Louisville": "Америка/Луисвил",
"America/Lower_Princes": "Америка/Долни принцове",
"America/Maceio": "Америка/Масейо",
"America/Managua": "Америка/Манагуа",
"America/Manaus": "Америка/Манаус",
"America/Marigot": "Америка/Маригот",
"America/Martinique": "Америка/Мартиника",
"America/Matamoros": "Америка/Матаморос",
"America/Mazatlan": "Америка/Мазатлан",
"America/Mendoza": "Америка/Мендоса",
"America/Menominee": "Америка/Меномини",
"America/Merida": "Америка/Мерида",
"America/Metlakatla": "Америка/Метлакатла",
"America/Mexico_City": "Америка/Мексико_Сити",
"America/Miquelon": "Америка/Микелон",
"America/Moncton": "Америка/Монктън",
"America/Monterrey": "Америка/Монтерей",
"America/Montevideo": "Америка/Монтевидео",
"America/Montreal": "Америка/Монреал",
"America/Montserrat": "Америка/Монсерат",
"America/Nassau": "Америка/Насау",
"America/New_York": "Америка/Ню Йорк",
"America/Nipigon": "Америка/Нипигон",
"America/Nome": "Америка/Име",
"America/Noronha": "Америка/Норонха",
"America/North_Dakota/Beulah": "Америка/Северна Дакота/Беула",
"America/North_Dakota/Center": "Америка/Северна Дакота/Център",
"America/North_Dakota/New_Salem": "Америка/Северна Дакота/Ню_Салем",
"America/Nuuk": "Америка/Ню Йорк",
"America/Ojinaga": "Америка/Ожинага",
"America/Panama": "Америка/Панама",
"America/Pangnirtung": "Америка/Пангниртунг",
"America/Paramaribo": "Америка/Парамарибо",
"America/Phoenix": "Америка/Феникс",
"America/Port-au-Prince": "Америка/Порт-о-Пренс",
"America/Port_of_Spain": "Америка/Пристанище_Испания",
"America/Porto_Acre": "Америка/Порто_Акр",
"America/Porto_Velho": "Америка/Порто_Вельо",
"America/Puerto_Rico": "Америка/Пуерто_Рико",
"America/Punta_Arenas": "Америка/Пунта_Аренас",
"America/Rainy_River": "Америка/Рейни_Ривър",
"America/Rankin_Inlet": "Америка/Ранкин_Инлет",
"America/Recife": "Америка/Ресифи",
"America/Regina": "Америка/Реджина",
"America/Resolute": "Америка/Решителен",
"America/Rio_Branco": "Америка/Рио_Бранко",
"America/Rosario": "Америка/Росарио",
"America/Santa_Isabel": "Америка/Санта_Изабел",
"America/Santarem": "Америка/Сантарем",
"America/Santiago": "Америка/Сантяго",
"America/Santo_Domingo": "Америка/Санто_Доминго",
"America/Sao_Paulo": "Америка/Сао_Пауло",
"America/Scoresbysund": "Америка/Скорсбисунд",
"America/Shiprock": "Америка/Шипрок",
"America/Sitka": "Америка/Ситка",
"America/St_Barthelemy": "Америка/Сейнт Бартелеми",
"America/St_Johns": "Америка/Сент_Джонс",
"America/St_Kitts": "Америка/Стен_Китс",
"America/St_Lucia": "Америка/Сейнт Лусия",
"America/St_Thomas": "Америка/Сент_Томас",
"America/St_Vincent": "Америка/Сейнт Винсент",
"America/Swift_Current": "Америка/Swift_Current",
"America/Tegucigalpa": "Америка/Тегусигалпа",
"America/Thule": "Америка/Туле",
"America/Thunder_Bay": "Америка/Тъндър_Бей",
"America/Tijuana": "Америка/Тихуана",
"America/Toronto": "Америка/Торонто",
"America/Tortola": "Америка/Тортола",
"America/Vancouver": "Америка/Ванкувър",
"America/Virgin": "Америка/Вирджиния",
"America/Whitehorse": "Америка/Уайт хорс",
"America/Winnipeg": "Америка/Уинипег",
"America/Yakutat": "Америка/Якутат",
"America/Yellowknife": "Америка/Йелоунайф",
"Antarctica/Casey": "Антарктика/Кейси",
"Antarctica/Davis": "Антарктика/Дейвис",
"Antarctica/DumontDUrville": "Антарктика/Дюмондървил",
"Antarctica/Macquarie": "Антарктика/Макари",
"Antarctica/Mawson": "Антарктика/Маусън",
"Antarctica/McMurdo": "Антарктика/Макмърдо",
"Antarctica/Palmer": "Антарктика/Палмър",
"Antarctica/Rothera": "Антарктика/Ротера",
"Antarctica/South_Pole": "Антарктика/Южен Полюс",
"Antarctica/Syowa": "Антарктика/Сьова",
"Antarctica/Troll": "Антарктика/Трол",
"Antarctica/Vostok": "Антарктика/Восток",
"Arctic/Longyearbyen": "Арктика/Лонгйърбиен",
"Asia/Aden": "Азия/Аден",
"Asia/Almaty": "Азия/Алмати",
"Asia/Amman": "Азия/Аман",
"Asia/Anadyr": "Азия/Анадир",
"Asia/Aqtau": "Азия/Актау",
"Asia/Aqtobe": "Азия/Актобе",
"Asia/Ashgabat": "Азия/Ашхабад",
"Asia/Ashkhabad": "Азия/Ашхабад",
"Asia/Atyrau": "Азия/Атирау",
"Asia/Baghdad": "Азия/Багдад",
"Asia/Bahrain": "Азия/Бахрейн",
"Asia/Baku": "Азия/Баку",
"Asia/Bangkok": "Азия/Банкок",
"Asia/Barnaul": "Азия/Барнаул",
"Asia/Beirut": "Азия/Бейрут",
"Asia/Bishkek": "Азия/Бишкек",
"Asia/Brunei": "Азия/Бруней",
"Asia/Calcutta": "Азия/Калкута",
"Asia/Chita": "Азия/Чита",
"Asia/Choibalsan": "Азия/Чойбалсан",
"Asia/Chongqing": "Азия/Чунцин",
"Asia/Chungking": "Азия/Чункинг",
"Asia/Colombo": "Азия/Коломбо",
"Asia/Dacca": "Азия/Дака",
"Asia/Damascus": "Азия/Дамаск",
"Asia/Dhaka": "Азия/Дака",
"Asia/Dili": "Азия/Дили",
"Asia/Dubai": "Азия/Дубай",
"Asia/Dushanbe": "Азия/Душанбе",
"Asia/Famagusta": "Азия/Фамагуста",
"Asia/Gaza": "Азия/Газа",
"Asia/Harbin": "Азия/Харбин",
"Asia/Hebron": "Азия/Хеброн",
"Asia/Ho_Chi_Minh": "Азия/Хо_Чи_Мин",
"Asia/Hong_Kong": "Азия/Хонг Конг",
"Asia/Hovd": "Азия/Ховд",
"Asia/Irkutsk": "Азия/Иркутск",
"Asia/Istanbul": "Азия/Истанбул",
"Asia/Jakarta": "Азия/Джакарта",
"Asia/Jayapura": "Азия/Джаяпура",
"Asia/Jerusalem": "Азия/Йерусалим",
"Asia/Kabul": "Азия/Кабул",
"Asia/Kamchatka": "Азия/Камчатка",
"Asia/Karachi": "Азия/Карачи",
"Asia/Kashgar": "Азия/Кашгар",
"Asia/Kathmandu": "Азия/Катманду",
"Asia/Katmandu": "Азия/Катманду",
"Asia/Khandyga": "Азия/Хандига",
"Asia/Kolkata": "Азия/Калкута",
"Asia/Krasnoyarsk": "Азия/Красноярск",
"Asia/Kuala_Lumpur": "Азия/Куала_Лумпур",
"Asia/Kuching": "Азия/Кучинг",
"Asia/Kuwait": "Азия/Кувейт",
"Asia/Macao": "Азия/Макао",
"Asia/Macau": "Азия/Макао",
"Asia/Magadan": "Азия/Магадан",
"Asia/Makassar": "Азия/Макасар",
"Asia/Manila": "Азия/Манила",
"Asia/Muscat": "Азия/Мускат",
"Asia/Nicosia": "Азия/Никозия",
"Asia/Novokuznetsk": "Азия/Новокузнецк",
"Asia/Novosibirsk": "Азия/Новосибирск",
"Asia/Omsk": "Азия/Омск",
"Asia/Oral": "Азия/Орално",
"Asia/Phnom_Penh": "Азия/Пном_Пен",
"Asia/Pontianak": "Азия/Понтианак",
"Asia/Pyongyang": "Азия/Пхенян",
"Asia/Qatar": "Азия/Катар",
"Asia/Qostanay": "Азия/Костанай",
"Asia/Qyzylorda": "Азия/Кизилорда",
"Asia/Rangoon": "Азия/Рангун",
"Asia/Riyadh": "Азия/Рияд",
"Asia/Saigon": "Азия/Сайгон",
"Asia/Sakhalin": "Азия/Сахалин",
"Asia/Samarkand": "Азия/Самарканд",
"Asia/Seoul": "Азия/Сеул",
"Asia/Shanghai": "Азия/Шанхай",
"Asia/Singapore": "Азия/Сингапур",
"Asia/Srednekolymsk": "Азия/Среднеколимск",
"Asia/Taipei": "Азия/Тайпе",
"Asia/Tashkent": "Азия/Ташкент",
"Asia/Tbilisi": "Азия/Тбилиси",
"Asia/Tehran": "Азия/Техеран",
"Asia/Tel_Aviv": "Азия/Тел_Авив",
"Asia/Thimbu": "Азия/Тимбу",
"Asia/Thimphu": "Азия/Тимфу",
"Asia/Tokyo": "Азия/Токио",
"Asia/Tomsk": "Азия/Томск",
"Asia/Ujung_Pandang": "Азия/Уйунг_Панданг",
"Asia/Ulaanbaatar": "Азия/Улан Батор",
"Asia/Ulan_Bator": "Азия/Улан_Батор",
"Asia/Urumqi": "Азия/Урумчи",
"Asia/Ust-Nera": "Азия/Уст-Нера",
"Asia/Vientiane": "Азия/Виентян",
"Asia/Vladivostok": "Азия/Владивосток",
"Asia/Yakutsk": "Азия/Якутск",
"Asia/Yangon": "Азия/Янгон",
"Asia/Yekaterinburg": "Азия/Екатеринбург",
"Asia/Yerevan": "Азия/Ереван",
"Atlantic/Azores": "Атлантика/Азорските острови",
"Atlantic/Bermuda": "Атлантика/Бермудски острови",
"Atlantic/Canary": "Атлантически/Канарски",
"Atlantic/Cape_Verde": "Атлантик/Кап_Верде",
"Atlantic/Faeroe": "Атлантика/Ферьорски",
"Atlantic/Faroe": "Атлантически/Фарьорско",
"Atlantic/Jan_Mayen": "Атлантик/Ян_Майен",
"Atlantic/Madeira": "Атлантика/Мадейра",
"Atlantic/Reykjavik": "Атлантик/Рейкявик",
"Atlantic/South_Georgia": "Атлантика/Южна Джорджия",
"Atlantic/St_Helena": "Атлантика/Света Елена",
"Atlantic/Stanley": "Атлантик/Стенли",
"Australia/ACT": "Австралия/ACT",
"Australia/Adelaide": "Австралия/Аделаида",
"Australia/Brisbane": "Австралия/Бризбейн",
"Australia/Broken_Hill": "Австралия/Брокен_Хил",
"Australia/Canberra": "Австралия/Канбера",
"Australia/Currie": "Австралия/Къри",
"Australia/Darwin": "Австралия/Дарвин",
"Australia/Eucla": "Австралия/Евкла",
"Australia/Hobart": "Австралия/Хобарт",
"Australia/LHI": "Австралия/LHI",
"Australia/Lindeman": "Австралия/Линдеман",
"Australia/Lord_Howe": "Австралия/Лорд_Хау",
"Australia/Melbourne": "Австралия/Мелбърн",
"Australia/NSW": "Австралия/NSW",
"Australia/North": "Австралия/Север",
"Australia/Perth": "Австралия/Пърт",
"Australia/Queensland": "Австралия/Куинсланд",
"Australia/South": "Австралия/Юг",
"Australia/Sydney": "Австралия/Сидни",
"Australia/Tasmania": "Австралия/Тасмания",
"Australia/Victoria": "Австралия/Виктория",
"Australia/West": "Австралия/Запад",
"Australia/Yancowinna": "Австралия/Янкоуина",
"Brazil/Acre": "Бразилия/Акра",
"Brazil/DeNoronha": "Бразилия/Деноронха",
"Brazil/East": "Бразилия/Изток",
"Brazil/West": "Бразилия/Запад",
"CET": "ТОВА",
"CST6CDT": "ЦСТ 6 ЦДТ",
"Canada/Atlantic": "Канада/Атлантик",
"Canada/Central": "Канада/Централен",
"Canada/Eastern": "Канада/Източна",
"Canada/Mountain": "Канада/Планина",
"Canada/Newfoundland": "Канада/Нюфаундленд",
"Canada/Pacific": "Канада/Тихи океан",
"Canada/Saskatchewan": "Канада/Саскачеван",
"Canada/Yukon": "Канада/Юкон",
"Chile/Continental": "Чили/Континентал",
"Chile/EasterIsland": "Чили/Източен остров",
"Cuba": "Куба",
"EET": "ЯДЕ",
"EST": "ЕСТ",
"EST5EDT": "ЕСТ5ЕДТ",
"Egypt": "Египет",
"Eire": "Ейр",
"Etc/GMT": "и т.н. Гмт",
"Etc/GMT+0": "и т.н. /GMT+0",
"Etc/GMT+1": "и т.н. Гмт+1",
"Etc/GMT+10": "и т.н. Гмт+10",
"Etc/GMT+11": "и т.н. /GMT+11",
"Etc/GMT+12": "и т.н. /GMT+12",
"Etc/GMT+2": "и т.н. Гмт+2",
"Etc/GMT+3": "и т.н. Гмт+3",
"Etc/GMT+4": "и т.н. /GMT+4",
"Etc/GMT+5": "и т.н. /GMT+5",
"Etc/GMT+6": "и др/GMT+6",
"Etc/GMT+7": "и т.н. /GMT+7",
"Etc/GMT+8": "и т.н. /GMT+8",
"Etc/GMT+9": "и т.н. /GMT+9",
"Etc/GMT-0": "и т.н. Гмт-0",
"Etc/GMT-1": "и т.н. Гмт-1",
"Etc/GMT-10": "и др/GMT-10",
"Etc/GMT-11": "и др/ГМТ-11",
"Etc/GMT-12": "и др/ГМТ-12",
"Etc/GMT-13": "и др/GMT-13",
"Etc/GMT-14": "и др/ГМТ-14",
"Etc/GMT-2": "И др/ГМТ-2",
"Etc/GMT-3": "и др/ГМТ-3",
"Etc/GMT-4": "и др/ГМТ-4",
"Etc/GMT-5": "и др/GMT-5",
"Etc/GMT-6": "и др/GMT-6",
"Etc/GMT-7": "и др/GMT-7",
"Etc/GMT-8": "и др/GMT-8",
"Etc/GMT-9": "и др/GMT-9",
"Etc/GMT0": "и т.н. /GMT0",
"Etc/Greenwich": "И т.н. гринуич",
"Etc/UCT": "и т.н. /UCT",
"Etc/UTC": "и т.н. UTC",
"Etc/Universal": "И т.н. универсален",
"Etc/Zulu": "И т.н. зулу",
"Europe/Amsterdam": "Европа/Амстердам",
"Europe/Andorra": "Европа/Андора",
"Europe/Astrakhan": "Европа/Астрахан",
"Europe/Athens": "Европа/Атина",
"Europe/Belfast": "Европа/Белфаст",
"Europe/Belgrade": "Европа/Белград",
"Europe/Berlin": "Европа/Берлин",
"Europe/Bratislava": "Европа/Братислава",
"Europe/Brussels": "Европа/Брюксел",
"Europe/Bucharest": "Европа/Букурещ",
"Europe/Budapest": "Европа/Будапеща",
"Europe/Busingen": "Европа/Бусинген",
"Europe/Chisinau": "Европа/Кишинев",
"Europe/Copenhagen": "Европа/Копенхаген",
"Europe/Dublin": "Европа/Дъблин",
"Europe/Gibraltar": "Европа/Гибралтар",
"Europe/Guernsey": "Европа/Гърнзи",
"Europe/Helsinki": "Европа/Хелзинки",
"Europe/Isle_of_Man": "Европа/Остров_Човек",
"Europe/Istanbul": "Европа/Истанбул",
"Europe/Jersey": "Европа/Джърси",
"Europe/Kaliningrad": "Европа/Калининград",
"Europe/Kiev": "Европа/Киев",
"Europe/Kirov": "Европа/Киров",
"Europe/Kyiv": "Европа/Киев",
"Europe/Lisbon": "Европа/Лисабон",
"Europe/Ljubljana": "Европа/Любляна",
"Europe/London": "Европа/Лондон",
"Europe/Luxembourg": "Европа/Люксембург",
"Europe/Madrid": "Европа/Мадрид",
"Europe/Malta": "Европа/Малта",
"Europe/Mariehamn": "Европа/Мариехамн",
"Europe/Minsk": "Европа/Минск",
"Europe/Monaco": "Европа/Монако",
"Europe/Moscow": "Европа/Москва",
"Europe/Nicosia": "Европа/Никозия",
"Europe/Oslo": "Европа/Осло",
"Europe/Paris": "Европа/Париж",
"Europe/Podgorica": "Европа/Подгорица",
"Europe/Prague": "Европа/Прага",
"Europe/Riga": "Европа/Рига",
"Europe/Rome": "Европа/Рим",
"Europe/Samara": "Европа/Самара",
"Europe/San_Marino": "Европа/Сан_Марино",
"Europe/Sarajevo": "Европа/Сараево",
"Europe/Saratov": "Европа/Саратов",
"Europe/Simferopol": "Европа/Симферопол",
"Europe/Skopje": "Европа/Скопие",
"Europe/Sofia": "Европа/София",
"Europe/Stockholm": "Европа/Стокхолм",
"Europe/Tallinn": "Европа/Талин",
"Europe/Tirane": "Европа/Тиран",
"Europe/Tiraspol": "Европа/Тираспол",
"Europe/Ulyanovsk": "Европа/Уляновск",
"Europe/Uzhgorod": "Европа/Ужгород",
"Europe/Vaduz": "Европа/Вадуц",
"Europe/Vatican": "Европа/Ватикан",
"Europe/Vienna": "Европа/Виена",
"Europe/Vilnius": "Европа/Вилнюс",
"Europe/Volgograd": "Европа/Волгоград",
"Europe/Warsaw": "Европа/Варшава",
"Europe/Zagreb": "Европа/Загреб",
"Europe/Zaporozhye": "Европа/Запорожие",
"Europe/Zurich": "Европа/Цюрих",
"GB": "ГБ",
"GB-Eire": "ГБ-Ейър",
"GMT": "ГМТ",
"GMT+0": "ГМТ+0",
"GMT-0": "ГМТ-0",
"GMT0": "ГМТ0",
"Greenwich": "Гринуич",
"HST": "ХСТ",
"Hongkong": "Хонконг",
"Iceland": "Исландия",
"Indian/Antananarivo": "Индийски/Антананариво",
"Indian/Chagos": "Индийски/Чагос",
"Indian/Christmas": "Индийски/Коледа",
"Indian/Cocos": "Индийски/Кокос",
"Indian/Comoro": "Индийски/Коморо",
"Indian/Kerguelen": "Индийски/Кергуелен",
"Indian/Mahe": "Индийски/Махе",
"Indian/Maldives": "Индийски/Малдиви",
"Indian/Mauritius": "Индийски/Мавриций",
"Indian/Mayotte": "Индийски/Майот",
"Indian/Reunion": "Индийски/Реюнион",
"Iran": "Иран",
"Israel": "Израел",
"Jamaica": "Ямайка",
"Japan": "Япония",
"Kwajalein": "Квайалейн",
"Libya": "Либия",
"MET": "СРЕЩНА СЕ",
"MST": "МСТ",
"MST7MDT": "МСТ7МДТ",
"Mexico/BajaNorte": "Мексико/Байанорте",
"Mexico/BajaSur": "Мексико/Баясур",
"Mexico/General": "Мексико/Генерал",
"NZ": "НЗ",
"NZ-CHAT": "NZ-ЧАТ",
"Navajo": "Навахо",
"PRC": "ПРК",
"PST8PDT": "ПСТ8ПДТ",
"Pacific/Apia": "Тихия океан/Апия",
"Pacific/Auckland": "Тихия океан/Окланд",
"Pacific/Bougainville": "Тихия океан/Бугенвил",
"Pacific/Chatham": "Тихия океан/Чатам",
"Pacific/Chuuk": "Тихия океан/Чук",
"Pacific/Easter": "Тихоокеански/Великден",
"Pacific/Efate": "Тихия океан/Ефате",
"Pacific/Enderbury": "Тихия океан/Ендербъри",
"Pacific/Fakaofo": "Тихия океан/Факаофо",
"Pacific/Fiji": "Тихия океан/Фиджи",
"Pacific/Funafuti": "Тихия океан/Фунафути",
"Pacific/Galapagos": "Тихия океан/Галапагос",
"Pacific/Gambier": "Тихоокеански/Гамбиер",
"Pacific/Guadalcanal": "Тихия океан/Гуадалканал",
"Pacific/Guam": "Тихия океан/Гуам",
"Pacific/Honolulu": "Тихия океан/Хонолулу",
"Pacific/Johnston": "Тихия океан/Джонстън",
"Pacific/Kanton": "Тихия океан/Кантон",
"Pacific/Kiritimati": "Тихия океан/Киритимати",
"Pacific/Kosrae": "Тихия океан/Косрае",
"Pacific/Kwajalein": "Тихия океан/Кваджалейн",
"Pacific/Majuro": "Тихия океан/Майуро",
"Pacific/Marquesas": "Тихия океан/Маркизи",
"Pacific/Midway": "Тихия океан/Мидуей",
"Pacific/Nauru": "Тихия океан/Науру",
"Pacific/Niue": "Тихия океан/Ниуе",
"Pacific/Norfolk": "Тихия океан/Норфолк",
"Pacific/Noumea": "Тихия океан/Нумеа",
"Pacific/Pago_Pago": "Тихия океан/Паго_Паго",
"Pacific/Palau": "Тихия океан/Палау",
"Pacific/Pitcairn": "Тихия океан/Питкерн",
"Pacific/Pohnpei": "Тихия океан/Понпей",
"Pacific/Ponape": "Тихия океан/Понапе",
"Pacific/Port_Moresby": "Тихия океан/Порт_Морсби",
"Pacific/Rarotonga": "Тихия океан/Раротонга",
"Pacific/Saipan": "Тихия океан/Сайпан",
"Pacific/Samoa": "Тихия океан/Самоа",
"Pacific/Tahiti": "Тихия океан/Таити",
"Pacific/Tarawa": "Тихия океан/Тарава",
"Pacific/Tongatapu": "Тихия океан/Тонгатапу",
"Pacific/Truk": "Тихия океан/Трук",
"Pacific/Wake": "Тихоокеански/Събуди се",
"Pacific/Wallis": "Тихия океан/Уолис",
"Pacific/Yap": "Тихия океан/Яп",
"Poland": "Полша",
"Portugal": "Португалия",
"ROC": "РОК",
"ROK": "РОК",
"Singapore": "Сингапур",
"Turkey": "Турция",
"UCT": "УКТ",
"US/Alaska": "САЩ/Аляска",
"US/Aleutian": "САЩ/Алеутски",
"US/Arizona": "САЩ/Аризона",
"US/Central": "Щат/Централен",
"US/East-Indiana": "САЩ/Източна Индиана",
"US/Eastern": "САЩ/Източен",
"US/Hawaii": "САЩ/Хавай",
"US/Indiana-Starke": "Щат/Индиана-Старк",
"US/Michigan": "САЩ/Мичиган",
"US/Mountain": "САЩ/Планина",
"US/Pacific": "САЩ/Тихоокеански",
"US/Samoa": "САЩ/Самоа",
"UTC": "UTC",
"Universal": "Универсален",
"W-SU": "В-СУ",
"WET": "МОКЪР",
"Zulu": "Зулу",
}