import _ from 'lodash'
import { createSelector } from 'reselect'
import { getSubject } from './commonSelector'


export const getSubjectPhoneNo = createSelector([getSubject], (subject) => {
  return _.get(subject, 'phoneNo', null)
})

export const getSubjectId = createSelector([getSubject], (subject) => {
  return _.get(subject, 'id', null)
})

export const getSubjectLanguage = createSelector([getSubject], (subject) => {
  return _.get(subject, 'language', null)
})