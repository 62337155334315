import { FontAwesome, Foundation, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import * as Linking from 'expo-linking'
import _ from 'lodash'
import { Card } from 'native-base'
import React, { Component } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import appConstants from '../constants/appConstants'
import constants from '../constants/constants'
import SubjectTimezone from '../utils/dateFormatUtils'
import { getActualCallDuration } from './scheduledCalls/callUtils'
import listStyles from '../styles/listStyles'
import moment from 'moment-timezone'

const getColor = (status) => {
  switch (status) {
    case constants.CALL_STATUS.STARTED:
      return {
        backgroundColor: '#FFBB3B',
        iconColor: '#ffffff',
      }
    case constants.CALL_STATUS.COMPLETED:
      return {
        backgroundColor: '#4caf50',
        iconColor: '#ffffff',
      }
    case constants.CALL_STATUS.SCHEDULED:
      return {
        backgroundColor: '#4A58A2',
        iconColor: '#ffffff',
      }
    default:
      break
  }
}

const getIsParticipantJoined = (subjectId, call) => {
  if (call.status == constants.CALL_STATUS.COMPLETED) {
    const subject = _.find(call.participants, (p) =>
      _.isEqual(_.toUpper(p.participantPkId), _.toUpper(subjectId))
    )
    if (
      subject.status == constants.PARTICIPANT_STATUS.JOINED ||
      subject.status == constants.PARTICIPANT_STATUS.LEFT
    ) {
      return true
    }
    return false
  }
  return true
}

const navigateToCallDetails = (e, navigation, call, timeZone, subjectId, updateSelectedCall) => {
  e.preventDefault()
  updateSelectedCall(call)
  navigation.navigate('CallDetails', {
    timeZone,
    subjectId,
  })
}
export default class PhoneCallCard extends Component {
  render() {
    const { call, timeZone, subjectId, navigation, updateSelectedCall, deviceTokenData, t } =
      this.props
    const colors = getColor(call.status)
    const isParticipantJoined = getIsParticipantJoined(subjectId, call)
    return (
      <Card style={{ flex: 1, borderRadius: 5 }}>
        <View style={{ flexDirection: 'row', borderRadius: 5 }}>
          <TouchableOpacity
            style={{
              flex: 1,
              backgroundColor: colors.backgroundColor,
              justifyContent: 'center',
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              padding: 5,
            }}
            onPress={(e) =>
              navigateToCallDetails(e, navigation, call, timeZone, subjectId, updateSelectedCall)
            }
            testID={`phone-call-${subjectId}-${call.callType}-btn`}
            accessible={true}
          >
            {call.callType === 'VIDEO' ? (
              <FontAwesome
                name="video-camera"
                style={{ alignSelf: 'center', marginLeft: 10 }}
                color={colors.iconColor}
                size={20}
              />
            ) : (
              <Foundation
                name="telephone"
                style={{ alignSelf: 'center', marginLeft: 10 }}
                color={colors.iconColor}
                size={30}
              />
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              textAlign: 'left',
              flex: 5,
              backgroundColor: '#ffffff',
              padding: 5,
              paddingVertical: 10,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
            flexDirection="row"
            onPress={(e) =>
              navigateToCallDetails(e, navigation, call, timeZone, subjectId, updateSelectedCall)
            }
            testID={`phone-call-${subjectId}-${call.callType}-btn`}
            accessible={true}
          >
            <View style={{ flex: 9 }}>
              <Text
                // style={{
                //   color: '#212121',
                //   paddingLeft: 10,
                //   fontSize: 16,
                //   paddingBottom: 5,
                //   fontFamily: 'Inter',
                // }}
                style={listStyles.textStyle}
              >
                {call.title}
              </Text>
              {call.appStudyVisit && (
                <Text style={listStyles.subTextStyle}>
                  {t('Visit')}: {call.appStudyVisit.visitName}
                </Text>
              )}

              {/* <Text style={{color:'white',fontSize:11.5,paddingLeft:10}}>{moment(call.date).format("DD-MMM-YYYY")} {call.time} {moment(call.date).tz(timeZone).format('z')} </Text> */}
              <Text
                // style={{
                //   color: '#616161',
                //   fontSize: 9,
                //   paddingLeft: 10,
                //   fontFamily: 'Inter',
                // }}
                style={listStyles.subTextStyle}
              >
                {t('Status')}:{' '}
                {call.status == constants.CALL_STATUS.COMPLETED && !isParticipantJoined
                  ? constants.CALL_STATUS.ENDED
                  : call.status}
              </Text>
              {constants.CALL_STATUS.SCHEDULED == call.status && (
                <Text
                  // style={{
                  //   color: '#616161',
                  //   fontSize: 9,
                  //   paddingLeft: 10,
                  //   fontFamily: 'Inter',
                  // }}
                  style={listStyles.subTextStyle}
                >
                  {t('ScheduledTime')}: {moment(call.date).format('DD-MMM-YYYY hh:mm A')}{' '}
                  {moment(call.date).tz(timeZone).format('z')}
                </Text>
              )}
              {constants.CALL_STATUS.SCHEDULED == call.status && (
                <Text
                  // style={{
                  //   color: '#616161',
                  //   fontSize: 9,
                  //   paddingLeft: 10,
                  //   fontFamily: 'Inter',
                  // }}
                  style={listStyles.subTextStyle}
                >
                  {t('ScheduledDuration')}:{' '}
                  {Math.floor(call.duration / 60) == 0
                    ? ''
                    : `${Math.floor(call.duration / 60)} ${call.duration > 119 ? 'hrs' : 'hr'} `}
                  {call.duration % 60 == 0
                    ? ''
                    : `${call.duration % 60} ${call.duration % 60 == 1 ? 'min' : 'mins'}`}
                </Text>
              )}
              {(constants.CALL_STATUS.COMPLETED == call.status ||
                constants.CALL_STATUS.STARTED == call.status) && (
                <Text
                  // style={{
                  //   color: '#616161',
                  //   fontSize: 9,
                  //   fontFamily: 'Inter',
                  //   paddingLeft: 10,
                  // }}
                  style={listStyles.subTextStyle}
                >
                  {t('StartedOn')}:{'\n'}
                  {'\n'}
                  <SubjectTimezone
                    dateTime={call.startTime}
                    format="DD-MMM-YYYY hh:mm:ss A z"
                  />{' '}
                </Text>
              )}
              {constants.CALL_STATUS.COMPLETED == call.status && (
                <Text
                  // style={{
                  //   color: '#616161',
                  //   fontSize: 9,
                  //   paddingLeft: 10,
                  //   fontFamily: 'Inter',
                  // }}
                  style={listStyles.subTextStyle}
                >
                  {t('Duration')}: {getActualCallDuration(call)}
                </Text>
              )}
            </View>
            <View
              style={
                constants.CALL_STATUS.COMPLETED == call.status && !isParticipantJoined
                  ? { flex: 1, paddingRight: 5 }
                  : { flex: 1, justifyContent: 'center', paddingRight: 5 }
              }
            >
              {constants.CALL_STATUS.COMPLETED == call.status && !isParticipantJoined && (
                <MaterialCommunityIcons name="call-missed" size={20} color="red" />
              )}
              <MaterialIcons
                name="navigate-next"
                size={24}
                color="#000000"
                style={{ flexDirection: 'column' }}
              />
            </View>
          </TouchableOpacity>
        </View>
      </Card>
    )
  }
}
