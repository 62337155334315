import { combineReducers } from 'redux'
import chat from './chat'
import crfData from './crfData'
import site from './site'
import appStatus from './storeAppStatus'
import users from './users'
import subject from './subject'
import studyMetaData from './studyMetaData'
import session from './session'
import syncQueue from './syncQueue'
import trainingForms from './trainingForms'


const AppReducer = combineReducers({
  users,
  subject,
  site,
  crfData,
  studyMetaData,
  appStatus,
  chat,
  session,
  syncQueue,
  trainingForms
})

export default AppReducer
