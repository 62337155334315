import React from "react";
import { PixelRatio, Platform, StyleSheet, View } from "react-native";
import _ from 'lodash';
import constants from "../../../constants/constants";
import PropTypes from 'prop-types';
import { getScalePosition, isInSequence } from "../../scales/utils";
import { alignments } from "../../scales/alignments";
import VerticalLabels from "./VerticalLabels";
import VerticalEdgeLabel from "../../scales/NRS/VerticalEdgeLabel";
import { fontScale } from "../../styles/fontResizer";
import BasicVASScale from "./BasicVASScale";

const {SCALE_POSITION} = constants

const VerticalScaleContainer = (props) => {
    const {scaleVerticalAlignment, min, max, step, value, disabled, id, isPercentage, onValueChange, orientation, options, scaleProps,fontConfig, 
      minimumFontSize, isCalculated, labelsData, labelsFlex} = props
    
    const scalePosition = getScalePosition(scaleVerticalAlignment)
    let noFlexStyle = {}
    if((labelsFlex.left=== 0 || labelsFlex.right === 0) && scaleVerticalAlignment!== SCALE_POSITION.CENTRE_OF_THE_PAGE ){
      noFlexStyle = {width:0}
    }

    const getMarginRight = () => {
      const largestDigitNumber = max.toString()
      const symbol = isPercentage?'%':''
      const largestDigitLength = (largestDigitNumber+symbol).length
      return Math.ceil(largestDigitLength * (fontConfig.requiredFontForMarks * 0.9)) < 30 ? 30 : Math.ceil(largestDigitLength * (fontConfig.requiredFontForMarks * 0.9))
    }
    const hasStartOrEndLabel=  !_.isEmpty(_.filter(options, loLabel => (loLabel?.labelPlacement === 'LEFT' || loLabel.labelPlacement === 'RIGHT' ) && (Number(loLabel.position) === Number(min) || Number(loLabel.position) === Number(max))));

    return ( 
        <View style={[styles.wrapperContainer, {justifyContent: scalePosition}]}>
      {isCalculated && (  <VerticalEdgeLabel 
          labelsFlex={labelsFlex}
          value={_.filter(options, label => label.labelPlacement === 'TOP' && isInSequence(Number(label.position), min, max, step))?.[0]?.value}
          isTopLabel={true}
          scalePosition={scaleVerticalAlignment}
          options={options}
          transformedFontSize={minimumFontSize}
          disabled={disabled}
          id={id}
          isVAS={true}
        />)}
        <View style={[styles.container, {justifyContent: scalePosition}]}>
           <View style={[noFlexStyle, { flex: labelsFlex.left }]}>
           {isCalculated && ( <VerticalLabels 
                    labels = {_.filter(options, op => op.labelPlacement === 'LEFT')}
                    start={min}
                    end={max}
                    step={step}
                    labelFlex={labelsFlex.left}
                    isPercentage={isPercentage}
                    orientation={orientation}
                    disabled={disabled}
                    id={id}
                    scaleProps={scaleProps}
                    transformedFont={minimumFontSize}
                    labelsData={labelsData}
                    hasStartOrEndLabel={hasStartOrEndLabel}
                />)}
          </View> 
          <View style={Platform.OS === "web" ? 
          {marginLeft: 5, marginRight: getMarginRight() } : {width: 60,marginLeft: 5, marginRight: getMarginRight() }}>
            <BasicVASScale 
            onValueChange={onValueChange}
            value={value}
            orientation={orientation}
            {...props}
            />
          </View>
        <View style={[noFlexStyle, {flex: labelsFlex.right}]}>
        {isCalculated && ( <VerticalLabels 
                    labels = {_.filter(options, op => op.labelPlacement === 'RIGHT')}
                    start={min}
                    end={max}
                    step={step}
                    labelFlex={labelsFlex.right}
                    isPercentage={isPercentage}
                    orientation={orientation}
                    disabled={disabled}
                    id={id}
                    scaleProps={scaleProps}
                    transformedFont={minimumFontSize}
                    labelsData={labelsData}
                    hasStartOrEndLabel={hasStartOrEndLabel}
                />)}
        </View>
        </View>
        {isCalculated && (<VerticalEdgeLabel 
          labelsFlex={labelsFlex}
          value={_.filter(options, label => label.labelPlacement === 'BOTTOM' && isInSequence(Number(label.position), min, max, step))?.[0]?.value}
          isTopLabel={false}
          scalePosition={scaleVerticalAlignment}
          options={options}
          transformedFontSize={minimumFontSize}
          startValueMarginBtm = {0}
          disabled={disabled}
          id={id}
          isVAS={true}
        />)}
        </View>
      );
}
const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    wrapperContainer: {
      flex: 1,
      flexDirection: 'column',
      paddingBottom: 50,
    }
   
});

VerticalScaleContainer.defaultProps = {
  isPercentage: false,
  min: 0,
  max: 10,
  step: 1,
  value: null,
  onValueChange: () => null,
  disabled: false,
  options: [],
  id: null,
  scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE,
  orientation: alignments.VERTICAL,
  scaleProps: {
    widthOfBox: 0,
    length: 0,
    scaleWidth: 0,
    fontSize: 10/(PixelRatio.getFontScale()),
    scale: 1,
    isCalculated: false
  },
  fontConfig: {
    requiredFontForMarks: 10/PixelRatio.getFontScale(),
    numberOfTerms: 0,
  },
  labelsData: {}, 
  minimumFontSize: fontScale(10),
  labelsFlex: {
    left: 0,
    right: 0,
  }
}

VerticalScaleContainer.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(Object),
  isPercentage: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  scaleVerticalAlignment: PropTypes.string,
  orientation: PropTypes.string,
  scaleProps: PropTypes.shape({
    widthOfBox: PropTypes.number,
    length: PropTypes.number,
    scaleWidth: PropTypes.number,
    fontSize: PropTypes.number,
    scale: PropTypes.number,
  }),
  fontConfig: PropTypes.shape({
    requiredFontForMarks: PropTypes.number,
    numberOfTerms: PropTypes.number,
  }),
  labelsData: PropTypes.instanceOf(Object), 
  labelsFlex: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  minimumFontSize: PropTypes.number,
  isCalculated: PropTypes.bool,
}
  
export default VerticalScaleContainer