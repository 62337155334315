import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  visitLabelContainer: {
    borderWidth: 1,
    borderColor: '#ffb84d',
    backgroundColor: '#ffb84d',
    flex: 0.1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
  },
  visitText: {
    fontSize: 16,
    color: '#ffffff',
    fontFamily: 'Inter',
  },
})
