import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import { generatePercentage } from '../../utils/util'
import _ from 'lodash'

export default function Header(props) {
  const { options, uiSchema, selfScored } = props
  const borderStyle = {
    borderColor: 'grey',
    borderWidth: StyleSheet.hairlineWidth,
    paddingTop: 15,
    paddingBottom: 15,
  }
  // const optionWidth = (50 /options.length)*100;
  const fieldBorderWidth =
    uiSchema.styles && uiSchema.styles.field && uiSchema.styles.field.containerBorder
      ? StyleSheet.hairlineWidth
      : 0
  const optionBorderWidth =
    uiSchema.styles && uiSchema.styles.options && uiSchema.styles.options.containerBorder
      ? StyleSheet.hairlineWidth
      : 0
  const fieldNameWidth = parseInt(_.get(uiSchema.properties, 'fieldNameWidth') || 4)
  const fieldOptionWidth = parseInt(_.get(uiSchema.properties, 'fieldOptionWidth') || 6)
  const total = fieldNameWidth + fieldOptionWidth

  const getOrderedComponents = (score, value) => {
    if (
      uiSchema.properties.commonOptions &&
      uiSchema.properties.commonOptionsScore &&
      selfScored
    ) {
      const labelOrder = uiSchema.properties.labelOrder
      if (labelOrder[0] === 'optionScore') {
        return (
          <React.Fragment>
            <Text style={{ textAlign: 'center' }}>{score}</Text>
            <Text style={{ textAlign: 'center' }}>{value}</Text>
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          <Text style={{ textAlign: 'center' }}>{value}</Text>
          <Text style={{ textAlign: 'center' }}>{score}</Text>
        </React.Fragment>
      )
    } else if (uiSchema.properties.commonOptions) {
      return <Text style={{ textAlign: 'center' }}>{value}</Text>
    } else if (uiSchema.properties.commonOptionsScore && selfScored) {
      return <Text style={{ textAlign: 'center' }}>{score}</Text>
    }
  }
  return (
    // <View style={{marginTop:5}}>
    //   <H3>{title}</H3>
    // </View>
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View
        style={{
          flex: generatePercentage(fieldNameWidth, total),
          ...borderStyle,
          padding: 2,
          borderWidth: fieldBorderWidth,
        }}
      >
        <Text>{`    `}</Text>
      </View>
      <View
        style={{
          flex: generatePercentage(fieldOptionWidth, total),
          flexDirection: 'row',
        }}
      >
        {options.map((option, index) => {
          const width = 100 / options.length
          return (
            <View
              key={`header-${index}`}
              style={{
                width: `${width}%`,
                ...borderStyle,
                alignItems: 'center',
                borderWidth: optionBorderWidth,
              }}
            >
              {getOrderedComponents(option.score, option.value)}
            </View>
          )
        })}
      </View>
    </View>
  )
}
