import { MaterialIcons, FontAwesome } from '@expo/vector-icons'
import axios from 'axios'
import Constants from 'expo-constants'
import { Formik } from 'formik'
import { Button, Item } from 'native-base'
import { createForm } from 'rc-form'
import React from 'react'
import {
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  StatusBar,
  Text as RcText,
  Text,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
} from 'react-native'
import { NavigationEvents } from 'react-navigation'
import * as Yup from 'yup'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
import customStyles from '../styles'
import { validateLoginDetails } from '../utils/loginUtils'
import { validatePhoneNumber } from '../utils/phoneNumberValidation'
import AutoHeightImage from './AutoHeightImage'
import { styles } from './styles/loginStyles'
import tryalLogoBox from '../../assets/base64/tryalLogoBox'
import LoginPhoneNoComponent from './LoginPhoneNoComponent'
import TextLogin from './Login/components/LoginHeader'
import constants from '../constants/constants'
import { StyleSheet } from 'react-native-web'
import { Keyboard } from 'react-native'
import CustomTextInput from './common/CustomTextInput'
import { fontResizer } from './styles/fontResizer'
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      languageSelected: '',
      loading: false,
      image: { width: 120 },
      callingCode: '',
      subject: {
        phoneNo: '',
        password: '',
      },
      errorMessage: {
        phoneNo: props.screenProps.t('USRNAMEWARNING'),
        password: props.screenProps.t('PWDWARNING'),
        showPhoneNoError: '',
        showPasswordError: '',
      },
      showPassword: false,
      cca2: 'US',
      modalVisible: false,
      accessCode: null,
    }
  }

  componentDidMount() {
    const { navigation } = this.props
    // this.getCallingCode()
    if (Platform.OS === 'web') {
      const urlParams = new URLSearchParams(window.location.search)
      const accessCode = urlParams.get('accessCode')
      if (accessCode) {
        navigation.setParams({ accessCode: accessCode })
        this.setState((prevState) => ({
          ...prevState,
          accessCode,
        }))
      }
    }
  }

  getCallingCode = async () => {
    // alert('did mount called');
    let callingCode = await axios.get('https://ipapi.co/country_calling_code')
    callingCode = callingCode.data.replace('+', '')
    this.changeCallingCode(callingCode)
  }

  updateUserName = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      subject: {
        ...prevState.subject,
        phoneNo: value,
      },
    }))
  }

  updatePassword = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      subject: {
        ...prevState.subject,
        password: value,
      },
    }))
  }

  resetCredentials = () => {
    this.setState({
      subject: {
        phoneNo: '',
        password: '',
      },
    })
  }

  onSubmit = (values) => {
    const { callingCode, accessCode } = this.state
    const { screenProps } = this.props
    const { setLocale } = screenProps
    const subjectPhoneNo = `+${values.phoneNo}`
    // alert(sub);
    this.setState({
      loading: true,
    })
    const subject = {
      phoneNo: subjectPhoneNo,
      password: values.password,
    }
    this.updateUserName(subjectPhoneNo)
    this.updatePassword(values.password)
    const {
      retrieveLogin: requestLogin,
      deviceToken,
      screenProps: { t },
    } = this.props
    if (validateLoginDetails(subject, this)) {
      requestLogin(
        subject,
        deviceToken,
        false,
        t,
        setLocale,
        accessCode
      )
      this.setState({
        loading: false,
      })
    }
  }

  showPassword = () => {
    this.setState((prevState) => ({
      showPassword: prevState.showPassword ? false : true,
    }))
  }

  forgotPassword = () => {
    const { navigation } = this.props
    navigation.navigate('UserValidation')
    //   navigation.navigate('ResetPassword');
  }

  changeToClinician = () => {
    const { navigation } = this.props
    navigation.navigate('Passcode', { appType: constants.AppType.SITESTAFF })
  }

  changeLanguage(language) {
    this.setState({ language: language })
  }

  selectLanguage = (language) => {
    if (language == '') {
      // this.setState({languageSelected  : "Change Language"});
      // return "Change Language";
    } else {
      this.setState({ languageSelected: language })
    }
  }

  onchangeLangauge = (props) => {
    const { languageSelected } = this.state
    props.values.language = languageSelected
    props.handleChange('password')
  }

  changeCallingCode = (callingCode) => {
    this.setState({
      callingCode,
    })
  }

  changeCallCode = (callCode) => {
    this.setState({
      callingCode: callCode,
    })
  }

  render() {
    const {
      callingCode,
      loading,
    } = this.state
    const {
      navigation,
      screenProps,
      isOfflineDataExists,
      phoneNoWithCntryCode,
    } = this.props
    const { t } = screenProps

    return (
      <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <KeyboardAvoidingView
          style={loginViewStyle.container}
          testID={'login-page-view'}
          accessible={true}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          {Platform.OS === 'ios' && (
            <StatusBar backgroundColor="rgba(0,0,0,0)" translucent barStyle="dark-content" />
          )}

          <View
            style={{ alignSelf: 'center', paddingVertical: 30 }}
            testID={'login-tryalLogoBox-view'}
            accessible={true}
          >
            {/* logo */}
            {Platform.OS !== 'web' ? (
              <AutoHeightImage
                source={{
                  uri: `data:image/png;base64,${tryalLogoBox}`,
                }}
                width={300}
                testID={'login-image-os-mobile-image'}
                accessible={true}
              />
            ) : (
              <Image
                source={{
                  uri: `data:image/png;base64,${tryalLogoBox}`,
                }}
                resizeMode="contain"
                style={[styles.image]}
                testID={'login-image-os-web-image'}
                accessible={true}
              />
            )}
          </View>
          {!Constants.expoConfig.extra.nativeApp && <TextLogin t={t}></TextLogin>}

          <Formik
            initialValues={{
              phoneNo:
                isOfflineDataExists && phoneNoWithCntryCode
                  ? phoneNoWithCntryCode.substring(1)
                  : '',
              password: '',
              language: '',
            }}
            validationSchema={Yup.object({
              phoneNo: Yup.string()
                .test('len', 'Must be a valid Phone Number.', (val) => {
                  return validatePhoneNumber(val)
                })
                .required(t('USRNAMEWARNING')),
              password: Yup.string().required(t('PWDWARNING')),
            })}
            onSubmit={(values, formikActions) => {
              setTimeout(() => {
                this.onSubmit(values)
                // Important: Make sure to setSubmitting to false so our loading indicator
                // goes away.
                formikActions.setSubmitting(false)
              }, 500)
            }}
          >
            {(props) => {
              const { setFieldValue, setValues } = props
              const getPhoneValue = (value, phoneNo) => {
                setFieldValue('phoneNo', value + phoneNo)
              }

              const isMobile = Platform.OS !== 'web'

              return (
                <View
                  style={!isMobile && SCREEN_WIDTH >= 1030 ? { width: 500, margin: 'auto' } : {}}
                  testID={'login-form-view'}
                  accessible={true}
                >
                  <NavigationEvents
                    onWillFocus={() => {
                      this.resetCredentials()
                    }}
                  />
                  <View testID={'login-phone-num-view'} accessible={true}>
                    <LoginPhoneNoComponent
                      callingCode={callingCode}
                      t={t}
                      getPhoneValue={getPhoneValue}
                    />
                    {props.touched.phoneNo && props.errors.phoneNo ? (
                      <Text
                        style={[styles.error, customStyles.fontStyle]}
                        testID={'login-phone-num-error-txt'}
                        accessible={true}
                      >
                        {props.errors.phoneNo}
                      </Text>
                    ) : null}
                  </View>

                  {/* </View> */}
                  <View style={{ padding: 10 }} testID={'login-password-view'} accessible={true}>
                    <Item
                      style={[styles.inputStyle]}
                      testID={'login-password-input-item'}
                      accessible={true}
                    >
                      <MaterialIcons
                        name="lock-outline"
                        size={20}
                        color="#bdbdbd"
                        style={styles.icon}
                      />
                      <CustomTextInput
                        keyboardType="default"
                        value={props.values.password}
                        //  value={subject.password}
                        placeholder={t('LoginACSPwd')}
                        placeholderTextColor="#bdbdbd"
                        secureTextEntry={true}
                        style={[styles.inputText, { fontSize: fontResizer(17),width: '100%'}]}
                        onChangeText={props.handleChange('password')}
                      />
                    </Item>
                    {props.touched.password && props.errors.password ? (
                      <Text
                        style={[styles.error, customStyles.fontStyle]}
                        testID={'login-password-error-txt'}
                        accessible={true}
                      >
                        {props.errors.password}
                      </Text>
                    ) : null}
                  </View>
                  <View
                    style={{ padding: 10, flexDirection: 'row', justifyContent: 'space-between' }}
                  >
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          setTimeout(() => {
                            this.forgotPassword()
                          }, 0)
                        }}
                        style={{ paddingVertical: 20 }}
                        testID={'login-forgot-password-btn'}
                        accessible={true}
                      >
                        <RcText style={[styles.buttonText, { color: buttonBackgroundColor }]}>
                          {t('LoginACSFrgtPwd')}
                        </RcText>
                      </TouchableOpacity>
                    </View>
                    <Button
                      style={{
                        justifyContent: 'flex-end',
                        padding: 10,
                        borderRadius: 5,
                        backgroundColor: buttonBackgroundColor,
                      }}
                      onPress={props.handleSubmit}
                      testID={'login-btn'}
                      accessible={true}
                    >
                      <Text
                        style={[
                          { color: 'white', textTransform: 'uppercase' },
                          customStyles.fontStyle,
                        ]}
                      >
                        {t('LoginACSLogin')}
                      </Text>
                    </Button>
                  </View>
                </View>
              )
            }}
          </Formik>

          {loading && (
            <View
              style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
            >
              <ActivityIndicator
                size="large"
                color={buttonBackgroundColor}
                overlayColor="rgba(0, 0, 0, 0.07)"
              />
            </View>
          )}
          <View style={loginViewStyle.bottomView}>
            <View style={{ justifyContent: 'space-between', flexDirection: 'column' }}>
              {!Constants.expoConfig.extra.nativeApp && (
                <TouchableOpacity
                  style={{
                    alignSelf: 'flex-end',
                    marginRight: 20,
                    flexDirection: 'row',
                    alignContent: 'center',
                  }}
                  onPress={() => this.changeToClinician()}
                >
                  <FontAwesome
                    name="stethoscope"
                    size={18}
                    style={{ marginRight: 10 }}
                    color={buttonBackgroundColor}
                  />
                  <Text style={{ color: buttonBackgroundColor, fontFamily: 'Inter', fontSize: 14 }}>
                    {t('ChangeToClinician')}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={{ backgroundColor: '#ffffff', alignSelf: 'center' }}
                onPress={() => navigation.navigate('VersionInfo')}
                testID={'login-version-btn'}
                accessible={true}
              >
                <Text style={loginViewStyle.versionText}>
                {t('Version')}: {Constants.expoConfig.version}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      </TouchableWithoutFeedback>
    )
  }
}

const loginViewStyle = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  versionText: { color: '#e0e0e0', fontFamily: 'Inter', fontSize: 14 },
  bottomView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingVertical: 20,
  },
})

export default createForm()(Login)
