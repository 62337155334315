import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    // backgroundColor:'#fff9c4',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 10
  },
  headingContainer: {
    // flexDirection: 'column',
    // backgroundColor:'#ffcdd2',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 7,
  },
  complianceHeading: {
    // flexDirection: 'column',
    // backgroundColor:'#ffcdd2',
    flex: 1,
    justifyContent: 'center',
    padding: 15,
    alignItems: 'center',
  },
  complianceContainer: {
    flex: 4,
    // backgroundColor:'#b2ebf2',
  },
  nextContainer: {
    flex: 1,
    // backgroundColor:'#dcedc8',
  },
  complianceRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    // backgroundColor:'#ff8a65',
  },
  compliance: {
    flex: 3,
    justifyContent: 'center',
    borderStyle: 'solid',
    borderColor: '#263238',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    // backgroundColor:'#dcedc8',
  },
})
