import React, { Component } from 'react'
import { connect } from 'react-redux'
import CallDetails from '../../components/scheduledCalls/callDetails'
import { getSelectedCall } from '../../selectors/session'
import { getUserTimezone } from '../../selectors/commonSelector'
import { getUserId } from '../../selectors/user'
import PropTypes from 'prop-types'

class CallDetailsPage extends Component {
  state = {}

  render() {
    const { selectedCall, userId} = this.props
    return <CallDetails {...this.props} call={selectedCall} userId={userId}/>
  }
}

const mapStateToProps = (state) => ({
  selectedCall: getSelectedCall(state),
  timeZone: getUserTimezone(state),
  userId: getUserId(state)
})

CallDetailsPage.propTypes = {
  selectedCall: PropTypes.instanceOf(Object),
  userId: PropTypes.string,
}

export default connect(mapStateToProps)(CallDetailsPage)
