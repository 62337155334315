const migrations = {
  0: (state) => {
    return {
      ...state,
      subjectStudyMetaData: {
        schedule: {
          formScheduleEvents: [
            {
              form: {},
              eventFrequency: {},
              eventOccurrences: {},
              eventTimeslot: [],
            },
          ],
        },
        subject: {
          subjectVisits: { loading: false, selectedVisit: {}, visits: [] },
        },
      },
    }
  },
  1: (state) => {
    return {
      ...state,
      formPrint:{
        selectedVisitFormOid: '',
        selectedSvfId: '',
        formAnswers: {
          data: [],
          loading: false,
        },
        form: {},
        loading: false,
        formList: [],
        formListLoading: false,
        field: {
          fieldListOfMultipleForms: {}
        }
      },
    }
  },
  2: (state) => {
    return {
      ...state,
      appStatus: {
        ...state.appStatus,
        isAcknowledged: false,
      }
    }
  },
}

export default migrations
