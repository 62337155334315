import _ from 'lodash'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import TextAvatar from 'react-native-text-avatar'
import styles from './styles'

export default class ParticipantList extends Component {
  render() {
    const { call } = this.props
    return (
      <View style={styles.callDetailsView} testID={`participants-list-view`} accessible={true}>
        {_.map(call.participants, (p) => (
          <View style={{ flexDirection: 'row', margin: '1%' }}>
            <TextAvatar backgroundColor="#e8e8e8" textColor="#1890ff" size={40} type="circle">
              {p.name}
            </TextAvatar>
            <Text style={styles.participantNameText}>{p.name} </Text>
          </View>
        ))}
      </View>
    )
  }
}
