import axios from 'axios'
import appConstants from '../constants/appConstants'
import { getConfig } from './api'

let baseUrl = `${appConstants.baseUrl}/api/app`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
})

axiosConfig.interceptors.request.use(
  (config) => getConfig(config),
  (error) => {
    Promise.reject(error)
  }
)
axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      console.log('Request canceled', error)
      return Promise.resolve(error)
    } else {
      return Promise.reject(error)
    }
  }
)

export default axiosConfig
