export const timeDifference = (current, previous) => {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = current - previous

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} seconds ago`
  }

  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} minute(s) ago`
  }

  if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} hour(s) ago`
  }

  if (elapsed < msPerMonth) {
    return `approximately ${Math.round(elapsed / msPerDay)} day(s) ago`
  }

  if (elapsed < msPerYear) {
    return `approximately ${Math.round(elapsed / msPerMonth)} month(s) ago`
  }

  return `approximately ${Math.round(elapsed / msPerYear)} year(s) ago`
}
