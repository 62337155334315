import { Dimensions, PixelRatio } from 'react-native'
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')


export const fontScale = (fontSize) => {
  const scale = PixelRatio.getFontScale();
  return fontSize/scale
}

export const fontResizer = (fontSize) => {
  switch (fontSize) {
    case 17:
      return fontType({
        xl: 17,
        lg: 14,
        sm: 12,
      })
    case 12:
      return fontType({
        xl: 12,
        lg: 12,
        sm: 10,
      })
    case 16:
      return fontType({
        xl: 16,
        lg: 14,
        sm: 12,
      })
    case 18:
      return fontType({
        xl: 18,
        lg: 14,
        sm: 12,
      })
    case 24:
      return fontType({
        xl: 24,
        lg: 18,
        sm: 16,
      })
    case 14:
      return fontType({
        xl: 14,
        lg: 12,
        sm: 10,
      })
    case 15:
        return fontType({
          xl: 15,
          lg: 12,
          sm: 12,
        })
    case 5:
          return fontType({
            xl: 5,
            lg: 5,
            sm: 2,
          })
  }
}

export const fontType = (fontObj) => {
  const { sm, lg, xl } = fontObj
  if (SCREEN_WIDTH >= 750) {
    return xl
  } else if (SCREEN_WIDTH > 360) {
    return lg
  } else {
    return sm
  }
}

export const heightResizer = (fontObj) => {
  const { sm, lg, xl } = fontObj
  if (SCREEN_HEIGHT >= 450) {
    return xl
  } else if (SCREEN_HEIGHT >= 350) {
    return lg
  } else {
    return sm
  }
}