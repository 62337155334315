import moment from 'moment'

export const getActualCallDuration = (cs) => {
  if (cs.endTime) {
    const startTime = moment(cs.startTime)
    const endTime = moment(cs.endTime)
    const duration = moment.duration(endTime.diff(startTime))

    if (duration.hours() > 0) {
      if (duration.minutes() == 0 && duration.seconds() == 0) {
        return `${duration.hours()}h`
      }
      return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
    }
    if (duration.hours() === 0 && duration.minutes() === 0) {
      return `${duration.seconds()}s`
    }
    return `${duration.minutes()}m ${duration.seconds()}s`
  }
  return ''
}
