import { createSelector } from 'reselect'
import { getUserId } from './user'
import _ from 'lodash'

export const getChatPool = (state) => state.chat

export const getUserChat = createSelector([getChatPool, getUserId], (chatPool, userId) => {
    return _.get(chatPool, userId, {})
})

