import { connect } from 'react-redux'
import React, { Component } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Card } from 'native-base'
import { fontResizer } from './styles/fontResizer'
import { getCrfVersion } from '../selectors/studyMetaData'

class VersionDetails extends Component {
  state = {}

  render() {
    const {
      versionInfo,
      screenProps: { t },
    } = this.props
    return (
      <View >
        <Card
          style={{
            borderRadius: 2,
            flexDirection: 'column',
            borderColor: 'grey',
          }}
        >
          <View style={{ fontFamily: 'Inter', paddingLeft: 20 }}>
            <Text style={{ ...styles.text, fontWeight: 600, fontSize: fontResizer(18) }}>
              {t('versionInformation')}
            </Text>
          </View>
          <View style={{ flex: 1, backgroundColor: '#fff', padding: 20 }}>
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('versionName')}: {versionInfo ? versionInfo.versionName : ''}
            </Text>
          </View>
        </Card>
      </View>
    )
  }
}
export const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontFamily: 'Inter',
    padding: 7,
  },
})

const mapStateToProps = (state) => ({
  versionInfo: getCrfVersion(state)
})

export default connect(mapStateToProps)(VersionDetails)
