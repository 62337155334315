import React from 'react'
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native'
import tryalLogoBox from '../../../../assets/base64/tryalLogoBox'
import { styles } from '../../styles/loginStyles'
import { buttonBackgroundColor } from '../../../containers/NavigationScreens'
import AutoHeightImage from '../../AutoHeightImage'
import constants from '../../../constants/constants'

function AccountSetUp(props) {
  const { navigation, screenProps , errorType} = props
  const message = navigation.getParam("message")
  return (
    <View style={{ flex: 1, justifyContent: 'center', backgroundColor: '#fff' }}>
      <View
        style={{
          alignSelf: 'center',
          paddingVertical: 30,
        }}
      >
        {Platform.OS !== 'web' ? (
          <AutoHeightImage
            source={{
              uri: `data:image/png;base64,${tryalLogoBox}`,
            }}
            width={300}
          />
        ) : (
          <Image
            source={{
              uri: `data:image/png;base64,${tryalLogoBox}`,
            }}
            resizeMode="contain"
            style={[styles.image]}
          />
        )}
      </View>
      <Text
        style={{
          textAlign: 'center',
          // fontWeight: 'bold',
          fontSize: 18,
          fontFamily: 'Inter',
          // color: '#469ae5',
        }}
      >
        {screenProps.t('GoToPortal')}
        <br />
        <br />
        {screenProps.t(message) }
      </Text>
      <TouchableOpacity
        style={{ alignSelf: 'center', marginTop: 25, backgroundColor: buttonBackgroundColor }}
        onPress={() => {
          navigation.navigate('Login', {appType: constants.AppType.SITESTAFF})
        }}
      >
        <Text
          style={[
            styles.buttonText,
            {
              color: 'white',
              fontSize: 14,
              padding: 10,
              fontFamily: 'Inter',
              borderWidth: 0.5,
              textTransform: 'uppercase',
              width: 200,
              textAlign: 'center',
            },
          ]}
        >
          {screenProps.t('BackToLogin')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

  export default AccountSetUp
