import _ from 'lodash'
import { createSelector } from 'reselect'
import { DATE_FORMAT } from '../utils/dateUtils'
import moment from 'moment'
import constants from '../constants/constants'
import { getUserSession } from './commonSelector'

export const getSessionCurrentUserId = (state) => state.session.currentUserId
export const getSessionPool = (state) => state.session 

export const getSelectedSubjectVisitId = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedSubjectVisitId', null)
})

export const getSelectedDate = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedDate', moment().format(DATE_FORMAT))
})

export const getSelectedSvfId = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedSvfId', null)
})

export const getselectedFormId = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedFormId', null)
})

export const getselectedChat = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedChat', {})
})

export const getSelectedCall = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedCall', {})
})

export const getCurrentScreen = createSelector([getUserSession], (session) => {
    return _.get(session, 'currentScreen', '')
})

export const getSelectedFormType = createSelector([getUserSession], (session) => {
    return _.get(session, 'selectedFormType', constants.formCategory.SUBJECT_DIARY)
})




  