import React from "react";
import { Text, View } from "react-native";
import PropTypes from 'prop-types'
const ShowValue = (props) => {
    const {labelText, value, testID, accessible} = props
      return (
        <View testID={testID} accessible={accessible}>
            <Text style={{ marginBottom: 20, marginLeft: 10, fontFamily: 'Inter', fontSize: 16 }}
            testID={testID+"-text"}
            accessible={accessible}
            >
              {labelText} : {value} 
            </Text>
        </View>
        )
   
}

ShowValue.defaultProps = {
  labelText: '',
  value: '',
  testID: null,
  accessible: true,
}

ShowValue.propTypes = {
  labelText: PropTypes.string,
  value: PropTypes.string,
  testID: PropTypes.string,
  accessible: PropTypes.bool,
}
export default ShowValue