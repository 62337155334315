import React, { Component } from 'react'
import { View } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'

// import { View } from 'native-base';
// import { TextareaItem } from '@ant-design/react-native';
// import { TextareaItem } from 'antd-mobile';
// import 'antd-mobile/dist/antd-mobile.css';

import { styles } from './styles'
import CustomTextInput from '../common/CustomTextInput'

export class TextArea extends Component {
  state = {}

  changeFieldValue = (value) => {
    const {
      form,
      field: { id },
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    setFieldsValue({
      [id]: value,
    })
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      disabled,
      fieldAnswer,
    } = this.props
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          marginHorizontal: 10,
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(id, {
          initialValue: crfData ? crfData.fieldValue : null,
          rules: [
            {
              required: isRequired,
              message: t('TextValMsg'),
            },
          ],
        })(
          <CustomTextInput
            onChangeText={this.changeFieldValue}
            placeholder={t('EnterHere')}
            editable={!disabled}
            multiline={true}
            style={[{ height: 100 }, styles.textArea, styles.textBoxBorderColor]}
          />
        )}
      </View>
    )
  }
}
