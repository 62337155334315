import { MaterialIcons } from '@expo/vector-icons'
import moment from 'moment'
import { Body, Container, Content, List, ListItem, Right } from 'native-base'
import React, { Component } from 'react'
import { StyleSheet, Text } from 'react-native'
import { NavigationEvents } from 'react-navigation'

class Health extends Component {
  state = {}

  render() {
    const {
      navigation,
      retrieveHealthkitData,
      subject,
      screenProps: { t },
    } = this.props
    return (
      <Container>
        <NavigationEvents
          onWillFocus={() => {
            const date = moment().format('YYYY-MM-DD').toString()
            retrieveHealthkitData(subject.id, date)
          }}
        />
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <List>
            <ListItem
              style={{ marginTop: 10 }}
              icon
              onPress={() => {
                navigation.navigate('Activity')
              }}
            >
              <Body>
                <Text style={styles.text}>{t('Activity')}</Text>
              </Body>
              <Right>
                <MaterialIcons name="keyboard-arrow-right" size={24} />
              </Right>
            </ListItem>
            <ListItem
              style={{ marginTop: 10 }}
              icon
              onPress={() => {
                navigation.navigate('Vitals')
              }}
            >
              <Body>
                <Text style={styles.text}>{t('Vitals')}</Text>
              </Body>
              <Right>
                <MaterialIcons name="keyboard-arrow-right" size={24} />
              </Right>
            </ListItem>
            <ListItem
              style={{ marginTop: 10 }}
              icon
              onPress={() => {
                navigation.navigate('Biometrics')
              }}
            >
              <Body>
                <Text style={styles.text}>{t('Biometrics')}</Text>
              </Body>
              <Right>
                <MaterialIcons name="keyboard-arrow-right" size={24} />
              </Right>
            </ListItem>
          </List>
        </Content>
      </Container>
    )
  }
}

export const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  iconContainer: {
    flex: 1,
  },
  textContainer: {
    flex: 5,
    alignSelf: 'flex-end',
  },
  text: {
    fontSize: 16,
    fontFamily: 'Inter',
  },
})

export default Health
