import _ from 'lodash'
import React from 'react'
import { Dimensions, View } from 'react-native'
import RenderHtml from 'react-native-render-html'

const window = Dimensions.get('window')
export default class FormInstructionScreen extends React.Component {
  render() {
    const { onNext, instruction, showOkButton, item } = this.props
    // console.log('instruction--->', instruction)
    return (
      <View
        style={{
          paddingHorizontal: 5,
          backgroundColor: 'white',
          marginVertical: 2,
          paddingVertical: 1,
        }}
      >
        {/* <H3 style={{marginBottom:5}}>Instructions</H3> */}
        {!_.isEmpty(instruction) && (
          <RenderHtml
            tagsStyles={{ p: { marginVertical: 1 } }}
            source={{ html: instruction }}
            contentWidth={window.width}
            baseStyle={{ margin: 0 }}
          />
        )}

        {/* {showOkButton &&
          <Button style={{padding:10,borderRadius:5,backgroundColor:'#084f64',alignSelf:'center'}}onPress={onNext}><Text>Ok</Text></Button>
        } */}
      </View>
    )
  }
}
