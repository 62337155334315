import { FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Card } from 'native-base'
import { fontResizer } from './styles/fontResizer'
import { getStudyData } from '../selectors/studyMetaData'

class StudyDetails extends Component {
  state = {}

  render() {
    const {
      studyInfo,
      screenProps: { t },
    } = this.props
    return (
      <View>
        <Card
          style={{
            borderRadius: 2,
            flexDirection: 'column',
            borderColor: 'grey',
          }}
        >
          <View style={{ fontFamily: 'Inter', paddingLeft: 20 }}>
            <Text style={{ ...styles.text, fontWeight: 600, fontSize: fontResizer(18) }}>
              {t('studyInformation')}
            </Text>
          </View>
          <View style={{ flex: 1, backgroundColor: '#fff', padding: 20, paddingTop: 10 }}>
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('study')}: {studyInfo.studyNumber}
            </Text>
            <Text style={[styles.text, { fontSize: fontResizer(16) }]}>
              {t('sponsor')}: {studyInfo.sponsorName}
            </Text>
          </View>
        </Card>
      </View>
    )
  }
}
export const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontFamily: 'Inter',
    padding: 7,
  },
})

const mapStateToProps = (state) => ({
  studyInfo: getStudyData(state)
})

export default connect(mapStateToProps)(StudyDetails)
