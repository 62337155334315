import React from 'react'
import { View, Text } from 'react-native'
import styles from './styles/trainingStyles'
import PropTypes from 'prop-types'

function TrainingLabel(props) {
  const {t} = props
  return (
    <View style={styles.trainingLabelContainer}>
      <Text style={styles.trainingText}>{t('ViewTrainingForms')}</Text>
    </View>
  )
}

TrainingLabel.defaultProps = {
  t: ()=> null
}

TrainingLabel.propTypes = {
  t: PropTypes.func
}

export default TrainingLabel
