import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { View } from 'react-native'
import api from '../utils/api'
import { serializeQuery } from '../utils/paginationUtils'
import MeetingSchedules from '../components/MeetingSchedules'
import showToast from '../utils/toast'
import { updateSelectedCall } from '../actions/session'
import { NavigationEvents } from 'react-navigation'

import { getDateBySubjectTimezone } from '../utils/dateFormatUtils'
import constants from '../constants/constants'
import { getSubject } from '../selectors/commonSelector'
import _ from 'lodash'
import PropTypes from 'prop-types'

class PastCallsScreen extends Component {
  state = {
    callSchedules: [],
    page: 0,
    loading: false,
    loadingMore: false,
  }

  componentDidUpdate(prevProps) {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline !== prevProps.isDeviceOnline && isDeviceOnline) {
      this.retrieveCallSchedules()
    }
  }

  fetchMoreCallSchedules = () => {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          loadingMore: true,
        }),
        () => {
          this.retrieveCallSchedules()
        }
      )
    }
  }

  retrieveCallSchedules = async (isRefresh) => {
    const {
      subject: { id: subjectId, timeZone },
      screenProps: { t },
    } = this.props
    const { page, loadingMore } = this.state
    const paginationParameters = {
      size: 15,
      page: isRefresh ? 0 : page,
    }

    const currentDate = getDateBySubjectTimezone(timeZone)
    try {
      if (!loadingMore) {
        this.setState({
          loading: true,
        })
      }
      const res = await api.get(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/calls?subjectId=${subjectId}&date=${currentDate}&timezone=${timeZone}&timeType=past&${serializeQuery(
          paginationParameters
        )}`
      )
      if (res.data) {
        const callSchedules = res.data.content
        this.setState((prevState) => ({
          callSchedules: isRefresh ? callSchedules : [...prevState.callSchedules, ...callSchedules],
        }))
      }
      this.setState({
        loading: false,
        loadingMore: false,
      })
    } catch (error) {
      showToast(t('FailedRetrieve'), 'danger', 2000)
      this.setState({
        loading: false,
        loadingMore: false,
      })
    }
  }

  refresh = () => {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline) {
      this.setState({
        page: 0,
      })
      this.retrieveCallSchedules(true)
    }
  }

  render() {
    const {
      screenProps,
      isDeviceOnline,
      subject,
      updateSelectedCall,
      navigation,
    } = this.props
    const { loading, loadingMore } = this.state
    let { callSchedules } = this.state
    let emptyMessage = screenProps.t('NoPastCalls')
    if (!isDeviceOnline) {
      callSchedules = []
      emptyMessage = screenProps.t('NoInternet')
    }
    return (
      <View testID={'past-callsmeeting-schedule'} accessible={true} style = {{flex: 1}}>
        <NavigationEvents
          onWillFocus={() => {
            this.retrieveCallSchedules(true)
          }}
        />
        <MeetingSchedules
          screenProps={screenProps}
          callSchedules={callSchedules}
          fetchMoreCallSchedules={this.fetchMoreCallSchedules}
          loading={loading}
          loadingMore={loadingMore}
          refresh={this.refresh}
          noCallsMessage={emptyMessage}
          timeZone={_.get(subject, 'timeZone', null)}
          subjectId={_.get(subject, 'id', null)}
          updateSelectedCall={updateSelectedCall}
          navigation={navigation}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  subject: getSubject(state),
  isDeviceOnline: state.appStatus.isDeviceOnline,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSelectedCall,
    },
    dispatch
  )

  PastCallsScreen.propTypes = {
    screenProps: PropTypes.shape({
      t: PropTypes.func
    }),
    isDeviceOnline: PropTypes.bool,
    subject: PropTypes.shape({
      id: PropTypes.string,
      timeZone: PropTypes.string
    }),
    updateSelectedCall: PropTypes.func,
    navigation: PropTypes.instanceOf(Object),
  }
export default connect(mapStateToProps, mapDispatchToProps)(PastCallsScreen)
