import initialState from '../store/initialState'
import {
  CLINRO_LOGIN_REQUEST,
  CLINRO_LOGIN_FAILURE,
  CLINRO_LOGOUT_REQUEST,
  CLINRO_LOGOUT_FAILURE,
  CLINRO_LOGOUT_SUCCESS,
  UPDATE_USERS,
  UPDATE_LOGGEDIN_USER,
  UPDATE_USER_PRIVILEGES,
  UPDATE_USER_DETAILS,
  UPDATE_RESET_PASSWORD,
  UPDATE_PRIVACY_POLICY,
  UPDATE_PASSWORD_EXPIRY,
  UPDATE_IS_SUBJECT_REGISTERED
} from '../actions/users'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import { UPDATE_TIMEZONE_SUCCESS } from '../actions/timeZone'

export default (state = initialState.users, action) => {
  switch (action.type) {
    case CLINRO_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CLINRO_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CLINRO_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CLINRO_LOGOUT_SUCCESS:
      return {
        ...state,
        [action?.userId]: {},
        loading: false,
      }
    case CLINRO_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_USERS:
      return {
        ...state,
        ...action.users,
      }
    case UPDATE_LOGGEDIN_USER:
      return {
        ...state,
        [action.user.id]: action.user,
      }
    case CLEAR_USER_STORAGE:
      return {
        ...state,
        [action.userId]: {}
      }
    case UPDATE_USER_PRIVILEGES:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          privileges: action.privileges
        }
      }
      case UPDATE_TIMEZONE_SUCCESS:
        return {
          ...state,
          [action.userId]: {
            ...state?.[action.userId],
            timeZone: action?.timezoneData?.timeZone,
          }
      }
      case UPDATE_USER_DETAILS:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          resetPassword: action.subject.resetPassword,
          passwordExpired: action.subject.passwordExpired,
          privacyPolicy : action.subject.privacyPolicy,
        }
      }
      case UPDATE_PRIVACY_POLICY:
        return {
           ...state,
           [action.userId]: {
             ...state?.[action.userId],
             privacyPolicy: {
               ...state?.[action.userId]?.privacyPolicy,
               agreedToTerms: action?.agreedToTerms,
             }
           }
         }
        case UPDATE_RESET_PASSWORD:
          return {
             ...state,
             [action.userId]: {
               ...state?.[action.userId],
               resetPassword : action.resetPassword,
             }
           }
        case UPDATE_PASSWORD_EXPIRY:
          return {
             ...state,
             [action.userId]: {
               ...state?.[action.userId],
             passwordExpired : action.passwordExpired,
             }
           }
        case UPDATE_IS_SUBJECT_REGISTERED:
            return {
               ...state,
               [action.userId]: {
                ...state?.[action.userId],
               isSubjectRegistered : action.isSubjectRegistered,
               }
             }
    default:
      return state
  }
}
