import {Item } from 'native-base'
import React from 'react'
import { Text, View } from 'react-native'
// import PhoneNumberInput from './forgotPassword/Input';
// import { fetchBodyTemperature, fetchLatestWeight, getWeight, testData } from '../utils/healthKit/Healthkit';
// import { saveHealthkitData } from '../actions/healthkit';
import { fontResizer } from './styles/fontResizer'
import CustomTextInput from './common/CustomTextInput'

var number = ''
class PhNumberInput extends React.Component {
  state = {
    phoneCode: '',
    phoneNumber: '',
  }

  componentDidMount() {
    const phoneCode = '1'
    this.setState({
      phoneCode,
    })
  }

  componentDidUpdate(prevProps) {
    const { callingCode } = this.props
    const phoneCode = callingCode
    if (prevProps.callingCode !== callingCode)
      this.setState({
        phoneCode: '1',
      })
  }

  getPhoneData = async () => {
    const { phoneCode, phoneNumber } = this.state
    const { getPhoneValue } = this.props
    const phNo = phoneCode + phoneNumber
    getPhoneValue(phNo)
  }

  render() {
    const { getPhoneValue, t, fromPinChange } = this.props
    const { phoneNumber, phoneCode } = this.state
    return (
      <View style={{ padding: 10 }}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <Item
            style={{ borderBottomWidth: 2, flex: 1, justifyContent: 'center' }}
            testID={'phoneno-countrycode-item'}
            accessible={true}
          >
            <CustomTextInput
              style={{ textAlign: 'center', alignItems: 'center', fontFamily: 'Inter' }}
              maxLength={5}
              keyboardType="default"
              placeholderTextColor="#bdbdbd"
              defaultValue={1}
              //  style={{color:'black'}}
              value={phoneCode}
              onChangeText={(value) => {
                this.setState({
                  phoneCode: value,
                })
                getPhoneValue(value, phoneNumber)
              }}
            />
          </Item>
          <View style={{ flex: 0.1, justifyContent: 'center', alignContent: 'center' }}>
            <Text style={{ fontSize: 25, textAlign: 'center' }}>-</Text>
          </View>
          <Item
            style={{ borderBottomWidth: 1, flex: 5 }}
            testID={'phoneno-input-text-item'}
            accessible={true}
          >
            <CustomTextInput
              maxLength={10}
              placeholder={t('LoginACSUserID')}
              keyboardType="default"
              placeholderTextColor="#bdbdbd"
              style={
                fromPinChange
                  ? { color: 'black', width: '10%', fontFamily: 'Inter', fontSize: fontResizer(17), marginLeft: 10 }
                  : { color: 'black', fontFamily: 'Inter', fontSize: fontResizer(17),width: '100%', marginLeft: 10 } }
              onChangeText={(value) => {
                this.setState({
                  phoneNumber: value,
                })
                getPhoneValue(phoneCode, value)
              }}
            />
          </Item>
        </View>
      </View>
    )
  }
}

export default PhNumberInput
