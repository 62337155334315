import React from 'react'
import { connectToDevTools } from 'react-devtools-core'
import { Dimensions, Platform, View } from 'react-native'
import Application from './src/containers/App'
import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants'


if (__DEV__) {
  connectToDevTools({
    host: 'localhost',
    port: 8097,
  })
}

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export default function App() {

  React.useEffect(() => {
    setupSentry();
  }, []);

  const setupSentry = () => {
    Sentry.init({
      dsn: Constants.expoConfig.extra.SENTRY_DSN,
      enableInExpoDevelopment: false,
      debug: Constants.expoConfig.extra.env === "UT5" || Constants.expoConfig.extra === "development", // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
      release: Constants.expoConfig.version,
      environment: Constants.expoConfig.extra.env,
      dist: Constants.expoConfig.extra === "development" ? 0 : Constants.expoConfig.extra?.contexts?.app?.app_build
    });
  }
  if (Platform.OS === 'web') {
    const isLandscape = SCREEN_WIDTH >= 1030;
    const landscapeStyles = isLandscape
      ? { alignItems: 'center', backgroundColor: '#b0bec5', overflow: 'hidden' }
      : {}

    return (
      <View
        style={{ flex: 1, ...landscapeStyles }}>
        <View style={{ width: isLandscape ? 800 : '100%', height: '99%' }}>
          <Application />
        </View>
      </View>
    )
  } else {
    return <Application />
  }
}
