import React, { useState } from 'react'
import { Text, View, Platform, Alert, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import WebAlert from '../../utils/WebAlert'
import { bindActionCreators } from 'redux'
import { logout } from '../../actions/users'
import { getCurrentUser } from '../../selectors/user'
const Logout = (props) => {
  const {
    navigation,
    screenProps: { t },
    logout: signoutUser,
  } = props
  const [isModalVisible, setModalVisible] = useState(false)
  const buttons = [ 
    { 'title': 'NO', 'action': () => setModalVisible(false) }, 
    { 'title': 'YES', 'action': () => logoutAction() } 
]
  const logoutAction = async () => {
    try {
      signoutUser()
      navigation.navigate('ClinRoLogin')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <View>
      <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={{
          borderWidth: 1,
          borderColor: '#ffffff',
          borderRadius: 30,
          marginTop: 10,
          alignItems: 'center',
          height: 20,
        }}
      >
        <Text style={{ fontSize: 12, fontFamily: 'Inter', color: '#ffffff' }}>Logout</Text>
      </TouchableOpacity>

      {isModalVisible && Platform.OS == 'web' && (
        <WebAlert
          headerText={''}
          hideModal={() => setModalVisible(false)}
          t={t}
          action={() => logoutAction()}
          message={t('LogoutMessage')}
          buttons={buttons}
        />
      )}
      {isModalVisible &&
        Platform.OS !== 'web' &&
        Alert.alert(
          '',
          t('LogoutMessage'),
          [
            {
              text: t('NO'),
              onPress: () => true,
              style: 'cancel',
            },
            {
              text: t('YES'),
              onPress: async () => {
                try {
                  setModalVisible(false)
                  signoutUser()

                  navigation.navigate('ClinRoLogin')
                } catch (error) {
                  console.log(error)
                }
              },
            },
          ],
          { cancelable: false }
        )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  site: state.site,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
