export default {
    "Africa/Accra": "Afrika/Akra",
"Africa/Addis_Ababa": "Afrika/Addis_Ababa",
"Africa/Algiers": "Afrika/Alžír",
"Africa/Asmara": "Afrika/Asmara",
"Africa/Asmera": "Afrika/Asmera",
"Africa/Bamako": "Afrika/Bamako",
"Africa/Bangui": "Afrika/Bangui",
"Africa/Banjul": "Afrika/Banjul",
"Africa/Bissau": "Afrika/Bissau",
"Africa/Blantyre": "Afrika/Blantyre",
"Africa/Brazzaville": "Afrika/Brazzaville",
"Africa/Bujumbura": "Afrika/Bujumbura",
"Africa/Cairo": "Afrika/Káhira",
"Africa/Casablanca": "Afrika/Casablanca",
"Africa/Ceuta": "Afrika/Ceuta",
"Africa/Conakry": "Afrika/Conakry",
"Africa/Dakar": "Afrika/Dakar",
"Africa/Dar_es_Salaam": "Afrika/Dar_es_Salaam",
"Africa/Djibouti": "Afrika/Džibutsko",
"Africa/Douala": "Afrika/Douala",
"Africa/El_Aaiun": "Afrika/El_Aaiun",
"Africa/Freetown": "Afrika/Freetown",
"Africa/Gaborone": "Afrika/Gaborone",
"Africa/Harare": "Afrika/Harare",
"Africa/Johannesburg": "Afrika/Johannesburg",
"Africa/Juba": "Afrika/Juba",
"Africa/Kampala": "Afrika/Kampala",
"Africa/Khartoum": "Afrika/Chartum",
"Africa/Kigali": "Afrika/Kigali",
"Africa/Kinshasa": "Afrika/Kinshasa",
"Africa/Lagos": "Afrika/Lagos",
"Africa/Libreville": "Afrika/Libreville",
"Africa/Lome": "Afrika/Lome",
"Africa/Luanda": "Afrika/Luanda",
"Africa/Lubumbashi": "Afrika/Lubumbashi",
"Africa/Lusaka": "Afrika/Lusaka",
"Africa/Malabo": "Afrika/Malabo",
"Africa/Maputo": "Afrika/Maputo",
"Africa/Maseru": "Afrika/Maseru",
"Africa/Mbabane": "Afrika/Mbabane",
"Africa/Mogadishu": "Afrika/Mogadishu",
"Africa/Monrovia": "Afrika/Monrovia",
"Africa/Nairobi": "Afrika/Nairobi",
"Africa/Ndjamena": "Afrika/Ndjamena",
"Africa/Niamey": "Afrika/Niamey",
"Africa/Nouakchott": "Afrika/Nouakchott",
"Africa/Ouagadougou": "Afrika/Ouagadougou",
"Africa/Porto-Novo": "Afrika/Porto-Novo",
"Africa/Sao_Tome": "Afrika/Sao_Tome",
"Africa/Timbuktu": "Afrika/Timbuktu",
"Africa/Tripoli": "Afrika/Tripolis",
"Africa/Tunis": "Afrika/Tunis",
"Africa/Windhoek": "Afrika/Windhoek",
"America/Adak": "Amerika/Adak",
"America/Anchorage": "Amerika/Anchorage",
"America/Anguilla": "Amerika/Anguilla",
"America/Antigua": "Amerika/Antigua",
"America/Araguaina": "Amerika/Araguaina",
"America/Argentina/Buenos_Aires": "Amerika/Argentína/Buenos_Aires",
"America/Argentina/Catamarca": "Amerika/Argentína/Catamarca",
"America/Argentina/ComodRivadavia": "Amerika/Argentína/Comodrivadavia",
"America/Argentina/Cordoba": "Amerika/Argentína/Córdoba",
"America/Argentina/Jujuy": "Amerika/Argentína/Jujuy",
"America/Argentina/La_Rioja": "Amerika/Argentína/La_Rioja",
"America/Argentina/Mendoza": "Amerika/Argentína/Mendoza",
"America/Argentina/Rio_Gallegos": "Amerika/Argentína/Rio_Gallegos",
"America/Argentina/Salta": "Amerika/Argentína/Salta",
"America/Argentina/San_Juan": "Amerika/Argentína/San_Juan",
"America/Argentina/San_Luis": "Amerika/Argentína/San_Luis",
"America/Argentina/Tucuman": "Amerika/Argentína/Tucuman",
"America/Argentina/Ushuaia": "Amerika/Argentína/Ushuaia",
"America/Aruba": "Amerika/Aruba",
"America/Asuncion": "Amerika/Asuncion",
"America/Atikokan": "Amerika/Atikokan",
"America/Atka": "Amerika/Atka",
"America/Bahia": "Amerika/Bahia",
"America/Bahia_Banderas": "Amerika/Bahia_Banderas",
"America/Barbados": "Amerika/Barbados",
"America/Belem": "Amerika/Belem",
"America/Belize": "Amerika/Belize",
"America/Blanc-Sablon": "Amerika/Blanc-Sablon",
"America/Boa_Vista": "Amerika/Boa_Vista",
"America/Bogota": "Amerika/Bogota",
"America/Boise": "Amerika/Boise",
"America/Buenos_Aires": "Amerika/Buenos_Aires",
"America/Cambridge_Bay": "Amerika/Cambridge_Bay",
"America/Campo_Grande": "Amerika/Campo_Grande",
"America/Cancun": "Amerika/Cancún",
"America/Caracas": "Amerika/Caracas",
"America/Catamarca": "Amerika/Catamarca",
"America/Cayenne": "Amerika/Cayenne",
"America/Cayman": "Amerika/Kajman",
"America/Chicago": "Amerika/Chicago",
"America/Chihuahua": "Amerika/Čihuahua",
"America/Ciudad_Juarez": "Amerika/Ciudad_Juarez",
"America/Coral_Harbour": "Amerika/Coral_Harbour",
"America/Cordoba": "Amerika/Córdoba",
"America/Costa_Rica": "Amerika/Costa_Rica",
"America/Creston": "Amerika/Creston",
"America/Cuiaba": "Amerika/Cuiaba",
"America/Curacao": "Amerika/Curacao",
"America/Danmarkshavn": "Amerika/Dánsko",
"America/Dawson": "Amerika/Dawson",
"America/Dawson_Creek": "Amerika/Dawson_Creek",
"America/Denver": "Amerika/Denver",
"America/Detroit": "Amerika/Detroit",
"America/Dominica": "Amerika/Dominika",
"America/Edmonton": "Amerika/Edmonton",
"America/Eirunepe": "Amerika/Eirunepe",
"America/El_Salvador": "Amerika/El_Salvador",
"America/Ensenada": "Amerika/Ensenada",
"America/Fort_Nelson": "Amerika/Fort_Nelson",
"America/Fort_Wayne": "Amerika/Fort_Wayne",
"America/Fortaleza": "Amerika/Fortaleza",
"America/Glace_Bay": "Amerika/Glace_Bay",
"America/Godthab": "Amerika/Godthab",
"America/Goose_Bay": "Amerika/Goose_Bay",
"America/Grand_Turk": "Amerika/Grand_Turk",
"America/Grenada": "Amerika/Grenada",
"America/Guadeloupe": "Amerika/Guadeloupe",
"America/Guatemala": "Amerika/Guatemala",
"America/Guayaquil": "Amerika/Guayaquil",
"America/Guyana": "Amerika/Guyana",
"America/Halifax": "Amerika/Halifax",
"America/Havana": "Amerika/Havana",
"America/Hermosillo": "Amerika/Hermosillo",
"America/Indiana/Indianapolis": "Amerika/Indiana/Indianapolis",
"America/Indiana/Knox": "Amerika/Indiana/Knox",
"America/Indiana/Marengo": "Amerika/Indiana/Marengo",
"America/Indiana/Petersburg": "Amerika/Indiana/Petrohrad",
"America/Indiana/Tell_City": "Amerika/Indiána/Tell_City",
"America/Indiana/Vevay": "Amerika/Indiana/Vevay",
"America/Indiana/Vincennes": "Amerika/Indiana/Vincennes",
"America/Indiana/Winamac": "Amerika/Indiana/Winamac",
"America/Indianapolis": "Amerika/Indianapolis",
"America/Inuvik": "Amerika/Inuvik",
"America/Iqaluit": "Amerika/Iqaluit",
"America/Jamaica": "Amerika/Jamajka",
"America/Jujuy": "Amerika/Jujuy",
"America/Juneau": "Amerika/Juneau",
"America/Kentucky/Louisville": "Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello": "Amerika/Kentucky/Monticello",
"America/Knox_IN": "Amerika/Knox_in",
"America/Kralendijk": "Amerika/Kralendijk",
"America/La_Paz": "Amerika/La_Paz",
"America/Lima": "Amerika/Lima",
"America/Los_Angeles": "Amerika/Los_Angeles",
"America/Louisville": "Amerika/Louisville",
"America/Lower_Princes": "Amerika/Lower_Princes",
"America/Maceio": "Amerika/Maceio",
"America/Managua": "Amerika/Managua",
"America/Manaus": "Amerika/Manaus",
"America/Marigot": "Amerika/Marigot",
"America/Martinique": "Amerika/Martinik",
"America/Matamoros": "Amerika/Matamoros",
"America/Mazatlan": "Amerika/Mazatlán",
"America/Mendoza": "Amerika/Mendoza",
"America/Menominee": "Amerika/Menominee",
"America/Merida": "Amerika/Merida",
"America/Metlakatla": "Amerika/Metlakatla",
"America/Mexico_City": "America/Mexico_City",
"America/Miquelon": "Amerika/Miquelon",
"America/Moncton": "Amerika/Moncton",
"America/Monterrey": "Amerika/Monterrey",
"America/Montevideo": "Amerika/Montevideo",
"America/Montreal": "Amerika/Montreal",
"America/Montserrat": "Amerika/Montserrat",
"America/Nassau": "Amerika/Nassau",
"America/New_York": "Amerika/New_York",
"America/Nipigon": "Amerika/Nipigon",
"America/Nome": "America/Názov",
"America/Noronha": "Amerika/Noronha",
"America/North_Dakota/Beulah": "Amerika/Severná Dakota/Beulah",
"America/North_Dakota/Center": "Amerika/Severná Dakota/Centrum",
"America/North_Dakota/New_Salem": "Amerika/Severná Dakota/Nový Salem",
"America/Nuuk": "America/New",
"America/Ojinaga": "Amerika/Ojinaga",
"America/Panama": "Amerika/Panama",
"America/Pangnirtung": "Amerika/Pangnirtung",
"America/Paramaribo": "Amerika/Paramaribo",
"America/Phoenix": "Amerika/Phoenix",
"America/Port-au-Prince": "Amerika/Port-au-Prince",
"America/Port_of_Spain": "Amerika/Port_of_Španielska",
"America/Porto_Acre": "Amerika/Porto_Acre",
"America/Porto_Velho": "Amerika/Porto_Velho",
"America/Puerto_Rico": "Amerika/Puerto_Rico",
"America/Punta_Arenas": "Amerika/Punta_Arenas",
"America/Rainy_River": "Amerika/Rainy_River",
"America/Rankin_Inlet": "Amerika/Rankin_Inlet",
"America/Recife": "Amerika/Recife",
"America/Regina": "Amerika/Regína",
"America/Resolute": "Amerika/Resolute",
"America/Rio_Branco": "Amerika/Rio_Branco",
"America/Rosario": "Amerika/Rosario",
"America/Santa_Isabel": "Amerika/Santa_Isabel",
"America/Santarem": "Amerika/Santarem",
"America/Santiago": "Amerika/Santiago",
"America/Santo_Domingo": "Amerika/Santo_Domingo",
"America/Sao_Paulo": "Amerika/Sao_Paulo",
"America/Scoresbysund": "Amerika/Scoresbysund",
"America/Shiprock": "Amerika/Shiprock",
"America/Sitka": "Amerika/Sitka",
"America/St_Barthelemy": "Amerika/St_Barthelemy",
"America/St_Johns": "Amerika/St_Johns",
"America/St_Kitts": "Amerika/St_Kitts",
"America/St_Lucia": "Amerika/St_Lucia",
"America/St_Thomas": "Amerika/St_Thomas",
"America/St_Vincent": "Amerika/St_Vincent",
"America/Swift_Current": "Amerika/Swift_Current",
"America/Tegucigalpa": "Amerika/Tegucigalpa",
"America/Thule": "Amerika/Thule",
"America/Thunder_Bay": "Amerika/Thunder_Bay",
"America/Tijuana": "Amerika/Tijuana",
"America/Toronto": "Amerika/Toronto",
"America/Tortola": "Amerika/Tortola",
"America/Vancouver": "Amerika/Vancouver",
"America/Virgin": "Amerika/Panna",
"America/Whitehorse": "Amerika/Whitehorse",
"America/Winnipeg": "Amerika/Winnipeg",
"America/Yakutat": "Amerika/Yakutat",
"America/Yellowknife": "Amerika/Yellowknife",
"Antarctica/Casey": "Antarktída/Casey",
"Antarctica/Davis": "Antarktíka/Davis",
"Antarctica/DumontDUrville": "Antarktída/Dumontdurville",
"Antarctica/Macquarie": "Antarktída/Macquarie",
"Antarctica/Mawson": "Antarktída/Mawson",
"Antarctica/McMurdo": "Antarktída/McMurdo",
"Antarctica/Palmer": "Antarktída/Palmer",
"Antarctica/Rothera": "Antarktíka/Rothera",
"Antarctica/South_Pole": "Antarktída/Južný pól",
"Antarctica/Syowa": "Antarktída/Syowa",
"Antarctica/Troll": "Antarktíka/Troll",
"Antarctica/Vostok": "Antarktída/Vostok",
"Arctic/Longyearbyen": "Arktika/Longyearbyen",
"Asia/Aden": "Ázia/Aden",
"Asia/Almaty": "Ázia/Almaty",
"Asia/Amman": "Ázia/Ammán",
"Asia/Anadyr": "Ázia/Anadyr",
"Asia/Aqtau": "Ázia/Aqtau",
"Asia/Aqtobe": "Ázia/Aqtobe",
"Asia/Ashgabat": "Ázia/Ašchabad",
"Asia/Ashkhabad": "Ázia/Ashkhabad",
"Asia/Atyrau": "Ázia/Atyrau",
"Asia/Baghdad": "Ázia/Bagdad",
"Asia/Bahrain": "Ázia/Bahrajn",
"Asia/Baku": "Ázia/Baku",
"Asia/Bangkok": "Ázia/Bangkok",
"Asia/Barnaul": "Ázia/Barnaul",
"Asia/Beirut": "Ázia/Bejrút",
"Asia/Bishkek": "Ázia/Biškek",
"Asia/Brunei": "Ázia/Brunej",
"Asia/Calcutta": "Ázia/Kalkata",
"Asia/Chita": "Ázia/Čita",
"Asia/Choibalsan": "Ázia/Choibalsan",
"Asia/Chongqing": "Ázia/Chongqing",
"Asia/Chungking": "Ázia/Chungking",
"Asia/Colombo": "Ázia/Kolombo",
"Asia/Dacca": "Ázia/Dacca",
"Asia/Damascus": "Ázia/Damask",
"Asia/Dhaka": "Ázia/Dhaka",
"Asia/Dili": "Ázia/Dili",
"Asia/Dubai": "Ázia/Dubaj",
"Asia/Dushanbe": "Ázia/Dušanbe",
"Asia/Famagusta": "Ázia/Famagusta",
"Asia/Gaza": "Ázia/Gaza",
"Asia/Harbin": "Ázia/Harbin",
"Asia/Hebron": "Ázia/Hebron",
"Asia/Ho_Chi_Minh": "Ázia/Ho_Chi_Minh",
"Asia/Hong_Kong": "Ázia/Hongkong",
"Asia/Hovd": "Ázia/Hovd",
"Asia/Irkutsk": "Ázia/Irkutsk",
"Asia/Istanbul": "Ázia/Istanbul",
"Asia/Jakarta": "Ázia/Jakarta",
"Asia/Jayapura": "Ázia/Jayapura",
"Asia/Jerusalem": "Ázia/Jeruzalem",
"Asia/Kabul": "Ázia/Kábul",
"Asia/Kamchatka": "Ázia/Kamčatka",
"Asia/Karachi": "Ázia/Karáči",
"Asia/Kashgar": "Ázia/Kašgar",
"Asia/Kathmandu": "Ázia/Kathmandu",
"Asia/Katmandu": "Ázia/Katmandu",
"Asia/Khandyga": "Ázia/Khandyga",
"Asia/Kolkata": "Ázia/Kalkata",
"Asia/Krasnoyarsk": "Ázia/Krasnojarsk",
"Asia/Kuala_Lumpur": "Ázia/Kuala_Lumpur",
"Asia/Kuching": "Ázia/Kuching",
"Asia/Kuwait": "Ázia/Kuvajt",
"Asia/Macao": "Ázia/Macao",
"Asia/Macau": "Ázia/Macao",
"Asia/Magadan": "Ázia/Magadan",
"Asia/Makassar": "Ázia/Makassar",
"Asia/Manila": "Ázia/Manila",
"Asia/Muscat": "Ázia/Muškát",
"Asia/Nicosia": "Ázia/Nikózia",
"Asia/Novokuznetsk": "Ázia/Novokuznetsk",
"Asia/Novosibirsk": "Ázia/Novosibirsk",
"Asia/Omsk": "Ázia/Omsk",
"Asia/Oral": "Ázia/Orálne",
"Asia/Phnom_Penh": "Ázia/Phnom_Penh",
"Asia/Pontianak": "Ázia/Pontianak",
"Asia/Pyongyang": "Ázia/Pchjongjang",
"Asia/Qatar": "Ázia/Katar",
"Asia/Qostanay": "Ázia/Qostanay",
"Asia/Qyzylorda": "Ázia/Qyzylorda",
"Asia/Rangoon": "Ázia/Rangoon",
"Asia/Riyadh": "Ázia/Rijád",
"Asia/Saigon": "Ázia/Saigon",
"Asia/Sakhalin": "Ázia/Sachalin",
"Asia/Samarkand": "Ázia/Samarkand",
"Asia/Seoul": "Ázia/Soul",
"Asia/Shanghai": "Ázia/Šanghaj",
"Asia/Singapore": "Ázia/Singapur",
"Asia/Srednekolymsk": "Ázia/Srednekolymsk",
"Asia/Taipei": "Ázia/Tchaj-pej",
"Asia/Tashkent": "Ázia/Taškent",
"Asia/Tbilisi": "Ázia/Tbilisi",
"Asia/Tehran": "Ázia/Teherán",
"Asia/Tel_Aviv": "Ázia/Tel_Aviv",
"Asia/Thimbu": "Ázia/Thimbu",
"Asia/Thimphu": "Ázia/Thimphu",
"Asia/Tokyo": "Ázia/Tokio",
"Asia/Tomsk": "Ázia/Tomsk",
"Asia/Ujung_Pandang": "Ázia/Ujung_Pandang",
"Asia/Ulaanbaatar": "Ázia/Ulanbátar",
"Asia/Ulan_Bator": "Ázia/Ulan_Bator",
"Asia/Urumqi": "Ázia/Urumqi",
"Asia/Ust-Nera": "Ázia/Ust-Black",
"Asia/Vientiane": "Ázia/Vientiane",
"Asia/Vladivostok": "Ázia/Vladivostok",
"Asia/Yakutsk": "Ázia/Jakutsk",
"Asia/Yangon": "Ázia/Yangon",
"Asia/Yekaterinburg": "Ázia/Jekaterinburg",
"Asia/Yerevan": "Ázia/Jerevan",
"Atlantic/Azores": "Atlantic/Azorské ostrovy",
"Atlantic/Bermuda": "Atlantic/Bermudy",
"Atlantic/Canary": "Atlantic/Kanársky",
"Atlantic/Cape_Verde": "Atlantic/Cape_Green",
"Atlantic/Faeroe": "Atlantic/Faerské",
"Atlantic/Faroe": "Atlantic/Faerské",
"Atlantic/Jan_Mayen": "Atlantic/Jan_Mayen",
"Atlantic/Madeira": "Atlantic/Madeira",
"Atlantic/Reykjavik": "Atlantic/Reykjavík",
"Atlantic/South_Georgia": "Atlantic/Južná Georgia",
"Atlantic/St_Helena": "Atlantic/St_Helena",
"Atlantic/Stanley": "Atlantic/Stanley",
"Australia/ACT": "Austrália/ACT",
"Australia/Adelaide": "Austrália/Adelaide",
"Australia/Brisbane": "Austrália/Brisbane",
"Australia/Broken_Hill": "Austrália/Broken_Hill",
"Australia/Canberra": "Austrália/Canberra",
"Australia/Currie": "Austrália/Currie",
"Australia/Darwin": "Austrália/Darwin",
"Australia/Eucla": "Austrália/Eucla",
"Australia/Hobart": "Austrália/Hobart",
"Australia/LHI": "Austrália/LHI",
"Australia/Lindeman": "Austrália/Lindeman",
"Australia/Lord_Howe": "Austrália/Lord_Howe",
"Australia/Melbourne": "Austrália/Melbourne",
"Australia/NSW": "Austrália/NSW",
"Australia/North": "Austrália/Sever",
"Australia/Perth": "Austrália/Perth",
"Australia/Queensland": "Austrália/Queensland",
"Australia/South": "Austrália/Juh",
"Australia/Sydney": "Austrália/Sydney",
"Australia/Tasmania": "Austrália/Tasmánia",
"Australia/Victoria": "Austrália/Viktória",
"Australia/West": "Austrália/Západ",
"Australia/Yancowinna": "Austrália/Yancowinna",
"Brazil/Acre": "Brazília/Acre",
"Brazil/DeNoronha": "Brazília/Denoronha",
"Brazil/East": "Brazília/východ",
"Brazil/West": "Brazília/Západ",
"CET": "TO",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Kanada/Atlantický oceán",
"Canada/Central": "Kanada/Central",
"Canada/Eastern": "Kanada/východná",
"Canada/Mountain": "Kanada/Hora",
"Canada/Newfoundland": "Kanada/Newfoundland",
"Canada/Pacific": "Kanada/Pacifik",
"Canada/Saskatchewan": "Kanada/Saskatchewan",
"Canada/Yukon": "Kanada/Yukon",
"Chile/Continental": "Čile/kontinentálne",
"Chile/EasterIsland": "Čile/východný ostrov",
"Cuba": "Kuba",
"EET": "JESŤ",
"EST": "EST",
"EST5EDT": "EŠTE RAZ",
"Egypt": "Egypt",
"Eire": "Ear",
"Etc/GMT": "Atd/GMT",
"Etc/GMT+0": "atď/GMT+0",
"Etc/GMT+1": "atď/GMT+1",
"Etc/GMT+10": "atď/GMT+10",
"Etc/GMT+11": "Atc/GMT+11",
"Etc/GMT+12": "atď/GMT+12",
"Etc/GMT+2": "atď/GMT+2",
"Etc/GMT+3": "atď/GMT+3",
"Etc/GMT+4": "atď/GMT+4",
"Etc/GMT+5": "atď/GMT+5",
"Etc/GMT+6": "atď/GMT+6",
"Etc/GMT+7": "atď/GMT+7",
"Etc/GMT+8": "atď/GMT+8",
"Etc/GMT+9": "atď/GMT+9",
"Etc/GMT-0": "atď/GMT-0",
"Etc/GMT-1": "atď/GMT-1",
"Etc/GMT-10": "atď/GMT-10",
"Etc/GMT-11": "atď/GMT-11",
"Etc/GMT-12": "Atc/GMT-12",
"Etc/GMT-13": "atď/GMT-13",
"Etc/GMT-14": "atď/GMT-14",
"Etc/GMT-2": "Atc/GMT-2",
"Etc/GMT-3": "atď/GMT-3",
"Etc/GMT-4": "atď/GMT-4",
"Etc/GMT-5": "atď/GMT-5",
"Etc/GMT-6": "atď/GMT-6",
"Etc/GMT-7": "atď/GMT-7",
"Etc/GMT-8": "atď/GMT-8",
"Etc/GMT-9": "atď/GMT-9",
"Etc/GMT0": "Atc/GMT0",
"Etc/Greenwich": "Atb/Greenwich",
"Etc/UCT": "ATC/UCT",
"Etc/UTC": "atď/UTC",
"Etc/Universal": "Atc/Univerzálne",
"Etc/Zulu": "Atc/Zulu",
"Europe/Amsterdam": "Európa/Amsterdam",
"Europe/Andorra": "Európa/Andorra",
"Europe/Astrakhan": "Európa/Astrachán",
"Europe/Athens": "Európa/Atény",
"Europe/Belfast": "Európa/Belfast",
"Europe/Belgrade": "Európa/Belehrad",
"Europe/Berlin": "Európa/Berlín",
"Europe/Bratislava": "Európa/Bratislava",
"Europe/Brussels": "Európa/Brusel",
"Europe/Bucharest": "Európa/Bukurešť",
"Europe/Budapest": "Európa/Budapešť",
"Europe/Busingen": "Európa/Busingen",
"Europe/Chisinau": "Európa/Kišiňov",
"Europe/Copenhagen": "Európa/Kodaň",
"Europe/Dublin": "Európa/Dublin",
"Europe/Gibraltar": "Európa/Gibraltár",
"Europe/Guernsey": "Európa/Guernsey",
"Europe/Helsinki": "Európa/Helsinki",
"Europe/Isle_of_Man": "Európa/Ostrov_človeka",
"Europe/Istanbul": "Európa/Istanbul",
"Europe/Jersey": "Európa/Jersey",
"Europe/Kaliningrad": "Európa/Kaliningrad",
"Europe/Kiev": "Európa/Kyjev",
"Europe/Kirov": "Európa/Kirov",
"Europe/Kyiv": "Európa/Kyjev",
"Europe/Lisbon": "Európa/Lisabon",
"Europe/Ljubljana": "Európa/Ľubľana",
"Europe/London": "Európa/Londýn",
"Europe/Luxembourg": "Európa/Luxembursko",
"Europe/Madrid": "Európa/Madrid",
"Europe/Malta": "Európa/Malta",
"Europe/Mariehamn": "Európa/Mariehamn",
"Europe/Minsk": "Európa/Minsk",
"Europe/Monaco": "Európa/Monako",
"Europe/Moscow": "Európa/Moskva",
"Europe/Nicosia": "Európa/Nikózia",
"Europe/Oslo": "Európa/Oslo",
"Europe/Paris": "Európa/Paríž",
"Europe/Podgorica": "Európa/Podgorica",
"Europe/Prague": "Európa/Praha",
"Europe/Riga": "Európa/Riga",
"Europe/Rome": "Európa/Rím",
"Europe/Samara": "Európa/Samara",
"Europe/San_Marino": "Európa/San_Marino",
"Europe/Sarajevo": "Európa/Sarajevo",
"Europe/Saratov": "Európa/Saratov",
"Europe/Simferopol": "Európa/Simferopol",
"Europe/Skopje": "Európa/Skopje",
"Europe/Sofia": "Európa/Sofia",
"Europe/Stockholm": "Európa/Štokholm",
"Europe/Tallinn": "Európa/Tallinn",
"Europe/Tirane": "Európa/Tirane",
"Europe/Tiraspol": "Európa/Tiraspol",
"Europe/Ulyanovsk": "Európa/Ulyanovsk",
"Europe/Uzhgorod": "Európa/Užgorod",
"Europe/Vaduz": "Európa/Vaduz",
"Europe/Vatican": "Európa/Vatikán",
"Europe/Vienna": "Európa/Viedeň",
"Europe/Vilnius": "Európa/Vilnius",
"Europe/Volgograd": "Európa/Volgograd",
"Europe/Warsaw": "Európa/Varšava",
"Europe/Zagreb": "Európa/Záhreb",
"Europe/Zaporozhye": "Európa/Zaporožje",
"Europe/Zurich": "Európa/Zürich",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HST",
"Hongkong": "Hongkong",
"Iceland": "Island",
"Indian/Antananarivo": "Indic/Antananarivo",
"Indian/Chagos": "Indic/Chagos",
"Indian/Christmas": "Indicky/Vianoce",
"Indian/Cocos": "Indický/Kokos",
"Indian/Comoro": "Indic/Komorské",
"Indian/Kerguelen": "Indic/Kerguelen",
"Indian/Mahe": "Indic/Mahe",
"Indian/Maldives": "Indicky/Maldivy",
"Indian/Mauritius": "Indic/Maurícius",
"Indian/Mayotte": "Indic/Mayotte",
"Indian/Reunion": "Indic/Reunion",
"Iran": "Irán",
"Israel": "Izrael",
"Jamaica": "Jamajka",
"Japan": "Japonsko",
"Kwajalein": "Kvajalein",
"Libya": "Líbya",
"MET": "STRETOL SA",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Mexiko/Bajanorte",
"Mexico/BajaSur": "Mexiko/Bajasur",
"Mexico/General": "Mexiko/Všeobecné",
"NZ": "NZ",
"NZ-CHAT": "NZ-CHAT",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Pacific/Apia",
"Pacific/Auckland": "Pacific/Auckland",
"Pacific/Bougainville": "Pacifik/Bougainville",
"Pacific/Chatham": "Pacifik/Chatham",
"Pacific/Chuuk": "Pacifik/Chuuk",
"Pacific/Easter": "Pacific/Veľká noc",
"Pacific/Efate": "Pacific/Efate",
"Pacific/Enderbury": "Pacifik/Enderbury",
"Pacific/Fakaofo": "Pacific/Fakaofo",
"Pacific/Fiji": "Pacifik/Fidži",
"Pacific/Funafuti": "Pacifik/Funafuti",
"Pacific/Galapagos": "Pacific/Galapágy",
"Pacific/Gambier": "Pacifik/Gambier",
"Pacific/Guadalcanal": "Pacific/Guadalcanal",
"Pacific/Guam": "Pacifik/Guam",
"Pacific/Honolulu": "Pacific/Honolulu",
"Pacific/Johnston": "Pacifik/Johnston",
"Pacific/Kanton": "Pacific/Kanton",
"Pacific/Kiritimati": "Pacific/Kiritimati",
"Pacific/Kosrae": "Pacifik/Kosrae",
"Pacific/Kwajalein": "Pacifik/Kwajalein",
"Pacific/Majuro": "Pacific/Major",
"Pacific/Marquesas": "Pacifik/Marquesas",
"Pacific/Midway": "Pacifik/Midway",
"Pacific/Nauru": "Pacifik/Nauru",
"Pacific/Niue": "Pacific/Niue",
"Pacific/Norfolk": "Pacifik/Norfolk",
"Pacific/Noumea": "Pacific/Noumea",
"Pacific/Pago_Pago": "Pacifik/Pago_Pago",
"Pacific/Palau": "Pacific/Palau",
"Pacific/Pitcairn": "Pacifik/Pitcairn",
"Pacific/Pohnpei": "Pacifik/Pohnpei",
"Pacific/Ponape": "Pacifik/Ponape",
"Pacific/Port_Moresby": "Pacifik/Port_Moresby",
"Pacific/Rarotonga": "Pacific/Rarotonga",
"Pacific/Saipan": "Pacifik/Saipan",
"Pacific/Samoa": "Pacific/Samoa",
"Pacific/Tahiti": "Pacifik/Tahiti",
"Pacific/Tarawa": "Pacifik/Tarawa",
"Pacific/Tongatapu": "Pacifik/Tongatapu",
"Pacific/Truk": "Pacific/Truk",
"Pacific/Wake": "Pacifik/Prebudenie",
"Pacific/Wallis": "Pacifik/Wallis",
"Pacific/Yap": "Pacific/Yap",
"Poland": "Poľsko",
"Portugal": "Portugalsko",
"ROC": "ROC",
"ROK": "ROK",
"Singapore": "Singapur",
"Turkey": "Turecko",
"UCT": "UCT",
"US/Alaska": "USA/Aljaška",
"US/Aleutian": "US/Aleutský",
"US/Arizona": "US/Arizona",
"US/Central": "US/Central",
"US/East-Indiana": "USA/Východná Indiana",
"US/Eastern": "USA/východ",
"US/Hawaii": "US/Havaj",
"US/Indiana-Starke": "US/Indiana-Starke",
"US/Michigan": "Spojené štáty/Michigan",
"US/Mountain": "US/Hora",
"US/Pacific": "USA/Pacifik",
"US/Samoa": "US/Samoa",
"UTC": "UTC",
"Universal": "Univerzálny",
"W-SU": "W-SU",
"WET": "MOKRÝ",
"Zulu": "Zulu",
}