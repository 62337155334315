import React from 'react'
import { View, Text } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'

function Banner() {
  return (
    <View
      style={{
        height: 40,
        backgroundColor: '#FFC0CB',
        flexDirection: 'row',
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          color: '#465D6D',
          width: '100%',
          fontSize: 20,
          fontWeight: 'bold',
          fontFamily: 'Inter',
        }}
      >
        <FontAwesome name="stethoscope" style={{ padding: 10 }} size={30} color="#465D6D" />
        CLINICIAN MODE
      </Text>
    </View>
  )
}

export default Banner
