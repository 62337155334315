import { getItemAsync } from 'expo-secure-store'
import _ from 'lodash'
import { MMKV } from 'react-native-mmkv'
import { persistor } from '../../store/configStore'

const storage = new MMKV()
export default {
  MMKVStorage: storage,
  addNewUser: (userDetails) => {
    const stringifiedUsers = storage.getString('users')
    let users = stringifiedUsers ? JSON.parse(stringifiedUsers) : {}
    if (!_.isEmpty(users[userDetails.id])) {
      delete users[userDetails.id]
    }
    users = {
      ...users,
      [userDetails.id]: {
        ...userDetails,
      },
    }
    storage.set('users', JSON.stringify(users))
  },
  getUserByUsername: (userName, users) => {
    const selectedUser = _.find(Object.values(users), (u) => _.isEqual(u.username, userName))
    return selectedUser
  },
  getUserById: (id) => {
    const stringifiedUsers = storage.getString('users')
    const users = stringifiedUsers ? JSON.parse(stringifiedUsers) : {}
    return !_.isEmpty(users) ? users[id] : {}
  },
  updateSessionContextOfUser: (id, sessionInfo) => {
    const stringifiedUsers = storage.getString('users')
    let users = JSON.parse(stringifiedUsers)
    users = {
      ...users,
      [id]: {
        ...users[id],
        sessionInfo,
      },
    }
    storage.set('users', JSON.stringify(users))
  },
  updateUser: (userDetails) => {
    const stringifiedUsers = storage.getString('users')
    const users = JSON.parse(stringifiedUsers)
    users[userDetails.id] = userDetails
    storage.set('users', JSON.stringify(users))
  },
  getCurrentUser: () => {
    const user = storage.getString('currentUser')
    return user ? JSON.parse(user) : {}
  },
  setCurrentUser: (user) => {
    storage.set('currentUser', JSON.stringify(user))
  },
  set: (key, val) => storage.set(key, val),
  getString: (key) => storage.getString(key),
  setItem: (key, val) => storage.set(key, JSON.stringify(val)),
  getItem: (key) => {
    const data = storage.getString(key)
    if (data) {
      return JSON.parse(data)
    }
    return data
  },
  delete: (key) => storage.delete(key),
  getBoolean: (key) => storage.getBoolean(key),
  clear: () => {
    storage.clearAll()
    persistor.purge();
    persistor.flush();
  },
}
// export
