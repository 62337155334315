// import SelectTimeZone from "./SelectTimeZone";
import { MaterialIcons } from '@expo/vector-icons'
import { Formik } from 'formik'
import WebModal from 'modal-react-native-web'
import { Body, Container, Header, Input, Item, Left, Right, Title } from 'native-base'
import React, { Component } from 'react'
import {
  ActivityIndicator,
  Dimensions,
  Modal as AppModal,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import * as Yup from 'yup'
import { styles } from '../components/styles/loginStyles'
import { backgroundColor } from '../containers/NavigationScreens'
import ChangeLanguage from './ChangeLanguage'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const Modal = Platform.OS === 'web' ? WebModal : AppModal

export default class SelectLanguage extends Component {
  state = {
    modalVisible: false,
    language: '',
    languageName: '',
    loadingList: true,
    languages: {
      en_US: 'English',
      fr_FR: 'Français',
      es_ES: 'Español',
      ja_JP: '日本語',
      ru_RU: 'русский',
    },
  }

  submitSelectedLanguage = (language, languageName) => {
    // alert(language);
    const {
      changeLanguage,
      screenProps: { setLocale },
      subjectPhoneNo,
      navigation,
    } = this.props
    changeLanguage(language, setLocale, subjectPhoneNo, navigation)
  }

  handleModalVisibility = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalVisible: !prevState.modalVisible,
    }))
  }

  render() {
    const {
      changeTimeZone,
      fromLogin,
      loading,
      selectedTimeZone,
      getTimeZoneList,
      timeZones,
      submitTimeZone,
      data,
      screenProps,
      changeLanguage,
      navigation,
    } = this.props
    const { t } = screenProps
    const { loadingList, language, modalVisible, languages } = this.state
    return (
      <Formik
        initialValues={{ language: language }}
        validationSchema={Yup.object({
          language: Yup.string().required('Select Language'),
        })}
        onSubmit={(values, formikActions) => {
          setTimeout(() => {
            this.submitSelectedLanguage(values.language)
            formikActions.setSubmitting(false)
          }, 500)
        }}
      >
        {(props) => {
          const { setFieldValue, setValues } = props
          const setLanguage = (language) => {
            setFieldValue('language', language)
          }
          return (
            <View style={{ flex: 1 }}>
              {loading && (
                <View
                  style={{
                    height: SCREEN_HEIGHT,
                    width: SCREEN_WIDTH,
                    position: 'absolute',
                    alignSelf: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ActivityIndicator
                    size="large"
                    color={backgroundColor}
                    overlayColor="rgba(0, 0, 0, 0.07)"
                  />
                </View>
              )}
              <View style={{ padding: 10 }}>
                <Text style={{ fontSize: 18, fontFamily: 'Inter' }}>Select Language</Text>
                <TouchableOpacity
                  onPress={() => {
                    this.handleModalVisibility()
                  }}
                  style={{
                    position: 'absolute',
                    marginTop: 30,
                    zIndex: 3,
                    height: 40,
                    width: '100%',
                  }}
                ></TouchableOpacity>
                <Item style={[styles.inputStyle]}>
                  <Input
                    disabled
                    style={[styles.inputText, { fontFamily: 'Inter' }]}
                    value={languages[props.values.language] || ''}
                    placeholder={'Tap here to select language'}
                    placeholderTextColor="#bdbdbd"
                  />
                </Item>
                {props.values.language == '' && props.touched.language && props.errors.language && (
                  <Text style={styles.error}>{props.errors.language}</Text>
                )}
                <TouchableOpacity
                  onPress={props.handleSubmit}
                  style={{
                    alignSelf: 'center',
                    alignItems: 'center',
                    padding: 10,
                    backgroundColor: '#9155FD',
                    marginTop: 30,
                    width: 100,
                    // marginLeft: 120,
                    borderRadius: 5,
                  }}
                >
                  <Text style={{ color: '#fff', alignItems: 'center', fontFamily: 'Inter' }}>
                    {t('Save')}
                  </Text>
                </TouchableOpacity>
              </View>
              <Modal
                style={{ width: '100%', height: '100%' }}
                transparent={true}
                animationType="slide"
                visible={modalVisible}
              >
                <View
                  style={
                    Platform.OS == 'web' && SCREEN_WIDTH >= 1030
                      ? {
                          flex: 1,
                          alignItems: 'center',
                          backgroundColor: '#b0bec5',
                          overflow: 'hidden',
                        }
                      : { flex: 1 }
                  }
                >
                  <Container
                    style={
                      Platform.OS == 'web'
                        ? { width: SCREEN_WIDTH >= 1030 ? 380 : '100%', height: '99%' }
                        : { width: '100%', backgroundColor: '#fff', alignSelf: 'center' }
                    }
                  >
                    <Header style={{ backgroundColor: backgroundColor }}>
                      <Left style={{ alignContent: 'flex-start', width: 10 }}>
                        <TouchableOpacity
                          onPress={() => {
                            this.handleModalVisibility()
                          }}
                        >
                          <Text>
                            <MaterialIcons name="arrow-back" color="white" size={24} />
                          </Text>
                        </TouchableOpacity>
                      </Left>
                      <Body style={{ alignContent: 'flex-start' }}>
                        <Title style={{ color: '#fff', fontFamily: 'Inter', fontSize: 16 }}>
                          Select Language
                        </Title>
                      </Body>
                      <Right></Right>
                    </Header>
                    <ChangeLanguage
                      setLanguage={setLanguage}
                      screenProps={screenProps}
                      changeLanguage={changeLanguage}
                      navigation={navigation}
                      selectedLang={language}
                      handleModalVisibility={this.handleModalVisibility}
                    />
                  </Container>
                </View>
              </Modal>
            </View>
          )
        }}
      </Formik>
    )
  }

  // const styles = StyleSheet.create({
  //   container: {
  //     padding: 10,
  //     justifyContent: "center"
  //   },
  //   cardItem: {
  //     justifyContent: "center",
  //     // backgroundColor: '#DDDDDD',
  //     padding: 10
  //   },
  //   button: {
  //     alignSelf: "center",
  //     alignItems: "center",
  //     padding: 10,
  //     backgroundColor: "#9155FD",
  //     marginTop: 30,
  //     width: 100,
  //     // marginLeft: 120,
  //     borderRadius:5
  //   },
  //   buttonText: {
  //     color: "#fff",
  //     alignItems: "center"
  //   }
  // });
}
