export default {
  TEXT: 'txt',
  TEXTAREA: 'txtarea',
  NUMBER: 'num',
  RADIO: 'singleslct',
  CHECKBOX: 'multislct',
  DATE: 'date',
  DATETIME: 'datetime',
  DATETIME12: 'datetime12',
  DATETIME24: 'datetime24',
  YESNO: 'yesno',
  YESNONA: 'yesnona',
  INSTRUCTION: 'instruct',
  PS: 'ps',
  NRS: 'nrs',
  VAS: 'vas',
  CLICKIMAGE: 'clkimg',
  PERCENT: 'percentage',
  IMAGE_UPLOAD: 'imgupload',
  VIDEO_UPLOAD: 'videoupload',
  AUDIO_UPLOAD: 'audioupload',
  BARCODE: 'barcode',
  SCORE: 'score',
  RESULT: 'result',
  LABEL: 'label',
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  // SCORE: 'score',
}
