import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUserTimezone } from '../selectors/commonSelector'

const SubjectTimezone = (props) => {
  const { dateTime, timezone, format } = props
  return moment.utc(dateTime, 'YYYY-MM-DD HH:mm:ss').clone().tz(timezone).format(format)
}

SubjectTimezone.defaultProps = {
  dateTime: '',
  format: 'DD-MMM-YYYY hh:mm A z',
}

const mapStateToProps = (state) => ({
  timezone: getUserTimezone(state) || moment.tz.guess(true),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SubjectTimezone)

export const getDateBySubjectTimezone = (subjectTimeZone) => {
  const dateTime = moment()
  const dateInSubjectTimezone = dateTime.clone().tz(subjectTimeZone).format('YYYY-MM-DD')
  return dateInSubjectTimezone
}

export const convertUTCtoAnotherTimezone = (dateTime, timezone, format) => {
  const utcCutoff = moment
    .utc(dateTime, 'YYYY-MM-DD HH:mm:ss')
    .clone()
    .tz('America/New_York')
    .format('DD-MMM-YYYY hh:mm:ss a z')
  const displayCutoff = utcCutoff.clone().tz('America/New_York')

  displayCutoff.format('DD-MMM-YYYY hh:mm:ss a z')
}
