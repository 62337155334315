import React, { Component } from 'react'
import { View, Text, Animated, Easing } from 'react-native'
import Dialog, { DialogContent, ScaleAnimation } from 'react-native-popup-dialog'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import PropTypes from 'prop-types'

 class Sync extends Component {
  constructor(props) {
    super(props)
    this.state = {
      spinAnim: new Animated.Value(0),
    }
  }

  componentDidMount() {
    Animated.loop(
      Animated.timing(this.state.spinAnim, {
        toValue: 1,
        duration: 3000,
        easing: Easing.linear,
        useNativeDriver: true,
      })
    ).start()
  }

  render() {
    const spin = this.state.spinAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    })

    const { syncing, screenProps: { t }} = this.props
    return (
      <View style={{ flex: 1, height: '90%', width: '100%' }}>
        { syncing && (
          <View
            style={{
              flex: 1,
              height: '90%',
              alignSelf: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: -50,
            }}
          >
            <Animated.Image
              source={require('../assets/images/cloud_load.gif')}
              style={{ width: 500, height: 300, borderRadius: 10 }}
            />
            <View
              style={{
                position: 'absolute',
                paddingTop: 30,
                width: 500,
                //  backgroundColor: 'yellow'
              }}
            >
              <Text
                style={{
                  color: 'grey',
                  fontFamily: 'Inter',
                  fontSize: 20,
                  alignSelf: 'center',
                }}
              >
                {t('Syncing')}
              </Text>
            </View>
          </View>
        )}
      </View>
    )
  }
}



Sync.defaultProps = {
  syncing: false,
  screenProps: {
    t: ()=> null
  }
}

Sync.propTypes = {
  syncing: PropTypes.bool,
  screenProps: PropTypes.shape({
    t: PropTypes.func,
  }),
}
export default Sync

export function SyncFinished({ showSyncingCompleted, screenProps: { t } }) {
  return (
    <Dialog
      width={0.9}
      visible={showSyncingCompleted}
      rounded
      dialogAnimation={new ScaleAnimation()}
      footer={<View />}
    >
      <DialogContent
        style={{
          backgroundColor: '#fff',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 10,
          paddingHorizontal: 5,
        }}
      >
        <MaterialCommunityIcons name="cloud-check" size={120} color="#4caf50" />
        <Text style={{ color: 'grey', fontFamily: 'Inter', fontSize: 20, alignSelf: 'center' }}>
          {t('SyncSuccess')}
        </Text>
      </DialogContent>
    </Dialog>
  )
}
