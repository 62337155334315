import React from 'react'
import { View, Text } from 'react-native'
import CheckBox from '../fields/CheckBox'
import _ from 'lodash'
import PropTypes from 'prop-types'

const ImageOptions = (props) => {
  const { areas = [], selectedOptions = [], disabled, selfScored } = props
  return (
    <View style = {{ flexDirection: 'column'}}>
      {areas.map((option, index) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 2
          }}
          key={`click-checkbox-${index}`}
        >
          {selfScored &&
          <Text style={{ flex: 0.1, textAlign: 'right' }}>{option.score}</Text>}
          <CheckBox
            checked={selectedOptions && _.findIndex(selectedOptions, (s) => s === option?.oid) >= 0}
            disabled={disabled}
            style={{
              fillColor: '#ffffff',
              tickColor: '#0d47a1',
              borderColor: '#9e9e9e',
            }}
            testID={`options-checkbox-${index}`}
          />
          <Text style={{ flex: 0.9 }}>{option.key + ' - ' + option.value}</Text>
        </View>
      ))}
    </View>
  )
}

ImageOptions.defaultProps = {
  areas: [],
  selectedOptions: [],
  disabled: false,
  selfScored: false,
}
ImageOptions.propTypes = {
  areas: PropTypes.array,
  selectedOptions: PropTypes.array,
  disabled: PropTypes.bool,
  selfScored: PropTypes.bool,
}
export default ImageOptions
