import React from 'react'
import { View } from 'react-native'
import Profile from '../clinroHeader/components/profile'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from '../../../actions/users'
import { getFullName, getRoleDetails } from '../../../selectors/user'
import { backgroundColor } from '../../../containers/NavigationScreens'
import constants from '../../../constants/constants'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { getCrfVersion, getStudyData, getUserStudySite } from '../../../selectors/studyMetaData'
import { getSubject, getUser } from '../../../selectors/commonSelector'
import PropTypes from 'prop-types'

const ProfileHeader = (props) => {
  const {
    navigation,
    screenProps,
    logout: signoutUser,
    fullName,
    roleDetails,
    study,
    subject,
    site,
    versionInfo,
    currentUser
  } = props
  
  const { appStatus } = useSelector((state) => state)

  const logoutAction = () => {
    try {
      signoutUser()
      navigation.navigate('Login', { appType: constants.AppType.SITESTAFF })
    } catch (error) {
      console.log(error)
    }
  }

  const getUserName = () => {
    const userFullName = fullName
    const userNameArr = userFullName.split(' ')
    return userNameArr[0].charAt(0) + '' + userNameArr[1].charAt(0)
  }

  if(_.isEqual(appStatus.appType, constants.AppType.SITESTAFF)){
  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: null,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: 5,
        }}
      >
        <Profile
          name={getUserName()}
          fullName={fullName}
          role={roleDetails}
          organizationName={currentUser?.userData.data.keycloakRealm}
          title={currentUser?.userData.data.jobTitle || 'Title'}
          logoutAction={() => logoutAction()}
          screenProps={screenProps}
          study={study}
          subject={subject}
          site={site}
          versionInfo={versionInfo}
        />
      </View>
    </View>
  )
 }

 return(<View></View>)
}


const mapStateToProps = (state) => ({
  fullName: getFullName(state),
  site: getUserStudySite(state),
  appType: state.appStatus.appType,
  roleDetails: getRoleDetails(state),
  currentUser: getUser(state),
  study: getStudyData(state),
  subject: getSubject(state),
  versionInfo: getCrfVersion(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch
  )



  ProfileHeader.defaultProps = {
    currentUser: {
      userData: {
        data: {
          jobTitle: null,
          keycloakRealm: null
        },
      }
    },
    navigation: {},
    logout: () => null,
    fullName: null,
    roleDetails: null,
    study: {},
    subject : {},
    site: {},
    versionInfo: {},
  }
  
  ProfileHeader.propTypes = {
    currentUser: PropTypes.shape({
      userData: PropTypes.shape({
        data: PropTypes.shape({
          jobTitle: PropTypes.string,
          keycloakRealm: PropTypes.string
        })
      })
    }),
    navigation: PropTypes.instanceOf(Object),
    logout: PropTypes.func,
    fullName: PropTypes.string,
    roleDetails: PropTypes.string,
    study: PropTypes.instanceOf(Object),
    subject : PropTypes.instanceOf(Object),
    site: PropTypes.instanceOf(Object),
    versionInfo: PropTypes.instanceOf(Object),
    
  }

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader)
