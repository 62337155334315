import React, { Component } from 'react'
import StudyList from '../components/study/list'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { retrieveStudies, updateSelectedStudy } from '../actions/study'
import { NavigationEvents } from 'react-navigation'
import { getUserStudySite } from '../selectors/studyMetaData'
import { updateSubject } from '../actions/subject'

class StudyListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      studies: [],
      list: [],
      searchTerm: ''
    }
  }

  componentDidMount() {
    this.getStudies()
  }

  getStudies = async () => {
    const { retrieveStudies: retrieveStudiesOfSite, siteId } = this.props
    this.setState({loading: true})
    try {
      const studies = await retrieveStudiesOfSite(siteId)
      this.setState({studies, loading: false, list: studies})
    } catch (error) {
      console.log(error)
      this.setState({loading: false})
    }
  }

  clearStudy = async () => {
    const { updateSelectedStudy: updateSelectedStudyInStore } = this.props
    this.setState({searchTerm: ''})
    updateSelectedStudyInStore(null)
    updateSubject(null)
  }

  onStudySelect = (study) => {
    const { updateSelectedStudy: updateSelectedStudyInStore, navigation } = this.props
    updateSelectedStudyInStore(study)
    navigation.navigate('SubjectListPage')
  }

  onSearchTerm = (searchTerm) => {
    const {studies} = this.state
    this.setState({searchTerm: searchTerm})
    if(!_.isEmpty(searchTerm)){
      const filteredStudies = studies.filter((study) => _.includes(_.lowerCase(study?.studyNumber), _.lowerCase(searchTerm)))
      this.setState({list: filteredStudies})
    }else{
      this.setState({list: studies})
    }
  }

  render() {
    const { list, loading , searchTerm} = this.state
    return (
      <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            this.clearStudy()
            this.getStudies()
          }}
        />
        <StudyList list={list} onStudySelect={this.onStudySelect} loading={loading} onSearchTerm={this.onSearchTerm} searchTerm={searchTerm}/>
      </View>
    )
  }
}

StudyListPage.defaultProps = {
  siteId: '',
}

StudyListPage.propTypes = {
  siteId: PropTypes.string,
}

const mapStateToProps = (state) => ({
  siteId: getUserStudySite(state)?.id,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveStudies,
      updateSelectedStudy,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StudyListPage)
