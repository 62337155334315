import { Text, View } from 'react-native'
import React, { Component } from 'react'

export default class Healthkit extends Component {
  render() {
    return (
      <View>
        <Text />
      </View>
    )
  }
}
