import { StyleSheet, Dimensions } from 'react-native'

const DEVICE_WIDTH = Dimensions.get('window').width
export const styles = StyleSheet.create({
  picture: {
    flex: 1,
    width: DEVICE_WIDTH,
    height: null,
  },
  loginContainer: {
    flex: 1,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 10,
  },
  loginDetailsContainer: {
    flex: 1.2,
    paddingVertical: 10,
    justifyContent: 'flex-start',
  },
  submitContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  changeLanguageContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  error: {
    fontFamily: 'Inter',
    fontSize: 11,
    color: '#f44336',
    padding: 5,
  },
  inputContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  inputStyle: {
    borderBottomWidth: 2,
  },
  inputText: {
    color: 'grey',
    marginLeft: 10,
    fontFamily: 'Inter',
  },
  icon: {
    paddingLeft: 10,
  },
  submitButton: {
    alignSelf: 'flex-end',
    backgroundColor: '#ffa726',
    borderRadius: 5,
  },
  buttonText: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: '#ffa726',
    textTransform: 'uppercase',
  },
  image: {
    width: 400,
    height: 100,
  },
  errorText: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: '#f44336',
    alignSelf: 'flex-start',
    marginTop: 4,
  },
  textInputStyle: {
    color: 'grey',
    fontFamily: 'Inter',
    height: 40,
    padding: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#cccccc',
  },
})
