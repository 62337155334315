import { View, Text } from 'react-native'
import React from 'react'
import PropTypes from 'prop-types'

function Result(props) {
  const {t} = props
  return (
    <View>
      <Text>{t('Result')}</Text>
    </View>
  )
}

Result.defaultProps = {
    t: ()=> null
}

Result.propTypes = {
    t: PropTypes.func
}

export default Result
