import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import { getUserId } from '../selectors/user'
import { getStudyData, getUserStudySite } from '../selectors/studyMetaData'

export const UPDATE_SELECTED_SUBJECT = createActionType('UPDATE_SELECTED_SUBJECT')
export const CLEAR_SELECTED_SUBJECT = createActionType('CLEAR_SELECTED_SUBJECT')

//To retrieve subjects for clinician
export const retrieveSubjects = (siteId) => async (dispatch, getState) => {
  const site = getUserStudySite(getState())
  const study = getStudyData(getState())
  try {
    const res = await api.get(
      `/${site.primaryOrganizationCode}/studies/${study.id}/sites/${siteId}/subjects`
    )
    return res.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateSubject = (subjectData, userId) => async (dispatch, getState) => {
  if(_.isEmpty(userId)){
    userId = getUserId(getState())
  }
  dispatch({
    type: UPDATE_SELECTED_SUBJECT,
    subject: subjectData,
    userId,
  })
}

export const clearSelectedSubject = (userId) => async (dispatch, getState) => {
  if(_.isEmpty(userId)){
    userId = getUserId(getState())
  }
  dispatch({
    type: CLEAR_SELECTED_SUBJECT,
    userId,
  })
}

