import React from "react"
import { StyleSheet, View } from "react-native"
import { Slider } from '@miblanchard/react-native-slider'
import PropTypes from 'prop-types'

const HorizontalScale = (props) => {
    const {trackComponent, customThumb, trackMarks, style} = props
    
    return (
        <View style={styles.container}>
            <Slider 
                {...props}
                renderTrackMarkComponent={(index) => trackComponent({index: (trackMarks[index].toFixed(1)*10)/10})}
                renderThumbComponent={customThumb}
                containerStyle={style}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
HorizontalScale.defaultProps = {
    trackComponent: ()=> null,
    customThumb: () => null,
    trackMarks: [],
    style: {}
  }
  HorizontalScale.propTypes = {
    trackComponent: PropTypes.func,
    customThumb: PropTypes.func,
    trackMarks: PropTypes.array,
    style: PropTypes.object
  }
  
export default HorizontalScale