import React from 'react'
import { View, Text } from 'react-native'
import styles from './styles/noDiaryStyles'
import PropTypes from 'prop-types'

function NoDiary(props) {
  const {
    screenProps: { t },
  } = props
  return (
    <View style={styles.noDiaryLabelContainer}>
      <Text style={styles.noDiaryText}>{t('NoDiary')}</Text>
    </View>
  )
}

NoDiary.propTypes = {
  screenProps: PropTypes.instanceOf(Object).isRequired,
}

export default NoDiary
