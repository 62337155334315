import { MaterialIcons } from '@expo/vector-icons'
import { Body, Container, Content, List, ListItem, Right } from 'native-base'
import React, { Component } from 'react'
import { BackHandler, Text, View } from 'react-native'
import tryalLogoBox from '../../assets/base64/tryalLogoBox'
import AutoHeightImage from './AutoHeightImage'
import styles from './sideBar/styles'

class AppLock extends Component {
  state = {}

  componentWillMount() {
    BackHandler.addEventListener('hardwareBackPress', () => false)
  }

 
  render() {
    const {
      navigation,
      screenProps: { t },
    } = this.props
    return (
      <Container>
        <Content style={{ backgroundColor: '#FFFFFF' }}>
          <View
            style={{ flex: 0.5, alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}
          >
            <AutoHeightImage
              source={{
                uri: `data:image/png;base64,${tryalLogoBox}`,
              }}
              width={100}
            />
          </View>
          <List>
            <ListItem
              style={{ marginTop: 10 }}
              icon
              onPress={() => {
                setTimeout(() => {
                  navigation.navigate('PinSetup', { changePin: true })
                }, 0)
              }}
            >
              <Body>
                <Text style={styles.text}>{t('PIN')}</Text>
              </Body>
              <Right>
                <MaterialIcons name="keyboard-arrow-right" size={24} />
              </Right>
            </ListItem>
          </List>
        </Content>
      </Container>
    )
  }
}


export default AppLock
