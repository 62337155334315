import React , { useMemo } from 'react'
import { View,Platform,Alert} from 'react-native'
import WebAlert from '../utils/WebAlert'
import _ from 'lodash'
import moment from 'moment-timezone'
const NoPopUp =  (props) => useMemo(() => {
  const { t, isTimeZoneNoClicked, setOrAckTimezoneChange, configuredValue, appStatus, ackTimezoneForNoChoice, buttons } = props
  return (
    <View>
    {Platform.OS === 'web' && (
      <WebAlert
      headerText={''}
      hideModal={ackTimezoneForNoChoice}
      t={t}
      action={ackTimezoneForNoChoice}
      message={t('DeviceTimezoneNoClicked')}
      buttons = {buttons}
    />
   )}
   {Platform.OS !== 'web' && (
      Alert.alert(
        '',
        t('DeviceTimezoneNoClicked'),
        [
          {
            text: t('OK'),
            onPress: async () => ackTimezoneForNoChoice(),
          },
        ],
        { cancelable: false }
      )
    )
   }
 </View>
  );
}, [props?.configuredValue, props?.isTimeZoneNoClicked])
const TimeZoneNoAlert = (props) => {
    const { t, isTimeZoneNoClicked, setOrAckTimezoneChange, configuredValue, appStatus } = props
    const inferredValue = moment.tz.guess(true);
    const ackTimezoneForNoChoice = () => {
      setOrAckTimezoneChange(false)
      isTimeZoneNoClicked(false)
    }
    const buttons = [ 
      { 'title': 'OK', 'action': () => ackTimezoneForNoChoice() }, 

  ]
    return ( inferredValue && configuredValue && <NoPopUp
      t ={t}
      isTimeZoneNoClicked ={isTimeZoneNoClicked}
      setOrAckTimezoneChange = {setOrAckTimezoneChange}
      configuredValue={configuredValue}
      appStatus={appStatus}
      ackTimezoneForNoChoice={ackTimezoneForNoChoice}
      buttons={buttons}
      />)
}
export default TimeZoneNoAlert