import React, { Component } from 'react'
import { Container, ListItem, Item, Input, Body, Icon } from 'native-base'
import PropTypes from 'prop-types'
import { View, FlatList, ActivityIndicator, Text } from 'react-native'
import styles from '../../styles/listStyles'
import appConstants from '../../constants/constants'
import moment from 'moment'

class SubjectList extends Component {
  constructor(props) {
    super(props)
  }

  renderList = () => {
    const { list, onSubjectSelect} = this.props
    if(list.length> 0){
      //----------------------List------------------
      return (
        <FlatList
        data={list}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <ListItem
            thumbnail
            onPress={() => {
              onSubjectSelect(item)
            }}
          >
            <Body>
              <View style={{ justifyContent: 'flex-start' }}>
                <Text style={styles.textStyle}>{item.subjectNo}</Text>
                <Text style={styles.subTextStyle}>
                  Status: {appConstants.subjectStatus[item.status]}
                </Text>
                <Text style={styles.subTextStyle}>
                  Diary Start Date:{' '}
                  {item.diaryStartDate ? moment(item.diaryStartDate).format('DD-MMM-YYYY') : ''}
                </Text>
              </View>
            </Body>
          </ListItem>
        )}
      />
      )
    }else{
      // -----------------No Data----------------
      return ( 
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text style={[styles.textStyle, { textAlign: 'center' }]}>No data.</Text>
      </View>)
    }
  }

  render() {
    const { loading, onSearchTerm, searchTerm} = this.props

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <View style={{ margin: 10 }}>
          <Item searchBar rounded>
            <Icon name="ios-search" style={{ padding: 2 }} />
            <Input
              placeholder="Search"
              style={styles.textStyle}
              autoCorrect={false}
              value={searchTerm}
              onChangeText={(term) => {
                onSearchTerm(term)
              }}
            />
          </Item>
        </View>

        {/* ------------- Loading ------------------------ */}
        {loading ? (
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} />
        ) : this.renderList()}
      </Container>
    )
  }
}

SubjectList.defaultProps = {
  onSubjectSelect: () => null,
  loading: false,
  list: [],
  searchTerm: null,
  onSearchTerm: () => null,
}
SubjectList.propTypes = {
  onSubjectSelect: PropTypes.func,
  loading: PropTypes.bool,
  list : PropTypes.array,
  searchTerm: PropTypes.string,
  onSearchTerm: PropTypes.func,
}

export default SubjectList
// export default SubjectList
