import React from 'react'
import { connect } from 'react-redux'
import { View, Text, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import styles from './styles/trainingStyles'
import Constants from 'expo-constants'
import appConstants from '../constants/constants'
import { store } from '../store/configStore'
import { getSubject } from '../selectors/commonSelector'

const getAppType = () => {
  const state = store.getState()
  const applicationType = state.appStatus.appType
  return applicationType
}

const navigateToDiary = (navigation) => {
  navigation.navigate('Diary', {
    isTraining: true,
  })
}

function Training(props) {
  const {
    isTrainingCompleted,
    screenProps: { t },
    showTrainingNotCompleted,
    navigation,
  } = props
  if (showTrainingNotCompleted) {
    return (
      <View style={styles.completeTrainingContainer}>
        <Text style={styles.trainingText}>{t('CompleteTraining')}</Text>
      </View>
    )
  }
  if (!isTrainingCompleted) {
    return (
      <View style={styles.startTrainingContainer}>
        <Text style={styles.trainingInfoStyle}>{t('TrainingNotCompleted')}</Text>
        <TouchableOpacity onPress={() => navigateToDiary(navigation)} style={styles.buttonStyle}>
          <Text style={styles.trainingText}>{t('StartTraining')}</Text>
        </TouchableOpacity>
      </View>
    )
  }
  return (
    <TouchableOpacity onPress={() => navigateToDiary(navigation)} style={styles.buttonStyle}>
      <Text style={styles.trainingText}>{t('ViewCompletedTraining')}</Text>
    </TouchableOpacity>
  )
}

Training.defaultProps = {
  isTrainingCompleted: false,
  showTrainingNotCompleted: false,
}
Training.propTypes = {
  isTrainingCompleted: PropTypes.bool,
  navigation: PropTypes.instanceOf(Object).isRequired,
  screenProps: PropTypes.instanceOf(Object).isRequired,
  showTrainingNotCompleted: PropTypes.bool,
}
const mapStateToProps = (state) => ({
  isTrainingCompleted:
    getAppType() === appConstants.AppType.SITESTAFF
      ? true
      : getSubject(state)?.trainingCompleted,
})

export default connect(mapStateToProps)(Training)
