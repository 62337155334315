import React from 'react'
import ParticipantLoginPage from './components/loginPage'
import _ from 'lodash'
import constants from '../../constants/constants'
import { _getParamFromURL } from '../../utils/util'
import SiteStaffLogin from './components/SiteStaffLogin'

const Login = (props) => {
  const { navigation, screenProps } = props
  const appType = navigation.getParam('appType')

  if (_.isEqual(appType, constants.AppType.SITESTAFF)) {
    return <SiteStaffLogin navigation={navigation} screenProps={screenProps} />
  } else {
    return <ParticipantLoginPage navigation={navigation} screenProps={screenProps} />
  }
}

export default Login
