
import { useEffect } from 'react';
import { decryptString } from '../../utils/cryptoUtil'
import storage from '../../utils/storage/storage'
import constants from '../../constants/constants';
import { validateToken } from '../../actions/users';
import { format } from '../../utils/stringUtils';
import { CALL_SUBSCRIPTION, CHAT_UNREAD_COUNT_SUBSCRIPTION, QUESTIONNAIRE_VERSION_UPGRADE_SUBSCRIPTION, SCHEDULE_VERSION_UPGRADE_SUBSCRIPTION } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveUnreadChats, setUnreadChats } from '../../actions/chat';
import { getselectedChat } from '../../selectors/session';
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import properties from '../../constants/appConstants'
import { questionnaireUpgradeRequired } from '../../actions/session';
import { Platform } from 'react-native';
import NotificationSetup from '../Notifications/NotificationSetup';
import PropTypes from 'prop-types';
import _ from 'lodash'

const CustomSocket = (props) => {
  const { user, children, appType, subject, onCallJoinRequest} = props;

  let questionnaireVersionUpgradeSubscription = null;
  let scheduleVersionUpgrdaeSubscription = null;
  let callSubscription = null;
  let chatSubscription = null;  

  let questionnaireVersionUpgradeConnection = null;
  let scheduleVersionUpgrdaeConnection = null;
  let callConnection = null;
  let chatConnection = null;  

  const dispatch = useDispatch();
  const selectedChat = useSelector(state => getselectedChat(state))
  const getHeaders = async () => {
    if(appType === constants.AppType.SITESTAFF){
      const data = await validateToken()
      if (!_.isEmpty(data)) {
        return {
          Authorization: data.accessToken,
          Realmname: data.keycloakRealm,
          PrimaryOrganizationcode: data.primaryOrganizationCode
        }
      }
    }
      return {
        Authorization: `${decryptString(storage.getString(user?.id + 'SubjectAuthorizationToken'))}`
      }
  }

  const connectScheduleVersionUpgradeSocket = async (headers) => {
    let sockConfig = new SockJS(properties.socketUrl)
    const socketConnection = Stomp.over(sockConfig)
    if (socketConnection) {
      socketConnection.connect(headers, () => {
        console.log('socket connected');
        scheduleVersionUpgrdaeConnection = socketConnection
        scheduleVersionUpgrdaeSubscription =  socketConnection.subscribe(format(SCHEDULE_VERSION_UPGRADE_SUBSCRIPTION, subject?.id), (message) => {
          onMessageReceived(message.body, "SCHEDULE_VERSION_UPGRADE");
        }, headers)
      }, (e) => console.log("Failed to connect socket", e))
      
    }
  }

  const connectQuestionnaireVersionUpgradeSocket = async (headers) => {
    let sockConfig = new SockJS(properties.socketUrl)
    const socketConnection = Stomp.over(sockConfig)
    if (socketConnection) {
      socketConnection.connect(headers, () => {
        console.log('socket connected');
        questionnaireVersionUpgradeConnection = socketConnection
        questionnaireVersionUpgradeSubscription =  socketConnection.subscribe(format(QUESTIONNAIRE_VERSION_UPGRADE_SUBSCRIPTION, subject?.id), (message) => {
          onMessageReceived(message.body, "QUESTIONNAIRE_VERSION_UPGRADE");
        }, headers)
      }, (e) => console.log("Failed to connect socket", e))
      
    }
  }

  const connectChatSocket = async (headers) => {
    let sockConfig = new SockJS(properties.socketUrl)
    const socketConnection = Stomp.over(sockConfig)
    if (socketConnection) {
      socketConnection.connect(headers, () => {
        console.log('socket connected');
        chatConnection = socketConnection
        chatSubscription =  socketConnection.subscribe(format(CHAT_UNREAD_COUNT_SUBSCRIPTION, subject?.id), (message) => {
          onMessageReceived(message.body, "CHAT_UNREAD_COUNT");
        }, headers)
      }, (e) => console.log("Failed to connect socket", e))
      
    }
  }

  const connectCallSocket = async (headers) => {
    let sockConfig = new SockJS(properties.socketUrl)
    const socketConnection = Stomp.over(sockConfig)
    if (socketConnection) {
      socketConnection.connect(headers, () => {
        console.log('socket connected');
        callConnection = socketConnection
        callSubscription =  socketConnection.subscribe(format(CALL_SUBSCRIPTION, subject?.id), (message) => {
          onMessageReceived(message.body, "CALL_SUBSCRIPTION");
        }, headers)
      }, (e) => console.log("Failed to connect socket", e))
      
    }
  }

  const disconnectSocket = () => {
      unsubscribeSubjectTopics();
  }
  const unsubscribeSubjectTopics = () => {
    if(questionnaireVersionUpgradeSubscription){
      questionnaireVersionUpgradeConnection.disconnect()
      questionnaireVersionUpgradeSubscription.unsubscribe()
    }
    if(scheduleVersionUpgrdaeSubscription){
      scheduleVersionUpgrdaeConnection.disconnect()
      scheduleVersionUpgrdaeSubscription.unsubscribe()
    }
    if(chatSubscription){
      chatConnection.disconnect()
      chatSubscription.unsubscribe()
    } 
    if(callSubscription){
      callConnection.disconnect()
      callSubscription.unsubscribe()
    }
  }

  const handleUnreadChatsMessage = (chatIds) => {
    let loChatIds = JSON.parse(chatIds)
    _.remove(loChatIds, (chatId) => selectedChat?.id === chatId)
    const count = loChatIds.length
    dispatch(setUnreadChats({ unreadChatIds: loChatIds, count }))
  }
  const onMessageReceived = (message, type) => {
    console.log("Message recieved", message)
    switch (type) {
      case "QUESTIONNAIRE_VERSION_UPGRADE":
        updateQuestionnaireVersionRequired();
        break
      case "SCHEDULE_VERSION_UPGRADE":
        updateQuestionnaireVersionRequired();
        break
      case "CHAT_UNREAD_COUNT":
        handleUnreadChatsMessage(message);
        break
      case "CALL_SUBSCRIPTION":
        onCallJoinRequest(JSON.parse(message));
        break
      default:
        console.log("Message recieved", message)
    }
  }
  const subscribeSubjectTopics = async () => {
    if(subject?.id){
      const headers = await getHeaders();
     connectQuestionnaireVersionUpgradeSocket(headers);
     connectScheduleVersionUpgradeSocket(headers);
     connectCallSocket(headers);
     connectChatSocket(headers)
      
    }
  }
  const updateQuestionnaireVersionRequired = () => {
    dispatch(questionnaireUpgradeRequired(true, user?.id));
  }
  useEffect(() => {
    if(!user?.id){
      disconnectSocket();
    }
    return () => {
      disconnectSocket()
    }
  }, [user?.id])  

  useEffect(() => {
    if(subject?.id){
      unsubscribeSubjectTopics();
      dispatch(retrieveUnreadChats(subject?.id))
      subscribeSubjectTopics();
    }
  }, [subject?.id]) 

  if(Platform.OS === 'web'){
    return children;
  }
  return <NotificationSetup user={user} subject={subject} updateQuestionnaireVersionRequired = {updateQuestionnaireVersionRequired}>{children}</NotificationSetup>
}

CustomSocket.defaultProps = {
  user: {},
  appType: '',
  subject: {},
  onCallJoinRequest: () => {}
}
CustomSocket.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  appType: PropTypes.string,
  subject: PropTypes.object,
  onCallJoinRequest: PropTypes.func
}
export default CustomSocket;