import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { numberCheck } from './VerticalLabelUtils';
import PropTypes from 'prop-types'
import LabelTextDisplay from './LabelTextDisplay';
import _ from 'lodash'

const LabelTextHorizontal = ({ text, widthArray, containerWidth, containerHeight, labelStyles, minFontSize,  testID, accessible, containerStyle, isTextFit, orientation}) => {
  const [viewHeight, setViewHeight] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(widthArray[0]);
  const [calculationCompleted, setCalculationCompleted] = useState(false);

  useEffect(() => {
    if (textHeight > 0 && viewHeight > 0) {
      if (textHeight <= viewHeight) {
        setCalculationCompleted(true)
      } else {
        const nextWidthIndex = widthArray.indexOf(currentWidth) + 1;
        if (nextWidthIndex < widthArray.length) {
          setCurrentWidth(widthArray[nextWidthIndex]);
        } else {
          setCurrentWidth(containerWidth);
          setCalculationCompleted(true)
        }
      }
    }
  }, [textHeight, viewHeight, currentWidth]);

  const textStyles = {
    ...styles.textStyle,
    ...labelStyles,
}

  return (
    <View>
      {/* Hidden View for Measurement */}
     
      {
      !calculationCompleted && (
        <React.Fragment>
      <View
        style={{ position: 'absolute', top: -1000, left: -1000, width: currentWidth}}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setTextHeight(height);
        }}
      >
        <Text style={[textStyles, { fontSize: _.isNaN(minFontSize)? fontScale(6) : numberCheck(minFontSize)}]}>{text}</Text>
      </View>

      {/* Main container for measuring height */}
       <View
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          width: currentWidth,
          height: containerHeight,
          top: -2000, left: -2000,
        }}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setViewHeight(height);
        }}
      />
        </React.Fragment>
      )}
      
      {
        calculationCompleted && (
          <LabelTextDisplay 
          isFit={isTextFit || _.isEmpty(text)}
          testID={testID}
          text={text}
          accessible={accessible}
          containerWidth={currentWidth}
          containerHeight={containerHeight}
          containerStyle={{...containerStyle, width: currentWidth}}
          fontsize={minFontSize}
          labelStyles={textStyles}
          orientation={orientation}
          />
        )
      }
    
    </View>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: 'Inter',
    color: 'black',
    textAlign: 'center',
    fontWeight: '500',
  }
});


LabelTextHorizontal.defaultProps = {
  widthArray: [],
  text: null, 
  minFontSize: 6, 
  containerWidth: 0, 
  containerHeight: 0, 
  labelStyles: {}, 
  testID: null,
  accessible: true,
  containerStyle: {},
  isTextFit: true,
  orientation: null,

}

LabelTextHorizontal.propTypes = {
  widthArray: PropTypes.array,
  text: PropTypes.string, 
  minFontSize: PropTypes.number, 
  containerWidth: PropTypes.number, 
  containerHeight: PropTypes.number, 
  labelStyles: PropTypes.instanceOf(Object), 
  testID: PropTypes.string,
  accessible: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
  isTextFit: PropTypes.bool,
  orientation: PropTypes.string,
}

export default LabelTextHorizontal;
