import constants from '../constants/constants'
import { getUserId } from '../selectors/user'
import createActionType from '../utils/action'
import api from '../utils/api'

export const ADD_MESSAGE = createActionType('ADD_MESSAGE')
export const DELETE_MESSAGE = createActionType('DELETE_MESSAGE')
export const REMOVE_MESSAGES_TO_DELETE = createActionType('REMOVE_MESSAGES_TO_DELETE')
export const ADD_MESSAGE_TO_DELETE = createActionType('ADD_MESSAGE_TO_DELETE')
export const SET_UNREAD_CHATS = createActionType('SET_UNREAD_CHATS')
export const REDUCE_UNREAD_CHATS_BY_1 = createActionType('REDUCE_UNREAD_CHATS_BY_1')
export const CLEAR_UNREAD_COUNT = createActionType('CLEAR_UNREAD_COUNT')

const addMessage = (message, userId) => ({
  type: ADD_MESSAGE,
  message,
  userId
})

const removeMessage = (messages, userId) => ({
  type: DELETE_MESSAGE,
  messages,
  userId
})

const updateUnreadChats = (unreadChats, userId) => ({
  type: SET_UNREAD_CHATS,
  unreadChats,
  userId
})

export const removeMessagesToDelete = (messages)  => (dispatch, getState) => {
  dispatch({
  type: REMOVE_MESSAGES_TO_DELETE,
  messages,
  userId: getUserId(getState())
})
}

export const addMessageToDelete = (message, userId) => ({
  type: ADD_MESSAGE_TO_DELETE,
  message,
  userId
})

const reduceUnreadChats = (unreadChats, userId) => ({
  type: REDUCE_UNREAD_CHATS_BY_1,
  unreadChats,
  userId
})


export const addMessageToSelectedChat = (message) => (dispatch, getState) => {
  dispatch(addMessage(message, getUserId(getState())))
}

export const addMessageToDeleteAction = (message) => async (dispatch, getState) => {
  dispatch(addMessageToDelete(message, getUserId(getState())))
}

export const deleteMessage = (messages) => async (dispatch, getState) => {
  dispatch(removeMessage(messages, getUserId(getState())))
}

export const reduceUnReadChatsBy1 = (unreadChats) => (dispatch, getState) => {
  dispatch(reduceUnreadChats(unreadChats, getUserId(getState())))
}

export const setUnreadChats = (unreadChats) => (dispatch, getState) => {
  dispatch(updateUnreadChats(unreadChats, getUserId(getState())))
}

export const retrieveUnreadChats = (participantPkId) => async (dispatch) => {
  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats?subjectId=${participantPkId}&unreadChats=true`)
    const unreadChats = {
      unreadChatIds: res.data,
      count: res.data.length,
    }
    dispatch(setUnreadChats(unreadChats))
  } catch (error) {
    console.log(error)
  }
}

const clearUnReadCountOfGivenChatId = (canClear, userId) => ({
  type: CLEAR_UNREAD_COUNT,
  canClear,
  userId
})

export const clearUnReadCount = (canClear) => (dispatch, getState) => {
  dispatch(clearUnReadCountOfGivenChatId(canClear, getUserId(getState())))
}
