import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    //   alignContent: 'center',
    paddingHorizontal: 10,
    backgroundColor: '#1a2226',
  },
  titleWrapper: {
    // backgroundColor: '#ffccbc',
    flex: 2,
    justifyContent: 'flex-end',
  },
  title: {
    color: 'black',
    fontSize: 16,
    fontWeight: '800',
    paddingVertical: 30,
  },
  wrapper: {
    flex: 1,
  },
  inputWrapper: {
    flex: 2,
    paddingTop: 5,
    paddingBottom: 20,
    paddingHorizontal: 20,
    // backgroundColor: '#b3e5fc',
  },
  inputLabel: {
    color: '#607d8b',
    fontSize: 14,
    textAlign: 'center',
    // fontFamily: 'Inter',
  },
  button: {
    padding: 10,
    marginHorizontal: 10,
    width: 120,
    backgroundColor: '#42a5f5',
    alignSelf: 'flex-end',
    borderRadius: 3,
  },
  appName: {
    color: '#607d8b',
    fontFamily: 'Inter',
    fontSize: 20,
    alignSelf: 'center',
    paddingVertical: 5,
  },
  loginButton: {
    width: 120,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: '#42a5f5',
    paddingVertical: 10,
    borderRadius: 3,
  },
  buttonText: {
    alignSelf: 'center',
    color: '#fff',
    fontFamily: 'Inter',
  },
  instructionText: {
    alignSelf: 'center',
    // paddingHorizontal: 10,
    fontSize: 30,
    color: '#455a64',
  },
  cautionText: {
    alignSelf: 'center',
    paddingVertical: 5,
    fontSize: 13,
    fontFamily: 'Inter',
  },
  inputItem: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 30,
  },
  horizontalBar: {
    alignSelf: 'center',
    height: 2,
    marginTop: 5,
    width: 200,
    backgroundColor: '#cfd8dc',
  },
  error: {
    fontFamily: 'Inter',
    fontSize: 11,
    color: '#f44336',
    // alignSelf: 'flex-start',
    // marginTop: 4,
    // backgroundColor: '#ffb74d',
    // borderRadius: 5,
    padding: 5,
  },
})
