import React from "react"
import TrackMarkComponent from "./TrackMarkComponent"
import { View } from "react-native"
import TrackMarkNumbers from "./TrackMarkNumbers"
const TrackMarksContainer = (props) => {

  
    return (
        <View>
            {/* ----------marks------------ */}
            <TrackMarkComponent {...props} />

            {/* ----------indication numbers------------ */}
            <TrackMarkNumbers {...props} />
        </View>
    )
}

export default TrackMarksContainer