import constants from '../constants/constants'
import api from './api'

export const retrieveSubjectCompliance = (subject) => {
  return new Promise(async (resolve, reject) => {
    try {
      // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
      api.defaults.headers.common['Accept-Language'] = constants.locale.english
      const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies//${constants.ContextProperties.STUDY_ID}/sites//${constants.ContextProperties.SITE_ID}/subjects/${subject.id}?isBasicDetails=true`)
      return resolve({
        dayCompliance: res.data.dayCompliance,
        weekCompliance: res.data.weekCompliance,
        monthCompliance: res.data.monthCompliance,
        totalCompliance: res.data.totalCompliance,
      })
    } catch (error) {
      console.log(error)
      return reject(error)
    }
  })
}
