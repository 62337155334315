import axios from 'axios'
import JSOG from 'jsog'
import _ from 'lodash'
import appConstants from '../constants/appConstants'
import { store } from '../store/configStore'
import constants from '../constants/constants'
import { getSubject, getUser } from '../selectors/commonSelector'
import { getUserStudyMetaData, getUserStudySite } from '../selectors/studyMetaData'

let baseUrl = `${appConstants.baseUrl}/api/app`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) => JSOG.decode(data)),
})

axiosConfig.interceptors.request.use(
  (config) => getConfig(config),
  (error) => {
    Promise.reject(error)
  }
)
axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      console.log('Request canceled', error)
      return Promise.resolve(error)
    } else {
      return Promise.reject(error)
    }
  }
)
const getConfig = async (config) => {
  const contextBasedUrl = getContextBasedUrl(config.url);
  config.url = contextBasedUrl;
  return config
  
}
const getContextBasedUrl = (url) => {
  const state = store.getState();
  const appType = getAppType();
  let contextBasedUrl = url;
  const currentUser = getUser(state)
  const userStudyMetadata = getUserStudyMetaData(state)
  const subject = getSubject(state)
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.PRIMARY_ORG_CODE, _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'primaryOrganizationCode', "") : _.get(userStudyMetadata?.studySite,'primaryOrganizationCode', ""))
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.STUDY_ID, _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'studyId', "") : _.get(userStudyMetadata?.study?.data,'id', ""))
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SITE_ID,   _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'siteId', "") : getUserStudySite(state)?.id)
  contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SUBJECT_ID, _.get(subject,'id', ""))
  return contextBasedUrl;
}

const getAppType = () => {
  const state = store.getState();
  return state.appStatus.appType
}
export default axiosConfig
