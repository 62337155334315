import initialState from '../store/initialState'
import {
  PASSCODE_VALIDATE_SUCCESS,
  PASSCODE_VALIDATE_REQUEST,
  PASSCODE_VALIDATE_FAILURE,
} from '../actions/site'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'

export default (state = initialState.site, action) => {
  switch (action.type) {
    case PASSCODE_VALIDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PASSCODE_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.data.id]: {
          ...state?.[action.data.id],
          ...action.data,
        },
        loading: false,
      }
    case PASSCODE_VALIDATE_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CLEAR_USER_STORAGE:
      return {
        ...state,
        [action.userId]: {}
      }
    default:
      return state
  }
}
