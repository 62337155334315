import _ from 'lodash'
import { Body, Card, CardItem, Spinner } from 'native-base'
import React, { Component } from 'react'
import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from '../styles/listStyles'
import {
  DATE,
  DATE_FORMAT,
  DATETIME12,
  formatDateInTimezone,
  isTimeIsInRange,
} from '../utils/dateUtils'
import NoDiary from '../components/NoDiary'
import { getSubjectVisitsWithVisitData } from '../selectors/visits'
import { getDeviceOnlieStatus } from '../selectors/user'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { getMetaData, retrieveFormsIfNotExist } from '../actions/studyMetaData'
import { updateSelectedDate, updateSelectedVisitId } from '../actions/session'
import { getCrfVersion, getMetadataLoader } from '../selectors/studyMetaData'
import { getUserTimezone, getSubject } from '../selectors/commonSelector'

function getVisitStatusColor(visit) {
  const colors = {
    grey: {
      backgroundColor: '#f2f2f2',
      borderColor: '#b3b3b3',
    },
    green: {
      backgroundColor: '#e6ffe6',
      borderColor: '#66ff66',
    },
  }
  const isInRange = isTimeIsInRange(
    visit.visitStartDateUtc,
    visit.visitEndDateUtc,
    moment.utc(),
  )
  return isInRange ? colors.green : colors.grey
}

const VisitView = (props) => {
  const { t, visit, navigation, userTimezone, updateSelectedVisitId, displayDiaryDateBasedOnVisitEndDate } = props
  const { backgroundColor, borderColor } = getVisitStatusColor(visit)

  return (
    <TouchableOpacity
      onPress={() => {
        updateSelectedVisitId(visit.visitId)
        displayDiaryDateBasedOnVisitEndDate(visit.visitEndDate)
        navigation.navigate('Diary', {
          title: visit.visit?.name || visit.visitType,
          subTitle:
            visit.visitStartDate && visit.visitEndDate
              ? `${moment.utc(visit.visitStartDate).format(DATE)} to ${moment
                  .utc(visit.visitEndDate)
                  .format(DATE)} `
              : null,
        })
      }}
      testID={`visits-${visit.visit?.name || visit.visitType}-click-btn`}
      accessible={true}
    >
      <Card>
        <CardItem
          style={{
            borderWidth: 1,
            borderRadius: 2,
            backgroundColor,
            borderColor,
          }}
        >
          <Body>
            <View style={styles.headerTextContainer}>
              <Text style={styles.textStyle}>{visit.visit?.name || visit.visitType}</Text>
              {visit.visitStartDate && (
                <React.Fragment>
                  <Text style={styles.subTextStyle}>
                    {t('StartTime')}:{' '}
                    {formatDateInTimezone(
                      visit.visitStartDate,
                      DATETIME12,
                      userTimezone
                    )}
                  </Text>
                  {visit.visitEndDate && (
                    <Text style={styles.subTextStyle}>
                      {t('EndTime')}:{' '}
                      {formatDateInTimezone(
                        visit.visitEndDate,
                        DATETIME12,
                        userTimezone
                      )}
                    </Text>
                  )}
                </React.Fragment>
              )}
            </View>
          </Body>
        </CardItem>
      </Card>
    </TouchableOpacity>
  )
}


VisitView.propTypes = {
  t: PropTypes.func,
  visit: PropTypes.instanceOf(Object),
  navigation: PropTypes.instanceOf(Object),
  userTimezone: PropTypes.string,
  updateSelectedVisitId: PropTypes.func, 
  displayDiaryDateBasedOnVisitEndDate: PropTypes.func
}

class Visits extends Component {
  refreshMetaData = async() => {
    const { subject, retrieveFormsIfNotExist, getMetaData } = this.props
    await getMetaData(subject.id, subject.lastUpdatedDateOn ? moment(subject.lastUpdatedDateOn).utc(): null, false)
    retrieveFormsIfNotExist()
  }

  displayDiaryDateBasedOnVisitEndDate = (visitEndDate) => {
    const { updateSelectedDate} = this.props
    const loVisitEndDate = moment.utc(visitEndDate).format(DATE_FORMAT)
    const currentDate = moment().format(DATE_FORMAT)
    if (moment(currentDate).isAfter(moment(loVisitEndDate), 'day')) {
      updateSelectedDate(loVisitEndDate)
    } else {
      updateSelectedDate(currentDate)
    }
  }

  render() {
    const {
      navigation,
      subject,
      subVisitLoader,
      userTimezone,
      versionInfo,
      screenProps: { t },
      screenProps,
      subjectVisits,
      updateSelectedVisitId,
      isDeviceOnline,
    } = this.props
    
    if (subVisitLoader) {
      return (
        <View style={{ backgroundColor: '#ffffff', flex: 1 }}>
          <Spinner color="#4e89ae" />
        </View>
      )
    }

    return (
      <View style={{ padding: 10, backgroundColor: '#fff', height: '100%' }}>
        <NavigationEvents
          onDidFocus={() => {
            if (subject.id && isDeviceOnline) {
              this.refreshMetaData()
            }
          }}
        />

        {subject?.isFirstTimeLogin == 1 && versionInfo && (
          <View style={styles.startVersionContainer}>
            <Text style={styles.versionInfoStyle}>
              {t('VersionUpgradeInfo')}
              {versionInfo.versionNumber}
            </Text>
          </View>
         )}
        {!_.isEmpty(subjectVisits) ? (
            <FlatList data={subjectVisits} renderItem={(rowData) => (
              <VisitView
                visit={rowData.item}
                t={t}
                navigation={navigation}
                userTimezone={userTimezone}
                updateSelectedVisitId={updateSelectedVisitId}
                displayDiaryDateBasedOnVisitEndDate={this.displayDiaryDateBasedOnVisitEndDate}
              />
            )} />
          ) : (
            <NoDiary screenProps={screenProps} />
        )}
      </View>
    )
  }
}
Visits.defaultProps = {
  subVisitLoader: false,
  versionInfo: null,
}
Visits.propTypes = {
  subject: PropTypes.object.isRequired,
  subVisitLoader: PropTypes.bool,
  versionInfo: PropTypes.object,
  subjectVisits: PropTypes.array.isRequired,
  isDeviceOnline: PropTypes.bool.isRequired,
  getMetaData: PropTypes.func.isRequired,
  updateSelectedVisitId: PropTypes.func,
  updateSelectedDate: PropTypes.func.isRequired,
  retrieveFormsIfNotExist: PropTypes.func.isRequired,
  navigation: PropTypes.instanceOf(Object),
  userTimezone: PropTypes.string,
  screenProps: PropTypes.shape({
    t: PropTypes.func
  }),
  t: PropTypes.func,

}
const mapStateToProps = (state) => ({
  subject: getSubject(state),
  subVisitLoader: getMetadataLoader(state),
  userTimezone: getUserTimezone(state),
  versionInfo: getCrfVersion(state),
  subjectVisits: getSubjectVisitsWithVisitData(state),
  isDeviceOnline: getDeviceOnlieStatus(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMetaData,
      updateSelectedVisitId,
      updateSelectedDate,
      retrieveFormsIfNotExist,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Visits)
