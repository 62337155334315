import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types'
import { fontScale } from '../styles/fontResizer';

function WebTextResize(props) {
  const {fontSize, text, textStyle, containerWidth, containerHeight, viewStyles} = props
  const [size, setSize] = useState(fontSize)
  const [viewHeight, setViewHeight] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    if (textHeight > viewHeight) {
      setSize(size - 1) 
    }
  }, [textHeight])

  return (
      <View
        style={[viewStyles, {
          width: containerWidth,
          height: containerHeight,
        }]}
        onLayout={(event) => {
          let { height } = event.nativeEvent.layout;
          setViewHeight(height)
        }}
      >
        <Text
          style={[textStyle, {
            fontSize: size,
          }]}
          onLayout={(event) => {
            let { height } = event.nativeEvent.layout;
            setTextHeight(height)
          }}
        >
          {text}
        </Text>
      </View>
  );
}

WebTextResize.defaultProps = {
  fontSize: fontScale(24),
  text: null,
  textStyle: {},
  containerWidth: '100%',
  containerHeight: '100%', 
  viewStyles: {}
}

WebTextResize.propTypes = {
  fontSize: PropTypes.number,
  text: PropTypes.string,
  textStyle: PropTypes.instanceOf(Object),
  containerWidth: PropTypes.string, 
  containerHeight: PropTypes.string, 
  viewStyles: PropTypes.instanceOf(Object)
}

export default WebTextResize 