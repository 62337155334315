import React from 'react'
import {  Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import { TermsAndConditionButtonStyles } from './TermsNConditionButtons'
import Logo from './Logo'
import styles from '../../../src/styles/listStyles'
import ParticipantLogout from '../common/ParticipantLogout'

export const TermsAndConditionCancelStyles = StyleSheet.create({
    containerStyle: {flex: 1, backgroundColor: '#ffffff', alignItems: 'center'},
    logoStyle: {marginTop: 30},
    viewStyle: {marginTop: 10},
    buttonStyle: {marginRight: 5},
    buttonContainer: {flexDirection: 'row', justifyContent: 'center'}
})

const TermsAndConditionsCancel = (props) => {
    const {navigation, screenProps } = props

    const handleBack = () => {
        navigation.navigate('TermsAndConditions')
    }

    return(
        <View style={TermsAndConditionCancelStyles.containerStyle}>
            <Logo />
            <View style={TermsAndConditionCancelStyles.viewStyle}><Text style={[styles.textStyle, {textAlign: 'center'}]}>{screenProps.t('TermsNConditionsCancel')}</Text></View>
            <View style={[TermsAndConditionCancelStyles.viewStyle, TermsAndConditionCancelStyles.buttonContainer]}>
                <View style={TermsAndConditionCancelStyles.buttonStyle}>
                    <TouchableOpacity style={TermsAndConditionButtonStyles.buttonStyle} onPress={() => handleBack()}>
                    <Text style={TermsAndConditionButtonStyles.buttonTxtStyle}>{screenProps.t('Back')}</Text>
                    </TouchableOpacity>
                </View>
                <View>
                    <ParticipantLogout navigation={navigation} screenProps={screenProps}/>
                </View>
            </View>
        </View>

    )
}

export default TermsAndConditionsCancel