import _ from 'lodash'
import React, { Component } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { connect } from 'react-redux'
import FormConsumer from '../components/form/FormConsumer'
import { backgroundColor, buttonBackgroundColor } from '../containers/NavigationScreens'
import { getSelectedSvf } from '../selectors/subjectVisitForm'
import constants from '../constants/constants'
import FormScreenHeader from '../components/common/FormScreenHeader'
import { store } from '../store/configStore'
import { getFieldGroupList, getQuestionnairesLoader, getFieldsFromFieldGroupList } from '../selectors/studyMetaData'
import { getUserCrfData } from '../selectors/crfData'
import { getSubject, getUserSession, getUserTimezone } from '../selectors/commonSelector'
import { getSelectedDate } from '../selectors/session'
import { bindActionCreators } from 'redux'
import { setCurrentScreen } from '../actions/session'
import { getCurrentVisit } from '../selectors/visits'
import PropTypes from 'prop-types'
import { storeModeInSessionStorage } from '../utils/util'

class FormScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alreadyLoadedOfflineData: false,
      offlineSaveLoading: false,
      alreadyLoadedOnlineData: false,
      showFormInstruction: true,
    }
    this.fields = []
  }

  static navigationOptions = ({ navigation, screenProps }) => {
   return (store.getState()?.appStatus?.appType === constants.AppType.SUBJECT) ? {
    headerLeft: () => <FormScreenHeader navigation={navigation} screenProps={screenProps} />  
  }: {
    header: () => <FormScreenHeader navigation={navigation} screenProps={screenProps} />
  }}

  componentDidMount() {
    const {
      navigation,
      isDeviceOnline,
      setCurrentScreen
    } = this.props
    storeModeInSessionStorage(constants.AppType.SUBJECT)
    setCurrentScreen('FORM')
    const isDeviceOnlineAtDiary = navigation.getParam('isDeviceOnlineAtDiary')
    if (isDeviceOnline && isDeviceOnlineAtDiary) {
      this.setState({
        alreadyLoadedOnlineData: true,
      })
    } else {
      this.setState({
        alreadyLoadedOfflineData: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline !== prevProps.isDeviceOnline && !isDeviceOnline) {
      this.setState({
        alreadyLoadedOfflineData: true,
      })
    } else if (isDeviceOnline !== prevProps.isDeviceOnline && isDeviceOnline) {
      this.setState({
        alreadyLoadedOnlineData: true,
      })
    }
  }

  updateOfflineSaveLoading = (offlineSaveLoading) => {
    this.setState({ offlineSaveLoading })
  }

  componentWillUnmount = () => {
    this.setState({
      alreadyLoadedOfflineData: false,
      alreadyLoadedOnlineData: false,
    })
    this.fields = []
  }

  onNext = () => {
    this.setState({
      showFormInstruction: false,
    })
  }

  render() {
    const {
      loading,
      userTimezone,
      screenProps,
      selectedSvf,
      fields,
      formAnswers, scheduleDate, isSvfUpdated, fieldGroupList, subject, subjectVisitId
    } = this.props
    const { offlineSaveLoading } = this.state
    this.fields = fields
    if (_.isEmpty(this.fields)) {
      return (
        <ActivityIndicator
          size="large"
          color={backgroundColor}
          animating={true}
          key={loading ? 'loading' : 'not-loading'}
        />
      )
    }
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {(loading || offlineSaveLoading) && (
          <View
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              zIndex: 2,
            }}
          >
            <ActivityIndicator
              size="large"
              color={buttonBackgroundColor}
              animating={true}
              key={loading ? 'loading' : 'not-loading'}
            />
          </View>
        )}

        {
          <FormConsumer
            {...this.props}
            screenProps={screenProps}
            selectedSvf={selectedSvf}
            updateOfflineSaveLoading={this.updateOfflineSaveLoading}
            formAnswers={formAnswers}
            scheduleDate={scheduleDate}
            isSvfUpdated={isSvfUpdated}
            fieldList={fields}
            fieldGroupList={fieldGroupList}
            subject={subject}
            subjectVisitId={subjectVisitId}
            userTimezone={userTimezone}
          />
        }
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  fields: getFieldsFromFieldGroupList(state),
  loading: getQuestionnairesLoader(state),
  userTimezone: getUserTimezone(state),
  selectedSvf: getSelectedSvf(state),
  formAnswers: getUserCrfData(state),
  fieldGroupList: getFieldGroupList(state),
  isSvfUpdated: _.get(getUserSession(state), 'isSelectedSvfUpdated', false),
  scheduleDate: getSelectedDate(state),
  subject: getSubject(state),
  subjectVisitId: _.get(getCurrentVisit(state), 'id', null),
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
    },
    dispatch
  )



  FormScreen.defaultProps = {
    setCurrentScreen: ()=> null,
    userTimezone: null,
    formAnswers: {},
    scheduleDate: null,
    isSvfUpdated: false,
    fieldGroupList: [],
    subject: {},
    subjectVisitId: null,
  }
  
  FormScreen.propTypes = {
    setCurrentScreen: PropTypes.func,
    userTimezone: PropTypes.string,
    formAnswers: PropTypes.instanceOf(Object),
    scheduleDate: PropTypes.string,
    isSvfUpdated: PropTypes.bool,
    fieldGroupList: PropTypes.array,
    subject: PropTypes.instanceOf(Object),
    subjectVisitId: PropTypes.string,
  } 
export default connect(mapStateToProps, mapDispatchToProps)(FormScreen)
