import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserTimezone } from '../actions/timeZone'
import { updateIsAcknowledged } from '../actions/session'
import ChangeSubjectTimezone from '../components/ChangeSubjectTimezone'
import { updateIsSubjectRegistered } from '../actions/users'
import moment from 'moment-timezone'
import { getSubjectId } from '../selectors/subject'
import { getSubject, getUserTimezone } from '../selectors/commonSelector'
import PropTypes from 'prop-types'

class SubjectTimeZone extends Component {
  static navigationOptions = ({ screenProps: { t } }) => ({
    title: t('SelectTimezone'),
  })

  constructor(props) {
    super(props)
  }

  submitTimezone = async  (timezone) => {
    const { subjectId, updateUserTimezone, updateIsAcknowledged,screenProps:{t}, subject } = this.props
    const loSubject = {
      id: subjectId,
      timeZone: timezone,
      lastUpdatedDateOn: subject?.lastUpdatedDateOn
    }
    await updateUserTimezone(loSubject, t)
    await updateIsAcknowledged(false)
  }

  render() {
    const { loading, navigation, screenProps,updateIsSubjectRegistered, userTimezone } = this.props
    const fromLogin = navigation.getParam('fromLogin')
    const timezonesFromMoment = moment.tz.names();
    const deprecatedTimezones = ['Asia/Calcutta', 'Asia/Macau', 'Africa/Asmera', 'Asia/Dacca', 'Asia/Katmandu',
    'Asia/Thimbu','Atlantic/Faeroe','Europe/Kiev','Pacific/Ponape', 'Asia/Ashkhabad', 'Asia/Ulan_Bator', 'Asia/Chungking'];
    const allTimeZones = timezonesFromMoment.filter(element =>!deprecatedTimezones.includes(element));
    return (
      <View style={{ flex: 1, marginTop: 0, backgroundColor: '#fff' }}>
        <ChangeSubjectTimezone
          fromLogin={fromLogin}
          loading={loading}
          navigation={navigation}
          screenProps={screenProps}
          timezoneList={allTimeZones}
          userTimezone={userTimezone}
          submitTimezone={this.submitTimezone}
          updateIsSubjectRegistered={updateIsSubjectRegistered}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  subjectId: getSubjectId(state),
  subject: getSubject(state),
  userTimezone: getUserTimezone(state)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserTimezone,
      updateIsAcknowledged,
      updateIsSubjectRegistered,
    },
    dispatch
  )



  SubjectTimeZone.defaultProps = {
   subjectId: null,
   updateUserTimezone: ()=> null,
   updateIsAcknowledged: ()=> null,
   screenProps: {
    t: ()=> null,
   },
   loading: false,
   subject: {
    lastUpdatedDateOn: null,
   },
   userTimezone: null,
   navigation: {},
   updateIsSubjectRegistered : ()=> null
  }
  
  SubjectTimeZone.propTypes = {
    subjectId: PropTypes.string,
    updateUserTimezone: PropTypes.func,
    updateIsAcknowledged: PropTypes.func,
    screenProps: PropTypes.shape({
      t: PropTypes.func
    }),
    loading: PropTypes.bool,
    subject: PropTypes.shape({
      lastUpdatedDateOn: PropTypes.string,
    }),
    userTimezone: PropTypes.string,
    navigation: PropTypes.instanceOf(Object),
    updateIsSubjectRegistered: PropTypes.func
  } 
export default connect(mapStateToProps, mapDispatchToProps)(SubjectTimeZone)
