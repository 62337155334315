import React from 'react'
import constants from '../constants/constants'
import { MaterialIcons, Entypo } from '@expo/vector-icons'
import { View } from 'react-native'
import styles from './styles/TimelineStyles'

const SubmissionIcon = (props) => {
  const { svf } = props
  if (svf.status === 'COMPLETED') {
    return (
      <View style={styles.headerIconContainer}>
        <Entypo name="check" size={24} color="#689f38" />
      </View>
    )
  }
  // if (svf.submissionStatus && svf.submissionStatus === constants.OfflineDataStatus.IN_PROGRESS) {
  //   return (
  //     <View style={styles.headerIconContainer}>
  //       <Entypo name="check" size={24} color="#f57b42" />
  //     </View>
  //   )
  // }
  // if (svf.submissionStatus && svf.submissionStatus === constants.OfflineDataStatus.SYNC_FAILED) {
  //   return (
  //     <View style={styles.headerIconContainer}>
  //       <MaterialIcons name="sync-problem" size={24} color="#f20505" />
  //     </View>
  //   )
  // }
  return <View />
}

export default SubmissionIcon
