import { View, ScrollView, Dimensions, StyleSheet, Platform } from 'react-native'
import React from 'react'
import tryalLogoBox from '../../../assets/base64/tryalLogoBox'
import AutoHeightImage from '../AutoHeightImage'

const TermsAndConditionStyles = StyleSheet.create({
    logoContainer:{
        flex: Platform.OS == 'ios' ? 1 : 0.5,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 60,
    },
    
});

const Logo = (props) => {
    return(
        <View style={TermsAndConditionStyles.logoContainer}>
        <AutoHeightImage
              source={{
                uri: `data:image/png;base64,${tryalLogoBox}`,
              }}
              width={100}
            />
        </View>
    )
}

export default Logo