import React, { Component } from 'react'
import { View } from 'react-native'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import CustomTextInput from '../common/CustomTextInput'

export class TextInput extends Component {
  state = {}

  changeFieldValue = (value) => {
    const {
      form,
      field: { id },
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    setFieldsValue({
      [id]: value,
    })
  }

  render() {
    const {
      field: { id, isRequired, uiSchema: fdUISchema },
      t,
      disabled,
      fieldAnswer,
    } = this.props
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
        }}
      >
        {this.props.form.getFieldDecorator(id, {
          initialValue: crfData ? crfData.fieldValue : null,
          // getValueFromEvent:(event)=> event.text,
          rules: [
            {
              required: isRequired,
              message: t('TextValMsg'),
            },
          ],
        })(
          <CustomTextInput
            placeholder={t('EnterHere')}
            onChangeText={this.changeFieldValue}
            editable={!disabled}
            // rows={10}
            // autoHeight={true}
            style={{
              paddingVertical: 2,
              paddingHorizontal: 5,
              borderBottomWidth: 2,
              borderBottomColor: '#cccccc',
            }}
          />
        )}
      </View>
    )
  }
}
