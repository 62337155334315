import React from 'react'
import { backgroundColor } from '../../../containers/NavigationScreens'
class PrintContent extends React.Component {
  getPageMargins = () => {
    return `@page { margin: 25mm 25mm 25mm 25mm !important; }`
  }
  getSectionData = (fgOid) => {
    const { data, renderItem } = this.props
    const sectionData = data.filter(
      (ele) =>
        (ele.data && ele.data.fieldGroup && ele.data.fieldGroup.fieldGroupOid === fgOid) ||
        (!ele.isHidden && ele.fieldGroupOid && ele.fieldGroupOid === fgOid)
    )
    return sectionData.map((item, index) => (
      <tr>
        <td>{renderItem({ item })}</td>
      </tr>
    ))
  }
  render() {
    console.log(this.props)
    const { data, renderItem, header, footer, printOptions } = this.props
    const contentWidth = printOptions.printLayout === 'mobile' ? '60%' : '100%'
    return (
      <>
        <div style={{ width: '100%', margin: 20 }}>
          <div style={{ margin: '0 auto', display: 'table', width: contentWidth }}>
            <table className="paging" width={'100%'}>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: backgroundColor,
                      height: '50px',
                      color: 'white',
                      fontFamily: 'Roboto',
                    }}
                  >
                    <div style={{ paddingHorizontal: 2 }}>{printOptions.formName}</div>
                  </td>
                </tr>
                {data.map((item, index) => {
                  return (
                    item.formId &&
                    !item.isHidden && (
                      <tr key={index}>
                        <td>{renderItem({ item })}</td>
                      </tr>
                    )
                  )
                })}
              </tbody>
            </table>
            {data.map((item, index) => {
              return (
                item.type === 'label' &&
                item.fieldGroupOid && (
                  <table
                    width={'100%'}
                    className={item.layout === 'grid' && !(index < 5) ? 'gridHeader' : null}
                  >
                    <tbody>{this.getSectionData(item.fieldGroupOid)}</tbody>
                  </table>
                )
              )
            })}
          </div>
        </div>
      </>
    )
  }
}
export default PrintContent
