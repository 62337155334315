// import hideableView
import _ from 'lodash'
import fieldTypes from '../../constants/fieldTypes'
import { removePercentageFromValue } from '../../utils/util'
// import { TextFieldDecorator } from '../../components/fields/TextInput';
// import { RadioFieldDecorator } from '../../components/fields/Radio';
const {
  TEXT,
  TEXTAREA,
  NUMBER,
  RADIO,
  CHECKBOX,
  DATE,
  DATETIME,
  YESNO,
  YESNONA,
  INSTRUCTION,
  PS,
  NRS,
  VAS,
  CLICKIMAGE,
  PERCENT,
  BARCODE,
  SCORE,
  IMAGE,
  AUDIO,
  VIDEO,
} = fieldTypes

export default (fieldValue, optionOid, field, svfId, crfVersionId) => {
  const { fieldType } = field

  switch (fieldType) {
    case TEXT:
    case TEXTAREA:
    case NUMBER:
    case DATE:
    case DATETIME:
    case PS:
    case IMAGE:
    case AUDIO:
    case VIDEO:
    case PERCENT:
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue,
        enFieldValue:fieldValue,
        fieldOid: field.fieldOid,
        optionOid: '',
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    case NRS:
    case VAS:
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue,
        enFieldValue:fieldValue,
        fieldOid: field.fieldOid,
        unitValue: _.get(field.fieldGroup, 'scoringEnabled', false) ? removePercentageFromValue(fieldValue) : null,
        optionOid: '',
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    case BARCODE:
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue,
        enFieldValue:fieldValue,
        fieldOid: field.fieldOid,
        optionOid: '',
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    case RADIO:
    case YESNO:
    case YESNONA:
      const oids = _.filter(field.dictionary.options, (option) => option.oid === fieldValue)
      let fieldV, score, optionFieldValue, enFieldV
      if (oids.length > 0) {
        fieldV = oids[0].value
        score = oids[0].score
        optionFieldValue = fieldValue
      } else {
        const opoids = _.filter(field.dictionary.options, (option) => option.oid === optionOid)
        fieldV = opoids.length > 0 ? opoids[0].value : null
        score = opoids.length > 0 ? opoids[0].score : null
        optionFieldValue = optionOid
      }
      const enoids = _.filter(field?.enDictionary?.options, (option) => option.oid === fieldValue)
      if (enoids.length > 0) {
        enFieldV = enoids[0].value
      } else {
        const enopoids = _.filter(field?.enDictionary?.options, (option) => option.oid === optionOid)
        enFieldV = enopoids.length > 0 ? enopoids[0].value : null
      }
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue: fieldV,
        enFieldValue: enFieldV,
        unitValue: score || null,
        fieldOid: field.fieldOid,
        optionOid: optionFieldValue,
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    case CHECKBOX: {
      const selectedOptions = _.filter(
        field.dictionary.options,
        (op) =>
          _.includes(JSON.parse(fieldValue), op.oid) || _.includes(JSON.parse(optionOid), op.oid)
      )
      const selectedScores = _.map(
        _.filter(selectedOptions, (op) => op.score),
        (option) => Number(option.score)
      )
      const enSelectedOptions = _.filter(
        field?.enDictionary?.options,
        (op) =>
          _.includes(JSON.parse(fieldValue), op.oid) || _.includes(JSON.parse(optionOid), op.oid)
      )
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue: fieldValue && JSON.stringify(selectedOptions.map((option) => option.value)),
        enFieldValue: fieldValue && JSON.stringify(enSelectedOptions.map((option) => option.value)),
        fieldOid: field.fieldOid,
        unitValue: _.size(selectedScores) > 0 ? _.sum(selectedScores) : null,
        optionOid: fieldValue && JSON.stringify(selectedOptions.map((option) => option.oid)),
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    }
    case CLICKIMAGE: {
      const selectedOptions = _.filter(
        field.dictionary.map.areas,
        (op) =>
          _.includes(JSON.parse(fieldValue), op?.oid) || _.includes(JSON.parse(optionOid), op?.oid)
      )
      const selectedScores = _.map(
        _.filter(selectedOptions, (op) => op.score),
        (option) => Number(option.score)
      )
      const enSelectedOptions = _.filter(
        field?.enDictionary?.map.areas,
        (op) =>
          _.includes(JSON.parse(fieldValue), op?.oid) || _.includes(JSON.parse(optionOid), op?.oid)
      )
      return {
        ...field.crfData,
        field: {
          id: field.id,
          fieldOid: field.fieldOid,
          fieldGroup: {
            id: field.fieldGroup.id,
          },
        },
        fieldValue: fieldValue && JSON.stringify(selectedOptions.map((option) => option.value)),
        enFieldValue: fieldValue && JSON.stringify(enSelectedOptions.map((option) => option.value)),
        unitValue: _.size(selectedScores) > 0 ? _.sum(selectedScores) : null,
        fieldOid: field.fieldOid,
        optionOid: fieldValue && JSON.stringify(selectedOptions.map((option) => option.oid)),
        subjectVisitForm: {
          id: svfId,
        },
        crfVersion: {
          id: crfVersionId,
        },
      }
    }
    // case DATE || DATETIME12 || DATETIME24:
    //     return {
    //         crfData: {
    //             field: {
    //                 id: field.id,
    //                 fieldOid: field.fieldOid
    //             },
    //             fieldValue: fieldValue,
    //             fieldOid: field.fieldOid,
    //             optionOid: '',
    //         }
    //     }
    // case INSTRUCTION:
    //     return getFieldDecorator(`${index}fv`)(fieldComponent);
    //     case PS:
    //     return {
    //         ...field.crfData,
    //         field: {
    //             id: field.id,
    //             fieldOid: field.fieldOid
    //         },
    //         fieldValue,
    //         fieldOid: field.fieldOid,
    //         optionOid: '',
    //         subjectVisitForm: {
    //             id: svfId,
    //         }
    // }
    // case NRS:
    //     return getFieldDecorator(`${index}fv`)(fieldComponent);
    // case VAS:
    //     return getFieldDecorator(`${index}fv`)(fieldComponent);
    // case CLICKIMAGE:
    //     return getFieldDecorator(`${index}fv`)(fieldComponent);
    // case SCORE:
    //   const scoreOids = field.dictionary.options.filter((option) => option.oid === fieldValue)
    //   const scoreField = scoreOids.length > 0 ? scoreOids[0].value : null
    //   const scoreVal = scoreOids.length > 0 ? scoreOids[0].score : 0
    //   return {
    //     ...field.crfData,
    //     field: {
    //       id: field.id,
    //       fieldOid: field.fieldOid,
    //     },
    //     fieldValue: scoreField,
    //     unitValue: scoreVal,
    //     fieldOid: field.fieldOid,
    //     optionOid: fieldValue,
    //     subjectVisitForm: {
    //       id: svfId,
    //     },
    //     crfVersion: {
    //       id: crfVersionId,
    //     },
    //   }
    default:
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue,
          optionOid: '',
          field: {
            ...field,
            fieldGroup: {
              id: field.fieldGroup.id,
            },
          },
        },
      }
  }
}

export const placeFieldValue = (field, fieldValue, optionOid) => {
  const { fieldType } = field

  switch (fieldType) {
    case TEXT:
    case TEXTAREA:
    case NUMBER:
    case DATE:
    case DATETIME12:
    case DATETIME24:
    case PS:
    case NRS:
    case VAS:
    case PERCENT:
    case IMAGE:
    case AUDIO:
    case VIDEO:
    case BARCODE:
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue,
          optionOid: '',
        },
      }
    case RADIO:
    case YESNO:
    case YESNONA:
      const oids = field.dictionary.options.filter((option) => option.oid === fieldValue)
      let fieldV, score, optionFieldValue
      if (oids.length > 0) {
        fieldV = oids[0].value
        score = oids[0].score
        optionFieldValue = fieldValue
      } else {
        const opoids = _.filter(field.dictionary.options, (option) => option.oid === optionOid)
        fieldV = opoids.length > 0 ? opoids[0].value : null
        score = opoids.length > 0 ? opoids[0].score : null
        optionFieldValue = optionOid
      }
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue: fieldV,
          unitValue: score || null,
          fieldOid: field.fieldOid,
          optionOid: optionFieldValue,
        },
      }
    case CHECKBOX: {
      const selectedOptions = _.filter(
        field.dictionary.options,
        (op) =>
          _.includes(JSON.parse(fieldValue), op.oid) || _.includes(JSON.parse(optionOid), op.oid)
      )
      const selectedScores = _.map(
        _.filter(selectedOptions, (op) => op.score),
        (option) => Number(option.score)
      )
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue: JSON.stringify(selectedOptions.map((option) => option.value)),
          fieldOid: field.fieldOid,
          optionOid: JSON.stringify(selectedOptions.map((option) => option.oid)),
          unitValue: _.size(selectedScores) > 0 ? _.sum(selectedScores) : null,
        },
      }
    }
    case CLICKIMAGE: {
      const selectedOptions = _.filter(
        field.dictionary.map.areas,
        (op) =>
          _.includes(JSON.parse(fieldValue), op.oid) || _.includes(JSON.parse(optionOid), op.oid)
      )
      const selectedScores = _.map(
        _.filter(selectedOptions, (op) => op.score),
        (option) => Number(option.score)
      )
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue: JSON.stringify(selectedOptions.map((option) => option.value)),
          fieldOid: field.fieldOid,
          optionOid: JSON.stringify(selectedOptions.map((option) => option.oid)),
          unitValue: _.size(selectedScores) > 0 ? _.sum(selectedScores) : null,
        },
      }
    }
    default:
      return {
        ...field,
        crfData: {
          ...field.crfData,
          fieldValue,
          optionOid: '',
        },
      }
  }
}
