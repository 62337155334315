import WebModal from 'modal-react-native-web'
import { Button } from 'native-base'
import React, { Component } from 'react'
import { Dimensions, Text, View } from 'react-native'
import { buttonBackgroundColor } from '../containers/NavigationScreens'
import { fontResizer, fontType, heightResizer } from '../components/styles/fontResizer'
import PropTypes from 'prop-types'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

class WebAlert extends Component {
  renderLogoutDialogue = () => {
    const { message, t, headerText, buttons, children, style } = this.props
    const loStyles = _.isEmpty(style) ? {
      fontFamily: 'Inter',
      display: 'table',
      backgroundColor: 'white',
      borderWidth: 1,
      borderRadius: 5,
      flexDirection: 'column',
      width: SCREEN_WIDTH >= 1030 ? '35%' : '75%',
      height: heightResizer({
        xl: '20%',
        lg: '5%',
        sm: '5%',
      }),
      marginTop: heightResizer({
        xl: (SCREEN_HEIGHT / 4),
        lg: (SCREEN_HEIGHT / 6),
        sm: (SCREEN_HEIGHT / 10),
      }),
      justifyContent: 'center',
      alignContent: 'center',
      alignSelf: 'center',
    } : style
    return (
      <View
        style={loStyles}
      >
        <View style={{ flex: 3, padding: 10 }}>
          <Text style={{ fontSize: fontType({xl: 18, lg: 12, sm: 12}), fontFamily: 'Inter' }}>{headerText}</Text>
          <Text style={{ alignSelf: 'center', padding: 10, fontSize: fontType({xl: 16, lg: 12, sm: 9}), fontFamily: 'Inter' }}>
            {message}
          </Text>
          {children}
        </View>
        <View style={{ flex: 3, padding: 10, marginTop: 5, flexDirection: 'row', justifyContent:'center' }}>
        {buttons != null &&
        (buttons.map(
          (btn) => 
        <View style={{ flexDirection: 'row-reverse' , justifyContent: 'center'}}>
            <View style={{ paddingLeft: 10, alignContent:'center', flexWrap:'wrap' }}>
              <Button
              onPress={() => {
                btn.action()
              }}
              style={{
                paddingLeft: 10,
                height: heightResizer({
                  xl: 30,
                  lg: 20,
                  sm: 20,
                }),
                paddingRight: 10,
                backgroundColor: buttonBackgroundColor,
                borderRadius: fontResizer(5),
                
              }}
            >
              <Text style={{ color: 'white', fontFamily: 'Inter', textTransform: 'uppercase', fontSize: fontResizer(14) }}>
                {t(btn.title)}
              </Text>
            </Button>
          </View>
            </View>))}
        </View>
      </View>
    )
  }

  render() {
    return (
      <WebModal
        style={{ width: '95%', height: SCREEN_HEIGHT, alignContent: 'center', padding: 30 }}
        // onShow={()=>this.refresh()}
        transparent={true}
        animationType="slide"
        visible={true}
      >
        {this.renderLogoutDialogue()}
      </WebModal>
    )
  }
}
WebAlert.defaultProps = {
  message: '',
  headerText: '',
  buttons: [],
  children: null,
  style: {},
  t: () => {},
}
WebAlert.propTypes = {
  message: PropTypes.string,
  headerText: PropTypes.string,
  buttons: PropTypes.array,
  children: PropTypes.element,
  style: PropTypes.object,
  t: PropTypes.func,
}
export default WebAlert
