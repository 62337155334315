import { StyleSheet } from 'react-native'

const chatStyles = StyleSheet.create({
  chatItemView: {
    //    flex: 1,
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 5,
  },
  avatrStyle: {
    flex: 1,
    fontFamily: 'Inter',
    fontSize: 16,
  },
  chatItemStyle: {
    flex: 5,
    justifyContent: 'center',
    flexDirection: 'column',
  },
  recentMsgStyle: {
    flexDirection: 'row',
  },
  unReadMsgStyle: {
    flex: 0.9,
    fontSize: 14,
  },
  readMsgStyle: {
    flex: 1,
    fontSize: 14,
  },
  unReadTextStyle: {
    fontFamily: 'Inter',
    fontSize: 14,
  },
  chatTitleStyle: {
    flex: 5.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleStyle: {
    flex: 1,
    paddingRight: 20,
    fontSize: 16,
    fontFamily: 'Inter',
  },
  recentMsgDateStyle: {
    flex: 0.5,
    fontSize: 14,
    fontFamily: 'Inter',
  },
  dateStyle: {
    color: '#546e7a',
    fontSize: 14,
    fontFamily: 'Inter',
  },
})

export default chatStyles
