import createActionType from '../utils/action'
import _ from 'lodash'
import api from '../utils/api'
import constants from '../constants/constants'
import moment from 'moment'
import { getDeviceStatus } from './users'
import { updateOnMobileStatus } from './storeAppStatus'
import { removeUserIdFromSessionStorage } from '../utils/util'
import { getMetaData, retrieveFormsIfNotExist, updateSvfStatus } from './studyMetaData'
import { getUserId, getUserType } from '../selectors/user'
import { getPrimaryOrgCode, getSubject } from '../selectors/commonSelector'
import { encryptAESKey, genAESKey, genAESOptions } from '../utils/cryptoUtil'
import CryptoJS from 'crypto-js'
import  Constants  from 'expo-constants'
import { Platform } from 'react-native'

export const PUSH_FORM_DATA_TO_QUEUE = createActionType('PUSH_FORM_DATA_TO_QUEUE')
export const STORE_SYNC_QUEUE_DATA = createActionType('STORE_SYNC_QUEUE_DATA')
export const UPDATE_FORM_STATUS_IN_QUEUE = createActionType('UPDATE_FORM_STATUS_IN_QUEUE')
export const REMOVE_FROM_QUEUE = createActionType('REMOVE_FROM_QUEUE')
export const CLEAR_USER_STORAGE = createActionType('CLEAR_USER_STORAGE')
export const STORE_SYNC_STATUS = createActionType('STORE_SYNC_STATUS')
export const UPDATE_SYNC_COMPLETE_STATUS = createActionType('UPDATE_SYNC_COMPLETE_STATUS')

const { ContextProperties: {PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} } = constants;
export const pushFormDataToQueue =
  (svf, subjectId, completedOn, crfVersionId, subjectVisitId) => async (dispatch, getState) => {
    const url = `datasync/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/crfVersions/${crfVersionId}/sites/${SITE_ID}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms`
    const userId = getUserId(getState())
    const userType = getUserType(getState())
    const primaryOrgCode = getPrimaryOrgCode(getState())
    const publicKey = _.get(getSubject(getState()), 'publicKey', null)

    const { aesEncData, rsaEncryptedAesKey } = encryptData(svf, userId, publicKey);
    
    const svfId = svf.id;
    const svfStatus = svf.status;

    const currentItem = {
      url: url,
      data: aesEncData,
      method: 'POST',
      headers: null,
      isActive: true,
      status: constants.OfflineDataStatus.ACTIVE,
      submittedOn: completedOn,
      attempts: 0,
      userId,
      subjectId,
      userType,
      primaryOrgCode,
      publicKey,
      rsaEncryptedAesKey,
      svfId,
      svfStatus,
      subjectVisitId
    }

    dispatch({
      type: PUSH_FORM_DATA_TO_QUEUE,
      queueItem: currentItem,
      userId,
    })
  }

const updateStatus = (key, status, userId) => ({
  type: UPDATE_FORM_STATUS_IN_QUEUE,
  key,
  status,
  userId
  // attempts
})

const removeFromQueue = (key, userId) => ({
  type: REMOVE_FROM_QUEUE,
  key,
  userId
})
export const processQueue = () => async (dispatch, getState) => {
  const subject = getSubject(getState())
  const userId = getUserId(getState())
  const syncPool = getState().syncQueue
  const userKeys = Platform.OS !== 'web' ? Object.keys(syncPool) : [userId]

  for(const uKey of userKeys){
    const subjectSyncQueue = _.get(syncPool[uKey], 'data', {})
    const keys  = !_.isEmpty(subjectSyncQueue) ? Object.keys(subjectSyncQueue): [];
    keys.sort((a, b) => a - b);
    for (const key of keys ) {
      const item = subjectSyncQueue[key]
      if (constants.OfflineDataStatus.ACTIVE === item.status && getDeviceStatus()) {
        // const attempts = item.attempts + 1
        await processSubmission(key,item,subject,dispatch, userId)
      }
    }
  } 
}


const processSubmission = async (key,item,subject,dispatch, userId) => {
  try {
    dispatch(updateStatus(key, constants.OfflineDataStatus.IN_PROGRESS, userId))
      const loItem = await prepareRequestBody(item)
      const res = await invokeApiEndPoint(loItem)
      if (res.status === 200 || res.status === 201) {
        const svf = {
          svfId: item.svfId,
          subjectVisitId: item.subjectVisitId,
          status: item.svfStatus
        }
        await updateSvfBasedOnSyncStatus(svf, item.submittedOn, dispatch)
          await dispatch(updateStatus(key, constants.OfflineDataStatus.SUCCESS, userId))
          await dispatch(removeFromQueue(key, userId))
          await refreshMetaData(dispatch, subject);
      }else{
        dispatch(updateStatus(key, constants.OfflineDataStatus.ACTIVE, userId))
      }
  } catch (error) {
    console.log(error)
    if(item.svfId){
      dispatch(updateStatus(key, constants.OfflineDataStatus.ACTIVE, userId))
    }
  }
}

const prepareRequestBody = async(item) => {
  const ele = {
    userId: item.userId,
    subjectId: item.subjectId,
    userType: item.userType,
    primaryOrgCode: item.primaryOrgCode,
    aesEncData: item.data,
    rsaEncryptedAesKey: item.rsaEncryptedAesKey
  }
  const prerequisiteInfo = generateSignature(ele)

    let loItem = {...item} 
    loItem.headers = prerequisiteInfo.headers
    loItem.data = prerequisiteInfo.requestBody
    return loItem
}
const invokeApiEndPoint = (item) => {
  return api({
    method: item.method,
    url: item.url,
    data: item.data,
    headers: item.headers,
  })
}

const refreshMetaData = async (dispatch,subject) => {
 try {
  await dispatch(getMetaData(subject.id, moment(subject.lastUpdatedDateOn).utc(), false))
  dispatch(retrieveFormsIfNotExist())
 } catch (error) {
    console.log("Failed to refresh meta data", error)
 }
}

export const storeSyncQueueData = (data) => ({
  type: STORE_SYNC_QUEUE_DATA,
  data: data
})

export const clearUserStorage = () => async (dispatch, getState) => {
  const loggedInUserId = getUserId(getState())
  dispatch({
    type: CLEAR_USER_STORAGE,
    userId: loggedInUserId
  })
  removeUserIdFromSessionStorage()
}

export const clearStorageAndStoreSyncQueue = () => async (dispatch, getState) => {
  const loSyncQueueData = getState().syncQueue
  const onMobile = getState().appStatus?.onMobile
  dispatch(clearUserStorage())
  dispatch(updateOnMobileStatus(onMobile))
  dispatch(storeSyncQueueData(loSyncQueueData))
}

export const updateSvfBasedOnSyncStatus = async(item, submittedOn, dispatch) => {
  try {
    await dispatch(updateSvfStatus(
      item.id,
      item.subjectVisitId,
      item.status === 'COMPLETED' ? constants.OfflineDataStatus.SUCCESS : item.status,
      submittedOn,
      item.status
    ))
  } catch (e){
    console.log("Failed to update svf status", e)
  }
}

export const storeSyncStatus = (value) => async (dispatch, getState) => {
  dispatch({
      type: STORE_SYNC_STATUS,
      isSyncing: value,
      userId: getUserId(getState())
    })
}

export const updateSyncCompleteStatus = (value) => async (dispatch, getState) => {
  dispatch({
      type: UPDATE_SYNC_COMPLETE_STATUS,
      value,
      userId: getUserId(getState())
    })
}

const generateSignature = (element) =>  {
  const { userId, subjectId, userType, primaryOrgCode, aesEncData: payloadData, rsaEncryptedAesKey } = element;
  const req = [{
    payload: payloadData,
    userId,
    subjectId,
    userType,
    primaryOrgCode,
    encAesKey: rsaEncryptedAesKey,
  }];
  const encryptionKey = Constants.expoConfig.extra.encryptionSecretKey;
  const signature = CryptoJS.HmacSHA1(JSON.stringify(req), encryptionKey);
  const hexSignature = signature.toString(CryptoJS.enc.Hex);
  return {
    requestBody: req,
    headers: {
      'Signature': hexSignature,
      'Content-Type': 'application/json',
    },
  };
};

export const getRequestPayloadOnline = (element) => {
  const { svf: data, publicKey, userId, subjectId, userType, primaryOrgCode } = element;
    const { aesEncData, rsaEncryptedAesKey } = encryptData(data, userId, publicKey);
    const req = {
      aesEncData,
      userId,
      subjectId,
      userType,
      primaryOrgCode,
      rsaEncryptedAesKey
    }
    return generateSignature(req);
};

const encryptData = (data, userId, publicKey) => {
  const aesKey = genAESKey();
  const aesOptions = genAESOptions(userId);
  const aesEncData = CryptoJS.AES.encrypt(JSON.stringify(data), aesKey, aesOptions);
  const rsaEncryptedAesKey = encryptAESKey(aesKey, publicKey);

  return { aesEncData: aesEncData.toString(), rsaEncryptedAesKey, aesKey, aesOptions };
};


