import React from "react"
import { View, StyleSheet } from "react-native";
import NRSHorizontalScale from "./NRSHorizontalScale";
import HorizontalLabels from "./HorizontalLabels";
import _ from 'lodash'
import PropTypes from 'prop-types'
import { alignments } from "../alignments";
import { fontScale } from "../../styles/fontResizer";

const HorizontalScaleWrapper = (props) => {

    const {start, end, step, value, disabled, id, isPercentage, onNumberChange, options,  orientation, fontSize, minimumFontSize, labelsData, isCalculated} = props

    return(
    <View style={styles.container}>
       <View>
      {isCalculated && (
          <HorizontalLabels 
                        labels = {_.filter(options, op => op.labelPlacement === 'TOP')}
                        start={start}
                        end={end}
                        step={step}
                        isPercentage={isPercentage}
                        orientation={orientation}
                        disabled={disabled}
                        id={id}
                        transformedFontSize={minimumFontSize}
                        labelsData={labelsData}
                    />
      )}  
       </View>
       <View>
         <NRSHorizontalScale 
             start={start}
             end={end}
             step={step}
             value={value}
             disabled={disabled}
             id={id}
             isPercentage={isPercentage}
             onNumberChange={onNumberChange}
             orientation={orientation}
             fontSize={fontSize}
         />
       </View>
       <View>
       {isCalculated && (
          <HorizontalLabels 
          labels = {_.filter(options, op => op.labelPlacement === 'BOTTOM')}
          start={start}
          end={end}
          step={step}
          isPercentage={isPercentage}
          orientation={orientation}
          disabled={disabled}
          id={id}
          transformedFontSize={minimumFontSize}
          labelsData={labelsData}
        />
        )}  
       </View>
     </View>
        
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        marginVertical: 5
    },
});
  
HorizontalScaleWrapper.defaultProps = {
  isPercentage: false,
  start: 0,
  end: 10,
  step: 1,
  value: null,
  onNumberChange: () => null,
  disabled: false,
  options: [],
  id: null,
  orientation: alignments.HORIZONTAL,
  minimumFontSize: fontScale(6), 
  labelsData: {}, 
  isCalculated: false,
 }
  
  HorizontalScaleWrapper.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    onNumberChange: PropTypes.func,
    options: PropTypes.arrayOf(Object),
    isPercentage: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    orientation: PropTypes.string,
    fontSize: PropTypes.number.isRequired,
    minimumFontSize: PropTypes.number, 
    labelsData: PropTypes.instanceOf(Object), 
    isCalculated: PropTypes.bool,
  }
export default HorizontalScaleWrapper