import React from 'react'
import { View, Text } from 'react-native'

function LoginHeader(props) {
  const { t, orgName, type } = props
  return (
    <View>
      <Text
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 20,
          fontFamily: 'Inter',
          color: type ? '#ffa500' : '#469ae5',
        }}
      >
        {type ? t('ClinicianLogin') : t('ParticipantLogin')}
      </Text>
      {type && (
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
            textAlign: 'center',
            fontFamily: 'Inter',
          }}
        >
          {t('Organization')}: {orgName}
        </Text>
      )}
      {type && (
        <Text
          style={{
            fontSize: 14,
            marginTop: 5,
            textAlign: 'center',
            fontFamily: 'Inter',
          }}
        >
          {t('OrgText1')}
        </Text>
      )}
      {type && (
        <Text
          style={{
            fontSize: 14,
            textAlign: 'center',
            fontFamily: 'Inter',
          }}
        >
          {t('OrgText2')}
        </Text>
      )}
    </View>
  )
}

export default LoginHeader
