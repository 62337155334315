import React from 'react'
import { Text, Platform, TouchableOpacity, StyleSheet } from 'react-native'
import Dialog, {
  DialogContent,
  DialogFooter,
  DialogButton,
  ScaleAnimation,
} from 'react-native-popup-dialog'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

class CallJoinRequest extends React.Component {
  render() {
    const { visible, closePopup, call, openCallLink, timeZone, t } = this.props
    return (
      <Dialog
        onDismiss={() => {
          closePopup()
        }}
        width={Platform.OS === 'web' ? 0.5 : 0.9}
        visible={visible}
        rounded
        dialogAnimation={new ScaleAnimation()}
        footer={
          <DialogFooter>
            <TouchableOpacity
              style={{ padding: 20, flex: 1, alignItems: 'center' }}
              onPress={() => {
                closePopup()
              }}
            >
              <Text style={{ color: '#e53935', fontSize: 20 }}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ padding: 20, backgroundColor: '#4caf50', flex: 1, alignItems: 'center' }}
              onPress={() => {
                openCallLink()
              }}
            >
              <Text
                style={{
                  color: '#ffff',
                  fontSize: 20,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >{t('Join')}
              </Text>
            </TouchableOpacity>
          </DialogFooter>
        }
      >
        <DialogContent
          style={{
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            height: 'auto',
          }}
        >
          <Text style={styles.callTitleText}>
            {call.callTitle} {t('CallInProgressJoinMessage')}
          </Text>
          <Text style={styles.callDetailsText}>
            {moment(call.scheduleDateTimeUtc)?.tz(timeZone).format('DD-MMM-YYYY hh:mm A z')}
          </Text>
          <Text style={styles.callDetailsText}>
            {t('Duration')}:{' '}
            {Math.floor(call.duration / 60) == 0
              ? ''
              : `${Math.floor(call.duration / 60)} ${call.duration > 119 ? 'hrs' : 'hr'} `}{' '}
            {call.duration % 60 == 0
              ? ''
              : `${call.duration % 60} ${call.duration % 60 == 1 ? 'min' : 'mins'}`}
          </Text>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = StyleSheet.create({
  callDetailsText: {
    color: '#000000',
    fontSize: 14,
  },
  callTitleText: {
    color: '#000000',
    fontSize: 18,
    marginVertical: 20,
  },
})

CallJoinRequest.defaultProps = {
  visible: false,
  closePopup: ()=> null, 
  openCallLink: () =>null, 
  timeZone: null,
  t: ()=> null,
  call: {
    title: null,
  }
}

CallJoinRequest.propTypes = {
  visible: PropTypes.bool,
  closePopup: PropTypes.func, 
  openCallLink: PropTypes.func, 
  timeZone: PropTypes.string,
  t: PropTypes.func,
  call: PropTypes.shape({
    title: PropTypes.string
  })
}

export default CallJoinRequest