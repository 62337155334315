import * as React from 'react'
import { useState } from 'react'
import { Image } from 'react-native'

function AutoHeightImage({ style, width, source, resizeMode }) {
  const [height, setHeight] = useState(0)

  const updateImageHeight = () => {
    Image.getSize(source.uri, (imgWidth, imgHeight) => {
      const ratio = imgWidth / imgHeight
      setHeight(width / ratio)
    })
  }

  return (
    <Image
      style={[style, { width: width, height: height || 100 }]}
      width={width}
      source={source}
      resizeMode={resizeMode}
      onLoad={updateImageHeight}
    />
  )
}

export default AutoHeightImage
