import React from 'react';
import WebAlert from '../../../utils/WebAlert';

const Modal = (props) => {
    return (
        <WebAlert
            {...props}
      />
    )
}

export default Modal;