import React, { useEffect } from 'react'
import { View, ActivityIndicator, Text, Platform } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getMetaData, getStudyDetails, retrieveFormWithFields } from '../actions/studyMetaData'
import _ from 'lodash'
import appConstants from '../constants/appConstants'
import constants from '../constants/constants'
import { isPinExists } from '../utils/secureStorageUtils'
import {  getSubjectId } from '../selectors/subject'
import { getMetadataLoader, getQuestionnairesLoader, getStudyFailedStatus, getStudyLoader } from '../selectors/studyMetaData'
import { getSubject, getUser } from '../selectors/commonSelector'
import PropTypes from 'prop-types'
import { getUserId } from '../selectors/user'

const OfflineDataLoader = (props) => {
  const {
    actions,
    studyDetailsLoader,
    studyDetailsFailed,
    subjectId,
    metaDataLoading,
    formsLoading,
    navigation,
    isSubjectRegistered,
    subjectPrivileges,
    trainingCompleted,
    locale,
    screenProps: { t, setLocale },
    loggedInUserPhoneNo,
    userId
  } = props
  useEffect(() => {
    loadData()
  }, [subjectId])

  const loadData = async () => {
    await actions.getStudyDetails(subjectId)
    await actions.getMetaData(subjectId, null, true)
    await actions.retrieveFormWithFields({userId})
    navigateToNextScreen()
  }

  const navigateToNextScreen = () => {
    setLocale(locale)
    if (!isSubjectRegistered) {
      navigation.navigate('SubjectTimeZone', { fromLogin: true })
    } else {
      if (
        Platform.OS == 'web' ||
        !subjectPrivileges?.includes(constants.APP_PRIVILEGES.SETUP_APPLOCK) ||
        isPinExists(loggedInUserPhoneNo)
      ) {
        if (trainingCompleted) {
          navigation.navigate(`${appConstants.urlPrefix}RootTabs`, { screen: 'Visits' })
        } else {
          navigation.navigate(`${appConstants.urlPrefix}RootTabs`)
        }
      } else {
        navigation.navigate('PinSetup')
      }
    }
    // putItem(locale, 'locale')
  }
  const getLoaderText = () => {
    if (studyDetailsLoader || metaDataLoading || formsLoading) {
      return t('DownloadStudydata')
    }
  }

  const getFailedText = () => {
    if (studyDetailsFailed) {
      return t('FailedRetrieveStudy')
    }
  }
  if (studyDetailsLoader || formsLoading || metaDataLoading) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          backgroundColor: '#ffffff',
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            fontSize: 16,
            fontFamily: 'Inter',
            justifyContent: 'center',
          }}
        >
          {getLoaderText()}...
        </Text>
        <ActivityIndicator
          size="large"
          animating={true}
          hidesWhenStopped={true}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </View>
    )
  }
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundColor: '#ffffff',
      }}
    >
      <Text>{getFailedText()}...</Text>
    </View>
  )
}

const mapStateToProps = (state) => {
  return {
    studyDetailsLoader: getStudyLoader(state),
    studyDetailsFailed: getStudyFailedStatus(state),
    subjectId: getSubjectId(state),
    metaDataLoading: getMetadataLoader(state),
    formsLoading: getQuestionnairesLoader(state),
    isSubjectRegistered: getUser(state)?.isSubjectRegistered,
    subjectPrivileges: getUser(state).privileges,
    trainingCompleted: getSubject(state)?.trainingCompleted,
    locale: getSubject(state)?.locale,
    loggedInUserPhoneNo: getUser(state)?.phoneNo, //offline loader is specifically for subject or caregiver login, logged
    userId: getUserId(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getStudyDetails,
      getMetaData,
      retrieveFormWithFields,
    },
    dispatch
  ),
})


OfflineDataLoader.defaultProps = {
  loggedInUserPhoneNo: null
}

OfflineDataLoader.propTypes = {
  loggedInUserPhoneNo: PropTypes.string,
  userId: PropTypes.string,
  actions: PropTypes.shape({
    retrieveFormWithFields: PropTypes.func
  })
} 


export default connect(mapStateToProps, mapDispatchToProps)(OfflineDataLoader)
