import { Button, Spinner, Text } from 'native-base'
import { View, StyleSheet } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { buttonBackgroundColor } from '../../containers/NavigationScreens'
import { Authorize } from '../../utils/appPrivileges'
import React from 'react'
import _ from 'lodash'
import { placeFieldValue } from '../../utils/fieldutils/fieldDecorator'
import { storeDraftData } from '../../utils/secureStorageUtils'
import PropTypes from 'prop-types'

var updatedFormData = []
class StepLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      linkedFieldList: {},
      showOnlySubmitButton: false,
      footerPageY:0
    }
    this.viewRef = React.createRef()
    this.scrollViewRef = React.createRef();
  }
  componentDidMount() {
    const { formData } = this.props
  }
  componentDidUpdate(prevProps) {
    const {formData} = this.props
    if(prevProps.formData !== formData){
      this.handleLayout()
    }
  }

  saveDraft = () => {
    const { handleSubmit, disableSubmitButton } = this.props
    const isSubmitDisabled = !disableSubmitButton()
    if (!isSubmitDisabled) {
      handleSubmit(false)
    }
  }

  nextItem = () => {
    const { formData, setCurrentPage, currentPage, form } = this.props
    form.validateFields((error, values) => {
      if(!error){
        const sections = formData.sections;
        var nextIndex = this.getNextIndex(currentPage)
        if (currentPage < sections.length - 1) {
          setCurrentPage(nextIndex)
          this.scrollToTop()
        }
      }
    })
  }

  getNextIndex = (index) => {
    const { formData } = this.props
    const sections = formData.sections;
    if (
      sections.length > index &&
      sections[index + 1] &&
      !_.isEmpty(formData.sections[index + 1]?.data)
      // &&
      // sections[index].data &&
      // sections[index].data.disabled
    ) {
      return index + 1
    } else if (
      sections.length < index &&
      sections[index + 1] &&
      _.isEmpty(formData.sections[index - 1]?.data)
    ) {
      return getNextIndex(index + 1)
    } else {
      return index
    }
  }

  getPrevIndex = (index) => {
    const { formData} = this.props
    const sections = formData.sections
    if (
      sections.length > index - 1 &&
      sections[index - 1] &&
      !_.isEmpty(formData.sections[index - 1]?.data)
      // &&
      // sections[index].data &&
      // sections[index].data.disabled
    ) {
      return index - 1
    } else if (
      sections.length > index - 1 &&
      sections[index - 1] &&
      _.isEmpty(formData.sections[index - 1])
    ) {
      return getPrevIndex(index - 1)
    } else {
      return index
    }
  }

  previousItem = () => {
    const { setCurrentPage, currentPage  } = this.props
    var prevIndex = this.getPrevIndex(currentPage)
    if (currentPage > 0) {
      setCurrentPage(prevIndex)
      this.scrollToTop()
    }
  }

  prepareDraftData = (field) => {
    const { selectedSvf, form, formAnswers } = this.props
    const formId = selectedSvf.svfId
    var selectedFieldValue = form.getFieldValue(field.id)
    const selectedFieldWithValue = {
      [field.id]: selectedFieldValue,
    }
    const fieldWithCrfData = placeFieldValue(
      field,
      selectedFieldValue,
      formAnswers[selectedSvf.svfId][field.id]?.optionOid
    )
    storeDraftData(selectedFieldWithValue, formId, fieldWithCrfData)
  }

  scrollToTop = () => {
    this.scrollViewRef.current?.scrollTo({ y: 0, animated: true });
  };

  handleLayout = () => {
    this.viewRef?.current?.measure((x, y, width, height, pageX, pageY) => {
      this.setState({
        footerPageY:pageY
      })
    });
  }
 

  render() {
    const { formData, renderFormItem, handleSubmit, disableSubmitButton, training, t, 
      submitted, currentPage: itemIndex } = this.props
    const {showOnlySubmitButton, footerPageY } = this.state
    const isSubmitDisabled = disableSubmitButton()
      
    if (_.isEmpty(formData)) return <Spinner />
    // return <Text>{JSON.stringify(formData[itemIndex])}</Text>
    return (
      <View style={{ flex: 1 }}>
        <ScrollView 
          ref={this.scrollViewRef} contentContainerStyle={[styles.container]}>
          {_.map(formData.sections[itemIndex]?.headers, (h) => renderFormItem(h, footerPageY))}
          {!_.isEmpty(formData.sections[itemIndex]?.data) &&
            _.map(formData.sections[itemIndex]?.data, (h) => renderFormItem(h, footerPageY))}
        </ScrollView>
        <View collapsable={false} ref={this.viewRef}  
            onLayout={this.handleLayout}>
          {_.map(formData.footer, (h) => renderFormItem(h, footerPageY))}
          <View
            style={{
              flexDirection: 'row',
              marginTop: 20,
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            {itemIndex > 0 && !showOnlySubmitButton ? (
              <Button
                style={{
                  borderColor: buttonBackgroundColor,
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                }}
                bordered
                onPress={this.previousItem}
              >
                <Text style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('FieldPrevious')}</Text>
              </Button>
            ) : (
              <Text></Text>
            )}
            {itemIndex < formData?.sections?.length - 1 && !showOnlySubmitButton && (
              <Button
                style={{
                  borderColor: buttonBackgroundColor,
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                }}
                bordered
                onPress={this.nextItem}
              >
                <Text style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('Next')}</Text>
              </Button>
            )}
            {(itemIndex === formData?.sections?.length - 1 || showOnlySubmitButton) && (
              <Authorize hasAnyGrant={["SUBMIT_FORM", "VIEW_SUBJECT"]}>
                <Button
                  style={{
                    borderColor: isSubmitDisabled || submitted ? '#bababa' : '#9155FD',
                    backgroundColor: isSubmitDisabled || submitted ? '#bababa' : '#9155FD',
                    borderRadius: 5,
                  }}
                  bordered
                  onPress={() => handleSubmit(true)}
                  disabled={isSubmitDisabled || submitted}
                >
                  <Text style={{ color: '#ffffff', textTransform: 'uppercase' }}>
                    {training ? t('SubmitTraining') : t('Submit')}
                  </Text>
                </Button>
              </Authorize>
            )}
          </View>
        </View>
      </View>
    )
  }
}


StepLayout.defaultProps = {
  formData: {},
  handleSubmit: () => null, 
  disableSubmitButton: false,
  renderFormItem: () => null, 
  training: false,
  t: null, 
  submitted: false, 
  currentPage: 0,
}
StepLayout.propTypes = {
  formData: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func, 
  disableSubmitButton: PropTypes.bool,
  renderFormItem: PropTypes.func, 
  training: PropTypes.bool,
  t: PropTypes.string, 
  submitted: PropTypes.bool, 
  currentPage: PropTypes.number,
  form: PropTypes.object.isRequired
}
const styles = StyleSheet.create({
  container: {
    paddingTop: 5, 
    marginTop: 5 
  },
});
export default StepLayout
