import React, { useEffect, useState } from 'react'
import { View, ActivityIndicator, Text } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { retrieveSubjectsOfCaregiver, retrieveSubjectById } from '../actions/caregiver'
import _ from 'lodash'
import ConstraintStatus from '../utils/constraintKeys'
import InvalidAccessPage from './invalidAccessPage'
import { getUserStudySite } from '../selectors/studyMetaData'
import { getUser } from '../selectors/commonSelector'
import PropTypes from 'prop-types'

const SubjectDataLoader = (props) => {
  const {
    actions,
    currentUser,
    navigation,
    screenProps: { t },
    screenProps,
  } = props
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])

  useEffect(() => {
    getSubjectsOfCaregiver()
  }, [currentUser.id])

  const getSubjectsOfCaregiver = async () => {
    try {
      setLoading(true)
      const data = await actions.retrieveSubjectsOfCaregiver(currentUser.id)
      setList(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response) {
        switch (error.response.status) {
          case 500: {
            showMessageForInternalServerError(error.response.data, t)
            break
          }
          default:
            setErrorMessage(t('SomethingWrong'))
        }
      } else {
        setErrorMessage(t('SomethingWrong'))
      }
    }
  }

  const showMessageForInternalServerError = (errorCode, t) => {
    if (errorCode && Number(errorCode) === ConstraintStatus.USER_CAREGIVER_INACTIVE) {
      showToast(t('SubjectCaregiverInactive'), 'danger', 3000)
    }
    if (errorCode && Number(errorCode) === ConstraintStatus.USER_INACTIVE) {
      setErrorMessage(t('SubjectInactive'))
    } else {
      setErrorMessage(t('SomethingWrong'))
    }
  }

  if (loading) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          backgroundColor: '#ffffff',
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            fontSize: 16,
            fontFamily: 'Inter',
            justifyContent: 'center',
          }}
        >
          Loading ....
        </Text>
        <ActivityIndicator
          size="large"
          animating={true}
          hidesWhenStopped={true}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        />
      </View>
    )
  } else if (errorMessage) {
    return <InvalidAccessPage msg={errorMessage} screenProps={screenProps} />
  } else if (!_.isEmpty(list) && _.size(list) > 1 && !errorMessage) {
    navigation.navigate('SubjectListPage')
  } else if (!_.isEmpty(list) && _.size(list) == 1 && !errorMessage) {
    navigation.navigate('OfflineDataLoader')
  }
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundColor: '#ffffff',
      }}
    >
      <Text>No Data</Text>
    </View>
  )
}

const mapStateToProps = (state) => {
  return {
    siteId: getUserStudySite(state)?.id,
    currentUser: getUser(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      retrieveSubjectsOfCaregiver,
      retrieveSubjectById,
    },
    dispatch
  ),
})



SubjectDataLoader.defaultProps = {
  actions: {
    retrieveSubjectsOfCaregiver  :()=> null,
    retrieveSubjectById :()=> null,
  },
  currentUser: {
    id: null,
  }
}

SubjectDataLoader.propTypes = {
  actions: PropTypes.shape({
    retrieveSubjectsOfCaregiver  :PropTypes.func,
    retrieveSubjectById :PropTypes.func,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  })
} 

export default connect(mapStateToProps, mapDispatchToProps)(SubjectDataLoader)
