import React from 'react';
import { TextInput, StyleSheet } from 'react-native';
import PropTypes from 'prop-types'

const CustomTextInput = (props) => {
    const {style} = props
    return (
        <TextInput {...props} style = {[{...styles.textInput},style]}/>
    )
}

const styles = StyleSheet.create({
    textInput: {
        height: 40,
        outlineStyle: 'none',
    }
})


CustomTextInput.defaultProps = {
    style: {}
}
  
CustomTextInput.propTypes = {
    style: PropTypes.instanceOf(Object),
}
export default CustomTextInput