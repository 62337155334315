const defaultFormat = {
    "time12": false
}
const locale_codes = {
    "en-us": {
        "code": "en",
        "time12": true
    },
    "cs": {
        ...defaultFormat,
        "code": "cs",
    },
    "de": {
        ...defaultFormat,
        "code": "de"
    },
    "el": {
        "code": "el",
        "time12": true
    },
    "fr": {
        ...defaultFormat,
        "code": "fr"
    },
    "it": {
        ...defaultFormat,
        "code": "it"
    },
    "ja": {
        ...defaultFormat,
        "code": "ja"
    },
    "ko": {
        "code": "ko",
        "time12": true
    },
    "pl": {
        ...defaultFormat,
        "code": "pl"
    },
    "pt-br": {
        ...defaultFormat,
        "code": "ptBR"
    },
    "ru": {
        ...defaultFormat,
        "code": "ru"
    },
    "sv": {
        ...defaultFormat,
        "code": "sv"
    },
    "tr": {
        ...defaultFormat,
        "code": "tr"
    },
    "zh-cn": {
        ...defaultFormat,
        "code": "zhCN"
    },
    "nl": {
        ...defaultFormat,
        "code": "nl"
    },
    "fr-ca": {
        ...defaultFormat,
        "code": "frCA"
    },
    "fi": {
        ...defaultFormat,
        "code": "fi"
    },
    "es": {
        ...defaultFormat,
        "code": "es"
    },
    "da": {
        ...defaultFormat,
        "code": "da"
    },
    "hi": {
        "code": "hi",
        "time12": true
    },
    "pt": {
        ...defaultFormat,
        "code": "pt"
    },
    "nb": {
        ...defaultFormat,
        "code": "nb"
    },
    "es-mx": {
        "code": "esMX",
        "time12": true
    },
}

export default locale_codes;
