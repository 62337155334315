import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SelectLanguage from '../components/SelectLanguage'
import { getSubject } from '../selectors/commonSelector'

class ChangeTimeZonePage extends Component {
  state = {
    timeZonesList: [],
    loading: true,
  }

  // static navigationOptions = ({ navigation, screenProps: { t } }) => ({
  //     title: t('SelectTimezone'),
  //   });

  render() {
    const { params } = this.props.navigation.state
    const {
      navigation,
      screenProps,
      selectedLanguage,
      loading,
      subjectPhoneNo,
    } = this.props
    // const setTimeZone = navigation.getParam("setTimeZone");
    const fromLogin = navigation.getParam('fromLogin')

    return (
      <View style={{ flex: 1, paddingTop: 20 }}>
        {/* <Header style={{ backgroundColor: backgroundColor}}>
                <TouchableOpacity onPress={()=>{
                    navigation.goBack();
                }} style={{flex:0.4,justifyContent:'flex-start',alignSelf:'center'}}>
                <MaterialIcons name="arrow-back" size={28} color="white" />
                    </TouchableOpacity>
                    <View style={{flex:5.6}} />
                </Header> */}
        <SelectLanguage
          screenProps={screenProps}
          fromLogin={fromLogin}
          navigation={navigation}
          selectedLang={selectedLanguage}
          loading={loading}
          subjectPhoneNo={subjectPhoneNo}
        />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state?.changeLanguage?.selectedLanguage,
    loading: state?.loading,
    subjectPhoneNo: getSubject(state)?.phoneNo
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTimeZonePage)
