import React from 'react';
import WebTextResize from './WebTextResize';
import { AutoSizeText, ResizeTextMode } from 'react-native-auto-size-text';
import { Platform } from 'react-native';
import PropTypes from 'prop-types'
import { fontScale } from '../styles/fontResizer';

const CustomTextResize = (props) => {
    const {textStyle, fontSize, title, viewStyles} = props
    if(Platform.OS !== 'web') {
        return (
            <AutoSizeText
                fontSize={fontSize}
                numberOfLines={6}
                mode={ResizeTextMode.max_lines}
                textStyle={textStyle}     
            >{title}</AutoSizeText>
        )
    }else {
        return (
            <WebTextResize
              text={title}
              containerWidth={'100%'}
              containerHeight={'100%'}
              textStyle={textStyle}
              fontSize={fontSize}
              viewStyles={viewStyles}
            />
        )
    }
}

CustomTextResize.defaultProps = {
    textStyle: {}, 
    fontSize: fontScale(24), 
    title: null, 
    viewStyles: {}
  }
  
  CustomTextResize.propTypes = {
    textStyle: PropTypes.instanceOf(Object), 
    fontSize: PropTypes.number, 
    title: PropTypes.string, 
    viewStyles: PropTypes.instanceOf(Object)
  }
export default CustomTextResize 