import React from 'react'
import { Text, View } from 'react-native'
import { List } from 'react-native-paper'
import { buildFaqContent, buildFaqHeader } from '../utils/faqUtils'
import styles from './styles/faqStyles'

function Faq({ faqs, loading, screenProps }) {
  const { t } = screenProps

  return (
    <View style={{ padding: 5, margin: 5 }}>
      {faqs.length > 0 ? (
        faqs.map((value, index) => {
          return (
            <List.Accordion
              style={{ borderColor: 'grey', borderWidth: 0, borderRadius: 5 }}
              title={buildFaqHeader(value, index)}
              children={buildFaqContent(value)}
            />
          )
        })
      ) : (
        <View style={{ flex: 1, justifyContent: 'center', marginTop: 20 }}>
          <Text style={[styles.noDataText, { padding: 10 }]}>{t('NoFaqs')}</Text>
        </View>
      )}
    </View>
  )
}

export default Faq
