import { Feather, Ionicons } from '@expo/vector-icons'
import { Fab } from 'native-base'
import React from 'react'
import { FloatingMenu, MainButton } from 'react-floating-button-menu'
import { ActivityIndicator, Dimensions, FlatList, Platform, Text, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import AddChatScreenPage from '../../containers/AddChatScreenPage'
import { Authorize } from '../../utils/appPrivileges'
import ChatItem from './chatItem'
import _ from 'lodash'
import { emptyDataStyles } from '../fields/styles'

class ChatList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
    }
  }

  renderListEmptyComponent = () => {
    const { noChatsMessage } = this.props
    return (
      <View style={{ flex: 1, height: 400, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ color: '#546e7a', fontFamily: 'Inter', fontSize: 16 }}>
          {noChatsMessage}
        </Text>
      </View>
    )
  }

  getItemSeperator = () => {
    return <View style={{ borderBottomWidth: 0.5, borderBottomColor: '#e8e8e8' }}></View>
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  render() {
    const {
      chats,
      fetchMoreChats,
      navigation,
      screenProps,
      loading,
      refresh,
      timeZone,
      setSelectedChatAndNavigate,
      clearSelectedChat,
    } = this.props
    const { isModalVisible } = this.state
    let { height } = Dimensions.get('window')
    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff' }}>
        {!loading && this.state.isModalVisible && (
          <AddChatScreenPage
            screenProps={screenProps}
            selectedParticipants={''}
            navigation={navigation}
            hideModal={this.hideModal}
            isModalVisible={isModalVisible}
          />
        )}

        <View style={{ flex: 1, padding: 10 }}>
          <NavigationEvents
            onDidFocus={() => {
              clearSelectedChat()
              // alert('Did focus');
            }}
            // onWillFocus={() => {
            //   clearSelectedChat();
            //   // alert('Will focus');
            // }}
          />
          {loading && (
            <View
              style={{
                height: height - height * (1 / 4),
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator
                size="large"
                color="#9155FD"
                animating={true}
                key={loading ? 'loading' : 'not-loading'}
              />
            </View>
          )}
          {!loading && !this.state.isModalVisible && (
            <FlatList
              data={chats}
              onEndReached={fetchMoreChats}
              onEndReachedThreshold={0.5}
              onRefresh={refresh}
              refreshing={loading}
              ListEmptyComponent={this.renderListEmptyComponent}
              // ItemSeparatorComponent = {this.getItemSeperator}
              renderItem={({ item: chat }) => (
                <ChatItem
                  chat={chat}
                  timeZone={timeZone}
                  setSelectedChatAndNavigate={setSelectedChatAndNavigate}
                />
              )}
              contentContainerStyle={_.isEmpty(chats) && emptyDataStyles.container}
            />
          )}
          <Authorize privilege="CREATE_CHAT">
            {!loading && (
              <View
                style={{
                  flex: 1,
                  alignSelf: 'flex-end',
                  height: '95%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                }}
              >
                {Platform.OS != 'web' ? (
                  <Fab
                    onPress={() => {
                      this.setState({
                        isModalVisible: true,
                      })
                    }}
                    position="bottomRight"
                    style={{ backgroundColor: '#9155FD' }}
                  >
                    <Feather name="plus" size={22} />
                  </Fab>
                ) : (
                  !isModalVisible && (
                    <FloatingMenu
                      slideSpeed={500}
                      direction="up"
                      spacing={8}
                      isOpen={this.state.isModalVisible}
                    >
                      <MainButton
                        background={'#9155FD'}
                        iconResting={<Ionicons name="add" size={25} color="white" />}
                        // iconActive={<CloseIcon htmlColor='white' style={{ fontSize: 25 }}/>}
                        onClick={() => {
                          // hideModal={this.hideModal} isModalVisible={isModalVisible}
                          // navigation.navigate('AddChatScreenPage',{ hidemodal: this.hideModal})
                          this.setState({
                            isOpen: !this.state.isOpen,
                            isModalVisible: !this.state.isModalVisible,
                          })
                        }}
                        size={50}
                        testID={'add-chat-click-btn'}
                        accessible={true}
                      />
                    </FloatingMenu>
                  )
                )}
              </View>
            )}
          </Authorize>
        </View>
      </View>
    )
  }
}

export default ChatList
