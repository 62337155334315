import { View } from 'react-native'
import React from 'react'
import RenderHtml from 'react-native-render-html'

const boldStyle = { fontWeight: '500' }
const italicStyle = { fontStyle: 'italic' }
const underlineStyle = { textDecorationLine: 'underline' }
const strikethroughStyle = { textDecorationLine: 'line-through' }
const tagsStyles = {
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  ins: underlineStyle,
  s: strikethroughStyle,
  strike: strikethroughStyle,
  a: {
    fontWeight: '500',
    color: '#007AFF',
  },
  h1: { fontWeight: '500', fontSize: 36 },
  h2: { fontWeight: '500', fontSize: 30 },
  h3: { fontWeight: '500', fontSize: 24 },
  h4: { fontWeight: '500', fontSize: 18 },
  h5: { fontWeight: '500', fontSize: 14 },
  h6: { fontWeight: '500', fontSize: 12 },
  sub: { fontSize: 12, lineHeight: 17 },
  sup: { fontSize: 12, lineHeight: 50 },
  p: { fontSize: 22 },
}

export default function Info(props) {
  const { title, isFooter } = props
  return (
    <View style={isFooter ? { marginTop: 5, textAlign: 'center' } : { marginTop: 5 }}>
      <RenderHtml tagsStyles={tagsStyles} source={{ html: title }} />
    </View>
  )
}
