const ConstraintStatus = {
  VERSION_NOT_PUBLISHED: 5,
  USER_INACTIVE: 6,
  NO_META_DATA_UPDATED: 15,
  USER_CAREGIVER_INACTIVE: 16,
  USER_WITHDRAWN: 8,
  USER_SCREENING_FAILED: 7,
}

export default ConstraintStatus
