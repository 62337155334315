import createActionType from '../utils/action'
import api from '../utils/api'
import showToast from '../utils/toast'
import moment from 'moment-timezone'
import constants from '../constants/constants'
import { getMetaData, retrieveFormsIfNotExist} from './studyMetaData'
import { getUserId } from '../selectors/user'

export const UPDATE_TIMEZONE_SUCCESS = createActionType('UPDATE_TIMEZONE_SUCCESS')

const updateUserTimezoneSuccess = (timezoneData, userId) => ({
  type: UPDATE_TIMEZONE_SUCCESS,
  timezoneData,
  userId,
})


export const updateUserTimezone = (subject,t) => async (dispatch, getState) => {
  try {
    const subjectUrl = `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}`
    await api.put(subjectUrl, subject)
    moment.tz.setDefault(subject.timeZone)
    await dispatch(updateUserTimezoneSuccess({ 
      timeZone: subject.timeZone,
    }, getUserId(getState())))

    dispatch(getMetaData(subject?.id, subject?.lastUpdatedDateOn ? moment(subject?.lastUpdatedDateOn).utc(): null, false))
    dispatch(retrieveFormsIfNotExist())
    showToast(t('SavedMessage'), 'success', 1000)
  } catch (error) {
    console.error('ERROR: updateSubjectTimezone: ', error)
    console.log(error)
  }
}

