import React, { useState } from "react"
import ToolTip from 'react-native-walkthrough-tooltip';
import PropTypes from 'prop-types'
import { Dimensions, Platform, StyleSheet, Text, TouchableOpacity } from "react-native";
const { width: SCREEN_WIDTH } = Dimensions.get('window')
const Tooltip = (props) => {
    const {children, tip, tipStyle, testID, accessible} = props
    const [visible, setVisible] = useState(false)

    let loTipTextStyle = {...styles.tipTextStyle, 
      fontSize: tipStyle.fontSize}
    let loTooltipStyle = {}
    let loContentStyle = {backgroundColor: styles.contentStyle.backgroundColor}

    if(Platform.OS === 'web'){
        loTipTextStyle = {...loTipTextStyle}
        loTooltipStyle = {...styles.tooltipStyle}
        loContentStyle = {...styles.contentStyle}
    }

    return(
        <ToolTip
            isVisible={visible}
            content={<Text style={loTipTextStyle}>{tip}</Text>}
            placement="bottom"
            onClose={() => setVisible(false)}
            disableShadow={true}
            showChildInTooltip={false}
            contentStyle={loContentStyle}
            tooltipStyle={loTooltipStyle}
        >
         <TouchableOpacity onPress={() => setVisible(!visible)} style={{width: tipStyle.width}} 
          testID={testID+"-tipBtn"}
          accessible={accessible}
          >
            {children}
         </TouchableOpacity>
        </ToolTip>
    )
} 


Tooltip.defaultProps = {
    children: null,
    tip: null,
    tipStyle: {},
    testID: null,
    accessible: true,
}
  
Tooltip.propTypes = {
    children: PropTypes.node,
    tip: PropTypes.string,
    tipStyle: PropTypes.instanceOf(Object),
    testID: PropTypes.string,
    accessible: true,
}

const styles = StyleSheet.create({
  tipTextStyle: {
     color: '#fff',
     paddingBottom: 10,
  },
  tooltipStyle: {
    width: SCREEN_WIDTH >= 1030? 700 : '100%', 
    alignSelf: 'center', 
  },
  contentStyle: {
    position: 'absolute', 
    backgroundColor: '#47476b'
  },
});
export default Tooltip