import _ from 'lodash'
import { createSelector } from 'reselect'
import { Platform } from 'react-native'
import { getUser } from './commonSelector'


const getMobileDeviceStatus = (state) => state.appStatus.isDeviceOnline
const getWebDeviceStatus = () => navigator.onLine

export const getDeviceOnlieStatus = createSelector([getMobileDeviceStatus, getWebDeviceStatus], (mobileStatus, webStatus) => {
  return Platform.OS === 'web' ? webStatus : mobileStatus
})



export const getFullName = createSelector([getUser], (user) => {
  const userMaybe = user;
  const names = [
    userMaybe?.userData?.data?.firstName,
    userMaybe?.userData?.data?.middleName,
    userMaybe?.userData?.data?.lastName,
  ].filter(Boolean).map((n) => n.trim())

  const fullName = names.length ? names.join(' ') : ''
  return fullName
})

export const getRoleDetails = createSelector([getUser], (user) => {
  const rolesMaybe = user?.userData?.data?.userRoles
  const roleNames = Array.isArray(rolesMaybe)
    ? rolesMaybe.map((role) => role.roleName).filter(Boolean).map((n) => n.trim()).join(',')
    : ''
  return roleNames
})


export const getUserId = createSelector([getUser], (user) => {
  return _.get(user, 'id', null)
})


export const getUserPrivileges = createSelector([getUser], (user) => {
  return _.get(user, 'privileges', [])
})

export const getPrivacyPolicy = createSelector([getUser], (user) => {
  return _.get(user, 'privacyPolicy', {})
})

export const getUserType = createSelector([getUser], (user) => {
  return _.get(user, 'role', null)
})
