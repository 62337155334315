import { FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'
import React, { Component } from 'react'
import { Platform, View } from 'react-native'
import { ProgressCircle } from 'react-native-svg-charts'

class Activity extends Component {
  state = {}

  roundValueTo2Decimals = (value) => Math.round(value * 100) / 100

  renderIcon = () => {
    const { iconName, size, iconColor } = this.props
    if (iconName == 'walking') {
      return <FontAwesome5 name={iconName} size={size} color={iconColor} />
    } else if (iconName == 'shoe-prints') {
      return (
        <FontAwesome5
          name={iconName}
          size={size}
          color={iconColor}
          style={{ transform: [{ rotateZ: '270deg' }] }}
        />
      )
    } else if (iconName == 'bed') {
      return <FontAwesome name={iconName} color={iconColor} size={size} />
    } else {
      return <MaterialCommunityIcons name={iconName} color={iconColor} size={size} />
    }
  }
  render() {
    const {
      progress,
      progressColor,
      iconName,
      iconColor,
      size,
      value,
      units,
      isTextPresent,
      backgroundColor,
      progressBackgroundColor,
    } = this.props
    if (Platform.OS !== 'web') {
      return (
        <ProgressCircle
          strokeWidth={10}
          style={{ height: 100, width: 80 }}
          progress={Number(progress)}
          progressColor={progressColor}
          backgroundColor={progressBackgroundColor}
        >
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {this.renderIcon()}
          </View>
        </ProgressCircle>
      )
    }
    return (
      <View style={{ height: 100, width: 80 }}>
        <ProgressCircle
          strokeWidth={10}
          style={{ height: 100, width: 80 }}
          progress={Number(progress)}
          progressColor={progressColor}
          backgroundColor={progressBackgroundColor}
        ></ProgressCircle>
        <View style={{ position: 'absolute', alignSelf: 'center', top: 35 }}>
          {this.renderIcon()}
        </View>
      </View>
    )
  }
}

export default Activity
