export default {
    "Africa/Abidjan": "Afrika/Abidjan",
    "Africa/Accra": "Afrika/Akra",
    "Africa/Addis_Ababa": "Afrika/Addis_Ababa",
    "Africa/Algiers": "Afrika/Algjeri",
    "Africa/Asmara": "Afrika/Asmara",
    "Africa/Asmera": "Afrika/Asmera",
    "Africa/Bamako": "Afrika/Bamako",
    "Africa/Bangui": "Afrika/Bangui",
    "Africa/Banjul": "Afrika/Banjul",
    "Africa/Bissau": "Afrika/Bisau",
    "Africa/Blantyre": "Afrika/Blantyre",
    "Africa/Brazzaville": "Afrika/Brazzaville",
    "Africa/Bujumbura": "Afrika/Bujumbura",
    "Africa/Cairo": "Afrika/Kajro",
    "Africa/Casablanca": "Afrika/Casablanca",
    "Africa/Ceuta": "Afrika/Ceuta",
    "Africa/Conakry": "Afrika/Konakri",
    "Africa/Dakar": "Afrika/Dakar",
    "Africa/Dar_es_Salaam": "Afrika/Dar_es_Salaam",
    "Africa/Djibouti": "Afrika/Xhibuti",
    "Africa/Douala": "Afrika/Douala",
    "Africa/El_Aaiun": "Afrika/El_Aaiun",
    "Africa/Freetown": "Afrika/Freetown",
    "Africa/Gaborone": "Afrika/Gaborone",
    "Africa/Harare": "Afrika/Harare",
    "Africa/Johannesburg": "Afrika/Johanesburg",
    "Africa/Juba": "Afrika/Xhuba",
    "Africa/Kampala": "Afrika/Kampala",
    "Africa/Khartoum": "Afrika/Khartoum",
    "Africa/Kigali": "Afrika/Kigali",
    "Africa/Kinshasa": "Afrika/Kinshasa",
    "Africa/Lagos": "Afrika/Lagos",
    "Africa/Libreville": "Afrika/Libreville",
    "Africa/Lome": "Afrika/Lome",
    "Africa/Luanda": "Afrika/Luanda",
    "Africa/Lubumbashi": "Afrika/Lubumbashi",
    "Africa/Lusaka": "Afrika/Lusaka",
    "Africa/Malabo": "Afrika/Malabo",
    "Africa/Maputo": "Afrika/Maputo",
    "Africa/Maseru": "Afrika/Maseru",
    "Africa/Mbabane": "Afrika/Mbabane",
    "Africa/Mogadishu": "Afrika/Mogadishu",
    "Africa/Monrovia": "Afrika/Monrovia",
    "Africa/Nairobi": "Afrika/Nairobi",
    "Africa/Ndjamena": "Afrika/Ndjamena",
    "Africa/Niamey": "Afrika/Niamey",
    "Africa/Nouakchott": "Afrika/Nouakchott",
    "Africa/Ouagadougou": "Afrika/Ouagadougou",
    "Africa/Porto-Novo": "Afrika/Porto-Novo",
    "Africa/Sao_Tome": "Afrika/Sao_Tome",
    "Africa/Timbuktu": "Afrika/Timbuktu",
    "Africa/Tripoli": "Afrika/Tripoli",
    "Africa/Tunis": "Afrika/Tunizi",
    "Africa/Windhoek": "Afrika/Windhoek",
    "America/Adak": "Amerika/Adak",
    "America/Anchorage": "Amerikë/Anchorage",
    "America/Anguilla": "Amerikë/Anguilla",
    "America/Antigua": "Amerika/Antigua",
    "America/Araguaina": "Amerika/Araguaina",
    "America/Argentina/Buenos_Aires": "Amerika/Argjentina/Buenos_Aires",
    "America/Argentina/Catamarca": "Amerika/Argjentina/Katamarka",
    "America/Argentina/ComodRivadavia": "Amerika/Argjentina/Comodrivadavia",
    "America/Argentina/Cordoba": "Amerika/Argjentina/Cordoba",
    "America/Argentina/Jujuy": "Amerika/Argjentina/Jujuy",
    "America/Argentina/La_Rioja": "Amerika/Argjentina/La_Rioja",
    "America/Argentina/Mendoza": "Amerika/Argjentina/Mendoza",
    "America/Argentina/Rio_Gallegos": "Amerika/Argjentina/Rio_Gallegos",
    "America/Argentina/Salta": "Amerika/Argjentina/Salta",
    "America/Argentina/San_Juan": "Amerika/Argjentina/San_Juan",
    "America/Argentina/San_Luis": "Amerika/Argjentina/San_Luis",
    "America/Argentina/Tucuman": "Amerika/Argjentina/Tucuman",
    "America/Argentina/Ushuaia": "Amerika/Argjentina/Ushuaia",
    "America/Aruba": "Amerikë/Aruba",
    "America/Asuncion": "Amerika/Asuncion",
    "America/Atikokan": "Amerika/Atikokan",
    "America/Atka": "Amerika/Atka",
    "America/Bahia": "Amerika/Bahia",
    "America/Bahia_Banderas": "Amerika/Bahia_Banderas",
    "America/Barbados": "Amerika/Barbados",
    "America/Belem": "Amerika/Belem",
    "America/Belize": "Amerika/Belize",
    "America/Blanc-Sablon": "Amerika/Blanc-Sablon",
    "America/Boa_Vista": "Amerikë/Boa_Vista",
    "America/Bogota": "Amerika/Bogota",
    "America/Boise": "Amerikë/Boise",
    "America/Buenos_Aires": "Amerika/Buenos_Aires",
    "America/Cambridge_Bay": "Amerikë/Cambridge_Bay",
    "America/Campo_Grande": "Amerikë/Campo_Grande",
    "America/Cancun": "Amerikë/Cancun",
    "America/Caracas": "Amerikë/Karakas",
    "America/Catamarca": "Amerika/Katamarka",
    "America/Cayenne": "Amerikë/Cayenne",
    "America/Cayman": "Amerikë/Kajman",
    "America/Chicago": "Amerikë/Çikago",
    "America/Chihuahua": "Amerikë/Chihuahua",
    "America/Ciudad_Juarez": "Amerika/Ciudad_Juarez",
    "America/Coral_Harbour": "Amerika/Coral_Harbour",
    "America/Cordoba": "Amerika/Kordoba",
    "America/Costa_Rica": "Amerikë/Kosta_Rika",
    "America/Creston": "Amerikë/Creston",
    "America/Cuiaba": "Amerika/Cuiaba",
    "America/Curacao": "Amerika/Curacao",
    "America/Danmarkshavn": "Amerika/Danimarkshavn",
    "America/Dawson": "Amerika/Dawson",
    "America/Dawson_Creek": "Amerikë/Dawson_Creek",
    "America/Denver": "Amerika/Denver",
    "America/Detroit": "Amerika/Detroit",
    "America/Dominica": "Amerikë/Dominikë",
    "America/Edmonton": "Amerika/Edmonton",
    "America/Eirunepe": "Amerika/Eirunepe",
    "America/El_Salvador": "Amerika/El_Salvador",
    "America/Ensenada": "Amerika/Ensenada",
    "America/Fort_Nelson": "Amerikë/Fort_Nelson",
    "America/Fort_Wayne": "Amerikë/Fort_Wayne",
    "America/Fortaleza": "Amerika/Fortaleza",
    "America/Glace_Bay": "Amerikë/Glace_Bay",
    "America/Godthab": "Amerikë/Godthab",
    "America/Goose_Bay": "Amerikë/Goose_Bay",
    "America/Grand_Turk": "Amerikë/Grand_Turk",
    "America/Grenada": "Amerikë/Grenada",
    "America/Guadeloupe": "Amerika/Guadeloupe",
    "America/Guatemala": "Amerikë/Guatemala",
    "America/Guayaquil": "Amerikë/Guayaquil",
    "America/Guyana": "Amerikë/Guajana",
    "America/Halifax": "Amerikë/Halifax",
    "America/Havana": "Amerikë/Havana",
    "America/Hermosillo": "Amerika/Hermosillo",
    "America/Indiana/Indianapolis": "Amerika/Indiana/Indianapolis",
    "America/Indiana/Knox": "Amerikë/Indiana/Knox",
    "America/Indiana/Marengo": "Amerika/Indiana/Marengo",
    "America/Indiana/Petersburg": "Amerika/Indiana/Petersburg",
    "America/Indiana/Tell_City": "Amerikë/Indiana/Tell_City",
    "America/Indiana/Vevay": "Amerika/Indiana/Vevay",
    "America/Indiana/Vincennes": "Amerika/Indiana/Vincennes",
    "America/Indiana/Winamac": "Amerika/Indiana/Winamac",
    "America/Indianapolis": "Amerikë/Indianapolis",
    "America/Inuvik": "Amerikë/Inuvik",
    "America/Iqaluit": "Amerika/Iqaluit",
    "America/Jamaica": "Amerikë/Xhamajka",
    "America/Jujuy": "Amerika/Jujuy",
    "America/Juneau": "Amerikë/Juneau",
    "America/Kentucky/Louisville": "Amerika/Kentucky/Louisville",
    "America/Kentucky/Monticello": "Amerika/Kentucky/Monticello",
    "America/Knox_IN": "Amerika/Knox_in",
    "America/Kralendijk": "Amerika/Kralendijk",
    "America/La_Paz": "Amerikë/La_Paz",
    "America/Lima": "Amerikë/Lima",
    "America/Los_Angeles": "Amerikë/Los_Anxhelos",
    "America/Louisville": "Amerika/Louisville",
    "America/Lower_Princes": "Amerikë/Princat e Ulët",
    "America/Maceio": "Amerikë/Maceio",
    "America/Managua": "Amerikë/Managua",
    "America/Manaus": "Amerika/Manaus",
    "America/Marigot": "Amerikë/Marigot",
    "America/Martinique": "Amerika/Martinique",
    "America/Matamoros": "Amerika/Matamoros",
    "America/Mazatlan": "Amerika/Mazatlan",
    "America/Mendoza": "Amerika/Mendoza",
    "America/Menominee": "Amerika/Menominee",
    "America/Merida": "Amerikë/Merida",
    "America/Metlakatla": "Amerika/Metlakatla",
    "America/Mexico_City": "Amerika/Qyteti i Meksikës",
    "America/Miquelon": "Amerika/Miquelon",
    "America/Moncton": "Amerikë/Moncton",
    "America/Monterrey": "Amerika/Monterrey",
    "America/Montevideo": "Amerikë/Montevideo",
    "America/Montreal": "Amerika/Montreal",
    "America/Montserrat": "Amerika/Montserrat",
    "America/Nassau": "Amerikë/Nassau",
    "America/New_York": "Amerikë/Nju Jork",
    "America/Nipigon": "Amerikë/Nipigon",
    "America/Nome": "Amerika/Emri",
    "America/Noronha": "Amerika/Noronha",
    "America/North_Dakota/Beulah": "Amerika/Dakota Veri/Beulah",
    "America/North_Dakota/Center": "Amerika/Dakota Veri/Qendra",
    "America/North_Dakota/New_Salem": "Amerika/Dakota Veri/New_Salem",
    "America/Nuuk": "Amerika/New",
    "America/Ojinaga": "Amerika/Ojinaga",
    "America/Panama": "Amerikë/Panama",
    "America/Pangnirtung": "Amerika/Pangnirtung",
    "America/Paramaribo": "Amerika/Paramaribo",
    "America/Phoenix": "Amerika/Phoenix",
    "America/Port-au-Prince": "Amerika/Port-au-Prince",
    "America/Port_of_Spain": "Amerika/Porti i Spanjës",
    "America/Porto_Acre": "Amerikë/Porto_Acre",
    "America/Porto_Velho": "Amerika/Porto_Velho",
    "America/Puerto_Rico": "Amerika/Puerto_Rico",
    "America/Punta_Arenas": "Amerikë/Punta_Arenas",
    "America/Rainy_River": "Amerika/Rainy_River",
    "America/Rankin_Inlet": "Amerikë/Rankin_Inlet",
    "America/Recife": "Amerikë/Recife",
    "America/Regina": "Amerika/Mbretërinë",
    "America/Resolute": "Amerikë/Resolute",
    "America/Rio_Branco": "Amerika/Rio_Branco",
    "America/Rosario": "Amerika/Rosario",
    "America/Santa_Isabel": "Amerika/Santa_Isabel",
    "America/Santarem": "Amerika/Santarem",
    "America/Santiago": "Amerikë/Santiago",
    "America/Santo_Domingo": "Amerika/Santo_Domingo",
    "America/Sao_Paulo": "Amerika/Sao_Paulo",
    "America/Scoresbysund": "Amerika/Scoresbysund",
    "America/Shiprock": "Amerikë/Shiprock",
    "America/Sitka": "Amerika/Sitka",
    "America/St_Barthelemy": "Amerika/St_Barthelemy",
    "America/St_Johns": "Amerika/St_Johns",
    "America/St_Kitts": "Amerika/St_Kitts",
    "America/St_Lucia": "Amerika/St_Lucia",
    "America/St_Thomas": "Amerika/St_Thomas",
    "America/St_Vincent": "Amerika/St_Vincent",
    "America/Swift_Current": "Amerikë/Swift_Current",
    "America/Tegucigalpa": "Amerika/Tegucigalpa",
    "America/Thule": "Amerika/Thule",
    "America/Thunder_Bay": "Amerikë/Thunder_Bay",
    "America/Tijuana": "Amerika/Tijuana",
    "America/Toronto": "Amerika/Toronto",
    "America/Tortola": "Amerika/Tortola",
    "America/Vancouver": "Amerika/Vancouver",
    "America/Virgin": "Amerikë/Virgjër",
    "America/Whitehorse": "Amerikë/Whitehorse",
    "America/Winnipeg": "Amerikë/Winnipeg",
    "America/Yakutat": "Amerika/Yakutat",
    "America/Yellowknife": "Amerika/Yellowknife",
    "Antarctica/Casey": "Antarktika/Casey",
    "Antarctica/Davis": "Antarktika/Davis",
    "Antarctica/DumontDUrville": "Antarktika/Dumontdurville",
    "Antarctica/Macquarie": "Antarktika/Macquarie",
    "Antarctica/Mawson": "Antarktika/Mawson",
    "Antarctica/McMurdo": "Antarktika/McMurdo",
    "Antarctica/Palmer": "Antarktika/Palmer",
    "Antarctica/Rothera": "Antarktika/Rothera",
    "Antarctica/South_Pole": "Antarktika/Poli Jugor",
    "Antarctica/Syowa": "Antarktika/Syowa",
    "Antarctica/Troll": "Antarktika/Troll",
    "Antarctica/Vostok": "Antarktika/Vostok",
    "Arctic/Longyearbyen": "Arktik/Longyearbyen",
    "Asia/Aden": "Azi/Aden",
    "Asia/Almaty": "Azi/Almaty",
    "Asia/Amman": "Azi/Amman",
    "Asia/Anadyr": "Azi/Anadyr",
    "Asia/Aqtau": "Azi/Aqtau",
    "Asia/Aqtobe": "Azi/Aqtobe",
    "Asia/Ashgabat": "Azi/Ashgabat",
    "Asia/Ashkhabad": "Azi/Ashkhabad",
    "Asia/Atyrau": "Azi/Atyrau",
    "Asia/Baghdad": "Azi/Bagdad",
    "Asia/Bahrain": "Azi/Bahreini",
    "Asia/Baku": "Azi/Baku",
    "Asia/Bangkok": "Azi/Bangkok",
    "Asia/Barnaul": "Azi/Barnaul",
    "Asia/Beirut": "Azi/Bejrut",
    "Asia/Bishkek": "Azi/Bishkek",
    "Asia/Brunei": "Azi/Brunei",
    "Asia/Calcutta": "Azi/Kalkuta",
    "Asia/Chita": "Azi/Chita",
    "Asia/Choibalsan": "Azi/Choibalsan",
    "Asia/Chongqing": "Azi/Chongqing",
    "Asia/Chungking": "Azi/Chungking",
    "Asia/Colombo": "Azi/Kolombo",
    "Asia/Dacca": "Azi/Dacca",
    "Asia/Damascus": "Azi/Damask",
    "Asia/Dhaka": "Azi/Dhaka",
    "Asia/Dili": "Asia/Dili",
    "Asia/Dubai": "Azi/Dubai",
    "Asia/Dushanbe": "Azi/Dushanbe",
    "Asia/Famagusta": "Azi/Famagusta",
    "Asia/Gaza": "Azi/Gaza",
    "Asia/Harbin": "Azi/Harbin",
    "Asia/Hebron": "Azi/Hebron",
    "Asia/Ho_Chi_Minh": "Azi/Ho_Chi_Minh",
    "Asia/Hong_Kong": "Azi/Hong_Kong",
    "Asia/Hovd": "Azi/Hovd",
    "Asia/Irkutsk": "Azi/Irkutsk",
    "Asia/Istanbul": "Azi/Stamboll",
    "Asia/Jakarta": "Azi/Xhakarta",
    "Asia/Jayapura": "Azi/Jayapura",
    "Asia/Jerusalem": "Azi/Jeruzalem",
    "Asia/Kabul": "Azi/Kabul",
    "Asia/Kamchatka": "Azi/Kamchatka",
    "Asia/Karachi": "Azi/Karachi",
    "Asia/Kashgar": "Azi/Kashgar",
    "Asia/Kathmandu": "Azi/Katmandu",
    "Asia/Katmandu": "Azi/Katmandu",
    "Asia/Khandyga": "Azi/Khandyga",
    "Asia/Kolkata": "Azi/Kolkata",
    "Asia/Krasnoyarsk": "Azi/Krasnoyarsk",
    "Asia/Kuala_Lumpur": "Azi/Kuala_Lumpur",
    "Asia/Kuching": "Azi/Kuching",
    "Asia/Kuwait": "Azi/Kuvajt",
    "Asia/Macao": "Azi/Makao",
    "Asia/Macau": "Azi/Macau",
    "Asia/Magadan": "Azi/Magadan",
    "Asia/Makassar": "Azi/Makassar",
    "Asia/Manila": "Azi/Manila",
    "Asia/Muscat": "Azi/Muscat",
    "Asia/Nicosia": "Azi/Nikosia",
    "Asia/Novokuznetsk": "Azi/Novokuznetsk",
    "Asia/Novosibirsk": "Azi/Novosibirsk",
    "Asia/Omsk": "Azi/Omsk",
    "Asia/Oral": "Azi/Oral",
    "Asia/Phnom_Penh": "Azi/Phnom_Penh",
    "Asia/Pontianak": "Azi/Pontianak",
    "Asia/Pyongyang": "Azi/Phenian",
    "Asia/Qatar": "Azi/Katar",
    "Asia/Qostanay": "Azi/Qostanay",
    "Asia/Qyzylorda": "Azi/Qyzylorda",
    "Asia/Rangoon": "Azi/Rangoon",
    "Asia/Riyadh": "Azi/Riad",
    "Asia/Saigon": "Azi/Saigon",
    "Asia/Sakhalin": "Azi/Sakhalin",
    "Asia/Samarkand": "Azi/Samarkand",
    "Asia/Seoul": "Azi/Seul",
    "Asia/Shanghai": "Azi/Shangai",
    "Asia/Singapore": "Azi/Singapori",
    "Asia/Srednekolymsk": "Azi/Srednekolymsk",
    "Asia/Taipei": "Azi/Taipei",
    "Asia/Tashkent": "Azi/Tashkent",
    "Asia/Tbilisi": "Azi/Tbilisi",
    "Asia/Tehran": "Azi/Teheran",
    "Asia/Tel_Aviv": "Azi/Tel_Aviv",
    "Asia/Thimbu": "Azi/Thimbu",
    "Asia/Thimphu": "Azi/Thimphu",
    "Asia/Tokyo": "Azi/Tokio",
    "Asia/Tomsk": "Azi/Tomsk",
    "Asia/Ujung_Pandang": "Azi/Ujung_Pandang",
    "Asia/Ulaanbaatar": "Azi/Ulaanbaatar",
    "Asia/Ulan_Bator": "Azi/Ulan_Bator",
    "Asia/Urumqi": "Azi/Urumçi",
    "Asia/Ust-Nera": "Azi/Ust-Nera",
    "Asia/Vientiane": "Azi/Vientiane",
    "Asia/Vladivostok": "Azi/Vladivostok",
    "Asia/Yakutsk": "Azi/Yakutsk",
    "Asia/Yangon": "Azi/Yangon",
    "Asia/Yekaterinburg": "Azi/Jekaterinburg",
    "Asia/Yerevan": "Azi/Jerevan",
    "Atlantic/Azores": "Atlantik/Azoret",
    "Atlantic/Bermuda": "Atlantik/Bermuda",
    "Atlantic/Canary": "Atlantik/Kanari",
    "Atlantic/Cape_Verde": "Atlantik/Cape_Verde",
    "Atlantic/Faeroe": "Atlantik/Faeroe",
    "Atlantic/Faroe": "Atlantik/Faroe",
    "Atlantic/Jan_Mayen": "Atlantik/Jan_Mayen",
    "Atlantic/Madeira": "Atlantik/Madeira",
    "Atlantic/Reykjavik": "Atlantik/Reykjavik",
    "Atlantic/South_Georgia": "Atlantik/Gjeorgjia e Jugut",
    "Atlantic/St_Helena": "Atlantik/St_Helena",
    "Atlantic/Stanley": "Atlantik/Stanley",
    "Australia/ACT": "Australi/ACT",
    "Australia/Adelaide": "Australi/Adelaide",
    "Australia/Brisbane": "Australi/Brisbane",
    "Australia/Broken_Hill": "Australi/Broken_Hill",
    "Australia/Canberra": "Australi/Canberra",
    "Australia/Currie": "Australi/Currie",
    "Australia/Darwin": "Australi/Darvin",
    "Australia/Eucla": "Australi/Eucla",
    "Australia/Hobart": "Australi/Hobart",
    "Australia/LHI": "Australi/LHI",
    "Australia/Lindeman": "Australi/Lindeman",
    "Australia/Lord_Howe": "Australi/Lord_Howe",
    "Australia/Melbourne": "Australi/Melburn",
    "Australia/NSW": "Australi/NSW",
    "Australia/North": "Australi/Veri",
    "Australia/Perth": "Australi/Perth",
    "Australia/Queensland": "Australi/Queensland",
    "Australia/South": "Australi/Jug",
    "Australia/Sydney": "Australi/Sidney",
    "Australia/Tasmania": "Australi/Tasmania",
    "Australia/Victoria": "Australi/Victoria",
    "Australia/West": "Australi/Perëndim",
    "Australia/Yancowinna": "Australi/Yancowinna",
    "Brazil/Acre": "Brazil/Acre",
    "Brazil/DeNoronha": "Brazil/Denoronha",
    "Brazil/East": "Brazil/Lindje",
    "Brazil/West": "Brazil/Perëndim",
    "CET": "KJO",
    "CST6CDT": "CST6CDT",
    "Canada/Atlantic": "Kanada/Atlantik",
    "Canada/Central": "Kanada/Qendrore",
    "Canada/Eastern": "Kanada/Lindore",
    "Canada/Mountain": "Kanada/Mali",
    "Canada/Newfoundland": "Kanada/Newfoundland",
    "Canada/Pacific": "Kanada/Paqësor",
    "Canada/Saskatchewan": "Kanada/Saskatchewan",
    "Canada/Yukon": "Kanada/Yukon",
    "Chile/Continental": "Çili/Kontinentale",
    "Chile/EasterIsland": "Kili/Ishulli Lindor",
    "Cuba": "Kuba",
    "EET": "HA",
    "EST": "ËSHTË",
    "EST5EDT": "ËSHTË E VOGËL",
    "Egypt": "Egjipt",
    "Eire": "E vetmja",
    "Etc/GMT": "Etj/GMT",
    "Etc/GMT+0": "etj/GMT+0",
    "Etc/GMT+1": "etj/GMT+1",
    "Etc/GMT+10": "etj/GMT+10",
    "Etc/GMT+11": "etj/GMT+11",
    "Etc/GMT+12": "etj/GMT+12",
    "Etc/GMT+2": "etj/GMT+2",
    "Etc/GMT+3": "etj/GMT+3",
    "Etc/GMT+4": "ETJ/GMT+4",
    "Etc/GMT+5": "etj/GMT+5",
    "Etc/GMT+6": "etj/GMT+6",
    "Etc/GMT+7": "etj/GMT+7",
    "Etc/GMT+8": "ETJ/GMT+8",
    "Etc/GMT+9": "etj/GMT+9",
    "Etc/GMT-0": "etj/GMT-0",
    "Etc/GMT-1": "etj/GMT-1",
    "Etc/GMT-10": "ETJ/GMT-10",
    "Etc/GMT-11": "ETJ/GMT-11",
    "Etc/GMT-12": "etj/GMT-12",
    "Etc/GMT-13": "etj/GMT-13",
    "Etc/GMT-14": "etj/GMT-14",
    "Etc/GMT-2": "Etj/GMT-2",
    "Etc/GMT-3": "etj/GMT-3",
    "Etc/GMT-4": "Etc/GMT-4",
    "Etc/GMT-5": "etc/GMT-5",
    "Etc/GMT-6": "ETJ/GMT-6",
    "Etc/GMT-7": "etj/GMT-7",
    "Etc/GMT-8": "ETJ/GMT-8",
    "Etc/GMT-9": "etj/GMT-9",
    "Etc/GMT0": "Etj/GMT0",
    "Etc/Greenwich": "Etc/Greenwich",
    "Etc/UCT": "Etj/UCT",
    "Etc/UTC": "etj/UTC",
    "Etc/Universal": "Etc/Universale",
    "Etc/Zulu": "Etc/Zulu",
    "Europe/Amsterdam": "Evropë/Amsterdam",
    "Europe/Andorra": "Evropë/Andorra",
    "Europe/Astrakhan": "Evropë/Astrakhan",
    "Europe/Athens": "Evropë/Athinë",
    "Europe/Belfast": "Evropë/Belfast",
    "Europe/Belgrade": "Evropë/Beograd",
    "Europe/Berlin": "Evropë/Berlin",
    "Europe/Bratislava": "Evropë/Bratislavë",
    "Europe/Brussels": "Evropë/Bruksel",
    "Europe/Bucharest": "Evropë/Bukuresht",
    "Europe/Budapest": "Evropë/Budapest",
    "Europe/Busingen": "Evropë/Busingen",
    "Europe/Chisinau": "Evropë/Kishinau",
    "Europe/Copenhagen": "Evropë/Kopenhagë",
    "Europe/Dublin": "Evropë/Dublin",
    "Europe/Gibraltar": "Evropë/Gjibraltari",
    "Europe/Guernsey": "Evropë/Guernsey",
    "Europe/Helsinki": "Evropë/Helsinki",
    "Europe/Isle_of_Man": "Evropa/Ishulli i Njeriut",
    "Europe/Istanbul": "Evropë/Stamboll",
    "Europe/Jersey": "Evropë/Jersey",
    "Europe/Kaliningrad": "Evropë/Kaliningrad",
    "Europe/Kiev": "Evropë/Kiev",
    "Europe/Kirov": "Evropë/Kirov",
    "Europe/Kyiv": "Evropë/Kiev",
    "Europe/Lisbon": "Evropë/Lisbonë",
    "Europe/Ljubljana": "Evropë/Ljubljana",
    "Europe/London": "Evropë/Londër",
    "Europe/Luxembourg": "Evropë/Luksemburg",
    "Europe/Madrid": "Evropë/Madrid",
    "Europe/Malta": "Evropë/Maltë",
    "Europe/Mariehamn": "Evropë/Mariehamn",
    "Europe/Minsk": "Evropë/Minsk",
    "Europe/Monaco": "Evropë/Monako",
    "Europe/Moscow": "Evropë/Moskë",
    "Europe/Nicosia": "Evropë/Nikosia",
    "Europe/Oslo": "Evropë/Oslo",
    "Europe/Paris": "Evropë/Paris",
    "Europe/Podgorica": "Evropë/Podgoricë",
    "Europe/Prague": "Evropë/Pragë",
    "Europe/Riga": "Evropë/Riga",
    "Europe/Rome": "Evropë/Romë",
    "Europe/Samara": "Evropë/Samara",
    "Europe/San_Marino": "Evropë/San_Marino",
    "Europe/Sarajevo": "Evropë/Sarajevë",
    "Europe/Saratov": "Evropë/Saratov",
    "Europe/Simferopol": "Evropë/Simferopol",
    "Europe/Skopje": "Evropë/Shkup",
    "Europe/Sofia": "Evropë/Sofje",
    "Europe/Stockholm": "Evropë/Stokholm",
    "Europe/Tallinn": "Evropë/Talin",
    "Europe/Tirane": "Evropë/Tiranë",
    "Europe/Tiraspol": "Evropë/Tiraspol",
    "Europe/Ulyanovsk": "Evropë/Ulyanovsk",
    "Europe/Uzhgorod": "Evropë/Uzhgorod",
    "Europe/Vaduz": "Evropë/Vaduz",
    "Europe/Vatican": "Evropë/Vatikani",
    "Europe/Vienna": "Evropë/Vjenë",
    "Europe/Vilnius": "Evropë/Vilnius",
    "Europe/Volgograd": "Evropë/Volgograd",
    "Europe/Warsaw": "Evropë/Varshavë",
    "Europe/Zagreb": "Evropë/Zagreb",
    "Europe/Zaporozhye": "Evropë/Zaporozhye",
    "Europe/Zurich": "Evropë/Cyrih",
    "GB": "GB",
    "GB-Eire": "GB-Eire",
    "GMT": "GMT",
    "GMT+0": "GMT+0",
    "GMT-0": "GMT-0",
    "GMT0": "GMT0",
    "Greenwich": "Grinviç",
    "HST": "HST",
    "Hongkong": "Hong Kong",
    "Iceland": "Islandë",
    "Indian/Antananarivo": "Indiane/Antananarivo",
    "Indian/Chagos": "Indiane/Chagos",
    "Indian/Christmas": "Indiane/Krishtlindje",
    "Indian/Cocos": "Indiane/Kokos",
    "Indian/Comoro": "Indiane/Komoro",
    "Indian/Kerguelen": "Indiane/Kerguelen",
    "Indian/Mahe": "Indiane/Mahe",
    "Indian/Maldives": "Indiane/Maldive",
    "Indian/Mauritius": "Indiane/Mauritius",
    "Indian/Mayotte": "Indiane/Mayotte",
    "Indian/Reunion": "Indiane/Ribashkim",
    "Iran": "Iran",
    "Israel": "Izraeli",
    "Jamaica": "Xhamajka",
    "Japan": "Japoni",
    "Kwajalein": "Kvajalein",
    "Libya": "Libi",
    "MET": "TAKUAR",
    "MST": "MST",
    "MST7MDT": "MST7MDT",
    "Mexico/BajaNorte": "Meksika/Bajanorte",
    "Mexico/BajaSur": "Meksika/Bajasur",
    "Mexico/General": "Meksikë e përgjithshme",
    "NZ": "NZ",
    "NZ-CHAT": "NZ-CHAT",
    "Navajo": "Navajo",
    "PRC": "PRC",
    "PST8PDT": "PST8PDT",
    "Pacific/Apia": "Paqësor/Apia",
    "Pacific/Auckland": "Paqësor/Auckland",
    "Pacific/Bougainville": "Paqësor/Bougainville",
    "Pacific/Chatham": "Paqësor/Chatham",
    "Pacific/Chuuk": "Paqësor/Chuuk",
    "Pacific/Easter": "Paqësor/Pashkët",
    "Pacific/Efate": "Paqësor/Efate",
    "Pacific/Enderbury": "Paqësor/Enderbury",
    "Pacific/Fakaofo": "Paqësor/Fakaofo",
    "Pacific/Fiji": "Paqësor/Fixhi",
    "Pacific/Funafuti": "Paqësor/Funafuti",
    "Pacific/Galapagos": "Paqësor/Galapagos",
    "Pacific/Gambier": "Paqësor/Gambier",
    "Pacific/Guadalcanal": "Paqësor/Guadalcanal",
    "Pacific/Guam": "Paqësor/Guam",
    "Pacific/Honolulu": "Paqësor/Honolulu",
    "Pacific/Johnston": "Paqësor/Johnston",
    "Pacific/Kanton": "Paqësor/Kanton",
    "Pacific/Kiritimati": "Paqësor/Kiritimati",
    "Pacific/Kosrae": "Paqësor/Kosrae",
    "Pacific/Kwajalein": "Paqësor/Kwajalein",
    "Pacific/Majuro": "Paqësor/Majuro",
    "Pacific/Marquesas": "Paqësor/Marquesas",
    "Pacific/Midway": "Paqësor/Midway",
    "Pacific/Nauru": "Paqësor/Nauru",
    "Pacific/Niue": "Paqësor/Niue",
    "Pacific/Norfolk": "Paqësor/Norfolk",
    "Pacific/Noumea": "Paqësor/Noumea",
    "Pacific/Pago_Pago": "Paqësor/Pago_Pago",
    "Pacific/Palau": "Paqësor/Palau",
    "Pacific/Pitcairn": "Paqësor/Pitcairn",
    "Pacific/Pohnpei": "Paqësor/Pohnpei",
    "Pacific/Ponape": "Paqësor/Ponape",
    "Pacific/Port_Moresby": "Paqësor/Port_Moresby",
    "Pacific/Rarotonga": "Paqësor/Rarotonga",
    "Pacific/Saipan": "Paqësor/Saipan",
    "Pacific/Samoa": "Paqësor/Samoa",
    "Pacific/Tahiti": "Paqësor/Tahiti",
    "Pacific/Tarawa": "Paqësor/Tarawa",
    "Pacific/Tongatapu": "Paqësor/Tongatapu",
    "Pacific/Truk": "Paqësor/Truk",
    "Pacific/Wake": "Paqësor/Zgjohu",
    "Pacific/Wallis": "Paqësor/Wallis",
    "Pacific/Yap": "Paqësor/Yap",
    "Poland": "Polonia",
    "Portugal": "Portugali",
    "ROC": "ROOK",
    "ROK": "YEAR",
    "Singapore": "Singapori",
    "Turkey": "Turqia",
    "UCT": "UCT",
    "US/Alaska": "SHBA/Alaska",
    "US/Aleutian": "SHBA/Aleutiane",
    "US/Arizona": "SHBA/Arizona",
    "US/Central": "SHBA/Qendrore",
    "US/East-Indiana": "SHBA/Lindore Indiana",
    "US/Eastern": "SHBA/Lindore",
    "US/Hawaii": "SHBA/Hawaii",
    "US/Indiana-Starke": "SHBA/Indiana-Forke",
    "US/Michigan": "SHBA/Michigan",
    "US/Mountain": "SHBA/mali",
    "US/Pacific": "SHBA/Paqësor",
    "US/Samoa": "SHBA/Samoa",
    "UTC": "UTC",
    "Universal": "Universale",
    "W-SU": "W S",
    "WET": "I LAGUR",
    "Zulu": "Zulu",    
}