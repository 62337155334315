import React, { Component } from 'react'
import { connect } from 'react-redux'
import { View, Text, Image } from 'react-native'
import styles from '../components/styles/trainingStyles'
import sessionTimeoutLogo from '../constants/sessionTimeoutLogo'
import storage from '../utils/storage/storage'
import NavigationService from './navigationService'
import { getUserId } from '../selectors/user'
import PropTypes from 'prop-types'
class SessionTimeoutScreen extends Component {
  componentDidMount() {}

  redirectToLogin = () => {
    const {userId} = this.props
    storage.delete(userId+'SubjectAuthorizationToken')
    NavigationService.navigate('Login', {fromLoginWithPin : true})
  }

  render() {
    const {
      screenProps: { t },
    } = this.props
    return (
      <View style={styles.sessionTimeoutContainer}>
        <Image
          source={{
            uri: `data:image/png;base64,${sessionTimeoutLogo}`,
          }}
          resizeMode="contain"
          style={[styles.image]}
        />
        <Text style={[styles.sessionLoginText, styles.sessionTextColor]}>
          {t('SessionTimeoutMessage')}
        </Text>
        <Text style={[styles.sessionLoginText, styles.sessionTextColor]}>
          <Text style={{ color: '#9155FD' }} onPress={this.redirectToLogin}>
            {t('Click')}
          </Text>{' '}
          <Text>{t('LoginText')}</Text>
        </Text>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  userId: getUserId(state)
})


SessionTimeoutScreen.propTypes ={
  userId: PropTypes.string,
  screenProps: PropTypes.shape({
    t: PropTypes.func,
  })
}
export default connect(mapStateToProps)(SessionTimeoutScreen)
