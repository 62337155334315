import DeviceInfo from 'react-native-device-info'

export const Tablet = ({ children }) => {
  let isTablet = true
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  return null
}
export const Default = ({ children }) => {
  let isTablet = true
  return isTablet ? children : null
}

export const isMobile = false
export const isTablet = true
