import { createSelector } from 'reselect'
import _ from 'lodash'
import { getLoggedInUserIdFromSession } from '../utils/util'
import constants from '../constants/constants'
const {AppType} = constants
import { getAppType } from './storeAppStatus'
import moment from 'moment'

const getSubjects = (state) => state.subject

const getSessionPool = (state) => state.session 

const getUsers = (state) => state.users

const getSessionCurrentUserId = (state) => state?.session?.currentUserId

const getstudyMetaDataPool = (state) => state.studyMetaData

export const getUser = createSelector([getSessionCurrentUserId, getUsers], (userId, users) => {
  const loggedInUserId = getLoggedInUserIdFromSession(userId)
   return _.get(users, loggedInUserId, {})
})

export const getSubject = createSelector(
    [getSubjects, getUser],
    (subjects, user)  => {
      return subjects?.[user?.id]
    }
)

export const getUserTimezone = createSelector([getUser, getSubject], (user, subject) => {
  const subTz = _.get(subject, 'timeZone', moment.tz.guess(true))
  if(user.role === AppType.SITESTAFF){
    return _.get(user?.userData?.data, 'timezone', subTz)
  }
  return _.get(user, 'timeZone', subTz)
})

export const getUserSession = createSelector([getSessionPool, getUser], (sessionPool, user) => {
    const userId = user?.id
    return _.get(sessionPool, userId, {})
})


export const getPrimaryOrgCode = createSelector([getUser, getstudyMetaDataPool, getAppType], (currentUser, studyMetadataPool, appType) => {
  const userStudyMetadata = _.get(studyMetadataPool, currentUser.id, {})
  return _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'primaryOrganizationCode', "") : _.get(userStudyMetadata?.studySite,'primaryOrganizationCode', "")
})