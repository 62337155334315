import React from 'react'
import { store } from '../store/configStore'
import appConstants from '../constants/constants'
import { getUser } from '../selectors/commonSelector'
import _ from 'lodash'
import PropTypes from 'prop-types'

export const bottomTabs = [
  {
    privilege: 'VIEW_DIARY',
    tabName: 'diaryTab',
  },
  {
    privilege: 'VIEW_CALL',
    tabName: 'meetingScheduleTab',
  },
  //due to bottom tab navigation unable to handle dynamic tabs, so commented home tab  as privilege is not given for subject
  // {
  //   privilege: 'VIEW_COMPLIANCE',
  //   tabName: 'homeTab',
  // },
  {
    privilege: 'VIEW_CHAT',
    tabName: 'chatTab',
  },
]

export const authorizeTabs = () => {
  const authorizedTabs = bottomTabs
  const authorizedTabNames = authorizedTabs?.map((tab) => {
    return tab.tabName
  })
  authorizedTabNames.push('moreTab') // This tab needs no permissions
  return authorizedTabNames
}

export const Authorize = ({ children, privilege, hasAnyGrant }) => {
  const state = store.getState()
  const userPrivileges = _.get(getUser(state), 'privileges', [])
  const applicationType = state.appStatus.appType
  if (hasAnyGrant.length && _.intersection(userPrivileges, hasAnyGrant).length > 0) {
    return children
  }
  if (userPrivileges?.includes(privilege)) {
    return children
  }
  if (appConstants.AppType.SITESTAFF === applicationType) {
    return children
  }
  return <></>
}
Authorize.defaultProps = {
  hasAnyGrant: [],
  privilege: '',
}

Authorize.propTypes = {
  children: PropTypes.node.isRequired,
  privilege: PropTypes.string,
  hasAnyGrant: PropTypes.arrayOf(PropTypes.string),
}
