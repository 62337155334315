export default {
    "Africa/Abidjan": "Aafrika/Abidjan",
"Africa/Accra": "Aafrika/Accra",
"Africa/Addis_Ababa": "Aafrika/Addis_Ababa",
"Africa/Algiers": "Aafrika/Alžeeria",
"Africa/Asmara": "Aafrika/Asmara",
"Africa/Asmera": "Aafrika/Asmera",
"Africa/Bamako": "Aafrika/Bamako",
"Africa/Bangui": "Aafrika/Bangui",
"Africa/Banjul": "Aafrika/Banjul",
"Africa/Bissau": "Aafrika/Bissau",
"Africa/Blantyre": "Aafrika/Blantyre",
"Africa/Brazzaville": "Aafrika/Brazzaville",
"Africa/Bujumbura": "Aafrika/Bujumbura",
"Africa/Cairo": "Aafrika/Kairo",
"Africa/Casablanca": "Aafrika/Casablanca",
"Africa/Ceuta": "Aafrika/Ceuta",
"Africa/Conakry": "Aafrika/Conakry",
"Africa/Dakar": "Aafrika/Dakar",
"Africa/Dar_es_Salaam": "Aafrika/Dar_es_Salaam",
"Africa/Djibouti": "Aafrika/Djibouti",
"Africa/Douala": "Aafrika/Douala",
"Africa/El_Aaiun": "Aafrika/El_Aaiun",
"Africa/Freetown": "Aafrika/Freetown",
"Africa/Gaborone": "Aafrika/Gaborone",
"Africa/Harare": "Aafrika/Harare",
"Africa/Johannesburg": "Aafrika/Johannesburg",
"Africa/Juba": "Aafrika/Juba",
"Africa/Kampala": "Aafrika/Kampala",
"Africa/Khartoum": "Aafrika/Khartoum",
"Africa/Kigali": "Aafrika/Kigali",
"Africa/Kinshasa": "Aafrika/Kinshasa",
"Africa/Lagos": "Aafrika/Lagos",
"Africa/Libreville": "Aafrika/Libreville",
"Africa/Lome": "Aafrika/Lome",
"Africa/Luanda": "Aafrika/Luanda",
"Africa/Lubumbashi": "Aafrika/Lubumbashi",
"Africa/Lusaka": "Aafrika/Lusaka",
"Africa/Malabo": "Aafrika/Malabo",
"Africa/Maputo": "Aafrika/Maputo",
"Africa/Maseru": "Aafrika/Maseru",
"Africa/Mbabane": "Aafrika/Mbabane",
"Africa/Mogadishu": "Aafrika/Mogadishu",
"Africa/Monrovia": "Aafrika/Monrovia",
"Africa/Nairobi": "Aafrika/Nairobi",
"Africa/Ndjamena": "Aafrika/Ndjamena",
"Africa/Niamey": "Aafrika/Niamey",
"Africa/Nouakchott": "Aafrika/Nouakchott",
"Africa/Ouagadougou": "Aafrika/Ouagadougou",
"Africa/Porto-Novo": "Aafrika/Porto-Novo",
"Africa/Sao_Tome": "Aafrika/Sao_Tome",
"Africa/Timbuktu": "Aafrika/Timbuktu",
"Africa/Tripoli": "Aafrika/Tripoli",
"Africa/Tunis": "Aafrika/Tuneesia",
"Africa/Windhoek": "Aafrika/Windhoek",
"America/Adak": "Ameerika/Adak",
"America/Anchorage": "Ameerika/Anchorage",
"America/Anguilla": "Ameerika/Anguilla",
"America/Antigua": "Ameerika/Antigua",
"America/Araguaina": "Ameerika/Araguaina",
"America/Argentina/Buenos_Aires": "Ameerika/Argentina/Buenos_Aires",
"America/Argentina/Catamarca": "Ameerika/Argentina/Catamarca",
"America/Argentina/ComodRivadavia": "Ameerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba": "Ameerika/Argentina/Cordoba",
"America/Argentina/Jujuy": "Ameerika/Argentina/Jujuy",
"America/Argentina/La_Rioja": "Ameerika/Argentina/La_Rioja",
"America/Argentina/Mendoza": "Ameerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos": "Ameerika/Argentina/Rio_Gallegos",
"America/Argentina/Salta": "Ameerika/Argentina/Salta",
"America/Argentina/San_Juan": "Ameerika/Argentina/San_Juan",
"America/Argentina/San_Luis": "Ameerika/Argentina/San_Luis",
"America/Argentina/Tucuman": "Ameerika/Argentina/Tucuman",
"America/Argentina/Ushuaia": "Ameerika/Argentina/Ushuaia",
"America/Aruba": "Ameerika/Aruba",
"America/Asuncion": "Ameerika/Asuncion",
"America/Atikokan": "Ameerika/Atikokan",
"America/Atka": "Ameerika/Atka",
"America/Bahia": "Ameerika/Bahia",
"America/Bahia_Banderas": "Ameerika/Bahia_Banderas",
"America/Barbados": "Ameerika/Barbados",
"America/Belem": "Ameerika/Belem",
"America/Belize": "Ameerika/Belize",
"America/Blanc-Sablon": "Ameerika/Blanc-Sablon",
"America/Boa_Vista": "Ameerika/Boa_Vista",
"America/Bogota": "Ameerika/Bogotá",
"America/Boise": "Ameerika/Boise",
"America/Buenos_Aires": "Ameerika/Buenos_Aires",
"America/Cambridge_Bay": "Ameerika/Cambridge_Bay",
"America/Campo_Grande": "Ameerika/Campo_Grande",
"America/Cancun": "Ameerika/Cancun",
"America/Caracas": "Ameerika/Caracas",
"America/Catamarca": "Ameerika/Catamarca",
"America/Cayenne": "Ameerika/Cayenne",
"America/Cayman": "Ameerika/Kaiman",
"America/Chicago": "Ameerika/Chicago",
"America/Chihuahua": "Ameerika/Chihuahua",
"America/Ciudad_Juarez": "Ameerika/Ciudad_Juarez",
"America/Coral_Harbour": "Ameerika/Coral_Harbour",
"America/Cordoba": "Ameerika/Cordoba",
"America/Costa_Rica": "Ameerika/Costa_Rica",
"America/Creston": "Ameerika/Creston",
"America/Cuiaba": "Ameerika/Cuiaba",
"America/Curacao": "Ameerika/Curacao",
"America/Danmarkshavn": "Ameerika/Danmarkshavn",
"America/Dawson": "Ameerika/Dawson",
"America/Dawson_Creek": "Ameerika/Dawson_Creek",
"America/Denver": "Ameerika/Denver",
"America/Detroit": "Ameerika/Detroit",
"America/Dominica": "Ameerika/Dominica",
"America/Edmonton": "Ameerika/Edmonton",
"America/Eirunepe": "Ameerika/Eirunepe",
"America/El_Salvador": "Ameerika/El_Salvador",
"America/Ensenada": "Ameerika/Ensenada",
"America/Fort_Nelson": "Ameerika/Fort_Nelson",
"America/Fort_Wayne": "Ameerika/Fort_Wayne",
"America/Fortaleza": "Ameerika/Fortaleza",
"America/Glace_Bay": "Ameerika/Glace_Bay",
"America/Godthab": "Ameerika/Godthab",
"America/Goose_Bay": "Ameerika/Goose_Bay",
"America/Grand_Turk": "Ameerika/Grand_Turk",
"America/Grenada": "Ameerika/Grenada",
"America/Guadeloupe": "Ameerika/Guadeloupe",
"America/Guatemala": "Ameerika/Guatemala",
"America/Guayaquil": "Ameerika/Guayaquil",
"America/Guyana": "Ameerika/Guyana",
"America/Halifax": "Ameerika/Halifax",
"America/Havana": "Ameerika/Havana",
"America/Hermosillo": "Ameerika/Hermosillo",
"America/Indiana/Indianapolis": "Ameerika/Indiana/Indianapolis",
"America/Indiana/Knox": "Ameerika/Indiaana/Knox",
"America/Indiana/Marengo": "Ameerika/Indiana/Marengo",
"America/Indiana/Petersburg": "Ameerika/Indiaana/Peterburi",
"America/Indiana/Tell_City": "Ameerika/Indiaana/Tell_City",
"America/Indiana/Vevay": "Ameerika/Indiaana/Vevay",
"America/Indiana/Vincennes": "Ameerika/Indiaana/Vincennes",
"America/Indiana/Winamac": "Ameerika/Indiaana/Winamac",
"America/Indianapolis": "Ameerika/Indianapolis",
"America/Inuvik": "Ameerika/Inuvik",
"America/Iqaluit": "Ameerika/Iqaluit",
"America/Jamaica": "Ameerika/Jamaica",
"America/Jujuy": "Ameerika/Jujuy",
"America/Juneau": "Ameerika/Juneau",
"America/Kentucky/Louisville": "Ameerika/Kentucky/Louisville",
"America/Kentucky/Monticello": "Ameerika/Kentucky/Monticello",
"America/Knox_IN": "Ameerika/Knox_in",
"America/Kralendijk": "Ameerika/Kralendijk",
"America/La_Paz": "Ameerika/La_Paz",
"America/Lima": "Ameerika/Lima",
"America/Los_Angeles": "Ameerika/Los_Angeles",
"America/Louisville": "Ameerika/Louisville",
"America/Lower_Princes": "Ameerika/Lower_Princes",
"America/Maceio": "Ameerika/Maceio",
"America/Managua": "Ameerika/Managua",
"America/Manaus": "Ameerika/Manaus",
"America/Marigot": "Ameerika/Marigot",
"America/Martinique": "Ameerika/Martinique",
"America/Matamoros": "Ameerika/Matamoros",
"America/Mazatlan": "Ameerika/Mazatlan",
"America/Mendoza": "Ameerika/Mendoza",
"America/Menominee": "Ameerika/Menominee",
"America/Merida": "Ameerika/Merida",
"America/Metlakatla": "Ameerika/Metlakatla",
"America/Mexico_City": "Ameerika/Mehhiko_linn",
"America/Miquelon": "Ameerika/Miquelon",
"America/Moncton": "Ameerika/Moncton",
"America/Monterrey": "Ameerika/Monterrey",
"America/Montevideo": "Ameerika/Montevideo",
"America/Montreal": "Ameerika/Montreal",
"America/Montserrat": "Ameerika/Montserrat",
"America/Nassau": "Ameerika/Nassau",
"America/New_York": "Ameerika/New_York",
"America/Nipigon": "Ameerika/Nipigon",
"America/Nome": "Ameerika/Nimi",
"America/Noronha": "Ameerika/Noronha",
"America/North_Dakota/Beulah": "Ameerika/Põhja-Dakota/Beulah",
"America/North_Dakota/Center": "Ameerika/Põhja_Dakota/Keskus",
"America/North_Dakota/New_Salem": "Ameerika/Põhja_Dakota/Uus_Salem",
"America/Nuuk": "Ameerika/Nuuk",
"America/Ojinaga": "Ameerika/Ojinaga",
"America/Panama": "Ameerika/Panama",
"America/Pangnirtung": "Ameerika/Pangnirtung",
"America/Paramaribo": "Ameerika/Paramaribo",
"America/Phoenix": "Ameerika/Phoenix",
"America/Port-au-Prince": "Ameerika/Port-au-Prince",
"America/Port_of_Spain": "Ameerika/Hispaania sadam",
"America/Porto_Acre": "Ameerika/Porto_Acre",
"America/Porto_Velho": "Ameerika/Porto_Velho",
"America/Puerto_Rico": "Ameerika/Puerto_Rico",
"America/Punta_Arenas": "Ameerika/Punta_Arenas",
"America/Rainy_River": "Ameerika/Rainy_River",
"America/Rankin_Inlet": "Ameerika/Rankin_Inlet",
"America/Recife": "Ameerika/Recife",
"America/Regina": "Ameerika/Regina",
"America/Resolute": "Ameerika/Resolute",
"America/Rio_Branco": "Ameerika/Rio_Branco",
"America/Rosario": "Ameerika/Rosario",
"America/Santa_Isabel": "Ameerika/Santa_Isabel",
"America/Santarem": "Ameerika/Santarem",
"America/Santiago": "Ameerika/Santiago",
"America/Santo_Domingo": "Ameerika/Santo_Domingo",
"America/Sao_Paulo": "Ameerika/Sao_Paulo",
"America/Scoresbysund": "Ameerika/Scoresbysund",
"America/Shiprock": "Ameerika/Shiprock",
"America/Sitka": "Ameerika/Sitka",
"America/St_Barthelemy": "Ameerika/St_Barthelemy",
"America/St_Johns": "Ameerika/St_Johns",
"America/St_Kitts": "Ameerika/St_Kitts",
"America/St_Lucia": "Ameerika/St_Lucia",
"America/St_Thomas": "Ameerika/St_Thomas",
"America/St_Vincent": "Ameerika/St_Vincent",
"America/Swift_Current": "Ameerika/Swift_Current",
"America/Tegucigalpa": "Ameerika/Tegucigalpa",
"America/Thule": "Ameerika/Thule",
"America/Thunder_Bay": "Ameerika/Thunder_Bay",
"America/Tijuana": "Ameerika/Tijuana",
"America/Toronto": "Ameerika/Toronto",
"America/Tortola": "Ameerika/Tortola",
"America/Vancouver": "Ameerika/Vancouver",
"America/Virgin": "Ameerika/Neitsi",
"America/Whitehorse": "Ameerika/Whitehorse",
"America/Winnipeg": "Ameerika/Winnipeg",
"America/Yakutat": "Ameerika/Yakutat",
"America/Yellowknife": "Ameerika/Yellowknife",
"Antarctica/Casey": "Antarktika/Casey",
"Antarctica/Davis": "Antarktika/Davis",
"Antarctica/DumontDUrville": "Antarktika/Dumontdurville",
"Antarctica/Macquarie": "Antarktika/Macquarie",
"Antarctica/Mawson": "Antarktika/Mawson",
"Antarctica/McMurdo": "Antarktika/McMurdo",
"Antarctica/Palmer": "Antarktika/Palmer",
"Antarctica/Rothera": "Antarktika/Rothera",
"Antarctica/South_Pole": "Antarktika/Lõuna-Pole",
"Antarctica/Syowa": "Antarktika/Syowa",
"Antarctica/Troll": "Antarktika/troll",
"Antarctica/Vostok": "Antarktika/Vostok",
"Arctic/Longyearbyen": "Arktika/Longyearbyen",
"Asia/Aden": "Aasia/Aden",
"Asia/Almaty": "Aasia/Almatõ",
"Asia/Amman": "Aasia/Amman",
"Asia/Anadyr": "Aasia/Anadyr",
"Asia/Aqtau": "Aasia/Aqtau",
"Asia/Aqtobe": "Aasia/Aqtobe",
"Asia/Ashgabat": "Aasia/Ašgabat",
"Asia/Ashkhabad": "Aasia/Ashkhabad",
"Asia/Atyrau": "Aasia/Atyrau",
"Asia/Baghdad": "Aasia/Bagdad",
"Asia/Bahrain": "Aasia/Bahrein",
"Asia/Baku": "Aasia/Bakuu",
"Asia/Bangkok": "Aasia/Bangkok",
"Asia/Barnaul": "Aasia/Barnaul",
"Asia/Beirut": "Aasia/Beirut",
"Asia/Bishkek": "Aasia/Biškek",
"Asia/Brunei": "Aasia/Brunei",
"Asia/Calcutta": "Aasia/Kalkutta",
"Asia/Chita": "Aasia/Chita",
"Asia/Choibalsan": "Aasia/Choibalsan",
"Asia/Chongqing": "Aasia/Chongqing",
"Asia/Chungking": "Aasia/Chungking",
"Asia/Colombo": "Aasia/Colombo",
"Asia/Dacca": "Aasia/Dakka",
"Asia/Damascus": "Aasia/Damaskus",
"Asia/Dhaka": "Aasia/Dhaka",
"Asia/Dili": "Aasia/Dili",
"Asia/Dubai": "Aasia/Dubai",
"Asia/Dushanbe": "Aasia/Dušanbe",
"Asia/Famagusta": "Aasia/Famagusta",
"Asia/Gaza": "Aasia/Gaza",
"Asia/Harbin": "Aasia/Harbin",
"Asia/Hebron": "Aasia/Hebron",
"Asia/Ho_Chi_Minh": "Aasia/Ho_Chi_Minh",
"Asia/Hong_Kong": "Aasia/Hong_Kong",
"Asia/Hovd": "Aasia/Hovd",
"Asia/Irkutsk": "Aasia/Irkutsk",
"Asia/Istanbul": "Aasia/Istanbul",
"Asia/Jakarta": "Aasia/Jakarta",
"Asia/Jayapura": "Aasia/Jayapura",
"Asia/Jerusalem": "Aasia/Jeruusalemm",
"Asia/Kabul": "Aasia/Kabul",
"Asia/Kamchatka": "Aasia/Kamtšatka",
"Asia/Karachi": "Aasia/Karachi",
"Asia/Kashgar": "Aasia/Kashgar",
"Asia/Kathmandu": "Aasia/Katmandu",
"Asia/Katmandu": "Aasia/Katmandu",
"Asia/Khandyga": "Aasia/Khandyga",
"Asia/Kolkata": "Aasia/Kolkata",
"Asia/Krasnoyarsk": "Aasia/Krasnojarsk",
"Asia/Kuala_Lumpur": "Aasia/Kuala_Lumpur",
"Asia/Kuching": "Aasia/Kuching",
"Asia/Kuwait": "Aasia/Kuveit",
"Asia/Macao": "Aasia/Macao",
"Asia/Macau": "Aasia/Macau",
"Asia/Magadan": "Aasia/Magadan",
"Asia/Makassar": "Aasia/Makassar",
"Asia/Manila": "Aasia/Manila",
"Asia/Muscat": "Aasia/Muscat",
"Asia/Nicosia": "Aasia/Nicosia",
"Asia/Novokuznetsk": "Aasia/Novokuznetsk",
"Asia/Novosibirsk": "Aasia/Novosibirsk",
"Asia/Omsk": "Aasia/Omsk",
"Asia/Oral": "Aasia/Oral",
"Asia/Phnom_Penh": "Aasia/Phnom_Penh",
"Asia/Pontianak": "Aasia/Pontianak",
"Asia/Pyongyang": "Aasia/Pyongyang",
"Asia/Qatar": "Aasia/Katar",
"Asia/Qostanay": "Aasia/Qostanay",
"Asia/Qyzylorda": "Aasia/Qyzylorda",
"Asia/Rangoon": "Aasia/Rangoon",
"Asia/Riyadh": "Aasia/Riyadh",
"Asia/Saigon": "Aasia/Saigon",
"Asia/Sakhalin": "Aasia/Sahhalin",
"Asia/Samarkand": "Aasia/Samarkand",
"Asia/Seoul": "Aasia/Soul",
"Asia/Shanghai": "Aasia/Shanghai",
"Asia/Singapore": "Aasia/Singapur",
"Asia/Srednekolymsk": "Aasia/Srednekolymsk",
"Asia/Taipei": "Aasia/Taipei",
"Asia/Tashkent": "Aasia/Taškent",
"Asia/Tbilisi": "Aasia/Thbilisi",
"Asia/Tehran": "Aasia/Teheran",
"Asia/Tel_Aviv": "Aasia/Tel_Aviv",
"Asia/Thimbu": "Aasia/Thimbu",
"Asia/Thimphu": "Aasia/Thimphu",
"Asia/Tokyo": "Aasia/Tokyo",
"Asia/Tomsk": "Aasia/Tomsk",
"Asia/Ujung_Pandang": "Aasia/Ujung_Pandang",
"Asia/Ulaanbaatar": "Aasia/Ulaanbaatar",
"Asia/Ulan_Bator": "Aasia/Ulan_Bator",
"Asia/Urumqi": "Aasia/Urumqi",
"Asia/Ust-Nera": "Aasia/Ust-Nera",
"Asia/Vientiane": "Aasia/Vientiane",
"Asia/Vladivostok": "Aasia/Vladivostok",
"Asia/Yakutsk": "Aasia/Jakutsk",
"Asia/Yangon": "Aasia/Yangon",
"Asia/Yekaterinburg": "Aasia/Jekaterinburg",
"Asia/Yerevan": "Aasia/Jerevan",
"Atlantic/Azores": "Atlandi ookeani/Assoorid",
"Atlantic/Bermuda": "Atlantic/Bermuda",
"Atlantic/Canary": "Atlantic/Kanaari",
"Atlantic/Cape_Verde": "Atlantic/Cape_Green",
"Atlantic/Faeroe": "Atlandi ookeani/Fääri keel",
"Atlantic/Faroe": "Atlandi ookeani/Fääri",
"Atlantic/Jan_Mayen": "Atlandi ookean/Jan_Mayen",
"Atlantic/Madeira": "Atlandi ookean/Madeira",
"Atlantic/Reykjavik": "Atlantic/Reykjavik",
"Atlantic/South_Georgia": "Atlandi ookeani/Lõuna-Georgia",
"Atlantic/St_Helena": "Atlandi ookean/St_Helena",
"Atlantic/Stanley": "Atlantic/Stanley",
"Australia/ACT": "Austraalia/ACT",
"Australia/Adelaide": "Austraalia/Adelaide",
"Australia/Brisbane": "Austraalia/Brisbane",
"Australia/Broken_Hill": "Austraalia/Broken_Hill",
"Australia/Canberra": "Austraalia/Canberra",
"Australia/Currie": "Austraalia/Currie",
"Australia/Darwin": "Austraalia/Darwin",
"Australia/Eucla": "Austraalia/Eucla",
"Australia/Hobart": "Austraalia/Hobart",
"Australia/LHI": "Austraalia/LHI",
"Australia/Lindeman": "Austraalia/Lindeman",
"Australia/Lord_Howe": "Austraalia/Lord_Howe",
"Australia/Melbourne": "Austraalia/Melbourne",
"Australia/NSW": "Austraalia/NSW",
"Australia/North": "Austraalia/Põhja",
"Australia/Perth": "Austraalia/Perth",
"Australia/Queensland": "Austraalia/Queensland",
"Australia/South": "Austraalia/Lõuna",
"Australia/Sydney": "Austraalia/Sydney",
"Australia/Tasmania": "Austraalia/Tasmaania",
"Australia/Victoria": "Austraalia/Victoria",
"Australia/West": "Austraalia/Lääs",
"Australia/Yancowinna": "Austraalia/Yancowinna",
"Brazil/Acre": "Brasiilia/Acre",
"Brazil/DeNoronha": "Brasiilia/Denoronha",
"Brazil/East": "Brasiilia/Ida",
"Brazil/West": "Brasiilia/Lääs",
"CET": "SEE",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "Kanada/Atlandi ookeani",
"Canada/Central": "Kanada/Kesk",
"Canada/Eastern": "Kanada/Ida",
"Canada/Mountain": "Kanada/mägi",
"Canada/Newfoundland": "Kanada/Newfoundland",
"Canada/Pacific": "Kanada/Vaikse ookeani piirkond",
"Canada/Saskatchewan": "Kanada/Saskatchewan",
"Canada/Yukon": "Kanada/Yukon",
"Chile/Continental": "Tšiili/kontinentaalne",
"Chile/EasterIsland": "Tšiili/EasterIsland",
"Cuba": "Kuuba",
"EET": "SÜÜA",
"EST": "EST",
"EST5EDT": "ON 5",
"Egypt": "Egiptus",
"Eire": "Eire",
"Etc/GMT": "Jetc/GMT",
"Etc/GMT+0": "Jetc/GMT+0",
"Etc/GMT+1": "Jetc/GMT+1",
"Etc/GMT+10": "Jetc/GMT+10",
"Etc/GMT+11": "Jetc/GMT+11",
"Etc/GMT+12": "Jetc/GMT+12",
"Etc/GMT+2": "Jetc/GMT+2",
"Etc/GMT+3": "Jetc/GMT+3",
"Etc/GMT+4": "Jetc/GMT+4",
"Etc/GMT+5": "Jetc/GMT+5",
"Etc/GMT+6": "Jetc/GMT+6",
"Etc/GMT+7": "Jetc/GMT+7",
"Etc/GMT+8": "Jetc/GMT+8",
"Etc/GMT+9": "Jetc/GMT+9",
"Etc/GMT-0": "Jetc/GMT-0",
"Etc/GMT-1": "Jetc/GMT-1",
"Etc/GMT-10": "Jetc/GMT-10",
"Etc/GMT-11": "Jetc/GMT-11",
"Etc/GMT-12": "Jetc/GMT-12",
"Etc/GMT-13": "Jetc/GMT-13",
"Etc/GMT-14": "Jetc/GMT-14",
"Etc/GMT-2": "Jetc/GMT-2",
"Etc/GMT-3": "Jetc/GMT-3",
"Etc/GMT-4": "Jetc/GMT-4",
"Etc/GMT-5": "Jetc/GMT-5",
"Etc/GMT-6": "Jetc/GMT-6",
"Etc/GMT-7": "Jetc/GMT-7",
"Etc/GMT-8": "Jetc/GMT-8",
"Etc/GMT-9": "Jetc/GMT-9",
"Etc/GMT0": "Jetc/GMT0",
"Etc/Greenwich": "Jet/Greenwich",
"Etc/UCT": "Jetc/UCT",
"Etc/UTC": "Jetc/UTC",
"Etc/Universal": "Jetc/Universaalne",
"Etc/Zulu": "Jetc/Zulu",
"Europe/Amsterdam": "Euroopa/Amsterdam",
"Europe/Andorra": "Euroopa/Andorra",
"Europe/Astrakhan": "Euroopa/Astrakhan",
"Europe/Athens": "Euroopa/Ateena",
"Europe/Belfast": "Euroopa/Belfast",
"Europe/Belgrade": "Euroopa/Belgrad",
"Europe/Berlin": "Euroopa/Berliin",
"Europe/Bratislava": "Euroopa/Bratislava",
"Europe/Brussels": "Euroopa/Brüssel",
"Europe/Bucharest": "Euroopa/Bukarest",
"Europe/Budapest": "Euroopa/Budapest",
"Europe/Busingen": "Euroopa/Busingen",
"Europe/Chisinau": "Euroopa/Chişinău",
"Europe/Copenhagen": "Euroopa/Kopenhaagen",
"Europe/Dublin": "Euroopa/Dublin",
"Europe/Gibraltar": "Euroopa/Gibraltar",
"Europe/Guernsey": "Euroopa/Guernsey",
"Europe/Helsinki": "Euroopa/Helsingi",
"Europe/Isle_of_Man": "Euroopa/Inimeste saar",
"Europe/Istanbul": "Euroopa/Istanbul",
"Europe/Jersey": "Euroopa/Jersey",
"Europe/Kaliningrad": "Euroopa/Kaliningrad",
"Europe/Kiev": "Euroopa/Kiiev",
"Europe/Kirov": "Euroopa/Kirov",
"Europe/Kyiv": "Euroopa/Kiiev",
"Europe/Lisbon": "Euroopa/Lissabon",
"Europe/Ljubljana": "Euroopa/Ljubljana",
"Europe/London": "Euroopa/London",
"Europe/Luxembourg": "Euroopa/Luksemburg",
"Europe/Madrid": "Euroopa/Madrid",
"Europe/Malta": "Euroopa/Malta",
"Europe/Mariehamn": "Euroopa/Mariehamn",
"Europe/Minsk": "Euroopa/Minsk",
"Europe/Monaco": "Euroopa/Monaco",
"Europe/Moscow": "Euroopa/Moskva",
"Europe/Nicosia": "Euroopa/Nicosia",
"Europe/Oslo": "Euroopa/Oslo",
"Europe/Paris": "Euroopa/Pariis",
"Europe/Podgorica": "Euroopa/Podgorica",
"Europe/Prague": "Euroopa/Praha",
"Europe/Riga": "Euroopa/Riia",
"Europe/Rome": "Euroopa/Rooma",
"Europe/Samara": "Euroopa/Samara",
"Europe/San_Marino": "Euroopa/San_Marino",
"Europe/Sarajevo": "Euroopa/Sarajevo",
"Europe/Saratov": "Euroopa/Saratov",
"Europe/Simferopol": "Euroopa/Simferopol",
"Europe/Skopje": "Euroopa/Skopje",
"Europe/Sofia": "Euroopa/Sofia",
"Europe/Stockholm": "Euroopa/Stockholm",
"Europe/Tallinn": "Euroopa/Tallinn",
"Europe/Tirane": "Euroopa/Tirane",
"Europe/Tiraspol": "Euroopa/Tiraspol",
"Europe/Ulyanovsk": "Euroopa/Uljanovsk",
"Europe/Uzhgorod": "Euroopa/Uzhgorod",
"Europe/Vaduz": "Euroopa/Vaduz",
"Europe/Vatican": "Euroopa/Vatikan",
"Europe/Vienna": "Euroopa/Viin",
"Europe/Vilnius": "Euroopa/Vilnius",
"Europe/Volgograd": "Euroopa/Volgograd",
"Europe/Warsaw": "Euroopa/Varssavi",
"Europe/Zagreb": "Euroopa/Zagreb",
"Europe/Zaporozhye": "Euroopa/Zaporozhye",
"Europe/Zurich": "Euroopa/Zürich",
"GB": "GB",
"GB-Eire": "GB-Eire",
"GMT": "GMT",
"GMT+0": "GMT+0",
"GMT-0": "GMT-0",
"GMT0": "GMT0",
"Greenwich": "Greenwich",
"HST": "HST",
"Hongkong": "Hongkong",
"Iceland": "Island",
"Indian/Antananarivo": "Indiaan/Antananarivo",
"Indian/Chagos": "Indiaan/Chagos",
"Indian/Christmas": "India/Jõulud",
"Indian/Cocos": "Indiaan/kokos",
"Indian/Comoro": "Indiaan/Komoro",
"Indian/Kerguelen": "Indiaan/Kerguelen",
"Indian/Mahe": "Indiaan/Mahe",
"Indian/Maldives": "India/Maldiivid",
"Indian/Mauritius": "Indiaan/Mauritius",
"Indian/Mayotte": "Indiaan/Mayotte",
"Indian/Reunion": "Indiaan/taasühinemine",
"Iran": "Iraan",
"Israel": "Iisrael",
"Jamaica": "Jamaica",
"Japan": "Jaapan",
"Kwajalein": "Kvajalein",
"Libya": "Liibüa",
"MET": "KOHTUS",
"MST": "MST",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "Mehhiko/Bajanorte",
"Mexico/BajaSur": "Mehhiko/Bajasur",
"Mexico/General": "Mehhiko/Üldine",
"NZ": "NZ",
"NZ-CHAT": "NZ-VESTLUS",
"Navajo": "Navajo",
"PRC": "PRC",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "Vaikse ookeane/Apia",
"Pacific/Auckland": "Vaikse ookeani piirkond/Auckland",
"Pacific/Bougainville": "Vaikse ookeani/Bougainville",
"Pacific/Chatham": "Vaikse ookeane/Chatham",
"Pacific/Chuuk": "Vaikse ookeani piirkond/Chuuk",
"Pacific/Easter": "Vaikse ookeani/lihavõtted",
"Pacific/Efate": "Vaikse ookeane/Efate",
"Pacific/Enderbury": "Vaikse ookeani piirkond/Enderbury",
"Pacific/Fakaofo": "Vaikse ookeane/Fakaofo",
"Pacific/Fiji": "Vaikse ookeani/Fidži",
"Pacific/Funafuti": "Vaikne ookean/Funafuti",
"Pacific/Galapagos": "Vaikse ookeane/Galapagos",
"Pacific/Gambier": "Vaikse ookeane/Gambier",
"Pacific/Guadalcanal": "Vaikse ookeani/Guadalcanal",
"Pacific/Guam": "Vaikse ookeane/Guam",
"Pacific/Honolulu": "Vaikse ookeane/Honolulu",
"Pacific/Johnston": "Vaikse ookeani piirkond/Johnston",
"Pacific/Kanton": "Vaikse ookeani/Kanton",
"Pacific/Kiritimati": "Vaikse ookeane/Kiritimati",
"Pacific/Kosrae": "Vaikse ookeani piirkond/Kosrae",
"Pacific/Kwajalein": "Vaikse ookeane/Kwajalein",
"Pacific/Majuro": "Vaikse ookeane/Majuro",
"Pacific/Marquesas": "Vaikse ookeani piirkond/Marquesas",
"Pacific/Midway": "Vaikse ookeane/Midway",
"Pacific/Nauru": "Vaikse ookeani/Nauru",
"Pacific/Niue": "Vaikse ookeane/Niue",
"Pacific/Norfolk": "Vaikse ookeani piirkond/Norfolk",
"Pacific/Noumea": "Vaikse ookeani/Noumea",
"Pacific/Pago_Pago": "Vaikse ookeani piirkond/Pago_Pago",
"Pacific/Palau": "Vaikse ookeani/Palau",
"Pacific/Pitcairn": "Vaikse ookeani piirkond/Pitcairn",
"Pacific/Pohnpei": "Vaikse ookeani piirkond/Pohnpei",
"Pacific/Ponape": "Vaikse ookeane/Ponape",
"Pacific/Port_Moresby": "Vaikse ookeani piirkond/Port_Moresby",
"Pacific/Rarotonga": "Vaikse ookeane/Rarotonga",
"Pacific/Saipan": "Vaikse ookeane/Saipan",
"Pacific/Samoa": "Vaikse ookeani/Samoa",
"Pacific/Tahiti": "Vaikse ookeane/Tahiti",
"Pacific/Tarawa": "Vaikse ookeane/Tarawa",
"Pacific/Tongatapu": "Vaikse ookeane/Tongatapu",
"Pacific/Truk": "Vaikse ookeane/Truk",
"Pacific/Wake": "Vaikse ookeane/ärkamine",
"Pacific/Wallis": "Vaikse ookeane/Wallis",
"Pacific/Yap": "Vaikse ookeane/Yap",
"Poland": "Poola",
"Portugal": "Portugal",
"ROC": "ROOK",
"ROK": "YEAR",
"Singapore": "Singapur",
"Turkey": "Türgi",
"UCT": "UCT",
"US/Alaska": "USA/Alaska",
"US/Aleutian": "USA/aleut",
"US/Arizona": "USA/Arizona",
"US/Central": "USA/Kesk",
"US/East-Indiana": "USA/Ida-Indiana",
"US/Eastern": "USA/idapoolne",
"US/Hawaii": "USA/Hawaii",
"US/Indiana-Starke": "USA/Indiana karm",
"US/Michigan": "USA/Michigan",
"US/Mountain": "USA/mägi",
"US/Pacific": "USA/Vaikse ookeani piirkond",
"US/Samoa": "USA/Samoa",
"UTC": "UTC",
"Universal": "Universaalne",
"W-SU": "W-SU",
"WET": "MÄRG",
"Zulu": "Zulu",
}