import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Messages from '../components/Messages'
import { View } from 'react-native'
import {
  retrieveMessagesRequest,
  retrieveMessagesFailure,
  retrieveMessagesSuccess,
} from '../actions/message'
import { retrievePushNotifications, updateReadStatus } from '../utils/pushNotificationUtils'
import { setCurrentScreen } from '../actions/session'
import { getSubjectId } from '../selectors/subject'
import PropTypes from 'prop-types'

class MessagesScreen extends Component {
  state = {
    pushNotifications: [],
    loading: false,
  }

  static navigationOptions = ({ navigation, screenProps: { t } }) => ({
    title: navigation.state.params ? navigation.state.params.title : t('Notifications'),
  })

  componentDidMount() {
    const { setCurrentScreen } = this.props
    setCurrentScreen('')
    this.fetchPushNotifications()
  }

  refresh = () => {
    this.fetchPushNotifications()
  }

  updatePushNotifications = (pushNotifications) => {
    this.setState({
      pushNotifications,
    })
  }


  fetchPushNotifications = async () => {
    const {
      subjectId,
      rmr,
      rmf,
      rms,
      screenProps: { t },
    } = this.props
    try {
      this.changeLoading()
      const res = await retrievePushNotifications(subjectId, { rmr, rmf, rms }, t)
      //    const messages = res ? res.filter(message => message.sentTime !== null) : [];
      const messages = [...res]

      setTimeout(() => this.changeLoading(), 2000)
      this.updatePushNotifications(messages)
      updateReadStatus(subjectId)
    } catch (error) {
      this.changeLoading()
      console.log(error.message, 'failed')
    }
  }

  changeLoading = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: !prevState.loading,
    }))
  }

  render() {
    const { navigation, screenProps } = this.props
    const { pushNotifications, loading } = this.state
    return (
      // <PTRView onRefresh={this.fetchPushNotifications} delay={0}>
      <View style={{ flex: 1 }}>
        <Messages
          navigation={navigation}
          pushNotifications={pushNotifications}
          screenProps={screenProps}
          loading={loading}
          refresh={this.refresh}
        />
      </View>
      // </PTRView>
    )
  }
}

const mapStateToProps = (state) => ({
  subjectId: getSubjectId(state)
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rmr: retrieveMessagesRequest,
      rmf: retrieveMessagesFailure,
      rms: retrieveMessagesSuccess,
      setCurrentScreen,
    },
    dispatch
  )

  
MessagesScreen.defaultProps = {
  navigation: {},
  screenProps: {}
}

MessagesScreen.propTypes = {
  navigation: PropTypes.instanceOf(Object),
  screenProps: PropTypes.instanceOf(Object)
} 
export default connect(mapStateToProps, mapDispatchToProps)(MessagesScreen)
