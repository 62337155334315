import { decryptString, encryptString } from '../cryptoUtil'
import storage from './storage'

export const reduxStorage = {
  setItem: (key, value) => {
    const encVal = encryptString(value)
    storage.MMKVStorage.set(key, encVal)
    return Promise.resolve(true)
  },
  getItem: (key) => {
    const value = storage.MMKVStorage.getString(key)
    const decVal = decryptString(value)
    return Promise.resolve(decVal)
  },
  removeItem: (key) => {
    storage.MMKVStorage.delete(key)
    return Promise.resolve()
  },
}
