import React from "react"
import { PixelRatio} from "react-native"
import { alignments } from "../../scales/alignments"
import PropTypes from 'prop-types'
import _ from 'lodash'
import VerticalScale from "./Scales/VerticalScale"
import ScaleTrack from "./Scales/ScaleTrack"
import CustomThumb from "./Scales/CustomThumb"
import HorizontalScale from "./Scales/HorizontalScale"
import constants from "./constants"

const {SHOW_MARKS} = constants
const Scale = (props) => {
    const { orientation } = props;
    if(orientation === alignments.HORIZONTAL){
        return (<HorizontalScale {...props}/>)
    }
    return (<VerticalScale {...props}/>)
}

Scale.defaultProps = {
    orientation: alignments.HORIZONTAL,
}
Scale.propTypes = {
    orientation: PropTypes.string,
}
const BasicVASScale = (props) => {
    const { min, max, onValueChange, disabled, id, value, orientation, fontConfig, scaleProps, step, showMarks} = props
    const {scaleWidth} = scaleProps
    const [selectedValue, setSelectedValue] = React.useState(value);
    const minStep = step >= 10 ? 1 : step
    const trackMarks = _.range(min, Number(max)+1, minStep)
    React.useEffect(() => {
        setSelectedValue(value)
    }, [value])
    
    const onValueSelect = (v) => {
      onValueChange(v)
    }

    let sliderStyle = {width: scaleWidth}
    if(orientation === alignments.VERTICAL){
        sliderStyle = {height: scaleWidth}
    }

    const _renderTrack = (trackprops) => {
        return (
            <ScaleTrack 
                {...props}
                {...trackprops} 
                requiredFontForMarks={fontConfig.requiredFontForMarks}
                numberOfTerms={fontConfig.numberOfTerms}
            />
        )
    }

    const _renderThumb = () => {
        return (
            <CustomThumb id={id} disabled={disabled}/>
        )
    }
    return (
            <Scale 
                minimumValue={min}
                maximumValue={max}
                step={minStep}
                originalStep = {step}
                minimumTrackTintColor="#c5c5c5"
                maximumTrackTintColor="#c5c5c5"
                vertical={orientation === alignments.VERTICAL}                
                inverted={orientation === alignments.VERTICAL}              
                disabled={disabled}
                trackHeight={5}
                thumbSize={0}
                slideOnTap={true}
                trackComponent={(trackProps) => _renderTrack(trackProps)}
                trackMarks={trackMarks}
                onValueChange={onValueSelect}
                customThumb={() => _renderThumb()}
                value={selectedValue}
                accessible = {!disabled}
                testID= {`${id}-scale-container`}
                thumbTouchSize={{width: 20, height: 20}}
                orientation={orientation}
                style={sliderStyle}
                showMarks = {showMarks}
            />
    )
}

BasicVASScale.defaultProps = {
    min: 0,
    max: 10,
    onValueChange: ()=> null,
    id: null,
    disabled: false,
    value: null,
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
    },
    fontConfig: {
        requiredFontForMarks: 10,
        numberOfTerms: 0,
    },
    step: 1,
    showMarks: SHOW_MARKS.NO_MARKS,
  }
  BasicVASScale.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    orientation: PropTypes.string,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
    }),
    fontConfig: PropTypes.shape({
        requiredFontForMarks: PropTypes.number,
        numberOfTerms: PropTypes.number,
    }),
    step: PropTypes.number,
    showMarks: PropTypes.string,
  }
export default BasicVASScale