import React, { Component } from 'react'
import { View, Image, Text, StyleSheet} from 'react-native'
import { Svg, Path } from 'react-native-svg'
import _ from 'lodash'
import { getDeviceStatus } from '../actions/users'
import Loader from './common/Loader'
import PropTypes from 'prop-types';

class ClickableImageScreen extends Component {
  state = {
    color1: 'transparent',
    color2: 'transparent',
    color3: 'transparent',
    color4: 'transparent',
    color5: 'transparent',
    height: 0,
    width: 0,
    selectedArray: [],
  }
  componentDidMount = () => {
      this.setImageDimensions()
  }

  componentDidUpdate = (prevProps) => {
    const { obj, imageUri } = this.props
    if (prevProps.obj !== obj || (prevProps.imageUri !== imageUri && !_.isEmpty(imageUri))) {
      this.setImageDimensions()
    }
  }

  setImageDimensions = () => {
    const { obj, imageUri } = this.props
    Image.getSize(imageUri, (width, height) => {
      this.setState({ width, height })
    })
  }

  getValues = () => {
    const { value } = this.props
    let tempValue = value
    if (!tempValue) {
      tempValue = []
    }
    try {
      const parsedValues = Array.isArray(tempValue) ? tempValue : JSON.parse(tempValue)
      return parsedValues
    } catch (error) {
      console.log('Unable to parse value', error)
      return []
    }
  }
  checkValue = (data) => {
    let { setValue } = this.props
    const value = this.getValues()
    const valIndex = _.findIndex(value, (v) => v === data?.oid)
    if (valIndex >= 0) {
      _.remove(value, (v) => v === data.oid)
      setValue(value)
    } else {
      value.push(data.oid)
      setValue(value)
    }
  }

  checkSelectedValue = (data) => {
    const { obj } = this.props
    const parsedValues = this.getValues()
    return _.findIndex(parsedValues, (v) => v === data?.oid) >= 0
      ? obj?.highlightColor || data.fillColor
      : 'transparent'
  }

 getImageHeight =() =>{
  const {footerPageY, parentViewDimensions} = this.props
  const {height} = this.state
  const remainingHeight = Math.abs(Number(footerPageY)) - Math.abs(Number(parentViewDimensions?.pageY))
  const imageHeight = Number(remainingHeight) > Number(height) ? Number(height) : Number(remainingHeight)
  return !_.isNaN(imageHeight) ? imageHeight : height
 }

 getDefaultImageHeight = () => {
  const {hasMultipleQuestionInPage, onMobile} = this.props
  if(onMobile && hasMultipleQuestionInPage){
    return {height: this.getImageHeight()}
  }else if(onMobile && !hasMultipleQuestionInPage){
    return {height: 500}
  }else {
    return {}
  }
 }

  render() {
    const { t, value, obj , testID, disabled, imageUri} = this.props
    const originalWidth = this.state.width
    const originalHeight = this.state.height
    const aspectRatio = originalWidth ? originalWidth / originalHeight : 0
    if(_.isEmpty(imageUri) && getDeviceStatus()){
      return(
      <View>
          <Loader loading={_.isEmpty(imageUri)}/>
          <Text style={{justifyContent: 'center', textAlign: 'center'}}>{t('ImageLoading')}</Text>
      </View> 
      )
    }
    if (!obj || !obj.map) {
      return <View></View>
    }
    return (
      <View
      style={[styles.container, this.getDefaultImageHeight() ]}
    >
        <Image
          source={{
            uri: imageUri 
          }}
          alt="Alternate Text"
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: -10,
          }}
          resizeMode={'contain'}
        />

        <View
          style={{
            aspectRatio: aspectRatio,
          }}
        >
          <Svg width="100%" height="100%" viewBox={`0 0 ${originalWidth} ${originalHeight}`}>
            {obj.map.areas.map((data, index) => {
              return (
                <Path
                  id={data?.key}
                  stroke={5}
                  fillOpacity={_.includes(value, data.oid) ? 0.4 : 1}
                  vector-effect="non-scaling-stroke"
                  fill={this.checkSelectedValue(data)}
                  onClick={() => this.checkValue(data)}
                  onPress={() => this.checkValue(data)}
                  d={data.coords}
                  accessible={!disabled}
                  testID={`${testID}-${index}-view`}
                />
              )
            })}
          </Svg>
        </View>
      </View>
    )
  }
}
ClickableImageScreen.defaultProps = {
  footerPageY: 0, 
  parentViewDimensions: {},
  hasMultipleQuestionInPage: false,
  onMobile: false,

}
ClickableImageScreen.propTypes = {
  footerPageY: PropTypes.number, 
  parentViewDimensions: PropTypes.instanceOf(Object),
  hasMultipleQuestionInPage: PropTypes.bool,
  onMobile: PropTypes.bool,
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
});
export default ClickableImageScreen
