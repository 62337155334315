import _ from 'lodash'
import { Spinner } from 'native-base'
import { createForm } from 'rc-form'
import React, { Component } from 'react'
import {
  Dimensions,
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { bindActionCreators } from 'redux'
import {
  sendPrintData,
  submitForm
} from '../../actions/form'
import { styles } from '../../components/fields/styles'
import { Authorize } from '../../utils/appPrivileges'
import { disableForm, getSvf } from '../../utils/fieldutils/field'
import { Default } from '../../utils/MatchMedia'
import showToast from '../../utils/toast'
import FormInstruction from '../FormInstructionScreen'
import Popup from '../Popup'
import Field from './Field'
import Header from './Header'
import Info from './Info'
import PrintContent from './print/PrintContent'
import Result from './Result'
import StepLayout from './StepLayout'
import { pushFormDataToQueue } from '../../actions/syncQueue'
import { updateFieldAnswer } from '../../actions/crfData'
import placeFieldValues from '../../utils/fieldutils/fieldDecorator'
import constants from '../../constants/constants'
import { getDeviceStatus } from '../../actions/users'
import DiaryDisplay from './DiaryDisplay'
import PropTypes from 'prop-types'
import { updateSelectedSvf } from '../../actions/session'
import { updateSvfStatus } from '../../actions/studyMetaData'
import { getPreparedFormItems } from '../../selectors/subjectVisitForm'

export class FormConsumer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formData: [],
      isInstructionsRead: false,
      previewFieldId: null,
      errorMessage: null,
      showError: false,
      dialogColor: null,
      currentPage: 0,
    }
    this.fieldRefs = []
  }

  printPdf = () => {
    setTimeout(() => {
      this.printElement.click()
    }, 1000)
  }

  async componentDidMount() {
    const {
      selectedSvf,
      preview,
      previewFieldId,
      mode,
      actions
    } = this.props
    if (preview) {
      this.setState({ previewFieldId: previewFieldId })
      setTimeout(() => {
        console.log(this.fieldRefs[previewFieldId])
        if (this.fieldRefs[previewFieldId] !== undefined) {
          this.fieldRefs[previewFieldId].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 1000)
    }
    this.getFormData(selectedSvf.isFilled, false)
    if (Platform.OS === 'web') {
      window.addEventListener('beforeunload', this.handletabClose)
    }
    if (mode === 'printView') {
      this.printPdf()
    }
    actions.updateSelectedSvf(false)
  }

  componentWillUnmount() {
    this.saveDraftDataToServer()
    if (Platform.OS === 'web') {
      window.removeEventListener('beforeunload', this.handletabClose)
    }
  }

  setCurrentPage = (page) => {
    this.setState((prevState) => ({
      ...prevState,
      currentPage: page,
    }))
  }
  handletabClose = (ev) => {
    const { selectedSvf } = this.props
    if (!disableForm(selectedSvf)) {
      ev.preventDefault()
      ev.returnValue = true
    } else {
      return
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const { fieldGroupList, formAnswers, selectedSvf, mode } = this.props
    const { isInstructionsRead, currentPage } = this.state
    if (
      !_.isEqual(prevProps.fieldGroupList, fieldGroupList) ||
      !_.isEqual(prevProps.formAnswers?.[selectedSvf?.svfId], formAnswers?.[selectedSvf?.svfId]) ||
      !_.isEqual(currentPage, prevState.currentPage) 
    ) {
      this.getFormData(isInstructionsRead, prevState.currentPage < currentPage)
    }
  }



  getFormData = (isInstructionsRead, isNewPage) => {
    const { formContent } = this.props
    this.setState({
      loading: true,
    })
    this.setState({
      formData: formContent,
      loading: false,
      isInstructionsRead,
    })
  }

  handleSubmit = (isFilled) => {
    const {
      subject,
      screenProps: { t },
      updateOfflineSaveLoading,
      fieldList,
      actions
    } = this.props
    this.props.form.validateFields(async (error, values) => {
      if (!error) {
        updateOfflineSaveLoading(true)
        const svf = this.prepareSvfData(isFilled)
        if (getDeviceStatus()) {
          await this.handleFormSubmissionOnline(svf, subject.id, isFilled)
        } else {
          await this.handleFormSubmissionOffline(svf, subject.id, isFilled)
        }
        updateOfflineSaveLoading(false)
      } else {
        const filteredFields = _.filter(fieldList, (fl) => fl.isRequired === true)
        if (!_.isEmpty(filteredFields) && !isFilled) {
          this.showError('FillRequiredFields')
        }
      }
    })
  }

  prepareSvfData = (isFilled) => {
    const { selectedSvf, subjectVisitId, userTimezone, formAnswers, fieldList, subject } = this.props
    let crfData = []
    const filteredFields = _.filter(
      fieldList,
      (fl) => fl.fieldType !== 'result' && fl.fieldType !== 'label'
    )
    _.forEach(filteredFields, (field) => {
      if (formAnswers?.[selectedSvf.svfId]?.[field.fieldOid]) {
        let loCrfdata = placeFieldValues(
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.fieldValue,
          formAnswers[selectedSvf.svfId]?.[field.fieldOid]?.optionOid,
          field,
          selectedSvf.svfId,
          selectedSvf.crfVersionId
        )
        loCrfdata = { ...loCrfdata, locale: subject?.locale }
        crfData.push(
          loCrfdata
        )
      }
    })
    const svf = getSvf(selectedSvf, subjectVisitId, userTimezone, crfData, isFilled)
    svf.locale = subject?.locale
    return svf
  }

  handleFormSubmissionOffline = async (svf, subjectId, isFilled) => {
    const { actions, navigation, screenProps: { t } } = this.props
    actions.pushFormDataToQueue(svf, subjectId, svf.completedDateTime, svf?.crfVersion?.id, svf?.subjectVisit?.id)
    actions.updateSvfStatus(
      svf.id,
      svf.subjectVisit?.id,
      constants.OfflineDataStatus.IN_PROGRESS,
      svf.completedDateTime,
      svf.status
    )
    if (isFilled) {
      setTimeout(() => {
        showToast(t('SavedMessage'), 'success', 3000)
        navigation.goBack()
      }, 3000)
    }
  }

  handleFormSubmissionOnline = async (svf, subjectId, isFilled) => {
    const { actions, updateOfflineSaveLoading, navigation, screenProps: { t } } = this.props
    try {
      const res = await actions.submitForm(svf.crfVersion?.id, svf.subjectVisit?.id, subjectId, svf)
      if (res.status == 200 || res.status == 201 && isFilled) {
        actions.updateSvfStatus(svf.id, svf.subjectVisit?.id, constants.OfflineDataStatus.SUCCESS, svf.completedDateTime, 'COMPLETED')
        actions.updateSelectedSvf(false)
        setTimeout(() => {
          showToast(t('SavedMessage'), 'success', 3000)
          navigation.goBack()
        }, 1000)
      }
    } catch (error) {
      console.log(error)
      updateOfflineSaveLoading(false)
      if (isFilled) {
        showToast(t('FailedMessage'), 'error', 3000)
      }
      actions.pushFormDataToQueue(svf, subjectId, svf.completedDateTime, svf.crfVersion?.id, svf.subjectVisit?.id)
    }
  }

  renderFormItem = (item, footerPageY) => {
    const { screenProps, selectedSvf, form, formAnswers, preview, mode, userTimezone } = this.props
    const { t, locale } = screenProps
    const { isInstructionsRead } = this.state
    switch (item.type) {
      case 'label':
        return <Info title={item.data} isFooter={false} />
      case 'instruction':
        return (
          <FormInstruction
            screenProps={screenProps}
            onNext={() => this.getFormData(true)}
            showOkButton={!isInstructionsRead}
            instruction={item.data}
          />
        )
        break
      case 'field': {
        const fieldAnswer = formAnswers?.[selectedSvf?.svfId]?.[item?.data?.fieldOid] || {}
        return (
          <Field
            {...item}
            t={t}
            form={form}
            selectedSvfId={selectedSvf.svfId}
            fieldAnswer={fieldAnswer}
            mode={mode === 'printView' ? mode : (preview ? 'preview' : '')}
            footerPageY={footerPageY}
            subjectTimezone={userTimezone}
            locale={locale}
          />
        )
        break
      }
      case 'divider': {
        return (
          <View
            style={{
              marginHorizontal: 10,
              marginVertical: 3,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: 'white',
            }}
          ></View>
        )
      }
      case 'result':
        return <Result {...item} t={t}/>
      case 'header':
        return (
          <Header
            options={item.data}
            uiSchema={item.uiSchema}
            selfScored={item.selfScored}
          />
        )
      case 'footer':
        return <Info title={item.data} isFooter={true} />
      default:
        return null
        break
    }
  }

  showError = (errorMessage) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        errorMessage: errorMessage,
        dialogColor: '#0d47a1',
        showError: true,
      }
    })
  }

  closePopup = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        errorMessage: '',
        showError: false,
      }
    })
  }

  renderItem = ({ item }) => {
    if (Platform.OS === 'web') {
      return (
        <div ref={(elem) => (this.fieldRefs[item.key] = elem)}>{this.renderFormItem(item)}</div>
      )
    } else {
      return <View>{this.renderFormItem(item)}</View>
    }
  }

  renderSeparator = () => {
    return (
      <View
        style={{
          height: 1,
          width: StyleSheet.hairlineWidth,
          backgroundColor: 'grey',
        }}
      />
    )
  }
  handlePrint = async (target) => {
    const {
      printOptions,
      formId,
      svfId,
      subjectId,
      visitFormOid,
      isTraining,
      siteName,
      siteId,
      recordNo,
      navigation,
      selectedVisitId,
    } = this.props
    let data = target.contentWindow.document.documentElement.outerHTML
    const printRequest = {
      formId: formId,
      content: '<!DOCTYPE html>' + data,
      header: printOptions.printHeader,
      footer: printOptions.printFooter,
      layout: printOptions.printLayout,
      svfId: svfId,
      subjectId: subjectId,
      visitFormOid: visitFormOid,
      training: isTraining,
      siteName: siteName,
      siteId:
        siteId !== 'null' && siteId !== null && siteId !== '' && siteId !== undefined
          ? siteId
          : null,
      recordNo: recordNo,
      selectedSubjectVisitId:
        selectedVisitId !== 'null' &&
          selectedVisitId !== null &&
          selectedVisitId !== '' &&
          selectedVisitId !== undefined
          ? selectedVisitId
          : null,
      primaryOrgCode: printOptions.primaryOrgCode,
      studyId: printOptions.studyId
    }
    const URL = await sendPrintData(printRequest)
    if (URL) {
      window.location.href = URL
    } else {
      navigation.navigate('PrintFail')
    }
  }

  disableSubmitButton = () => {
    const { selectedSvf } = this.props
    return disableForm(selectedSvf)
  }

  saveDraftDataToServer = (e) => {
    const { isSvfUpdated, selectedSvf } = this.props
    if (isSvfUpdated && !disableForm(selectedSvf)) {
      this.handleSubmit(false)
    }
  }

  render() {
    const { loading, formData, showError, errorMessage, dialogColor, currentPage } = this.state
    const {
      selectedSvf,
      mode,
      screenProps: { t },
      printOptions,
      form,
      fieldList,
      preview,
      onMobile
    } = this.props
    const { training } = selectedSvf
    if (loading) {
      return <Spinner color="#4e89ae" />
    }
    return (
      <View style={{ flex: 1, paddingHorizontal: 5 }}>
        <Popup
          color={dialogColor}
          visible={showError}
          message={t(errorMessage)}
          closePopup={this.closePopup}
          t={t}
        />
        {mode === 'printView' ? (
          <>
            <View
              style={{
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: Dimensions.get('window').height,
              }}
            >
              <Spinner color="#4e89ae" />
              <Text>Generating pdf...</Text>
            </View>
            <View style={{ visibility: 'hidden' }}>
              <ReactToPrint
                print={this.handlePrint}
                trigger={() => {
                  return (
                    <a
                      ref={(link) => (this.printElement = link)}
                      href="#"
                      style={{ display: 'none', fontSize: 20, color: 'black' }}
                    >
                      Print this out!
                    </a>
                  )
                }}
                pageStyle={
                  this.props.svfId
                    ? '@page { size: auto; margin: 150px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                    : '@page { size: auto; margin: 105px 0px 100px 0px; } tr { page-break-inside: avoid;  } .gridHeader { page-break-inside: avoid;  }'
                }
                content={() => this.componentRef}
              />
              <PrintContent
                ref={(el) => (this.componentRef = el)}
                data={formData}
                renderItem={this.renderItem}
                printOptions={printOptions}
              ></PrintContent>
            </View>
          </>
        ) : (
          <>
            <DiaryDisplay preview={preview} t={t}>
              { onMobile ? (
                <StepLayout
                form={form}
                selectedSvf={selectedSvf}
                formData={formData}
                renderFormItem={this.renderFormItem}
                handleSubmit={this.handleSubmit}
                disableSubmitButton={this.disableSubmitButton}
                training={training}
                t={t}
                fieldList={fieldList}
                setCurrentPage={this.setCurrentPage}
                currentPage={currentPage}
              /> ) : (
              <React.Fragment>
                <FlatList
                  initialNumToRender={20}
                  data={formData}
                  renderItem={this.renderItem}
                  keyExtractor={(item) => item.key}
                />
              
              <Authorize hasAnyGrant={["SUBMIT_FORM", "VIEW_SUBJECT"]}>
                <Default>
                  <View style={[styles.submitButtonContainer]}>
                    {this.disableSubmitButton() ? (
                      <Text />
                    ) : (
                      <TouchableOpacity
                        style={styles.submitButton}
                        onPress={() => this.handleSubmit(true)}
                      >
                        <Text
                          style={{
                            fontFamily: 'Inter',
                            alignSelf: 'center',
                            color: '#fff',
                            textTransform: 'uppercase',
                          }}
                        >
                          {training ? t('SubmitTraining') : t('Submit')}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </Default>
              </Authorize>
              </React.Fragment>
              )}
            </DiaryDisplay>
          </>
        )}
      </View>
    )
  }
}

FormConsumer.defaultProps = {
  footerPageY: 0,
}

FormConsumer.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  selectedSvf: PropTypes.instanceOf(Object).isRequired,
  fieldGroupList: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired, 
  userTimezone: PropTypes.string.isRequired, 
  formAnswers: PropTypes.array.isRequired,
  footerPageY: PropTypes.number,
  subjectVisitId: PropTypes.string,
  screenProps: PropTypes.shape({
    t: PropTypes.func,
    locale: PropTypes.string,
  }),
  subject: PropTypes.shape({
    locale: PropTypes.string,
  }),
  isSvfUpdated: PropTypes.bool,
  fieldList: PropTypes.array,
  actions: PropTypes.shape({
    updateSelectedSvf: PropTypes.func,
    pushFormDataToQueue: PropTypes.func,
    updateFieldAnswer: PropTypes.func,
    updateSvfStatus: PropTypes.func,
    submitForm: PropTypes.func,
  }),
  formContent: PropTypes.arrayOf(Object),
} 

const mapStateToProps = (state) => ({
  onMobile: state?.appStatus?.onMobile,
  formContent: getPreparedFormItems(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateSelectedSvf,
      pushFormDataToQueue,
      updateFieldAnswer,
      updateSvfStatus,
      submitForm,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  createForm({
    onValuesChange: (props, changed, all) => {
      const fieldId = Object.keys(changed)[0]
      const selectedFieldValue = changed[fieldId]
      const currentChangedField = _.find(props.fieldList, field => _.isEqual(field.id, fieldId))
      const loChanged = {
        [currentChangedField.fieldOid]: selectedFieldValue
      }
      props.actions.updateFieldAnswer(loChanged, props.selectedSvf.svfId)
    },
    onFieldsChange: (props, changed, all) => {
      props.actions.updateSelectedSvf(true)
    },
  })(FormConsumer)
)