import React, { useEffect, useState } from 'react'
import { Body, Container, Content, Header, Left, Right, Text, Button } from 'native-base'
import {
  Image,
  View,
  Platform,
  FlatList,
  ImageBackground,
  Pressable,
  Modal as AppModal,
  useWindowDimensions,
} from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { uploadImageToBucket } from '../../actions/form'
import _ from 'lodash'
import ImagePlaceholder from '../media/ImagePlaceholder'
import { MaterialIcons } from '@expo/vector-icons'
import { backgroundColor } from '../../containers/NavigationScreens'
import WebModal from 'modal-react-native-web'
const Modal = Platform.OS === 'web' ? WebModal : AppModal
function CaptureImage(props) {
  const {
    field: { crfData, id },
  } = props
  // console.log('crfData', crfData)
  const [imagePath, setImagePath] = useState([])
  const [urls, setUrls] = useState([])
  const [allowPermissions, setAllowPermissions] = useState({
    allow: false,
    isCameraBtnTouched: false,
    cameraText: 'Open Camera',
    allowMedia: false,
    isMediaBtnTouched: false,
  })
  const [modalContent, setModalContent] = useState({ visible: false, selectedImage: '' })
  const [height, setHeight] = useState(0)
  const { height: winHeight, width: winWidth } = useWindowDimensions()

  useEffect(() => {
    ;(async () => {
      await requestCameraAccess()
    })()
  }, [])

  useEffect(() => {
    if (crfData && crfData.fieldValue && !_.isEmpty(JSON.parse(crfData.fieldValue))) {
      setImagePath(JSON.parse(crfData.fieldValue))
    }
  }, [])

  useEffect(() => {
    onImageUploaded()
  }, [JSON.stringify(urls)])

  // const deviceStyles =
  //   Platform.OS === 'web'
  //     ? { flex: 1, alignItems: 'center', justifyContent: 'center' }
  //     : { flex: 1, flexDirection: 'row', justifyContent: 'space-around' }

  //uploading an image
  const pickImage = async () => {
    // await requestCameraAccess()
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: false,
    })
    if (!result.cancelled) {
      await uploadImage(result)
    }
  }
  const uploadImage = async (result) => {
    setImagePath((prevImagePath) => [...prevImagePath, result.uri])
    const formData = new FormData()

    if (Platform.OS === 'web') {
      const res = await fetch(result.uri)
      const blob = await res.blob()
      formData.append('photo', blob)
      const extension = result.uri.split(';')[0].split('/')[1]
      formData.append('extension', extension)
    } else {
      formData.append('photo', { uri: result.uri, name: 'photo.jpg', type: 'image/jpg' })
      formData.append('extension', result.uri.split('.').pop())
    }

    const URL = await uploadImageToBucket(formData)
    console.log('URL', URL)
    if (URL) {
      console.log('uploaded successfully!!!')
      setUrls((prevUrls) => [...prevUrls, URL])
    } else {
      alert('Failed to upload')
    }
  }
  const onImageUploaded = () => {
    const {
      field: { id },
      form,
    } = props
    const { setFieldsValue } = form
    if (!_.isEmpty(urls)) {
      setFieldsValue({
        [id]: JSON.stringify(urls),
      })
    }
  }

  const requestCameraAccess = async () => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        setAllowPermissions({
          ...allowPermissions,
          allowMedia: false,
          isMediaBtnTouched: true,
        })
        alert('Sorry, we need gallery permissions to make this work!')
      }
      const { status: cameraStatus } = await ImagePicker.getCameraPermissionsAsync()
      if (cameraStatus !== 'granted') {
        const { status: permission } = await ImagePicker.requestCameraPermissionsAsync()
        if (permission !== 'granted') {
          setAllowPermissions({
            ...allowPermissions,
            allow: false,
            isCameraBtnTouched: true,
          })
          console.log('Sorry, we need camera permissions to make this work!')
        }
      }
    }
  }

  // //Using Camera
  const openCamera = async () => {
    const result = await ImagePicker.launchCameraAsync()
    // console.log(result)
    if (!result.cancelled) {
      // setImagePath((prevImagePath) => [...prevImagePath, result.uri])
      await uploadImage(result)
    }
  }
  const handleDelete = (image) => {
    console.log('deleting image', image)
    const newImagePaths = imagePath.filter((item) => item !== image)
    setImagePath(newImagePaths)
  }
  const openImageInBigWindow = (uri) => {
    console.log('opening image', uri)
    setModalContent({
      ...modalContent,
      visible: true,
      selectedImage: uri,
    })
  }
  const hideModal = () => {
    setModalContent({
      ...modalContent,
      visible: false,
      selectedImage: '',
    })
  }

  return (
    <React.Fragment>
      {props.form.getFieldDecorator(id, {
        initialValue: null,
      })(
        <React.Fragment>
          {_.chunk(imagePath, 2).map((images, index) => (
            <View
              key={index}
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 3,
                justifyContent: 'center',
              }}
            >
              {images.map((image, index) => (
                <React.Fragment key={`image-${index}`}>
                  <Pressable
                    accessible={true}
                    testID={`${id}-image-bigwindow-btn`}
                    onPress={() => openImageInBigWindow(image)}
                  >
                    <ImageBackground
                      source={Platform.OS == 'web' ? image : { uri: image }}
                      style={{ width: 180, height: 180, marginLeft: 3 }}
                    >
                      <Pressable
                        accessible={true}
                        testID={`${id}-image-delete-btn`}
                        onPress={() => handleDelete(image)}
                      >
                        <MaterialIcons
                          name="delete-forever"
                          size={30}
                          color="white"
                          style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'red' }}
                        />
                      </Pressable>
                    </ImageBackground>
                  </Pressable>
                </React.Fragment>
              ))}
            </View>
          ))}
        </React.Fragment>
      )}
      <View
        style={{
          padding: 3,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      ></View>
      <View style={{ height: 100, padding: 3 }}>
        <ImagePlaceholder
          onPress={Platform.OS === 'web' ? pickImage : openCamera}
          testID={`${id}-choose-image-view`}
        />
      </View>
      {/* <View style={deviceStyles}>
        <Button title="Upload image" onPress={pickImage} />
        {Platform.OS !== 'web' && <Button title="Allow Camera" onPress={openCamera} />}
      </View> */}
      {!allowPermissions.allow && allowPermissions.isCameraBtnTouched && (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ color: 'red', marginTop: 10 }}>
            You've refused to allow this app to access your camera!
          </Text>
        </View>
      )}

      <View>
        {Platform.OS === 'web' && (
          <Text style={{ color: 'orange', fontSize: 14, textAlign: 'center', marginTop: 50 }}>
            Camera feature is not available on Web. You can upload images from your computer instead
          </Text>
        )}
      </View>
      <Modal transparent={true} animationType="slide" visible={!!modalContent.selectedImage}>
        <Header style={{ backgroundColor: backgroundColor }}>
          <Left style={{ alignContent: 'flex-start', width: 10 }}>
            <Pressable accessible={true} testID={`${id}-image-modal-btn`} onPress={hideModal}>
              <Text>
                <MaterialIcons name="arrow-back" color="white" size={24} />
              </Text>
            </Pressable>
          </Left>
          <Body style={{ alignContent: 'flex-start' }}></Body>
          <Right></Right>
        </Header>
        <View style={{ flex: 1, backgroundColor: 'white', marginTop: 10 }}>
          <Image
            source={{ uri: modalContent.selectedImage }}
            style={{
              width: winWidth,
              height: 500,
            }}
          />
        </View>
      </Modal>
    </React.Fragment>
  )
}

export default CaptureImage
