import _ from 'lodash'
import { Container } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'

class Biometrics extends Component {
  state = {}

  captalize = (name) => {
    return _.startCase(name)
  }

  render() {
    const {
      BiometricsData,
      screenProps: { t },
    } = this.props
    return (
      <Container style={{ backgroundColor: '#ffffff' }}>
        <NavigationEvents
          onWillFocus={() => {
            // BackHandler.addEventListener('hardwareBackPress',() => false )
          }}
        />

        <View
          style={{
            flex: 1,
            padding: 10,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            backgroundColor: 'white',
          }}
        >
          <View style={{ flex: 2, padding: 5, borderRadius: 10, width: '100%' }}>
            <View style={{ padding: 20, justifyContent: 'center', paddingTop: 15 }}>
              <Text
                style={{
                  fontSize: 70,
                  textAlign: 'center',
                  color: '#00e676',
                  fontFamily: 'Inter',
                }}
              >
                {Math.round(BiometricsData.bmi)}
                <Text style={{ fontSize: 20 }}> kg/m²</Text>
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                }}
              >
                BMI{' '}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  color: 'black',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                }}
              >
                ({t('bmi')})
              </Text>
            </View>
          </View>
          <View style={{ flex: 1, borderRadius: 10, flexDirection: 'row' }}>
            <View
              style={{
                padding: 15,
                width: '50%',
                backgroundColor: 'white',
                borderRadius: 10,
                justifyContent: 'center',
              }}
            >
              <View style={{ justifyContent: 'center' }}>
                <Text
                  style={{
                    fontSize: 35,
                    textAlign: 'center',
                    padding: 10,
                    color: '#00b0ff',
                    fontFamily: 'Inter',
                  }}
                >
                  {Math.round(BiometricsData.height)}{' '}
                  <Text style={{ fontWeight: 'normal', fontSize: 18 }}>cms</Text>
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Inter',
                  }}
                >
                  {t('Height')}
                </Text>
              </View>
            </View>
            <View
              style={{
                padding: 15,
                width: '50%',
                backgroundColor: 'white',
                borderRadius: 10,
                justifyContent: 'center',
              }}
            >
              <View style={{ justifyContent: 'center' }}>
                <Text
                  style={{
                    fontSize: 35,
                    textAlign: 'center',
                    padding: 10,
                    color: '#00e5ff',
                    fontFamily: 'Inter',
                  }}
                >
                  {Math.round(BiometricsData.weight)}{' '}
                  <Text style={{ fontWeight: 'normal', fontSize: 18 }}>kg</Text>
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Inter',
                  }}
                >
                  {t('Weight')}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flex: 1, borderRadius: 10, flexDirection: 'row' }}>
            <View
              style={{
                padding: 15,
                width: '50%',
                backgroundColor: 'white',
                borderRadius: 10,
                justifyContent: 'center',
              }}
            >
              <View style={{ justifyContent: 'center' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 35,
                    paddingTop: 20,
                    color: '#ffc400',
                    fontFamily: 'Inter',
                  }}
                >
                  {Math.round(BiometricsData.leanBodyMass)}{' '}
                  <Text style={{ fontWeight: 'normal', fontSize: 18 }}>kg</Text>
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Inter',
                  }}
                >
                  {t('LeanBodyMass')}
                </Text>
              </View>
            </View>
            <View
              style={{
                padding: 15,
                width: '50%',
                backgroundColor: 'white',
                borderRadius: 10,
                justifyContent: 'center',
              }}
            >
              <View style={{ justifyContent: 'center' }}>
                <Text
                  style={{
                    fontSize: 35,
                    textAlign: 'center',
                    paddingTop: 20,
                    color: '#ff9100',
                    fontFamily: 'Inter',
                  }}
                >
                  {Math.round(BiometricsData.bodyFat)} <Text style={{ fontSize: 18 }}>%</Text>
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Inter',
                  }}
                >
                  {t('BodyFat')}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flex: 1, borderRadius: 10, flexDirection: 'row' }}>
            <View
              style={{
                padding: 15,
                width: '50%',
                backgroundColor: 'white',
                borderRadius: 10,
                justifyContent: 'center',
              }}
            >
              <View style={{ justifyContent: 'center' }}>
                <Text
                  style={{
                    textAlign: 'right',
                    fontSize: 35,
                    paddingTop: 10,
                    color: '#ff8a65',
                    fontFamily: 'Inter',
                  }}
                >
                  {this.captalize(BiometricsData.sex)}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    paddingBottom: 15,
                    textAlign: 'center',
                    color: 'black',
                    fontFamily: 'Inter',
                  }}
                >
                  {t('Sex')}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Container>
    )
  }
}

export default Biometrics
