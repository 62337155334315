import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import RenderHtml from 'react-native-render-html'
import { styles } from '../../components/fields/styles'
import fieldTypes from '../../constants/fieldTypes'

const boldStyle = { fontWeight: '500' }
const italicStyle = { fontStyle: 'italic' }
const underlineStyle = { textDecorationLine: 'underline' }
const strikethroughStyle = { textDecorationLine: 'line-through' }
const tagsStyles = {
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  ins: underlineStyle,
  s: strikethroughStyle,
  strike: strikethroughStyle,
  a: {
    fontWeight: '500',
    color: '#007AFF',
  },
  h1: { fontWeight: '500', fontSize: 36 },
  h2: { fontWeight: '500', fontSize: 30 },
  h3: { fontWeight: '500', fontSize: 24 },
  h4: { fontWeight: '500', fontSize: 18 },
  h5: { fontWeight: '500', fontSize: 14 },
  h6: { fontWeight: '500', fontSize: 12 },
  sub: { fontSize: 12, lineHeight: 17 },
  sup: { fontSize: 12, lineHeight: 50 },
  p: { fontSize: 22 },
}
export const wrapHidableView = (fieldComponent, field, currentOrdinal) => {
  let scrollEnabled = true
  let fieldViewStyle = {}
  const { PS, NRS, VAS } = fieldTypes
  if (field.fieldType === PS || field.fieldType === NRS || field.fieldType === VAS) {
    scrollEnabled = false
    fieldViewStyle = { flex: 1 }
  }
  const source = {
    html: field.fieldName,
  }
  return (
    <View
      // duration={150}
      style={[
        styles.animatedView,
        {
          zIndex: field.ordinal === currentOrdinal ? 6 : 0,
          display: field.ordinal === currentOrdinal ? 'flex' : 'none',
        },
      ]}
      // visible={field.ordinal === currentOrdinal ? true : false}
      key={field.id}
    >
      <ScrollView scrollEnabled={scrollEnabled} contentContainerStyle={[styles.fieldContainer]}>
        <View style={styles.htmlContainer}>
          <RenderHtml tagsStyles={tagsStyles} source={source} />
        </View>
        {(field.location === 'ABOVE' || field.location === null) && (
          <View style={styles.textContainer}>
            {field.instruction && (
              <RenderHtml
                tagsStyles={{
                  ...tagsStyles,
                  p: {
                    fontSize: 16,
                    color: '#696969',
                  },
                }}
                source={{ html: field.instruction }}
              />
            )}
          </View>
        )}
        <View style={fieldViewStyle}>{fieldComponent}</View>

        {field.location === 'BELOW' && (
          <View style={[styles.textContainer, { marginBottom: 0, paddingTop: 10 }]}>
            {field.instruction && (
              <RenderHtml
                tagsStyles={{
                  ...tagsStyles,
                  p: {
                    fontSize: 16,
                    color: '#696969',
                  },
                }}
                source={{ html: field.instruction }}
              />
            )}
          </View>
        )}
      </ScrollView>
    </View>
  )
}

const styls = StyleSheet.create({
  b: boldStyle,
  strong: boldStyle,
  i: italicStyle,
  em: italicStyle,
  ins: underlineStyle,
  s: strikethroughStyle,
  strike: strikethroughStyle,
  a: {
    fontWeight: '500',
    color: '#007AFF',
  },
  h1: { fontWeight: '500', fontSize: 36 },
  h2: { fontWeight: '500', fontSize: 30 },
  h3: { fontWeight: '500', fontSize: 24 },
  h4: { fontWeight: '500', fontSize: 18 },
  h5: { fontWeight: '500', fontSize: 14 },
  h6: { fontWeight: '500', fontSize: 12 },
  sub: { fontSize: 12, lineHeight: 17 },
  sup: { fontSize: 12, lineHeight: 50 },
})
