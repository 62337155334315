import React, { useEffect, useState } from "react";
import { checkTextFit, getFontSizeCalculated, isLabelsDataPrepared, prepareLabelsMetaData } from "./VerticalLabelUtils";
import VerticalScaleContainer from "./VerticalScaleContainer";
import { getLabelsFlex } from "../../scales/utils";
import { PixelRatio, View } from "react-native";
import PropTypes from 'prop-types';
import constants from "../../../constants/constants";
import _ from 'lodash'
import { alignments } from "../../scales/alignments";

const {SCALE_POSITION} = constants
const VerticalScaleContainerWrapper = (props) => {
    const {min, max, step, options, scaleProps, scaleVerticalAlignment, orientation} = props
    const {widthOfBox} = scaleProps
    const [labelsData, setLabelsData] = useState({})
    const labelsFlex = getLabelsFlex(options, scaleVerticalAlignment);

    const setBasicLabelsDetails = (data) => {
      setLabelsData(data)
     }

    useEffect(() => {
        prepareLabelsMetaData({
            start: min, end: max, step, labelsData, options, widthOfBox, labelsFlex, isVAS: true
        }, setBasicLabelsDetails)   
   }, [options])

   const setLabelsDetails = (data) => {
    if(_.isEmpty(_.filter(data, ele => !_.isNumber(ele?.fontSize) && !_.isBoolean(ele?.fit)))){  
      setLabelsData(data)
    }
   }

   const textStyles = {textAlign: 'center'}

    return (
        <View>
           {/* -------------checking fontsize is available or not */}
           {!isLabelsDataPrepared(labelsData) &&  checkTextFit({start: min,end: max, step, labelsData, defaultLabelWidth: 0, defaultlabelHeight: 0, labels: options, textStyles}, setLabelsDetails)}
            <VerticalScaleContainer 
              {...props}
              labelsData={labelsData}
              labelsFlex={labelsFlex}
              minimumFontSize = {getFontSizeCalculated(labelsData, options, orientation).minFont}
              isCalculated={getFontSizeCalculated(labelsData, options, orientation).isCalculated}
         
            /> 
           
        </View>

    )

}


VerticalScaleContainerWrapper.defaultProps = {
    step: 1,
    min: 0,
    max: 10,
    options: [],
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
      },
      scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE, 
      orientation: alignments.VERTICAL,
  }
  
  VerticalScaleContainerWrapper.propTypes = {
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    options: PropTypes.arrayOf(Object),
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
      }),
    scaleVerticalAlignment: PropTypes.string,  
    orientation: PropTypes.string,
   
}

export default VerticalScaleContainerWrapper