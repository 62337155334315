import React, { Component } from 'react'
import { connect } from 'react-redux'
import CallHistory from '../../components/scheduledCalls/callHistory'
import { getSubjectId } from '../../selectors/subject'
import { getSelectedCall } from '../../selectors/session'
import { getUserTimezone } from '../../selectors/commonSelector'

class CallHistoryPage extends Component {
  state = {}

  render() {
    const { navigation, selectedCall } = this.props
    return <CallHistory {...this.props} call={selectedCall} />
  }
}

const mapStateToProps = (state) => ({
  selectedCall: getSelectedCall(state),
  subjectId: getSubjectId(state),
  timeZone: getUserTimezone(state)
})

export default connect(mapStateToProps)(CallHistoryPage)
