import React, { Component } from 'react'
import { Container, ListItem, Item, Input, Body, Icon } from 'native-base'
import PropTypes from 'prop-types'
import { View, FlatList, ActivityIndicator, Text } from 'react-native'
import styles from '../../styles/listStyles'

class StudyList extends Component {
  constructor(props) {
    super(props)
  }


  renderList = () => {
    const {list, onStudySelect} = this.props
    if(list.length > 0){
   /* ------------- Render list ------------------------ */
      return (
        <FlatList
            data={list}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
              <ListItem
                thumbnail
                onPress={() => {
                  onStudySelect(item)
                }}
              >
                <Body>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <Text style={styles.textStyle}>{item.studyNumber}</Text>
                    <Text style={styles.subTextStyle}>Sponsor : {item.sponsorName}</Text>
                  </View>
                </Body>
              </ListItem>
            )}
          />
      )
    }else{
      /* ------------- Render No data ------------------------ */
      return (
        <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={[styles.textStyle, { textAlign: 'center' }]}>No data.</Text>
          </View>
      )

    }
  }
  render() {
    const { loading, onSearchTerm, searchTerm } = this.props

    return (
      <Container style={styles.containerStyle}>
        {/* ------------- search bar ------------------------ */}
        <View style={{ margin: 10 }}>
          <Item searchBar rounded>
            <Icon name="ios-search" style={{ padding: 2 }} />
            <Input
              placeholder="Search"
              style={styles.textStyle}
              autoCorrect={false}
              value={searchTerm}
              onChangeText={(term) => {
                onSearchTerm(term)
              }}
            />
          </Item>
        </View>

        {/* ------------- Loading ------------------------ */}
        {loading ? (
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} />
        ) : this.renderList()}
      </Container>
    )
  }
}

StudyList.defaultProps = {
  list: [],
  onStudySelect: () => null,
  loading: false,
  onSearchTerm: ()=> null,
  searchTerm: null,
}
StudyList.propTypes = {
  list: PropTypes.arrayOf(Object),
  onStudySelect: PropTypes.func,
  loading: PropTypes.bool,
  onSearchTerm: PropTypes.func,
  searchTerm: PropTypes.string,
}

export default StudyList
