import { Body, Card, CardItem } from 'native-base'
import React from 'react'
import { Text, View } from 'react-native'
import Hyperlink from 'react-native-hyperlink'
import styles from '../components/styles/PushNotificationStyles'
import api from './api'
import SubjectTimezone from './dateFormatUtils'
import constants from '../constants/constants'

export const buildMessage = (pn, openCallLink) => {
  return (
    <Card
      key={pn.id}
      style={styles.cardBorder}
      testID={`push-notifications-${pn.id}-card`}
      accessible={true}
    >
      <CardItem style={styles.cardBorder}>
        <Body>
          <View>
            <View style={styles.bottomPadder}>
              <Text style={styles.headerText}>{pn.title}</Text>
            </View>
          </View>
          <View style={styles.bottomPadder}>
            <Hyperlink
              // linkStyle={}
              linkText={(url) => (
                <Text
                  selectable
                  style={{ color: '#03a9f4', fontSize: 14, textDecorationLine: 'underline' }}
                >
                  {url}
                </Text>
              )}
              onPress={(url, text) => openCallLink(url)}
            >
              <Text selectable style={styles.messageText}>
                {pn.message}
              </Text>
            </Hyperlink>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={[styles.timeContainer, { justifyContent: 'flex-end' }]}>
              {/* <Text style={[styles.time,]}>Read</Text><MaterialCommunityIcons
                  style={{flex: 1, alignSelf: 'flex-end'}}
                  name="clock"
                /> */}
              <Text style={[styles.time]}>
                {/* {localeStore.MessageRead}  */}
                <SubjectTimezone dateTime={pn.sentTime || pn.createdTs} />
              </Text>
            </View>
          </View>
        </Body>
      </CardItem>
    </Card>
  )
}

export const retrievePushNotifications = (subjectId, action, t) => {
  return new Promise(async (resolve, reject) => {
    try {
      action.rmr()
      const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/pushNotifications`)
      action.rms()
      return resolve(res.data)
    } catch (error) {
      const message = t('FailedRetrieveMsgs')
      action.rmf(message)
      return reject(error)
    }
  })
}

export const updateReadStatus = async (subjectId) => {
  try {
    await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/pushNotifications`)
  } catch (error) {
    console.log(error)
  }
}
