import _ from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import {
  addMessageToDeleteAction,
  addMessageToSelectedChat,
  clearUnReadCount,
  reduceUnReadChatsBy1,
} from '../actions/chat'
import ChatList from '../components/chat/chatList'
import {
  addMessage,
  addParticipant,
  removeMessage,
  removeParticipant,
} from '../components/chat/utils'
import properties from '../constants/appConstants'
import api from '../utils/api'
import { serializeQuery } from '../utils/paginationUtils'
import showToast from '../utils/toast'
import constants from '../constants/constants'
import { clearSelectedChat, setSelectedChat } from '../actions/session'
import { getUserSession, getSubject, getUser } from '../selectors/commonSelector'
import { getUserChat } from '../selectors/chat'
import { format } from '../utils/stringUtils'
import { CHAT_SUBSCRIPTION } from '../components/Socket/constants'
import { decryptString } from '../utils/cryptoUtil'
import { storage } from '../utils/storage'
import PropTypes from 'prop-types'

class ChatListPage extends Component {
  state = {
    chats: [],
    chatPage: 0,
    loading: false,
    loadingMore: false,
    selectedChat: {},
    page: 0,
  }

  subscription = null
  stompClient = null

  componentDidMount() {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline) {
      this.connectSocket()
      this.retrieveChats()
    }
  }

  connectSocket = async () => {
    const {
      subject: { id }, user,
    } = this.props
    var stompClient = null
    var socket = new SockJS(properties.socketUrl)
    stompClient = Stomp.over(socket)
    const headers = {
      Authorization: `${decryptString(storage.getString(user?.id + 'SubjectAuthorizationToken'))}`
    }
    if (stompClient) {
      stompClient.connect(headers, () => {
        this.subscription = stompClient.subscribe(
          format(CHAT_SUBSCRIPTION, id),
          (message) => {
            this.onMessageReceived(message)
          },
          headers
        )
      })
    }
  }

  componentWillUnmount = () => {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect()
    }
  }
  onMessageReceived = (msg) => {
    const {
      subject: { id: subjectId },
      setSelectedChat: loSetSelectedChat,
      addMessageToSelectedChat: loAddMessage,
      navigation,
      addMessageToDeleteAction: loAddMessageToDelete,
      selectedChat,
      clearUnReadCount,
    } = this.props
    const {
      //   selectedChat,
      chats,
    } = this.state
    const message = JSON.parse(msg.body)
    switch (message.eventType) {
      case 'MESSAGE': {
        const obj = addMessage({
          message: { ...message },
          selectedChatId: selectedChat.id,
          chats: [...chats],
          userId: subjectId,
        })
        this.setState((prevState) => ({
          ...prevState,
          chats: obj.chats,
        }))
        if (
          _.isEqual(
            _.toUpper(selectedChat.id),
            _.toUpper(_.get(obj.ezProChatMessage, 'ezProChat.id'))
          )
        ) {
          loAddMessage(obj.ezProChatMessage)
          loSetSelectedChat(
            _.find(chats, (ch) => _.isEqual(_.toUpper(ch.id), _.toUpper(selectedChat.id)))
          )
          navigation.setParams({
            selectedChat: _.find(chats, (ch) =>
              _.isEqual(_.toUpper(ch.id), _.toUpper(selectedChat.id))
            ),
          })
        }
        if (message.chatId === selectedChat.id) {
          clearUnReadCount(true)
        }
        break
      }
      case 'PARTICIPANT_ADDED': {
        const obj = addParticipant({
          message: { ...message },
          chats: [...chats],
          userId: subjectId,
          selectedChatId: selectedChat.id,
        })
        this.setState((prevState) => ({
          ...prevState,
          chats: obj.chats,
          selectedChat: obj.selectedChat || {},
        }))
        loSetSelectedChat(obj.selectedChat || {})
        if (message.chatId === selectedChat.id) {
          clearUnReadCount(true)
        }
        navigation.setParams({
          selectedChat: obj.selectedChat || {},
        })
        break
      }
      case 'PARTICIPANT_REMOVED': {
        const obj = removeParticipant({
          message: { ...message },
          chats: [...chats],
          userId: subjectId,
          selectedChatId: selectedChat.id,
        })
        this.setState((prevState) => ({
          ...prevState,
          chats: obj.chats,
        }))
        if (_.isEqual(_.toUpper(subjectId), _.toUpper(_.get(obj.participant, 'participantPkId')))) {
          loSetSelectedChat({})
          navigation.setParams({
            selectedChat: {},
          })
        }
        if (_.isEqual(_.toUpper(selectedChat.id), _.toUpper(_.get(message, 'chatId')))) {
          loSetSelectedChat(
            _.find(obj.chats, (ch) => _.isEqual(_.toUpper(ch.id), _.toUpper(selectedChat.id)))
          )
          navigation.setParams({
            selectedChat: _.find(obj.chats, (ch) =>
              _.isEqual(_.toUpper(ch.id), _.toUpper(selectedChat.id))
            ),
          })
        }
        if (message.chatId === selectedChat.id) {
          clearUnReadCount(true)
        }
        break
      }
      case 'MESSAGE_DELETED': {
        const obj = removeMessage({
          message: { ...message },
          selectedChatId: selectedChat.id,
          chats: [...chats],
          userId: subjectId,
        })
        this.setState((prevState) => ({
          ...prevState,
          chats: obj.chats,
        }))
        if (
          _.isEqual(
            _.toUpper(selectedChat.id),
            _.toUpper(_.get(obj.ezProChatMessage, 'ezProChat.id'))
          )
        ) {
          loAddMessageToDelete(message.ezProChatMessage)
        }
        if (message.chatId === selectedChat.id) {
          clearUnReadCount(true)
        }
        break
      }
      default:
        break
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isDeviceOnline,
      selectedChat: { id },
      canClearUnreadCountOfSelectedChat,
    } = this.props
    if (isDeviceOnline !== prevProps.isDeviceOnline && isDeviceOnline) {
      this.retrieveChats()
    }
    if (id && canClearUnreadCountOfSelectedChat) {
      this.updateLastSeen(id)
    }
  }

  updateUnreadCount = (id) => {
    const { chats } = this.state
    const { reduceUnReadChatsBy1: loReduceUnReadChatsBy1, unreadChats } = this.props
    if (unreadChats.count > 0) {
      const loUnreadChatIds = [...unreadChats.unreadChatIds]
      if (_.includes(loUnreadChatIds, id));
      _.remove(loUnreadChatIds, (chatId) => id === chatId)
      loReduceUnReadChatsBy1({ unreadChatIds: loUnreadChatIds, count: loUnreadChatIds.length })
    }
    const loChats = [...chats]
    const chat = _.find(loChats, (ch) => _.isEqual(ch.id, id))
    chat.unRead = 0
    this.setState((prevState) => ({
      ...prevState,
      chats,
    }))
  }

  updateLastSeen = async (selectedChatId) => {
    const {
      subject: { id },
      updateUnreadCount,
      clearUnReadCount,
    } = this.props
    clearUnReadCount(false)
    const ezProChatParticipant = {
      ezProChat: {
        id: selectedChatId,
      },
      participantPkId: id,
      lastSeenDate: moment().utc(),
    }

    try {
      await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${selectedChatId}/participants/${constants.ContextProperties.SUBJECT_ID}`, ezProChatParticipant)
      this.updateUnreadCount(selectedChatId)
    } catch (error) {
      console.log(error)
    }
  }

  setSelectedChatAndNavigate = (id) => {
    const { chats } = this.state
    const { navigation, setSelectedChat: loSetSelectedChat } = this.props
    const selectedChat = _.find(chats, (ch) => _.isEqual(_.toUpper(id), _.toUpper(ch.id)))
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedChat,
      }),
      () => {
        navigation.navigate('Conversation', {
          selectedChat,
          // updateLastSeen: (selectedChatId) => this.updateLastSeen(selectedChatId),
        })
      }
    )
    loSetSelectedChat(selectedChat)
  }

  fetchMoreChats = () => {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline) {
      this.setState(
        (prevState) => ({
          chatPage: prevState.chatPage + 1,
          page: prevState.page + 1,
          loadingMore: true,
        }),
        () => {
          this.retrieveChats()
        }
      )
    }
  }

  retrieveChats = async (isRefresh) => {
    const {
      subject: { id: subjectId },
      screenProps: { t },
    } = this.props
    const { page, loadingMore } = this.state
    const paginationParameters = {
      size: 15,
      page: isRefresh ? 0 : page,
    }

    try {
      if (!loadingMore) {
        this.setState({
          loading: true,
        })
      }
      const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats?subjectId=${subjectId}&${serializeQuery(paginationParameters)}`)
      if (res.data) {
        const chats = res.data.content
        this.setState((prevState) => ({
          chats: isRefresh ? chats : [...prevState.chats, ...chats],
        }))
      }
      this.setState({
        loading: false,
        loadingMore: false,
      })
    } catch (error) {
      console.log(error)
      showToast(t('FailedRetrieve'), 'danger', 2000)
      this.setState({
        loading: false,
        loadingMore: false,
      })
    }
  }

  refresh = () => {
    const { isDeviceOnline } = this.props
    if (isDeviceOnline) {
      this.setState({
        page: 0,
      })
      this.retrieveChats(true)
    }
  }

  render() {
    const {
      screenProps,
      navigation,
      isDeviceOnline,
      clearSelectedChat,
      subject: { timeZone, id },
    } = this.props
    const { loading, loadingMore } = this.state
    let { chats } = this.state
    let emptyMessage = screenProps.t('NoChats')
    if (!isDeviceOnline) {
      chats = []
      emptyMessage = screenProps.t('NoInternet')
    }

    return (
      <ChatList
        navigation={navigation}
        screenProps={screenProps}
        chats={chats}
        fetchMoreChats={this.fetchMoreChats}
        loading={loading}
        loadingMore={loadingMore}
        refresh={this.refresh}
        noChatsMessage={emptyMessage}
        timeZone={timeZone}
        setSelectedChatAndNavigate={this.setSelectedChatAndNavigate}
        clearSelectedChat={clearSelectedChat}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  subject: getSubject(state),
  isDeviceOnline: state.appStatus.isDeviceOnline,
  unreadChats: _.get(getUserChat(state), 'unreadChats', []),
  selectedChat: _.get(getUserSession(state), 'selectedChat', {}),
  canClearUnreadCountOfSelectedChat: _.get(getUserChat(state), 'canClearUnreadCountOfSelectedChat', false),
  user: getUser(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedChat,
      addMessageToSelectedChat,
      reduceUnReadChatsBy1,
      addMessageToDeleteAction,
      clearSelectedChat,
      clearUnReadCount,
    },
    dispatch
  )

  ChatListPage.defaultProps = {
  subject: {},
  screenProps: {},
  navigation: {},
  isDeviceOnline: false,
  clearSelectedChat: () => {},
  setSelectedChat: () => {},
  addMessageToSelectedChat: () => {},
  reduceUnReadChatsBy1: () => {},
  addMessageToDeleteAction: () => {},
  unreadChats: {},
  selectedChat: {},
  canClearUnreadCountOfSelectedChat: false,
  user: {},
  clearUnReadCount: () => {},
  }
ChatListPage.propTypes = {
  subject: PropTypes.object,
  screenProps: PropTypes.object,
  navigation: PropTypes.object,
  isDeviceOnline: PropTypes.bool,
  clearSelectedChat: PropTypes.func,
  setSelectedChat: PropTypes.func,
  addMessageToSelectedChat: PropTypes.func,
  reduceUnReadChatsBy1: PropTypes.func,
  addMessageToDeleteAction: PropTypes.func,
  unreadChats: PropTypes.object,
  selectedChat: PropTypes.object,
  canClearUnreadCountOfSelectedChat: PropTypes.bool,
  user: PropTypes.object,
  clearUnReadCount: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatListPage)
