import {
  PUSH_FORM_DATA_TO_QUEUE,
  STORE_SYNC_QUEUE_DATA,
  UPDATE_FORM_STATUS_IN_QUEUE,
  REMOVE_FROM_QUEUE,
  STORE_SYNC_STATUS,
  UPDATE_SYNC_COMPLETE_STATUS,
} from '../actions/syncQueue'
import initialState from '../store/initialState'
import _ from 'lodash'

export default (state = initialState.syncQueue, action) => {
  switch (action.type) {
    case PUSH_FORM_DATA_TO_QUEUE:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          data: {
            ...state?.[action.userId]?.data,
            [new Date().valueOf()]: {
              ...action.queueItem, 
            }
          }
        }
      }
    case STORE_SYNC_QUEUE_DATA:
      return {
        ...state,
        ...action.data,
      }
    case UPDATE_FORM_STATUS_IN_QUEUE:
      return !_.isEmpty(state?.[action.userId]?.data?.[action.key]) ? {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          data: {
            ...state?.[action.userId]?.data,
            [action.key]: {
              ...state?.[action.userId]?.data?.[action.key],
              status: action.status,
            }
          }
        } 
      
      } : { ...state }
    case REMOVE_FROM_QUEUE: {
      delete state[action.userId].data?.[action.key]
      return {
        ...state,
      }
    }
    case STORE_SYNC_STATUS:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          isSyncing: action.isSyncing,
        }
      }  
    case UPDATE_SYNC_COMPLETE_STATUS:
        return { ...state, 
          [action.userId]: {
            ...state?.[action.userId],
          syncCompleted: action.value }
        }
    default:
      return state
  }
}
