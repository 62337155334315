import constants from '../constants/constants';
import api from '../utils/api'

export const handlePrivacyPolicyAgreement = (request, studyId) => async dispatch => {
    try {
        const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${request.participantId}/privacyPolicyAndTerms`, request);
        return { ...res.data}
    } catch (error) {
        console.log(error)
        throw error;
    }
}