import { Entypo, FontAwesome } from '@expo/vector-icons'
import { Card, Container } from 'native-base'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'

class Vitals extends Component {
  state = {}

  render() {
    const {
      subjectHealthData,
      screenProps: { t },
    } = this.props
    return (
      <Container style={{ backgroundColor: '#ffffff' }}>
        <NavigationEvents
          onWillFocus={() => {
            // BackHandler.addEventListener('hardwareBackPress',() => false )
          }}
        />

        <View
          style={{
            flex: 1,
            padding: 10,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <Card transparent style={{ padding: 10, borderRadius: 10, backgroundColor: 'white' }}>
            <View>
              <FontAwesome
                name="heartbeat"
                size={150}
                color="#ff5252"
                style={{ alignSelf: 'center' }}
                // style={{ transform: [{ rotateZ: "-69deg" }] }}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                padding: 20,
                justifyContent: 'space-between',
                paddingTop: 15,
              }}
            >
              <View>
                <Text style={{ textAlign: 'center', fontFamily: 'Inter' }}>{t('HeartRate')}</Text>
                <Text style={{ fontSize: 28, textAlign: 'center', fontFamily: 'Inter' }}>
                  {Math.round(subjectHealthData.heartRate)}{' '}
                  <Text style={{ fontSize: 12 }}>bpm</Text>
                </Text>
              </View>
              <View>
                <Text style={{ textAlign: 'center', fontFamily: 'Inter' }}>
                  {t('BloodPressure')}
                </Text>
                <Text style={{ fontSize: 28, textAlign: 'center', fontFamily: 'Inter' }}>
                  {Math.round(subjectHealthData.systolicBp)}/
                  {Math.round(subjectHealthData.diastolicBp)}{' '}
                  <Text style={{ fontSize: 12 }}>mmHg</Text>
                </Text>
              </View>
            </View>
          </Card>
          <View style={{ flex: 3, borderRadius: 10 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: '100%' }}>
              <Card
                transparent
                style={{
                  width: '31%',
                  borderRadius: 10,
                  backgroundColor: 'white',
                  justifyContent: 'center',
                }}
              >
                <View>
                  <Entypo
                    name="air"
                    size={50}
                    color="#00b8d4"
                    style={{ alignSelf: 'center' }}
                    // style={{ transform: [{ rotateZ: "-69deg" }] }}
                  />
                </View>
                <View style={{ paddingTop: 15 }}>
                  <Text style={{ textAlign: 'center', fontFamily: 'Inter' }}>
                    {t('RespirationRate')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                      padding: 10,
                      fontFamily: 'Inter',
                    }}
                  >
                    {Math.round(subjectHealthData.respiratoryRate)} per min
                  </Text>
                </View>
              </Card>
              <Card
                transparent
                style={{
                  justifyContent: 'center',
                  width: '32%',
                  backgroundColor: 'white',
                  borderRadius: 10,
                }}
              >
                <View>
                  <Entypo
                    name="thermometer"
                    size={50}
                    color="#333F44"
                    style={{ alignSelf: 'center' }}
                    // style={{ transform: [{ rotateZ: "-69deg" }] }}
                  />
                </View>
                <View style={{ paddingTop: 15 }}>
                  <Text style={{ textAlign: 'center', fontFamily: 'Inter' }}>
                    {t('BodyTemperature')}{' '}
                  </Text>
                  <Text
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                      padding: 10,
                      fontFamily: 'Inter',
                    }}
                  >
                    {Math.round(subjectHealthData.bodyTemp)} °C
                  </Text>
                </View>
              </Card>
              <Card
                transparent
                style={{
                  justifyContent: 'center',
                  width: '30%',
                  backgroundColor: 'white',
                  borderRadius: 10,
                }}
              >
                <View>
                  <FontAwesome
                    name="bolt"
                    size={50}
                    color="orange"
                    style={{ alignSelf: 'center' }}
                    // style={{ transform: [{ rotateZ: "-69deg" }] }}
                  />
                </View>
                <View style={{ paddingTop: 15 }}>
                  <Text style={{ textAlign: 'center', fontFamily: 'Inter' }}>
                    {t('BloodGlucose')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 20,
                      textAlign: 'center',
                      padding: 10,
                      fontFamily: 'Inter',
                    }}
                  >
                    {Math.round(subjectHealthData.bloodGlucose)} mg/dL
                  </Text>
                </View>
              </Card>
            </View>
          </View>
        </View>
      </Container>
    )
  }
}

export default Vitals
