import React from 'react';
import { View, StyleSheet, PixelRatio} from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types'
import { getHzTextAlign, isInSequence } from '../../scales/utils';
import { getValue } from './VerticalLabelUtils';
import { fontScale } from '../../styles/fontResizer';
import LabelTextHorizontal from './LabelTextHorizontal';
import { getHzLabelHeight, getWidthArray } from './HorizontalLabelUtils';
import { alignments } from '../../scales/alignments';
import { useSelector } from 'react-redux';

const HorizontalLabels = (props) => {
  const { start, step, end, labels, id, disabled, scaleProps, labelsData, minimumFontSize} = props
  const {scaleWidth, widthOfBox} = scaleProps
  const { onMobile } = useSelector(state => state?.appStatus);

 const calculateLastNumberWidth = (number) => {
  const arr = _.map(_.range(start, Number(end) + 1, step), number => number)
  if(isInSequence(end, start, end, step) && (number === end || number=== _.nth(arr, -2))){
    return widthOfBox/2
  }else if(number !== _.last(arr)){
    return widthOfBox
  }else {
    //lastButOneNumber where end is not in sequence
    return scaleWidth - (arr.length-1)*widthOfBox
  }
 }

  let boxStyles = {...styles.box}
  if(!_.isEmpty(_.filter(labels, label => label.labelPlacement === 'BOTTOM'))){
    boxStyles = {...boxStyles, justifyContent: 'flex-start'}
  }


  const getLabelBoxStyles = (number) => {
    let labelBoxStyle = {...styles.labelBoxStyle}
    if(number === end){
      labelBoxStyle = {
        ...labelBoxStyle,
        alignItems: 'flex-end',
      }
    }
    return labelBoxStyle
  }

  const getTextStyles = (number) => {
    return {textAlign: getHzTextAlign(number, end),  marginLeft:-2}
  }

  return (
    <View style={styles.container}>
      <View style={{width: scaleWidth, flexDirection: 'row', alignSelf: 'center'}}>
      {_.map(_.range(start, Number(end) + 1, step), (number) => {
          const labelBoxStyles = getLabelBoxStyles(number)
          const loWidth =  _.get(labelsData[number], 'width', calculateLastNumberWidth(number))
          const isTextFit =  _.get(labelsData[number], 'fit', false)
          return (
            <View style={[boxStyles, {width: calculateLastNumberWidth(number)}]}
              testID={`numeric-rating-scale-sliderLabels-${id}-${number}-hz-label`}
              accessible={!disabled}
            >
                <View style={[labelBoxStyles, {width: calculateLastNumberWidth(number)}]}>
                  <LabelTextHorizontal 
                    text={getValue(number, labels)}
                    minFontSize={minimumFontSize}
                    containerWidth={loWidth}
                    containerHeight={getHzLabelHeight(loWidth, widthOfBox)}
                    labelStyles={getTextStyles(number)}
                    testID={`visual-analog-scale-sliderLabels-${id}-${number}-hz-label`}
                    accessible={!disabled} 
                    containerStyle={{width: loWidth}}
                    widthArray={onMobile ? [loWidth] : getWidthArray(loWidth, widthOfBox)}
                    isTextFit={isTextFit}
                    orientation={alignments.HORIZONTAL}
                  />
                </View>
                 
          </View>
          )
        })
       }
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    flex: 1,
  },
  box: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  labelBoxStyle: {
    marginVertical: 5,
  }
});

HorizontalLabels.defaultProps = {
    labels: [],
    start: 0,
    end: 10,
    step: 1,
    id: null,
    disabled: false,
    scaleProps: {
      widthOfBox: 0,
      length: 0,
      scaleWidth: 0,
      fontSize: 10/(PixelRatio.getFontScale()),
      scale: 1,
    },
    labelsData: {}, 
    minimumFontSize: fontScale(10)
  }
  
  HorizontalLabels.propTypes = {
    labels: PropTypes.arrayOf(Object),
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
      widthOfBox: PropTypes.number,
      length: PropTypes.number,
      scaleWidth: PropTypes.number,
      fontSize: PropTypes.number,
      scale: PropTypes.number,
    }),
    labelsData: PropTypes.instanceOf(Object), 
    minimumFontSize: PropTypes.number,
}
export default HorizontalLabels;




