import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Tooltip from '../../scales/NRS/Tooltip';
import ClippedText from './ClippedText';
import _ from 'lodash';
import { numberCheck } from './VerticalLabelUtils';
import PropTypes from 'prop-types';

const LabelTextDisplay = ({ isFit, text, fontsize, containerWidth, containerHeight, labelStyles, testID, accessible, containerStyle, orientation}) => {

const loTextStyle = {
  ...styles.textStyle,
  ...labelStyles
}
const getTextView = (text) => {
    return (
      <View style={containerStyle}>
          <Text style={[loTextStyle, { fontSize: numberCheck(fontsize) }]}>{text}</Text>
      </View>
    )
  }

  return (
    <View>
     
      {/* Show the text only if it fits with any of the font sizes */}
      {isFit  || _.isEmpty(text) ? getTextView(text) : (
        <Tooltip tip = {text} tipStyle={{fontSize: numberCheck(fontsize), width: containerWidth }} testID={testID} accessible={accessible}>
          {/* ---displaying text with ellipsis and tooltip--- */}
          <ClippedText 
            text={text}
            width={containerWidth}
            height={containerHeight}
            fontSize={numberCheck(fontsize)}
            style={loTextStyle}
            containerStyle={containerStyle}
            orientation={orientation}
          />
        </Tooltip>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: 'Inter',
    color: 'black',
    textAlign: 'center',
    fontWeight: '500',
  }
});


LabelTextDisplay.defaultProps = {
  isFit : false,
  text: null, 
  fontsize: 10,
  containerWidth: 0, 
  containerHeight: 0, 
  labelStyles: {
    width: 0,
  }, 
  testID: null, 
  accessible: true, 
  containerStyle: {},
  orientation: null
}

LabelTextDisplay.propTypes = {
  isFit: PropTypes.bool,
  text: PropTypes.string, 
  fontsize: PropTypes.number,
  containerWidth: PropTypes.number, 
  containerHeight: PropTypes.number, 
  labelStyles: PropTypes.shape({
    width: PropTypes.number,
  }), 
  testID: PropTypes.string, 
  accessible: PropTypes.bool, 
  containerStyle: PropTypes.instanceOf(Object),
  orientation: PropTypes.string,
}
export default LabelTextDisplay;
