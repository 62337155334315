export default {
    SHOW_MARKS: {
      NO_MARKS: 'NO_MARKS',
      SHOW_MARKS: 'SHOW_MARKS',
      SHOW_EDGE_MARKS: 'SHOW_EDGE_MARKS',
      INDICATION_NUMBER: 'INDICATION_NUMBER'
    },
    SCALE_MIN_MARK_HEIGHT: 20,
    SCALE_MAX_MARK_HEIGHT: 35,
    SCALE_SELECTED_MARK_COLOR: 'red',
    SCALE_MARK_COLOR: 'rgb(144, 164, 174)',
    SCALE_MARK_WIDTH: 1,
    SCALE_POSITION : {
        CENTRE_OF_THE_PAGE: 'CENTRE_OF_THE_PAGE',
        LEFT_SIDE_OF_THE_PAGE: 'LEFT_SIDE_OF_THE_PAGE',
        RIGHT_SIDE_OF_THE_PAGE: 'RIGHT_SIDE_OF_THE_PAGE',
      },
  }
  