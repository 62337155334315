import _ from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux'
import constants from '../../constants/constants';
import ProfileHeader from './Profile/index'

const HeaderRight = (props) => {
    const { appStatus: {appType} } = useSelector((state) => state)
    if(_.isEqual(appType, constants.AppType.SITESTAFF)){
        return <ProfileHeader {...props}/>
    } else {
        return null;
    }
}

export default HeaderRight;