import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  callDetailsView: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  callDetailLabelText: {
    color: '#212121',
    fontSize: 16,
    paddingLeft: 10,
    fontFamily: 'Inter',
    paddingVertical: 5,
  },
  callDetailText: {
    color: '#616161',
    fontSize: 16,
    fontFamily: 'Inter',
  },
  joinCallBtn: {
    paddingVertical: 5,
    width: '40%',
    alignSelf: 'center',
    borderRadius: 5,
    backgroundColor: '#9155FD',
    marginTop: 10,
  },
  joinCallText: {
    fontFamily: 'Inter',
    alignSelf: 'center',
    color: '#fff',
    textTransform: 'uppercase',
  },
  participantNameText: {
    color: '#212121',
    paddingLeft: 10,
    alignSelf: 'center',
    fontSize: 16,
    fontFamily: 'Inter',
  },
})

export default styles
