import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { numberCheck } from './VerticalLabelUtils';
import PropTypes from 'prop-types'

const TextFitChecker = ({ text, fontSizes, containerWidth, containerHeight, labelStyles, onFontSize, number}) => {
  const [viewHeight, setViewHeight] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [currentFontSize, setCurrentFontSize] = useState(fontSizes[0]);

  useEffect(() => {
    if (textHeight > 0 && viewHeight > 0) {
      if (textHeight <= viewHeight) {
        //Text has been fit
        onFontSize(number, currentFontSize, true)
      } else {
        const nextFontSizeIndex = fontSizes.indexOf(currentFontSize) + 1;
        if (nextFontSizeIndex < fontSizes.length) {
          setTextHeight(null); 
          setCurrentFontSize(fontSizes[nextFontSizeIndex]);
        } else {
          //Text didn't fit
          onFontSize(number, 6, false)
        }
      }
    }
  }, [textHeight, viewHeight, currentFontSize]);

  const textStyles = {
    ...styles.textStyle,
    ...labelStyles,
}

  return (
    <View>
      {/* Hidden View for Measurement */}
     
      <View
        style={{ position: 'absolute', top: -1000, left: -1000, width: containerWidth}}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setTextHeight(height);
        }}
      >
        <Text style={[textStyles, { fontSize: numberCheck(currentFontSize) }]}>{text}</Text>
      </View>

      {/* Main container for measuring height */}
       <View
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          width: containerWidth,
          height: containerHeight,
          top: -2000, left: -2000,
        }}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setViewHeight(height);
        }}
      />
    
    </View>
  );
};

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: 'Inter',
    color: 'black',
    textAlign: 'center',
    fontWeight: '500',
  }
});


TextFitChecker.defaultProps = {
  text: null, 
  fontSizes: [], 
  containerWidth: 0, 
  containerHeight: 0, 
  labelStyles: {}, 
  onFontSize: ()=> null,
  number: 0,
}

TextFitChecker.propTypes = {
  text: PropTypes.string, 
  fontSizes: PropTypes.array, 
  containerWidth: PropTypes.number, 
  containerHeight: PropTypes.number, 
  labelStyles: PropTypes.instanceOf(Object), 
  onFontSize: PropTypes.func,
  number: PropTypes.number,
}

export default TextFitChecker;
