export default {
    "Africa/Abidjan":"Afrika/Abidjan",
"Africa/Accra":"Afrika/Akra",
"Africa/Addis_Ababa":"Afrika/Addis_Ababa",
"Africa/Algiers":"Afrika/Alžir",
"Africa/Asmara":"Afrika/Asmara",
"Africa/Asmera":"Afrika/Asmera",
"Africa/Bamako":"Afrika/Bamako",
"Africa/Bangui":"Afrika/Bangui",
"Africa/Banjul":"Afrika/Banjul",
"Africa/Bissau":"Afrika/Bissau",
"Africa/Blantyre":"Afrika/Blantyre",
"Africa/Brazzaville":"Afrika/Brazzaville",
"Africa/Bujumbura":"Afrika/Bujumbura",
"Africa/Cairo":"Afrika/Kairo",
"Africa/Casablanca":"Afrika/Casablanca",
"Africa/Ceuta":"Afrika/Ceuta",
"Africa/Conakry":"Afrika/Conakry",
"Africa/Dakar":"Afrika/Dakar",
"Africa/Dar_es_Salaam":"Afrika/Dar_es_Salaam",
"Africa/Djibouti":"Afrika/Džibuti",
"Africa/Douala":"Afrika/Douala",
"Africa/El_Aaiun":"Afrika/El_Aaiun",
"Africa/Freetown":"Afrika/Freetown",
"Africa/Gaborone":"Afrika/Gaborone",
"Africa/Harare":"Afrika/Harare",
"Africa/Johannesburg":"Afrika/Johannesburg",
"Africa/Juba":"Afrika/Juba",
"Africa/Kampala":"Afrika/Kampala",
"Africa/Khartoum":"Afrika/Khartum",
"Africa/Kigali":"Afrika/Kigali",
"Africa/Kinshasa":"Afrika/Kinshasa",
"Africa/Lagos":"Afrika/Lagos",
"Africa/Libreville":"Afrika/Libreville",
"Africa/Lome":"Afrika/Lome",
"Africa/Luanda":"Afrika/Luanda",
"Africa/Lubumbashi":"Afrika/Lubumbashi",
"Africa/Lusaka":"Afrika/Lusaka",
"Africa/Malabo":"Afrika/Malabo",
"Africa/Maputo":"Afrika/Maputo",
"Africa/Maseru":"Afrika/Maseru",
"Africa/Mbabane":"Afrika/Mbabane",
"Africa/Mogadishu":"Afrika/Mogadishu",
"Africa/Monrovia":"Afrika/Monrovija",
"Africa/Nairobi":"Afrika/Nairobi",
"Africa/Ndjamena":"Afrika/Ndjamena",
"Africa/Niamey":"Afrika/Niamey",
"Africa/Nouakchott":"Afrika/Nouakchott",
"Africa/Ouagadougou":"Afrika/Ouagadougou",
"Africa/Porto-Novo":"Afrika/Porto-Novo",
"Africa/Sao_Tome":"Afrika/Sao_Tome",
"Africa/Timbuktu":"Afrika/Timbuktu",
"Africa/Tripoli":"Afrika/Tripoli",
"Africa/Tunis":"Afrika/Tunis",
"Africa/Windhoek":"Afrika/Windhoek",
"America/Adak":"Amerika/Adak",
"America/Anchorage":"Amerika/Sidrišče",
"America/Anguilla":"Amerika/Angvila",
"America/Antigua":"Amerika/Antigva",
"America/Araguaina":"Amerika/Araguaina",
"America/Argentina/Buenos_Aires":"Amerika/Argentina/Buenos_Aires",
"America/Argentina/Catamarca":"Amerika/Argentina/Catamarca",
"America/Argentina/ComodRivadavia":"Amerika/Argentina/Comodrivadavia",
"America/Argentina/Cordoba":"Amerika/Argentina/Cordoba",
"America/Argentina/Jujuy":"Amerika/Argentina/Jujuy",
"America/Argentina/La_Rioja":"Amerika/Argentina/La_Rioja",
"America/Argentina/Mendoza":"Amerika/Argentina/Mendoza",
"America/Argentina/Rio_Gallegos":"Amerika/Argentina/Rio_Gallegos",
"America/Argentina/Salta":"Amerika/Argentina/Salta",
"America/Argentina/San_Juan":"Amerika/Argentina/San_Juan",
"America/Argentina/San_Luis":"Amerika/Argentina/San_Luis",
"America/Argentina/Tucuman":"Amerika/Argentina/Tucuman",
"America/Argentina/Ushuaia":"Amerika/Argentina/Ushuaia",
"America/Aruba":"Amerika/Aruba",
"America/Asuncion":"Amerika/Asuncion",
"America/Atikokan":"Amerika/Atikokan",
"America/Atka":"Amerika/Atka",
"America/Bahia":"Amerika/Bahia",
"America/Bahia_Banderas":"Amerika/Bahia_Banderas",
"America/Barbados":"Amerika/Barbados",
"America/Belem":"Amerika/Belem",
"America/Belize":"Amerika/Belize",
"America/Blanc-Sablon":"Amerika/Blanc-Sablon",
"America/Boa_Vista":"Amerika/Boa_Vista",
"America/Bogota":"Amerika/Bogota",
"America/Boise":"Amerika/Boise",
"America/Buenos_Aires":"Amerika/Buenos_Aires",
"America/Cambridge_Bay":"Amerika/Cambridge_Bay",
"America/Campo_Grande":"Amerika/Campo_Grande",
"America/Cancun":"Amerika/Cancun",
"America/Caracas":"Amerika/Caracas",
"America/Catamarca":"Amerika/Katamarka",
"America/Cayenne":"Amerika/Cayenne",
"America/Cayman":"Amerika/Kajman",
"America/Chicago":"Amerika/Chicago",
"America/Chihuahua":"Amerika/Chihuahua",
"America/Ciudad_Juarez":"Amerika/Ciudad_Juarez",
"America/Coral_Harbour":"Amerika/Coral_Harbour",
"America/Cordoba":"Amerika/Cordoba",
"America/Costa_Rica":"Amerika/Costa_Rica",
"America/Creston":"Amerika/Creston",
"America/Cuiaba":"Amerika/Cuiaba",
"America/Curacao":"Amerika/Curacao",
"America/Danmarkshavn":"Amerika/Danmarkshavn",
"America/Dawson":"Amerika/Dawson",
"America/Dawson_Creek":"Amerika/Dawson_Creek",
"America/Denver":"Amerika/Denver",
"America/Detroit":"Amerika/Detroit",
"America/Dominica":"Amerika/Dominika",
"America/Edmonton":"Amerika/Edmonton",
"America/Eirunepe":"Amerika/Eirunepe",
"America/El_Salvador":"Amerika/El_Salvador",
"America/Ensenada":"Amerika/Ensenada",
"America/Fort_Nelson":"Amerika/Fort_Nelson",
"America/Fort_Wayne":"Amerika/Fort_Wayne",
"America/Fortaleza":"Amerika/Fortaleza",
"America/Glace_Bay":"Amerika/Glace_Bay",
"America/Godthab":"Amerika/Godthab",
"America/Goose_Bay":"Amerika/Goose_Bay",
"America/Grand_Turk":"Amerika/Grand_Turk",
"America/Grenada":"Amerika/Grenada",
"America/Guadeloupe":"Amerika/Guadeloupe",
"America/Guatemala":"Amerika/Gvatemala",
"America/Guayaquil":"Amerika/Guayaquil",
"America/Guyana":"Amerika/Gvajana",
"America/Halifax":"Amerika/Halifax",
"America/Havana":"Amerika/Havana",
"America/Hermosillo":"Amerika/Hermosillo",
"America/Indiana/Indianapolis":"Amerika/Indiana/Indianapolis",
"America/Indiana/Knox":"Amerika/Indiana/Knox",
"America/Indiana/Marengo":"Amerika/Indiana/Marengo",
"America/Indiana/Petersburg":"Amerika/Indiana/Petersburg",
"America/Indiana/Tell_City":"Amerika/Indiana/Tell_City",
"America/Indiana/Vevay":"Amerika/Indiana/Vevay",
"America/Indiana/Vincennes":"Amerika/Indiana/Vincennes",
"America/Indiana/Winamac":"Amerika/Indiana/Winamac",
"America/Indianapolis":"Amerika/Indianapolis",
"America/Inuvik":"Amerika/Inuvik",
"America/Iqaluit":"Amerika/Iqaluit",
"America/Jamaica":"Amerika/Jamajka",
"America/Jujuy":"Amerika/Jujuy",
"America/Juneau":"Amerika/Juneau",
"America/Kentucky/Louisville":"Amerika/Kentucky/Louisville",
"America/Kentucky/Monticello":"Amerika/Kentucky/Monticello",
"America/Knox_IN":"America/Knox_in",
"America/Kralendijk":"Amerika/Kralendijk",
"America/La_Paz":"Amerika/La_Paz",
"America/Lima":"Amerika/Lima",
"America/Los_Angeles":"Amerika/Los_Angeles",
"America/Louisville":"Amerika/Louisville",
"America/Lower_Princes":"Amerika/Lower_Princes",
"America/Maceio":"Amerika/Maceio",
"America/Managua":"Amerika/Managua",
"America/Manaus":"Amerika/Manaus",
"America/Marigot":"Amerika/Marigot",
"America/Martinique":"Amerika/Martinik",
"America/Matamoros":"Amerika/Matamoros",
"America/Mazatlan":"Amerika/Mazatlan",
"America/Mendoza":"Amerika/Mendoza",
"America/Menominee":"Amerika/Menominee",
"America/Merida":"Amerika/Merida",
"America/Metlakatla":"Amerika/Metlakatla",
"America/Mexico_City":"Amerika/Mehiko_City",
"America/Miquelon":"Amerika/Miquelon",
"America/Moncton":"Amerika/Moncton",
"America/Monterrey":"Amerika/Monterrey",
"America/Montevideo":"Amerika/Montevideo",
"America/Montreal":"Amerika/Montreal",
"America/Montserrat":"Amerika/Montserrat",
"America/Nassau":"Amerika/Nassau",
"America/New_York":"Amerika/New York",
"America/Nipigon":"Amerika/Nipigon",
"America/Nome":"America/Ime",
"America/Noronha":"Amerika/Noronha",
"America/North_Dakota/Beulah":"Amerika/Severna Dakota/Beulah",
"America/North_Dakota/Center":"Amerika/Severna_Dakota/Center",
"America/North_Dakota/New_Salem":"Amerika/Severna Dakota/New_Salem",
"America/Nuuk":"Amerika/Nova Zelandija",
"America/Ojinaga":"Amerika/Ojinaga",
"America/Panama":"Amerika/Panama",
"America/Pangnirtung":"Amerika/Pangnirtung",
"America/Paramaribo":"Amerika/Paramaribo",
"America/Phoenix":"Amerika/Phoenix",
"America/Port-au-Prince":"Amerika/Port-au-Prince",
"America/Port_of_Spain":"Amerika/Port_of_Spain",
"America/Porto_Acre":"Amerika/Porto_Acre",
"America/Porto_Velho":"Amerika/Porto_Velho",
"America/Puerto_Rico":"Amerika/Puerto_Rico",
"America/Punta_Arenas":"Amerika/Punta_Arenas",
"America/Rainy_River":"Amerika/Rainy_River",
"America/Rankin_Inlet":"Amerika/Rankin_Inlet",
"America/Recife":"Amerika/Recife",
"America/Regina":"Amerika/Regina",
"America/Resolute":"Amerika/Resolute",
"America/Rio_Branco":"Amerika/Rio_Branco",
"America/Rosario":"Amerika/Rosario",
"America/Santa_Isabel":"Amerika/Santa_Isabel",
"America/Santarem":"Amerika/Santarem",
"America/Santiago":"Amerika/Santiago",
"America/Santo_Domingo":"Amerika/Santo_Domingo",
"America/Sao_Paulo":"Amerika/Sao_Paulo",
"America/Scoresbysund":"Amerika/Scoresbysund",
"America/Shiprock":"Amerika/Shiprock",
"America/Sitka":"Amerika/Sitka",
"America/St_Barthelemy":"Amerika/St_Barthelemy",
"America/St_Johns":"Amerika/St_Johns",
"America/St_Kitts":"Amerika/St_Kitts",
"America/St_Lucia":"Amerika/St_Lucia",
"America/St_Thomas":"Amerika/St_Thomas",
"America/St_Vincent":"Amerika/St_Vincent",
"America/Swift_Current":"America/Swift_Current",
"America/Tegucigalpa":"Amerika/Tegucigalpa",
"America/Thule":"Amerika/Thule",
"America/Thunder_Bay":"Amerika/Thunder_Bay",
"America/Tijuana":"Amerika/Tijuana",
"America/Toronto":"Amerika/Toronto",
"America/Tortola":"Amerika/Tortola",
"America/Vancouver":"Amerika/Vancouver",
"America/Virgin":"Amerika/Devica",
"America/Whitehorse":"Amerika/Whitehorse",
"America/Winnipeg":"Amerika/Winnipeg",
"America/Yakutat":"Amerika/Yakutat",
"America/Yellowknife":"Amerika/Yellowknife",
"Antarctica/Casey":"Antarktika/Casey",
"Antarctica/Davis":"Antarktika/Davis",
"Antarctica/DumontDUrville":"Antarktika/Dumontdurville",
"Antarctica/Macquarie":"Antarktika/Macquarie",
"Antarctica/Mawson":"Antarktika/Mawson",
"Antarctica/McMurdo":"Antarktika/McMurdo",
"Antarctica/Palmer":"Antarktika/Palmer",
"Antarctica/Rothera":"Antarktika/Rothera",
"Antarctica/South_Pole":"Antarktika/južni pol",
"Antarctica/Syowa":"Antarktika/Syowa",
"Antarctica/Troll":"Antarktika/Troll",
"Antarctica/Vostok":"Antarktika/Vostok",
"Arctic/Longyearbyen":"Arktika/Longyearbyen",
"Asia/Aden":"Azija/Aden",
"Asia/Almaty":"Azija/Almaty",
"Asia/Amman":"Azija/Aman",
"Asia/Anadyr":"Azija/Anadyr",
"Asia/Aqtau":"Azija/Aqtau",
"Asia/Aqtobe":"Azija/Aqtobe",
"Asia/Ashgabat":"Azija/Ašgabat",
"Asia/Ashkhabad":"Azija/Ashkhabad",
"Asia/Atyrau":"Azija/Atyrau",
"Asia/Baghdad":"Azija/Bagdad",
"Asia/Bahrain":"Azija/Bahrajn",
"Asia/Baku":"Azija/Baku",
"Asia/Bangkok":"Azija/Bangkok",
"Asia/Barnaul":"Azija/Barnaul",
"Asia/Beirut":"Azija/Bejrut",
"Asia/Bishkek":"Azija/Biškek",
"Asia/Brunei":"Azija/Brunej",
"Asia/Calcutta":"Azija/Kalkuta",
"Asia/Chita":"Azija/Čita",
"Asia/Choibalsan":"Azija/Choibalsan",
"Asia/Chongqing":"Azija/Chongqing",
"Asia/Chungking":"Azija/Chungking",
"Asia/Colombo":"Azija/Colombo",
"Asia/Dacca":"Azija/Dakka",
"Asia/Damascus":"Azija/Damask",
"Asia/Dhaka":"Azija/Dhaka",
"Asia/Dili":"Azija/Dili",
"Asia/Dubai":"Azija/Dubaj",
"Asia/Dushanbe":"Azija/Dušanbe",
"Asia/Famagusta":"Azija/Famagusta",
"Asia/Gaza":"Azija/Gaza",
"Asia/Harbin":"Azija/Harbin",
"Asia/Hebron":"Azija/Hebron",
"Asia/Ho_Chi_Minh":"Azija/Ho_Chi_Minh",
"Asia/Hong_Kong":"Azija/Hong_Kong",
"Asia/Hovd":"Azija/Hovd",
"Asia/Irkutsk":"Azija/Irkutsk",
"Asia/Istanbul":"Azija/Istanbul",
"Asia/Jakarta":"Azija/Džakarta",
"Asia/Jayapura":"Azija/Jayapura",
"Asia/Jerusalem":"Azija/Jeruzalem",
"Asia/Kabul":"Azija/Kabul",
"Asia/Kamchatka":"Azija/Kamčatka",
"Asia/Karachi":"Azija/Karači",
"Asia/Kashgar":"Azija/Kašgar",
"Asia/Kathmandu":"Azija/Katmandu",
"Asia/Katmandu":"Azija/Katmandu",
"Asia/Khandyga":"Azija/Khandyga",
"Asia/Kolkata":"Azija/Kalkuta",
"Asia/Krasnoyarsk":"Azija/Krasnojarsk",
"Asia/Kuala_Lumpur":"Azija/Kuala_Lumpur",
"Asia/Kuching":"Azija/Kuching",
"Asia/Kuwait":"Azija/Kuvajt",
"Asia/Macao":"Azija/Macao",
"Asia/Macau":"Azija/Macau",
"Asia/Magadan":"Azija/Magadan",
"Asia/Makassar":"Azija/Makassar",
"Asia/Manila":"Azija/Manila",
"Asia/Muscat":"Azija/Muškat",
"Asia/Nicosia":"Azija/Nikozija",
"Asia/Novokuznetsk":"Azija/Novokuznetsk",
"Asia/Novosibirsk":"Azija/Novosibirsk",
"Asia/Omsk":"Azija/Omsk",
"Asia/Oral":"Azija/Ustno",
"Asia/Phnom_Penh":"Azija/Phnom_Penh",
"Asia/Pontianak":"Azija/Pontianak",
"Asia/Pyongyang":"Azija/Pjongjang",
"Asia/Qatar":"Azija/Katar",
"Asia/Qostanay":"Azija/Qostanay",
"Asia/Qyzylorda":"Azija/Qyzylorda",
"Asia/Rangoon":"Azija/Rangoon",
"Asia/Riyadh":"Azija/Rijad",
"Asia/Saigon":"Azija/Saigon",
"Asia/Sakhalin":"Azija/Sahalin",
"Asia/Samarkand":"Azija/Samarkand",
"Asia/Seoul":"Azija/Seul",
"Asia/Shanghai":"Azija/Šanghaj",
"Asia/Singapore":"Azija/Singapur",
"Asia/Srednekolymsk":"Azija/Srednekolymsk",
"Asia/Taipei":"Azija/Tajpej",
"Asia/Tashkent":"Azija/Taškent",
"Asia/Tbilisi":"Azija/Tbilisi",
"Asia/Tehran":"Azija/Teheran",
"Asia/Tel_Aviv":"Azija/Tel_Aviv",
"Asia/Thimbu":"Azija/Thimbu",
"Asia/Thimphu":"Azija/Thimphu",
"Asia/Tokyo":"Azija/Tokio",
"Asia/Tomsk":"Azija/Tomsk",
"Asia/Ujung_Pandang":"Azija/Ujung_Pandang",
"Asia/Ulaanbaatar":"Azija/Ulan Bator",
"Asia/Ulan_Bator":"Azija/Ulan_Bator",
"Asia/Urumqi":"Azija/Urumči",
"Asia/Ust-Nera":"Azija/Ust-Nera",
"Asia/Vientiane":"Azija/Vientiane",
"Asia/Vladivostok":"Azija/Vladivostok",
"Asia/Yakutsk":"Azija/Jakutsk",
"Asia/Yangon":"Azija/Yangon",
"Asia/Yekaterinburg":"Azija/Jekaterinburg",
"Asia/Yerevan":"Azija/Erevan",
"Atlantic/Azores":"Atlantik/Azori",
"Atlantic/Bermuda":"Atlantik/Bermudi",
"Atlantic/Canary":"Atlantik/Kanarski",
"Atlantic/Cape_Verde":"Atlantik/Cape Verde",
"Atlantic/Faeroe":"Atlantik/Ferski",
"Atlantic/Faroe":"Atlantik/Ferski",
"Atlantic/Jan_Mayen":"Atlantik/Jan_Mayen",
"Atlantic/Madeira":"Atlantik/Madeira",
"Atlantic/Reykjavik":"Atlantik/Reykjavik",
"Atlantic/South_Georgia":"Atlantik/Južna Georgia",
"Atlantic/St_Helena":"Atlantik/Sveta Helena",
"Atlantic/Stanley":"Atlantik/Stanley",
"Australia/ACT":"Avstralija/ACT",
"Australia/Adelaide":"Avstralija/Adelaide",
"Australia/Brisbane":"Avstralija/Brisbane",
"Australia/Broken_Hill":"Avstralija/Broken_Hill",
"Australia/Canberra":"Avstralija/Canberra",
"Australia/Currie":"Avstralija/Currie",
"Australia/Darwin":"Avstralija/Darwin",
"Australia/Eucla":"Avstralija/Eucla",
"Australia/Hobart":"Avstralija/Hobart",
"Australia/LHI":"Avstralija/LHI",
"Australia/Lindeman":"Avstralija/Lindeman",
"Australia/Lord_Howe":"Avstralija/Lord_Howe",
"Australia/Melbourne":"Avstralija/Melbourne",
"Australia/NSW":"Avstralija/NSW",
"Australia/North":"Avstralija/sever",
"Australia/Perth":"Avstralija/Perth",
"Australia/Queensland":"Avstralija/Queensland",
"Australia/South":"Avstralija/jug",
"Australia/Sydney":"Avstralija/Sydney",
"Australia/Tasmania":"Avstralija/Tasmanija",
"Australia/Victoria":"Avstralija/Victoria",
"Australia/West":"Avstralija/Zahod",
"Australia/Yancowinna":"Avstralija/Yancowinna",
"Brazil/Acre":"Brazilija/Acre",
"Brazil/DeNoronha":"Brazilija/denoronha",
"Brazil/East":"Brazilija/Vzhod",
"Brazil/West":"Brazilija/Zahod",
"CET":"TO",
"CST6CDT":"CST6CDT",
"Canada/Atlantic":"Kanada/Atlantik",
"Canada/Central":"Kanada/Central",
"Canada/Eastern":"Kanada/Vzhodna",
"Canada/Mountain":"Kanada/Gora",
"Canada/Newfoundland":"Kanada/Newfoundland",
"Canada/Pacific":"Kanada/Pacifik",
"Canada/Saskatchewan":"Kanada/Saskatchewan",
"Canada/Yukon":"Kanada/Yukon",
"Chile/Continental":"Čile/celinsko",
"Chile/EasterIsland":"Čile/vzhodni otok",
"Cuba":"Kuba",
"EET":"JESTI",
"EST":"EST",
"EST5EDT":"JE 5 LET",
"Egypt":"Egipt",
"Eire":"Ear",
"Etc/GMT":"ITC/GMT",
"Etc/GMT+0":"ITC/GMT+0",
"Etc/GMT+1":"ITC/GMT+1",
"Etc/GMT+10":"ITC/GMT+10",
"Etc/GMT+11":"ITC/GMT+11",
"Etc/GMT+12":"ITC/GMT+12",
"Etc/GMT+2":"ITC/GMT+2",
"Etc/GMT+3":"ITC/GMT+3",
"Etc/GMT+4":"ITC/GMT+4",
"Etc/GMT+5":"ITC/GMT+5",
"Etc/GMT+6":"ITC/GMT+6",
"Etc/GMT+7":"ITC/GMT+7",
"Etc/GMT+8":"ITC/GMT+8",
"Etc/GMT+9":"ITC/GMT+9",
"Etc/GMT-0":"ITC/GMT-0",
"Etc/GMT-1":"ITC/GMT-1",
"Etc/GMT-10":"ITC/GMT-10",
"Etc/GMT-11":"ITC/GMT-11",
"Etc/GMT-12":"ITC/GMT-12",
"Etc/GMT-13":"ITC/GMT-13",
"Etc/GMT-14":"ITC/GMT-14",
"Etc/GMT-2":"ITC/GMT-2",
"Etc/GMT-3":"ITC/GMT-3",
"Etc/GMT-4":"ITC/GMT-4",
"Etc/GMT-5":"ITC/GMT-5",
"Etc/GMT-6":"ITC/GMT-6",
"Etc/GMT-7":"ITC/GMT-7",
"Etc/GMT-8":"ITC/GMT-8",
"Etc/GMT-9":"ITC/GMT-9",
"Etc/GMT0":"ITC/GMT0",
"Etc/Greenwich":"Etc/Greenwich",
"Etc/UCT":"ITC/UCT",
"Etc/UTC":"ITC/UTC",
"Etc/Universal":"Etc/Univerzalno",
"Etc/Zulu":"Etc/Zulu",
"Europe/Amsterdam":"Evropa/Amsterdam",
"Europe/Andorra":"Evropa/Andora",
"Europe/Astrakhan":"Evropa/Astrahan",
"Europe/Athens":"Evropa/Atene",
"Europe/Belfast":"Evropa/Belfast",
"Europe/Belgrade":"Evropa/Beograd",
"Europe/Berlin":"Evropa/Berlin",
"Europe/Bratislava":"Evropa/Bratislava",
"Europe/Brussels":"Evropa/Bruselj",
"Europe/Bucharest":"Evropa/Bukarešta",
"Europe/Budapest":"Evropa/Budimpešta",
"Europe/Busingen":"Evropa/Busingen",
"Europe/Chisinau":"Evropa/Kišinjev",
"Europe/Copenhagen":"Evropa/København",
"Europe/Dublin":"Evropa/Dublin",
"Europe/Gibraltar":"Evropa/Gibraltar",
"Europe/Guernsey":"Evropa/Guernsey",
"Europe/Helsinki":"Evropa/Helsinki",
"Europe/Isle_of_Man":"Evropa/Isle_of_Man",
"Europe/Istanbul":"Evropa/Istanbul",
"Europe/Jersey":"Evropa/Jersey",
"Europe/Kaliningrad":"Evropa/Kaliningrad",
"Europe/Kiev":"Evropa/Kijev",
"Europe/Kirov":"Evropa/Kirov",
"Europe/Kyiv":"Evropa/Kijev",
"Europe/Lisbon":"Evropa/Lizbona",
"Europe/Ljubljana":"Evropa/Ljubljana",
"Europe/London":"Evropa/London",
"Europe/Luxembourg":"Evropa/Luksemburg",
"Europe/Madrid":"Evropa/Madrid",
"Europe/Malta":"Evropa/Malta",
"Europe/Mariehamn":"Evropa/Mariehamn",
"Europe/Minsk":"Evropa/Minsk",
"Europe/Monaco":"Evropa/Monako",
"Europe/Moscow":"Evropa/Moskva",
"Europe/Nicosia":"Evropa/Nikozija",
"Europe/Oslo":"Evropa/Oslo",
"Europe/Paris":"Evropa/Pariz",
"Europe/Podgorica":"Evropa/Podgorica",
"Europe/Prague":"Evropa/Praga",
"Europe/Riga":"Evropa/Riga",
"Europe/Rome":"Evropa/Rim",
"Europe/Samara":"Evropa/Samara",
"Europe/San_Marino":"Evropa/San_Marino",
"Europe/Sarajevo":"Evropa/Sarajevo",
"Europe/Saratov":"Evropa/Saratov",
"Europe/Simferopol":"Evropa/Simferopol",
"Europe/Skopje":"Evropa/Skopje",
"Europe/Sofia":"Evropa/Sofija",
"Europe/Stockholm":"Evropa/Stockholm",
"Europe/Tallinn":"Evropa/Talin",
"Europe/Tirane":"Evropa/Tirana",
"Europe/Tiraspol":"Evropa/Tiraspol",
"Europe/Ulyanovsk":"Evropa/Uljanovsk",
"Europe/Uzhgorod":"Evropa/Uzhgorod",
"Europe/Vaduz":"Evropa/Vaduz",
"Europe/Vatican":"Evropa/Vatikan",
"Europe/Vienna":"Evropa/Dunaj",
"Europe/Vilnius":"Evropa/Vilna",
"Europe/Volgograd":"Evropa/Volgograd",
"Europe/Warsaw":"Evropa/Varšava",
"Europe/Zagreb":"Evropa/Zagreb",
"Europe/Zaporozhye":"Evropa/Zaporožje",
"Europe/Zurich":"Evropa/Zürich",
"GB":"GB",
"GB-Eire":"GB-Eire",
"GMT":"GMT",
"GMT+0":"GMT+0",
"GMT-0":"GMT-0",
"GMT0":"GMT0",
"Greenwich":"Greenwich",
"HST":"HST",
"Hongkong":"Hongkong",
"Iceland":"Islandija",
"Indian/Antananarivo":"Indijska/Antananarivo",
"Indian/Chagos":"Indijski/Chagos",
"Indian/Christmas":"Indijska/božič",
"Indian/Cocos":"Indijski/Kokos",
"Indian/Comoro":"Indijski/Komorski",
"Indian/Kerguelen":"Indijski/Kerguelen",
"Indian/Mahe":"Indijska/Mahe",
"Indian/Maldives":"Indijski/Maldivi",
"Indian/Mauritius":"Indijska/Mauritius",
"Indian/Mayotte":"Indijska/Mayotte",
"Indian/Reunion":"Indijska/srečanje",
"Iran":"Iran",
"Israel":"Izrael",
"Jamaica":"Jamajka",
"Japan":"Japonska",
"Kwajalein":"Kvajalein",
"Libya":"Libija",
"MET":"SREČAL",
"MST":"MST",
"MST7MDT":"MST7MDT",
"Mexico/BajaNorte":"Mehika/Bajanorte",
"Mexico/BajaSur":"Mehika/Bajasur",
"Mexico/General":"Mehika/Splošno",
"NZ":"NZ",
"NZ-CHAT":"NZ-KLEPET",
"Navajo":"Navajo",
"PRC":"PRC",
"PST8PDT":"PST8PDT",
"Pacific/Apia":"Pacific/Apia",
"Pacific/Auckland":"Pacifik/Auckland",
"Pacific/Bougainville":"Pacifik/Bougainville",
"Pacific/Chatham":"Pacifik/Chatham",
"Pacific/Chuuk":"Pacifik/Chuuk",
"Pacific/Easter":"Pacific/Velika noč",
"Pacific/Efate":"Pacific/Efate",
"Pacific/Enderbury":"Pacifik/Enderbury",
"Pacific/Fakaofo":"Pacific/Fakaofo",
"Pacific/Fiji":"Pacifik/Fidži",
"Pacific/Funafuti":"Pacifik/Funafuti",
"Pacific/Galapagos":"Pacific/Galapagos",
"Pacific/Gambier":"Pacifik/Gambier",
"Pacific/Guadalcanal":"Pacific/Guadalcanal",
"Pacific/Guam":"Pacifik/Guam",
"Pacific/Honolulu":"Pacific/Honolulu",
"Pacific/Johnston":"Pacifik/Johnston",
"Pacific/Kanton":"Pacific/Kanton",
"Pacific/Kiritimati":"Pacific/Kiritimati",
"Pacific/Kosrae":"Pacifik/Kosrae",
"Pacific/Kwajalein":"Pacifik/Kwajalein",
"Pacific/Majuro":"Pacific/Majuro",
"Pacific/Marquesas":"Pacifik/Marquesas",
"Pacific/Midway":"Pacifik/Midway",
"Pacific/Nauru":"Pacifik/Nauru",
"Pacific/Niue":"Pacific/Niue",
"Pacific/Norfolk":"Pacifik/Norfolk",
"Pacific/Noumea":"Pacific/Noumea",
"Pacific/Pago_Pago":"Pacifik/Pago_Pago",
"Pacific/Palau":"Pacific/Palau",
"Pacific/Pitcairn":"Pacifik/Pitcairn",
"Pacific/Pohnpei":"Pacific/Pohnpei",
"Pacific/Ponape":"Pacifik/Ponape",
"Pacific/Port_Moresby":"Pacifik/Port_Moresby",
"Pacific/Rarotonga":"Pacific/Rarotonga",
"Pacific/Saipan":"Pacifik/Saipan",
"Pacific/Samoa":"Pacifik/Samoa",
"Pacific/Tahiti":"Pacifik/Tahiti",
"Pacific/Tarawa":"Pacifik/Tarawa",
"Pacific/Tongatapu":"Pacifik/Tongatapu",
"Pacific/Truk":"Pacific/Truk",
"Pacific/Wake":"Pacific/Wake",
"Pacific/Wallis":"Pacifik/Wallis",
"Pacific/Yap":"Pacific/Yap",
"Poland":"Poljska",
"Portugal":"Portugalska",
"ROC":"ROC",
"ROK":"ROK",
"Singapore":"Singapur",
"Turkey":"Turčija",
"UCT":"UCT",
"US/Alaska":"Združene/Aljaska",
"US/Aleutian":"US/Aleutski",
"US/Arizona":"ZDA/Arizona",
"US/Central":"USD/Central",
"US/East-Indiana":"Združene/Vzhodna Indiana",
"US/Eastern":"Združene/Vzhodne",
"US/Hawaii":"Združene/Havaji",
"US/Indiana-Starke":"Ameriško/Indiana-Starke",
"US/Michigan":"ZDA/Michigan",
"US/Mountain":"US/gora",
"US/Pacific":"Združene/Pacifik",
"US/Samoa":"ZDA/Samoa",
"UTC":"UTC",
"Universal":"Univerzalno",
"W-SU":"W-SU",
"WET":"MOKRO",
"Zulu":"Zulu",
}