import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Contact from '../components/Contact'
import { getUserStudySite } from '../selectors/studyMetaData'
import { setCurrentScreen } from '../actions/session'
import _ from 'lodash'
import PropTypes from 'prop-types'

class ContactScreen extends Component {
  state = {}
  componentDidMount() {
    const { setCurrentScreen } = this.props
    setCurrentScreen('')
  }

  componentDidUpdate(prevProps) {
    const {
      setCurrentScreen
    } = this.props
    setCurrentScreen('')
  }

  render() {
    const { navigation, client, screenProps, isDeviceOnline } = this.props
    return (
      <Contact
        navigation={navigation}
        client={client}
        screenProps={screenProps}
        isDeviceOnline={isDeviceOnline}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  client: _.get(getUserStudySite(state), 'client', {}),
  isDeviceOnline: state.appStatus.isDeviceOnline,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentScreen,
    },
    dispatch
  )

  ContactScreen.propTypes = {
    setCurrentScreen: PropTypes.func,
    navigation: PropTypes.instanceOf(Object),
    client: PropTypes.instanceOf(Object),
    screenProps: PropTypes.instanceOf(Object), 
    isDeviceOnline : PropTypes.bool
  }


export default connect(mapStateToProps, mapDispatchToProps)(ContactScreen)
