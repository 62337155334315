import React from 'react'
import { View, Image, Platform, TouchableOpacity } from 'react-native'
import AutoHeightImage from '../AutoHeightImage'
import tryalLogo from '../../../assets/base64/tryalLogo'
import { MaterialIcons } from '@expo/vector-icons'
import { fontResizer } from '../styles/fontResizer'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import CustomTextResize from './CustomTextResize'
import { getScreenWidth } from '../../utils/util'
import constants from '../../constants/constants'
import _ from 'lodash'

const Header = (props) => {
  const { title, navigation, canShowBackIcon, showHeaderLogo, headerRight=true, onBack} = props
  const { onMobile, appType } = useSelector(state => state.appStatus);

  const getLeftHeaderWidth = () => {
    if(_.isEqual(appType, constants.AppType.SITESTAFF) && headerRight && onMobile){
      return 0.5*getScreenWidth()
    }else if(_.isEqual(appType, constants.AppType.SITESTAFF) || onMobile || !headerRight){
      return 0.7*getScreenWidth()
    }else if(headerRight){
      return 0.3*getScreenWidth()
    }
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      {canShowBackIcon && (
        <TouchableOpacity
          onPress={() => onBack ? onBack() : navigation.goBack()}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <MaterialIcons name="arrow-back" color="#212121" size={24} />
        </TouchableOpacity>
      )}

      {showHeaderLogo && (
        <View style={{ width: 65, height: 60, marginRight: 8, marginLeft: 5, justifyContent: 'center' }}>
          {Platform.OS !== 'web' ? (
            <AutoHeightImage
              source={{
                uri: `data:image/png;base64,${tryalLogo}`,
              }}
              width={45}
              style={{ marginRight: 8 }}
            />
          ) : (
            <Image
              source={{
                uri: `data:image/png;base64,${tryalLogo}`,
              }}
              resizeMode="contain"
              style={{ width: 55, height: 60, marginRight: 8 }}
            />
          )}
        </View>
      )}
      <View
        style={{
          width: getLeftHeaderWidth(),
          justifyContent: 'center',
          marginLeft: title !== 'Passcode' ? 0 : 10,
          height: 65,
        }}
      >
        <CustomTextResize
            textStyle={{
              fontWeight: '400',
              fontFamily: 'Inter',
              color: '#212121',
            }}
            fontSize={fontResizer(24)}
            title={title}
            viewStyles={{justifyContent: 'center'}}
         />
      </View>
    </View>
  )
}

Header.defaultProps = {
  showHeaderLogo: true,
  headerRight: true,
  title: null, 
  navigation: {
    goBack: () => null,
  }, 
  canShowBackIcon: false,
  onBack: null,
}
Header.propTypes = {
  showHeaderLogo: PropTypes.bool,
  headerRight: PropTypes.bool,
  title: PropTypes.string, 
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
  }), 
  canShowBackIcon: PropTypes.bool,
  onBack: PropTypes.func,
}

export default Header
