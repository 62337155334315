import { Formik } from 'formik'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Body, Card, CardItem } from 'native-base'
import { createForm } from 'rc-form'
import React from 'react'
import { ActivityIndicator, Keyboard, Text, TouchableOpacity, View } from 'react-native'
import * as Yup from 'yup'
import { buttonBackgroundColor } from '../../containers/NavigationScreens'
import api from '../../utils/api'
import { validatePhoneNumber } from '../../utils/phoneNumberValidation'
import { putItem } from '../../utils/secureStorageUtils'
import showToast from '../../utils/toast'
import PhNumberInput from '../PhNumberInput'
import styles from './forgotpasswordStyles'
import ConstraintStatus from '../../utils/constraintKeys'
import openapi from '../../utils/openapi'

class UserValidation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      callingCode: '1',
    }
  }

  componentDidMount() {
    // const { deviceLocation } = this.props
    const callingCode = '1'
    //deviceLocation.location.calling_code || '1'
    this.changeCallCode(callingCode)
  }

  changeLoading = () => {
    const { loading } = this.state
    this.setState({
      loading: !loading,
    })
  }

  validatePhoneNumber = (rule, value, callback) => {
    const {
      screenProps: { t },
    } = this.props
    const phoneNumber = parsePhoneNumberFromString(value)
    if (phoneNumber === undefined || !phoneNumber.isValid() === true) {
      callback(t('NotValidPhone'))
    } else {
      callback()
    }
  }

  submitPhoneNumber = (values) => {
    const subjectPhoneNo = `+${values.phoneNo}`
    this.verifyAndGenerateOtp(subjectPhoneNo)
  }

  verifyAndGenerateOtp = async (phoneNumber) => {
    const {
      navigation,
      screenProps: { t },
    } = this.props
    Keyboard.dismiss()
    try {
      this.changeLoading()
      const res = await openapi.post('/subject/otp', { phoneNumber })
      const transactionId = res.data.transactionId
      const otpData = {
        phoneNumber,
        transactionId,
      }
      putItem(JSON.stringify(otpData), 'otpData')
      this.changeLoading()
      navigation.replace('OtpVerification')
    } catch (error) {
      console.log(error)
      this.changeLoading()
      if (error.response) {
        switch (error.response.status) {
          case 400:
            this.contactAdmin()
          case 404:
            showToast(t('NetworkError'), 'danger', 3000)
            this.userNotFound()
            break
          case 500:
            if (
              error.response.data &&
              Number(error.response.data) == ConstraintStatus.USER_INACTIVE
            ) {
              showToast(t('UserInactive'), 'danger', 3000)
            } else {
              showToast(t('SomethingWrong'), 'danger', 3000)
            }

            break
          case 401 || 403:
            showToast(t('InvPhonePSWD'), 'danger', 3000)
            break
          case 423:
            showToast(t('UserLocked'), 'danger', 10000)
            break
          default:
            showToast(t('NetworkError'), 'danger', 3000)
        }
      } else {
        showToast(t('NetworkError'), 'danger', 3000)
      }
    }
  }

  userNotFound = () => {
    const {
      screenProps: { t },
    } = this.props
    showToast(t('PhoneNotReg'), 'default', 5000)
  }

  contactAdmin = () => {
    const {
      screenProps: { t },
    } = this.props
    showToast(t.SomethingWrong, 'default', 3000)
  }

  changeCallingCode = (callingCode) => {
    this.setState({
      callingCode,
    })
  }

  changeCallCode = (callCode) => {
    this.setState({
      callingCode: callCode,
    })
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldError },
      navigation,
      screenProps: { t },
      deviceLocation,
    } = this.props
    const { callingCode, loading } = this.state

    return (
      <View style={styles.container}>
        <Formik
          initialValues={{ phoneNo: '' }}
          validationSchema={Yup.object({
            phoneNo: Yup.string()
              .test('len', 'Must be a valid Phone Number.', (val) => {
                return validatePhoneNumber(val, callingCode)
              })
              .required(t('USRNAMEWARNING')),
          })}
          onSubmit={(values, formikActions) => {
            setTimeout(() => {
              this.submitPhoneNumber(values)
              // Important: Make sure to setSubmitting to false so our loading indicator
              // goes away.
              formikActions.setSubmitting(false)
            }, 500)
          }}
        >
          {(props) => {
            const { setFieldValue, setValues } = props

            const getPhoneValue = (value, phoneNo) => {
              setFieldValue('phoneNo', value + phoneNo)
            }

            return (
              <Card style={{ borderRadius: 10, flexShrink: 1, overflow: 'hidden' }}>
                <CardItem style={{ borderRadius: 10 }}>
                  <Body style={{ alignItems: 'center', borderRadius: 10 }}>
                    {/* Forgot password? */}
                    <View>
                      <Text style={{ fontSize: 25, color: '#455a64', fontFamily: 'Inter' }}>
                        {t('LoginACSFrgtPwd')}
                      </Text>
                    </View>
                    <View style={{ paddingTop: 10 }}>
                      <Text style={{ fontSize: 14, color: '#455a64', fontFamily: 'Inter' }}>
                        {t('OTPMSG')}
                      </Text>
                    </View>
                    <View style={{ paddingTop: 20 }}>
                      <Text style={{ fontSize: 14, color: '#455a64', fontFamily: 'Inter' }}>
                        {t('FP_PNO')}
                      </Text>
                    </View>
                    <View style={{ alignSelf: 'baseline', overflow: 'hidden', width: '100%' }}>
                      <PhNumberInput
                        fromPinChange={false}
                        deviceLocation={deviceLocation}
                        t={t}
                        getPhoneValue={getPhoneValue}
                      />
                      {props.touched.phoneNo && props.errors.phoneNo ? (
                        <Text style={styles.error}>{props.errors.phoneNo}</Text>
                      ) : null}
                    </View>
                    <View style={{ padding: 30 }}>
                      <TouchableOpacity
                        onPress={props.handleSubmit}
                        style={{
                          borderRadius: 5,
                          backgroundColor: buttonBackgroundColor,
                          padding: 10,
                          paddingHorizontal: 40,
                        }}
                      >
                        <Text
                          style={{ color: '#fff', fontFamily: 'Inter', textTransform: 'uppercase' }}
                        >
                          {t('Submit')}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          paddingTop: 20,
                          alignSelf: 'center',
                        }}
                        onPress={() => navigation.goBack()}
                      >
                        <Text
                          style={{
                            color: buttonBackgroundColor,
                            fontFamily: 'Inter',
                            textTransform: 'uppercase',
                          }}
                        >
                          {t('Cancel')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </Body>
                </CardItem>
              </Card>
            )
          }}
        </Formik>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator
              size="large"
              color={buttonBackgroundColor}
              overlayColor="rgba(0, 0, 0, 0.07)"
            />
          </View>
        )}
      </View>
    )
  }
}

export default createForm()(UserValidation)
