import _ from 'lodash'
import TextFitChecker from './TextFitChecker'
import { getScaleWidth } from '../../scales/utils'
import { Platform } from 'react-native'
import { fontScale } from '../../styles/fontResizer'
import constants from '../../../constants/constants'
import { alignments } from '../../scales/alignments'

const {labelPlacement, EDGE_LABEL_HEIGHT} = constants
export const checkTextFit = (props, setLabelsData) => {
    const {start, end, step, labelsData, defaultLabelWidth, defaultlabelHeight, labels, textStyles} = props
    let loLabelsData = {...labelsData}
        return _.map(_.orderBy(_.range(start, Number(end) + 1, step), [], 'desc'), (number, index) => {
            const loWidth =  _.get(labelsData[number], 'width', defaultLabelWidth)
            const loHeight =  _.get(labelsData[number], 'height', defaultlabelHeight)
            if(!_.isEmpty(getValue(number, labels))){
            return (
                <TextFitChecker 
                        text={getValue(number, labels)}
                        fontSizes={[10,9,8,7,6]}
                        containerWidth={loWidth}
                        containerHeight={loHeight}
                        labelStyles={textStyles}
                        onFontSize={(num, font, isContentFit)=> {
                            loLabelsData[num]= {
                                ...loLabelsData[num],
                                fontSize: font,
                                fit: isContentFit,
                            }
                            setLabelsData(loLabelsData)
                         }}
                        number={number}
                />
            )
        }  
        })
}

export const getValue = (number, labels) => {
    return _.filter(labels, label => Number(label.position)=== number)?.[0]?.value
}

export const getLabelWidth = (labelFlex) => {
    return labelFlex*getScaleWidth()
}

export const isLabelsDataPrepared = (labelsData) => {
    if(!_.isEmpty(labelsData)){
        return _.isEmpty(_.filter(labelsData, ele => !_.isNumber(ele?.fontSize) && !_.isBoolean(ele?.fit)))
    }else{
        return true
    }
}

export const getMinLabelFont = (labelsData) => {
    return isLabelsDataPrepared(labelsData) ? Math.min(...Object.values(labelsData).map(o => o.fontSize)) : 6
}


export const getFlexibleLabelWidth = (labelFlex) => {
   return getLabelWidth(labelFlex)-(Platform.OS === 'web' ? 50 : 20)
}

export const getFlexibleLabelHeight = (number, labels, start, widthOfBox, step) => {
    const labelHeight = getLabelHeight(number, labels, start, widthOfBox, step)
    switch (true) {
        case number===Number(start) && !_.isEmpty(getValue(number, labels)): return widthOfBox > 30 ? widthOfBox : 30
        case labelHeight > widthOfBox : return labelHeight-10
        default: return labelHeight
    }
}

export const getLabelHeight = (number, labels, start, widthOfBox, step) => {
    const label = _.find(labels, label => Number(label.position) === number)
    if(!_.isEmpty(label)){
        let givenBoxHeight = widthOfBox
        const orderedLabels = _.orderBy(labels, label => Number(label.position), 'desc');
        let loNextNumber = start
        if (number > start) {
            const nextLabel = _.find(orderedLabels, label => Number(label.position) < number )
            if(!_.isEmpty(nextLabel)){
                loNextNumber = Number(nextLabel.position)
            }
            return ((number - loNextNumber)/step)*givenBoxHeight
        }
        return givenBoxHeight
    }else {
        return 0
    }
}



export const numberCheck = (fontSz) => {
  return _.isNumber(fontSz)? fontScale(fontSz): fontScale(6)
}

export const prepareLabelsMetaData = (props, setLabelsData) => {
    const {start, end, step, labelsData, options, widthOfBox, labelsFlex, isVAS} = props
    let loLabelsData = {...labelsData}
    _.map(_.orderBy(_.range(start, Number(end) + 1, step), [], 'desc'), (number, index) => {   
        if(!_.isEmpty(getValue(number, options))){
            const label = getLabel(number, options)
             loLabelsData = {
                ...loLabelsData,
                [number]: {
                    ...loLabelsData[number],
                    height: getLabelHeightBasedOnPlacement(label, options, start, widthOfBox, step),
                    width: getWidth(isVAS, labelsFlex, label),
                }
            } 
        } 
    })
    setLabelsData(loLabelsData)
}

const getWidth = (isVAS, labelsFlex, label) => {
    const width = getLabelWidthBasedOnPlacement(labelsFlex, label)
    if(isVAS && (label.labelPlacement === labelPlacement.RIGHT || label.labelPlacement === labelPlacement.LEFT) && Platform.OS !== 'web'){
        return width-(0.14*getScaleWidth())
    }else{
        return width
    }
}

export const getLabel = (number, labels) => {
    return _.filter(labels, label => Number(label.position)=== number)?.[0]
}

export const getLabelWidthBasedOnPlacement = (labelsFlex, label) => {
    switch (label.labelPlacement) {
        case labelPlacement.TOP:  return getScaleWidth()/2
        case labelPlacement.BOTTOM:  return getScaleWidth()/2
        case labelPlacement.LEFT: return getFlexibleLabelWidth(labelsFlex.left)
        case labelPlacement.RIGHT: return getFlexibleLabelWidth(labelsFlex.right)
    }
}

export const getLabelHeightBasedOnPlacement = (label, options, start, widthOfBox, step) => {
    const labels = getLabelsOfSameLabelPlacement(options, label.labelPlacement )
    switch (label.labelPlacement) {
        case labelPlacement.TOP:  return EDGE_LABEL_HEIGHT
        case labelPlacement.BOTTOM:  return EDGE_LABEL_HEIGHT
        case labelPlacement.LEFT: return getFlexibleLabelHeight(Number(label.position), labels, start, widthOfBox, step)
        case labelPlacement.RIGHT: return getFlexibleLabelHeight(Number(label.position), labels, start, widthOfBox, step)
    }
}

export const getLabelsOfSameLabelPlacement = (options, placement) => {
    return _.filter(options, op => op.labelPlacement === placement)
}

export const getMinFontSizeForVerticalLabels = (labelsData, options, orientation) => {
    if(_.isEmpty(options) || (orientation === alignments.VERTICAL && (_.isEmpty(getLabelsOfSameLabelPlacement(options, labelPlacement.LEFT)) && _.isEmpty(getLabelsOfSameLabelPlacement(options, labelPlacement.RIGHT))))){
        return fontScale(10)
    }else {
        return getMinLabelFont(labelsData)
    }
}

export const getFontSizeCalculated = (labelsData, options, orientation=alignments.VERTICAL) => {
    if(!_.isEmpty(options)){
        if(!_.isEmpty(labelsData) && isLabelsDataPrepared(labelsData)){
            return {
                isCalculated: true,
                minFont: getMinFontSizeForVerticalLabels(labelsData, options, orientation)
            }
        }else{
            return {
                isCalculated: false,
                minFont: null
            }
        }
    }else{
        return {
            isCalculated: true,
            minFont: fontScale(10)
        }
    }
}