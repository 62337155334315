import moment from 'moment-timezone'

export function getTzDetailsByOfffsetFromName(timezone, timezoneList) {
  const foundMaybe = (timezoneList || []).find(({ timeZoneName }) => {
    const inferredOffset = moment.tz(timezone).utcOffset()
    const configuredOffset = moment.tz(timeZoneName).utcOffset()
    if(configuredOffset === inferredOffset) {
      return timeZoneName
    } else {
      return null
    }
  })
  return foundMaybe
}

export function getTzObject(timezone, timezoneList) {
  const timezoneobj = (timezoneList || []).find(({ timeZoneName }) => {
    return timeZoneName === timezone
  })
  return timezoneobj ? timezoneobj : timezone ? {timeZoneName:timezone} : null
}