import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { validatePasscode } from '../../actions/site'
import Passcode from './components/Passcode'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Dimensions,
    FlatList,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    ActivityIndicator
  } from 'react-native'
import constants from '../../constants/constants'
import { buttonBackgroundColor } from '../../containers/NavigationScreens'
 
const PasscodeStyles= StyleSheet.create({
    loaderStyle:{
        position: 'absolute',
        justifyContent: 'center',
        height: Dimensions.get('window').height,
        alignSelf: 'center',
    },
});

const PassCodePage = (props) => {
  const { screenProps: {t}, navigation, screenProps, loading } = props
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const getPassCodeFromURL = () => {
    if(Platform.OS === 'web'){
      const urlParams = new URLSearchParams(window.location.search)
      const passcode = urlParams.get('passcode') 
      return passcode
    }
    return null
  }

  const passcode = getPassCodeFromURL()
  useEffect(() => {
        validate(passcode, false);
  }, []);

const validate = async (passcode, fromSiteStaffLogin) => {
    if(!_.isEmpty(passcode)){
      try{
         const data = await props.validatePasscode(passcode, t)
         if(!_.isEmpty(data)){
            navigation.navigate('Login', {appType : constants.AppType.SITESTAFF})
         }    
      } catch (error) {
        console.log(error)
      }
    }else if(_.isEmpty(passcode) && fromSiteStaffLogin){
       setShowErrorMessage(true)
    }
}

  if(!_.isEmpty(passcode) && loading){
    return (
        <View style={PasscodeStyles.loaderStyle}>
            <ActivityIndicator
            size="large"
            color={buttonBackgroundColor}
            overlayColor="rgba(0, 0, 0, 0.07)"
          />
        </View>
    ) 
  }

  return <Passcode navigation={navigation} screenProps={screenProps} validate={(code, fromSiteStaff) => validate(code, fromSiteStaff)} showErrorMessage={showErrorMessage}/>
 
}

const mapStateToProps = (state) => ({
    loading: state.site.loading
})

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        validatePasscode,
      },
      dispatch
    )
    
export default connect(mapStateToProps, mapDispatchToProps)(PassCodePage)
