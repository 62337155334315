import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  noDiaryLabelContainer: {
    borderWidth: 1,
    borderColor: '#ffb84d',
    backgroundColor: '#ffb84d',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    borderTopWidth: 10,
    borderBottomWidth: 10,
  },
  noDiaryText: {
    fontSize: 16,
    color: '#ffffff',
    fontFamily: 'Inter',
  },
})
