import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import Header from '../header'
import { backgroundColor } from '../../../containers/NavigationScreens'
import constants from '../../../constants/constants'
import Banner from '../../Banner'
import ProfileHeader from '../Profile/index'

const SiteStaffProfileHeader = (props) => {
  const { title, navigation, canShowBackIcon, appType, screenProps, fromDiaryScreen } = props

  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: 120,
      }}
    >
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 5 }}>
        <Header navigation={navigation} title={title} canShowBackIcon={canShowBackIcon}></Header>
        <ProfileHeader navigation={navigation} screenProps={screenProps} />
      </View>
      {appType === constants.AppType.SITESTAFF && !fromDiaryScreen && (
        <View style={{ marginBottom: 10, marginTop: 10, zIndex: -1 }}>
          <Banner />
        </View>
      )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  appType: state.appStatus.appType,
})

export default connect(mapStateToProps, null)(SiteStaffProfileHeader)
