import { getselectedChat } from '../selectors/session'
import { getUserId } from '../selectors/user'
import createActionType from '../utils/action'
import { removeSiteIdFromSessionStorage, storeSiteIdInSessionStorage, storeUserIdInSessionStorage } from '../utils/util'
import _ from 'lodash'

export const UPDATE_CURRENT_USER_ID = createActionType('UPDATE_CURRENT_USER_ID')
export const UPDATE_SELECTED_VISIT_ID = createActionType('UPDATE_SELECTED_VISIT_ID')
export const UPDATE_SELECTED_VISIT_FROM_ID = createActionType('UPDATE_SELECTED_VISIT_FROM_ID')
export const UPDATE_SELECTED_DATE = createActionType('UPDATE_SELECTED_DATE')
export const UPDATE_SELECTED_CALL = createActionType('UPDATE_SELECTED_CALL')
export const STORE_SELECTED_SVF_UPDATE = createActionType('STORE_SELECTED_SVF_UPDATE')
export const UPDATE_SELECTEDCHAT = createActionType('UPDATE_SELECTEDCHAT')
export const CLEAR_SELECTED_CHAT = createActionType('CLEAR_SELECTED_CHAT')
export const STORE_CURRENT_SCREEN = createActionType('STORE_CURRENT_SCREEN')
export const UPDATE_CURRENT_SITE_ID = createActionType('UPDATE_CURRENT_SITE_ID')
export const UPDATE_SELECTED_FORMTYPE = createActionType('UPDATE_SELECTED_FORMTYPE')
export const SESSION_TIMEOUT = createActionType('SESSION_TIMEOUT')
export const UPDATE_IS_ACKNOWLEDGED = createActionType('UPDATE_IS_ACKNOWLEDGED')
export const QUESTIONNAIRE_UPGRADE_REQUIRED = createActionType('QUESTIONNAIRE_UPGRADE_REQUIRED')

export const updateCurrentUserId = (userId) => (dispatch, getState) =>  {
    dispatch({type: UPDATE_CURRENT_USER_ID, userId})
    storeUserIdInSessionStorage(userId)
}

export const updateCurrentSiteId = (siteId) => (dispatch, getState) =>  {
  dispatch({type: UPDATE_CURRENT_SITE_ID, siteId})
  storeSiteIdInSessionStorage(siteId)
}


export const updateSelectedVisitId = (visitId) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_SELECTED_VISIT_ID,
    selectedVisitId: visitId,
    userId: getUserId(getState())
  })
}

export const updateSelectedFormIdAndSelectedSvfId = (formId, svfId, userId=null) => async (dispatch, getState) => {
    if(_.isEmpty(userId)){
      userId = getUserId(getState())
    }
    dispatch({
      type: UPDATE_SELECTED_VISIT_FROM_ID,
      visitFormId: formId,
      svfId: svfId,
      userId: userId
    })
}

export const updateSelectedDate = (selectedDate) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SELECTED_DATE,
      date: selectedDate,
      userId: getUserId(getState())
    })
}


export const updateSelectedCall = (selectedCall) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SELECTED_CALL,
      selectedCall,
      userId: getUserId(getState())
    })
}

export const updateSelectedSvf = (isSvfUpdated) => async (dispatch, getState) => {
  dispatch({
  type: STORE_SELECTED_SVF_UPDATE,
  isSvfUpdated,
  userId: getUserId(getState())
  })
}


const updateSelectedChat = (selectedChat, userId, isUnreadMsgsPresent) => ({
  type: UPDATE_SELECTEDCHAT,
  selectedChat,
  userId,
  isUnreadMsgsPresent
})


export const setSelectedChat = (selectedChat) => async (dispatch, getState) => {
  const existingChat = getselectedChat(getState())
  const isUnreadMsgsPresent = _.isEqual(
    _.toUpper(existingChat?.id),
    _.toUpper(selectedChat?.id)
  )
  dispatch(updateSelectedChat(selectedChat,getUserId(getState()), isUnreadMsgsPresent ))
}

export const clearSelectedChat = () => async (dispatch, getState) => {
  dispatch({ 
    type: CLEAR_SELECTED_CHAT,
    userId: getUserId(getState())
   })
}

export const setCurrentScreen = (screen) => (dispatch, getState) => {
  dispatch({
    type: STORE_CURRENT_SCREEN,
    screen,
    userId: getUserId(getState())
  })
}

export const clearCurrentSiteId = () => (dispatch) => {
  dispatch({type: UPDATE_CURRENT_SITE_ID, siteId: null})
  removeSiteIdFromSessionStorage()
}


export const updateSelectedFormType = (selectedFormType, userId) => ({
  type: UPDATE_SELECTED_FORMTYPE,
  selectedFormType,
  userId,
})


export const updateIsAcknowledged = (isAcknowledged) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_IS_ACKNOWLEDGED,
    isAcknowledged,
    userId: getUserId(getState())
  })
}

export const questionnaireUpgradeRequired = (isQuestionnaireUpgradeRequired, userId) => async (dispatch) => {
  dispatch({
    type: QUESTIONNAIRE_UPGRADE_REQUIRED,
    isQuestionnaireUpgradeRequired,
    userId
})
}

