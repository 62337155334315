import React, { Component } from 'react'
import { Text, View } from 'react-native'

export class HeaderText extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      value,
      firstTimeLogin,
      heading,
      screenProps: { t },
    } = this.props
    return (
      <View>
        <Text style={{ fontSize: 16, lineHeight: 24, fontFamily: 'Inter', color: '#6E41C0' }}>
          {heading} : {value?.length > 20 ? value?.substring(0, 18) + '...' : value}
          {firstTimeLogin ? (
            <Text
              style={{
                color: 'white',
                background: 'green',
                borderRadius: '50%',
                width: 100,
                height: 100,
                padding: 2,
                margin: 5,
                fontSize: 10,
              }}
            >
              {' '}
              {t('New')}{' '}
            </Text>
          ) : (
            <Text></Text>
          )}
        </Text>
      </View>
    )
  }
}
