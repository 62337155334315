import enLocale from '../utils/localization/locale_en.json'

import api from '../utils/api'
import { getUserId } from '../selectors/user'
import showToast from '../utils/toast'
import { retrieveStudyDetailsSuccess } from './studyMetaData'


export const retrieveStudies = (siteId) => async (dispatch, getState) => {
  try {
    const res = await api.get(`clients/${siteId}/studies`)
    return res.data
  } catch (error) {
    console.log(error)
    showToast(enLocale.FailedRetrieve, 'danger', 3000)
    throw error
  }
}

export const updateSelectedStudy = (study) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  dispatch(retrieveStudyDetailsSuccess(study, userId))
}


