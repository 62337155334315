import { Slider } from '@miblanchard/react-native-slider'
import React from 'react'
import { Dimensions, StyleSheet, Text, View } from 'react-native'
import _ from 'lodash'
import { getFieldUISchemaData } from '../../utils/fieldutils/fieldType'
import { buttonBackgroundColor } from '../../containers/NavigationScreens'

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export default class BasicScale extends React.Component {
  state = {
    sliderDimensions: {
      width: 300,
      height: 500,
      didTouch: false,
      sliderValue: null,
    },
  }

  componentDidMount() {
    let {
      field: { dictionary, crfData },
      fieldAnswer,
    } = this.props
    let labels = []
    let step = 1
    if (dictionary.step) {
      step = Number(dictionary.step)
    }
    if (dictionary.labels) {
      labels = dictionary.labels
    } else {
      labels = dictionary.options
    }
    console.log(dictionary)
    let vertical = dictionary.orientation !== 'Horizontal'
    this.setState(
      {
        min: Number(dictionary.start || dictionary.min),
        max: Number(dictionary.end || dictionary.max),
        step,
        vertical: vertical,
        labels: labels,
        sliderValue: fieldAnswer && fieldAnswer.fieldValue ? Number(fieldAnswer.fieldValue) : null,
        showMarks: dictionary.showMarks,
      },
      () => this.calculateDivisions()
    )
    let sliderDimensions = {
      width: vertical ? 400 : 300,
      height: vertical ? 100 : 300,
    }
    this.setState({ sliderDimensions })
    console.log('labels')
    console.log(this.state.labels)
  }

  calculateDivisions = () => {
    const { min, max, step } = this.state

    let subStep = 0
    if (step % 0.5 === 0) {
      this.setState({ subStep })
    }
    const difference = max - min
    const noOfDivisions = difference / step

    const divisionPositions = []
    Array(difference + 2)
      .fill(0)
      .map((_, index) => {
        divisionPositions.push(index)
      })
    this.setState({ divisionPositions })
    console.log('min', min)
    console.log('max', max)
    console.log('step', step)
  }

  renderTrackMarkComponent = (index) => {
    let {
      field: { dictionary },
    } = this.props
    const placementSet = { top: -1, bottom: 1 }
    const labelObj = _.find(dictionary.options, (o) => o.position == index)
    const placement = placementSet[labelObj?.placement || 'top']
    const textAlign = labelObj?.textAlign || 'left'
    const { min, max, step, vertical, showMarks, sliderValue } = this.state
    let marginTop = showMarks ? 55 : 25
    if (placement == -1) {
      marginTop = showMarks ? -250 : -200
    }
    let markerStyle = {
      width: StyleSheet.hairlineWidth,
      height: 20,
    }
    if (index % 5 == 0 || index - 1 == max) {
      markerStyle.width = 1
      markerStyle.height = 30
    }
    // if(index == 0){
    // markerStyle.left = index === 0 ? 0 : 20;
    // }
    // if(index == 10){
    //   markerStyle.marginRight = 1;
    // }
    // console.log(index);
    const isMinOrMax =
      ((!sliderValue || sliderValue === min) && index === min) ||
      (sliderValue === max && max === index - 1)
    // alert(isMinOrMax)
    return (
      <React.Fragment>
        {showMarks && (
          <View
            style={{
              marginHorizontal: -1,
              // left: 1,
              borderRightColor: isMinOrMax ? 'red' : 'rgb(144, 164, 174)',
              borderRightWidth: markerStyle.width,
              height: isMinOrMax ? 35 : markerStyle.height,
              width: markerStyle.width,
              // backgroundColor: "teal",
            }}
          ></View>
        )}
        {showMarks && (
          <>
            {index % 5 == 0 && (
              <Text
                style={{
                  marginTop: 35,
                  marginLeft: -2,
                  position: 'absolute',
                  fontSize: 8,
                  color: '#000000',
                  transform: [{ rotate: vertical ? '90deg' : '0deg' }],
                }}
              >
                {index}
              </Text>
            )}
          </>
        )}
        {labelObj && (
          <View
            style={{
              width: 40,
              height: 'auto',
              marginTop: marginTop,
              position: 'absolute',
              justifyContent: 'center',
              alignItems: vertical ? 'flex-end' : 'center',
              marginLeft: vertical ? 0 : -14,
            }}
          >
            <Text
              style={[{
                marginLeft: showMarks ? -6 : -18,
                width: vertical ? 60 : 40,
                color: '#000000',
                fontSize: 8,
                transform: [{ rotate: vertical ? '90deg' : '0deg' }],
                textAlign: textAlign,
              }, vertical ? {marginTop: 30} : {}]}
            >
              {labelObj.value}
            </Text>
          </View>
        )}
      </React.Fragment>
    )
  }

  onSliderChange = (value) => {
    const {
      form,
      field: { id },
    } = this.props
    const { setFieldsValue, getFieldsValue } = form
    setFieldsValue({
      [id]: value[0],
    })
    this.setState({ sliderValue: value[0] })
  }

  getThumbStyle = (value) => {
    // if(value){
    //   return {
    //     height: 5,
    //     width: 5
    //   }
    // }
    return {
      display: 'none',
      width: 1,
      height: 10,
      borderWidth: 1,
    }
  }
  render() {
    const {
      field: {
        dictionary: { options, orientation, isPercentage, showValue },
        id,
        isRequired,
        uiSchema: fdUISchema,
      },
      form,
      t,
      disabled,
      fieldAnswer,
    } = this.props
    const { min, max, step, vertical, sliderDimensions, sliderValue } = this.state
    const crfData = { ...fieldAnswer }
    return (
      <View
        style={{
          marginTop: getFieldUISchemaData(fdUISchema, 'options', 'top', 0),
          marginBottom: getFieldUISchemaData(fdUISchema, 'options', 'bottom', 0),
          height: vertical ? 600 : 300
        }}
        accessible = {true}
        testID= {`${id}-scale-container`}
      >
        {showValue && (
          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <Text style={{ fontWeight: '800' }}>{`Selected Value : `}</Text>
            <Text style={{ borderWidth: 1, padding: 2, width: 30, textAlign: 'center' }}>
              {this.state.sliderValue || min }
            </Text>
          </View>
        )}
        {form.getFieldDecorator(`${id}`, {
          type: 'number',
          initialValue: crfData && crfData.fieldValue ? Number(crfData.fieldValue) : null,
          rules: [
            {
              required: isRequired,
              message: t('BScaleValMsg'),
            },
          ],
        })(
          <View style = {{ flex: 0.9, 
            height: vertical ? 500 : 200,
            width: SCREEN_WIDTH >= 1030 ? 800 : SCREEN_WIDTH,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20
            }}>
          <Slider
            step={step}
            minimumValue={min}
            maximumValue={max}
            containerStyle={{
              width: sliderDimensions.width,
              height: sliderDimensions.height,
              alignSelf: 'center',
            }}
            onSlidingComplete={this.onSliderChange}
            vertical={vertical}
            trackMarks={this.state.divisionPositions}
            renderTrackMarkComponent={this.renderTrackMarkComponent}
            disabled={disabled}
            minimumTrackTintColor="#c5c5c5"
            maximumTrackTintColor="#c5c5c5"
            accessible = {true}
            testID= {`${id}-slider`}
            value={sliderValue}
            // minimumTrackStyle = {{height: 2, color: 'pink'}}
            renderThumbComponent={() =>
              !sliderValue || sliderValue === min || sliderValue === max ? (
                <View />
              ) : (
                <View
                accessible = {true}
                testID= {`${id}-slider-thumb`}
                  style={{
                    marginHorizontal: -1,
                    // left: 1,
                    borderRightColor: 'red',
                    borderRightWidth: 1,
                    height: 35,
                    width: 1,
                    // backgroundColor: "teal",
                  }}
                ></View>
              )
            }
            // thumbStyle = {this.getThumbStyle(crfData && crfData.fieldValue)}
          />
          </View>
        )}
      </View>
    )
  }
}
