export default {
    "Africa/Abidjan": "אפריקה/אבידג'אן",
"Africa/Accra": "אפריקה/אקרה",
"Africa/Addis_Ababa": "אפריקה/אדיס_אבבה",
"Africa/Algiers": "אפריקה/אלג'יר",
"Africa/Asmara": "אפריקה/אסמארה",
"Africa/Asmera": "אפריקה/אסמרה",
"Africa/Bamako": "אפריקה/במקו",
"Africa/Bangui": "אפריקה/בנגוי",
"Africa/Banjul": "אפריקה/בנג'ול",
"Africa/Bissau": "אפריקה/ביסאו",
"Africa/Blantyre": "אפריקה/בלנטייר",
"Africa/Brazzaville": "אפריקה/ברזוויל",
"Africa/Bujumbura": "אפריקה/בוג'ומבורה",
"Africa/Cairo": "אפריקה/קהיר",
"Africa/Casablanca": "אפריקה/קזבלנקה",
"Africa/Ceuta": "אפריקה/סוטה",
"Africa/Conakry": "אפריקה/קונאקרי",
"Africa/Dakar": "אפריקה/דקאר",
"Africa/Dar_es_Salaam": "אפריקה/דאר_אס_סלאם",
"Africa/Djibouti": "אפריקה/ג'יבוטי",
"Africa/Douala": "אפריקה/דואלה",
"Africa/El_Aaiun": "אפריקה/אל_איון",
"Africa/Freetown": "אפריקה/פריטאון",
"Africa/Gaborone": "אפריקה/גאבורון",
"Africa/Harare": "אפריקה/הארארה",
"Africa/Johannesburg": "אפריקה/יוהנסבורג",
"Africa/Juba": "אפריקה/ג'ובה",
"Africa/Kampala": "אפריקה/קמפלה",
"Africa/Khartoum": "אפריקה/חרטום",
"Africa/Kigali": "אפריקה/קיגאלי",
"Africa/Kinshasa": "אפריקה/קינשאסה",
"Africa/Lagos": "אפריקה/לאגוס",
"Africa/Libreville": "אפריקה/ליברוויל",
"Africa/Lome": "אפריקה/לומה",
"Africa/Luanda": "אפריקה/לואנדה",
"Africa/Lubumbashi": "אפריקה/לובומבאשי",
"Africa/Lusaka": "אפריקה/לוסאקה",
"Africa/Malabo": "אפריקה/מלאבו",
"Africa/Maputo": "אפריקה/מפוטו",
"Africa/Maseru": "אפריקה/מזרו",
"Africa/Mbabane": "אפריקה/מבאנה",
"Africa/Mogadishu": "אפריקה/מוגדישו",
"Africa/Monrovia": "אפריקה/מונרוביה",
"Africa/Nairobi": "אפריקה/ניירובי",
"Africa/Ndjamena": "אפריקה/נדג'מנה",
"Africa/Niamey": "אפריקה/ניאמי",
"Africa/Nouakchott": "אפריקה/נואקשוט",
"Africa/Ouagadougou": "אפריקה/אואגאדוגו",
"Africa/Porto-Novo": "אפריקה/פורטו-נובו",
"Africa/Sao_Tome": "אפריקה/סאו_טומה",
"Africa/Timbuktu": "אפריקה/טימבוקטו",
"Africa/Tripoli": "אפריקה/טריפולי",
"Africa/Tunis": "אפריקה/תוניס",
"Africa/Windhoek": "אפריקה/וינדהוק",
"America/Adak": "אמריקה/אדאק",
"America/Anchorage": "אמריקה/אנקורג'",
"America/Anguilla": "אמריקה/אנגווילה",
"America/Antigua": "אמריקה/אנטיגואה",
"America/Araguaina": "אמריקה/אראגואיינה",
"America/Argentina/Buenos_Aires": "אמריקה/ארגנטינה/בואנוס_איירס",
"America/Argentina/Catamarca": "אמריקה/ארגנטינה/קטמרקה",
"America/Argentina/ComodRivadavia": "אמריקה/ארגנטינה/קומודריבדביה",
"America/Argentina/Cordoba": "אמריקה/ארגנטינה/קורדובה",
"America/Argentina/Jujuy": "אמריקה/ארגנטינה/ג'וג'וי",
"America/Argentina/La_Rioja": "אמריקה/ארגנטינה/לה ריוחה",
"America/Argentina/Mendoza": "אמריקה/ארגנטינה/מנדוזה",
"America/Argentina/Rio_Gallegos": "אמריקה/ארגנטינה/ריו_גלגוס",
"America/Argentina/Salta": "אמריקה/ארגנטינה/סלטה",
"America/Argentina/San_Juan": "אמריקה/ארגנטינה/סאנ_חואן",
"America/Argentina/San_Luis": "אמריקה/ארגנטינה/סאנ_לואיס",
"America/Argentina/Tucuman": "אמריקה/ארגנטינה/טוקומן",
"America/Argentina/Ushuaia": "אמריקה/ארגנטינה/אושואיה",
"America/Aruba": "אמריקה/ארובה",
"America/Asuncion": "אמריקה/אסונסיון",
"America/Atikokan": "אמריקה/אטיקוקן",
"America/Atka": "אמריקה/אטקה",
"America/Bahia": "אמריקה/באהיה",
"America/Bahia_Banderas": "אמריקה/באיה_בנדרס",
"America/Barbados": "אמריקה/ברבדוס",
"America/Belem": "אמריקה/בלם",
"America/Belize": "אמריקה/בליז",
"America/Blanc-Sablon": "אמריקה/בלנק-סבלון",
"America/Boa_Vista": "אמריקה/בוא_ויסטה",
"America/Bogota": "אמריקה/בוגוטה",
"America/Boise": "אמריקה/בויז",
"America/Buenos_Aires": "אמריקה/בואנוס_איירס",
"America/Cambridge_Bay": "אמריקה/קמברידג'_ביי",
"America/Campo_Grande": "אמריקה/קמפו_גרנדה",
"America/Cancun": "אמריקה/קנקון",
"America/Caracas": "אמריקה/קראקס",
"America/Catamarca": "אמריקה/קטמרקה",
"America/Cayenne": "אמריקה/קאיין",
"America/Cayman": "אמריקה/קיימן",
"America/Chicago": "אמריקה/שיקגו",
"America/Chihuahua": "אמריקה/צ'יוואווה",
"America/Ciudad_Juarez": "אמריקה/סייוד_חוארז",
"America/Coral_Harbour": "אמריקה/קורל_הארבור",
"America/Cordoba": "אמריקה/קורדובה",
"America/Costa_Rica": "אמריקה/קוסטה_ריקה",
"America/Creston": "אמריקה/קרסטון",
"America/Cuiaba": "אמריקה/קויאבה",
"America/Curacao": "אמריקה/קוראסאו",
"America/Danmarkshavn": "אמריקה/דנמרקשבן",
"America/Dawson": "אמריקה/דוסון",
"America/Dawson_Creek": "אמריקה/דוסון_קריק",
"America/Denver": "אמריקה/דנבר",
"America/Detroit": "אמריקה/דטרויט",
"America/Dominica": "אמריקה/דומיניקה",
"America/Edmonton": "אמריקה/אדמונטון",
"America/Eirunepe": "אמריקה/איירונפה",
"America/El_Salvador": "אמריקה/אל סלבדור",
"America/Ensenada": "אמריקה/אנסנדה",
"America/Fort_Nelson": "אמריקה/פורט_נלסון",
"America/Fort_Wayne": "אמריקה/פורט_וויין",
"America/Fortaleza": "אמריקה/פורטלזה",
"America/Glace_Bay": "אמריקה/גלייס_ביי",
"America/Godthab": "אמריקה/גודטאב",
"America/Goose_Bay": "אמריקה/Goose_Bay",
"America/Grand_Turk": "אמריקה/גרנד_טורק",
"America/Grenada": "אמריקה/גרנדה",
"America/Guadeloupe": "אמריקה/גוואדלופ",
"America/Guatemala": "אמריקה/גואטמלה",
"America/Guayaquil": "אמריקה/גואיאקיל",
"America/Guyana": "אמריקה/גיאנה",
"America/Halifax": "אמריקה/הליפקס",
"America/Havana": "אמריקה/הוואנה",
"America/Hermosillo": "אמריקה/הרמוסילו",
"America/Indiana/Indianapolis": "אמריקה/אינדיאנה/אינדיאנפוליס",
"America/Indiana/Knox": "אמריקה/אינדיאנה/נוקס",
"America/Indiana/Marengo": "אמריקה/אינדיאנה/מרנגו",
"America/Indiana/Petersburg": "אמריקה/אינדיאנה/פטרסבורג",
"America/Indiana/Tell_City": "אמריקה/אינדיאנה/טל_סיטי",
"America/Indiana/Vevay": "אמריקה/אינדיאנה/ווואי",
"America/Indiana/Vincennes": "אמריקה/אינדיאנה/וינסנס",
"America/Indiana/Winamac": "אמריקה/אינדיאנה/וינמאק",
"America/Indianapolis": "אמריקה/אינדיאנפוליס",
"America/Inuvik": "אמריקה/איניוביק",
"America/Iqaluit": "אמריקה/איקאלואיט",
"America/Jamaica": "אמריקה/ג'מייקה",
"America/Jujuy": "אמריקה/ג'וג'וי",
"America/Juneau": "אמריקה/ג'ונו",
"America/Kentucky/Louisville": "אמריקה/קנטאקי/לואיוויל",
"America/Kentucky/Monticello": "אמריקה/קנטאקי/מונטיסלו",
"America/Knox_IN": "אמריקה/נוקס_אין",
"America/Kralendijk": "אמריקה/קרלנדייק",
"America/La_Paz": "אמריקה/לה_פז",
"America/Lima": "אמריקה/לימה",
"America/Los_Angeles": "אמריקה/לאס_אנג'לס",
"America/Louisville": "אמריקה/לואיוויל",
"America/Lower_Princes": "אמריקה/לואר_פרינסס",
"America/Maceio": "אמריקה/מאסיו",
"America/Managua": "אמריקה/מנגואה",
"America/Manaus": "אמריקה/מנאוס",
"America/Marigot": "אמריקה/מריגוט",
"America/Martinique": "אמריקה/מרטיניק",
"America/Matamoros": "אמריקה/מטמורוס",
"America/Mazatlan": "אמריקה/מזטלן",
"America/Mendoza": "אמריקה/מנדוזה",
"America/Menominee": "אמריקה/מנומיני",
"America/Merida": "אמריקה/מרידה",
"America/Metlakatla": "אמריקה/מטלקאטלה",
"America/Mexico_City": "אמריקה/מקסיקו_סיטי",
"America/Miquelon": "אמריקה/מיקלון",
"America/Moncton": "אמריקה/מונקטון",
"America/Monterrey": "אמריקה/מונטריי",
"America/Montevideo": "אמריקה/מונטווידאו",
"America/Montreal": "אמריקה/מונטריאול",
"America/Montserrat": "אמריקה/מונטסראט",
"America/Nassau": "אמריקה/נסאו",
"America/New_York": "אמריקה/ניו_יורק",
"America/Nipigon": "אמריקה/ניפיגון",
"America/Nome": "אמריקה/נום",
"America/Noronha": "אמריקה/נורוניה",
"America/North_Dakota/Beulah": "אמריקה/צפון_דקוטה/ביולה",
"America/North_Dakota/Center": "אמריקה/צפון_דקוטה/מרכז",
"America/North_Dakota/New_Salem": "אמריקה/צפון_דקוטה/ניו_סאלם",
"America/Nuuk": "אמריקה/נואוק",
"America/Ojinaga": "אמריקה/אוג'ינאגה",
"America/Panama": "אמריקה/פנמה",
"America/Pangnirtung": "אמריקה/פנגנירטונג",
"America/Paramaribo": "אמריקה/פרמריבו",
"America/Phoenix": "אמריקה/פיניקס",
"America/Port-au-Prince": "אמריקה/פורט-או-פרינס",
"America/Port_of_Spain": "אמריקה/נמל ספרד",
"America/Porto_Acre": "אמריקה/פורטו_עכו",
"America/Porto_Velho": "אמריקה/פורטו_ולו",
"America/Puerto_Rico": "אמריקה/פוארטו_ריקו",
"America/Punta_Arenas": "אמריקה/פונטה_ארנס",
"America/Rainy_River": "אמריקה/רייניי_ריבר",
"America/Rankin_Inlet": "אמריקה/רנקינ_אינלט",
"America/Recife": "אמריקה/רסיפה",
"America/Regina": "אמריקה/רג'ינה",
"America/Resolute": "אמריקה/נחושה",
"America/Rio_Branco": "אמריקה/ריו_ברנקו",
"America/Rosario": "אמריקה/רוסאריו",
"America/Santa_Isabel": "אמריקה/סנטה_איזבל",
"America/Santarem": "אמריקה/סנטרם",
"America/Santiago": "אמריקה/סנטיאגו",
"America/Santo_Domingo": "אמריקה/סנטו_דומינגו",
"America/Sao_Paulo": "אמריקה/סאו_פאולו",
"America/Scoresbysund": "אמריקה/סקורסביסונד",
"America/Shiprock": "אמריקה/שיפרוק",
"America/Sitka": "אמריקה/סיטקה",
"America/St_Barthelemy": "אמריקה/סנט ברתלמי",
"America/St_Johns": "אמריקה/סט_ג'ונס",
"America/St_Kitts": "אמריקה/סט_קיטס",
"America/St_Lucia": "אמריקה/סנט לוסיה",
"America/St_Thomas": "אמריקה/סט_תומאס",
"America/St_Vincent": "אמריקה/סנט וינסנט",
"America/Swift_Current": "אמריקה/סוויפט_קורנט",
"America/Tegucigalpa": "אמריקה/טגוסיגלפה",
"America/Thule": "אמריקה/תולה",
"America/Thunder_Bay": "אמריקה/ת'נדר_ביי",
"America/Tijuana": "אמריקה/טיחואנה",
"America/Toronto": "אמריקה/טורונטו",
"America/Tortola": "אמריקה/טורטולה",
"America/Vancouver": "אמריקה/ונקובר",
"America/Virgin": "אמריקה/בתולה",
"America/Whitehorse": "אמריקה/וייטהורס",
"America/Winnipeg": "אמריקה/וויניפג",
"America/Yakutat": "אמריקה/יקוטאט",
"America/Yellowknife": "אמריקה/ילונייף",
"Antarctica/Casey": "אנטארקטיקה/קייסי",
"Antarctica/Davis": "אנטארקטיקה/דייויס",
"Antarctica/DumontDUrville": "אנטארקטיקה/דומונטדורוויל",
"Antarctica/Macquarie": "אנטארקטיקה/מקווארי",
"Antarctica/Mawson": "אנטארקטיקה/מוסון",
"Antarctica/McMurdo": "אנטארקטיקה/מקמורדו",
"Antarctica/Palmer": "אנטארקטיקה/פאלמר",
"Antarctica/Rothera": "אנטארקטיקה/רותרה",
"Antarctica/South_Pole": "אנטארקטיקה/דרום קוטב",
"Antarctica/Syowa": "אנטארקטיקה/סיובה",
"Antarctica/Troll": "אנטארקטיקה/טרול",
"Antarctica/Vostok": "אנטארקטיקה/ווסטוק",
"Arctic/Longyearbyen": "ארקטיק/לונגיירביין",
"Asia/Aden": "אסיה/עדן",
"Asia/Almaty": "אסיה/אלמטי",
"Asia/Amman": "אסיה/עמאן",
"Asia/Anadyr": "אסיה/אנאדיר",
"Asia/Aqtau": "אסיה/אקטאו",
"Asia/Aqtobe": "אסיה/אקטובה",
"Asia/Ashgabat": "אסיה/אשגבאט",
"Asia/Ashkhabad": "אסיה/אשחבאד",
"Asia/Atyrau": "אסיה/אטיראו",
"Asia/Baghdad": "אסיה/בגדאד",
"Asia/Bahrain": "אסיה/בחריין",
"Asia/Baku": "אסיה/באקו",
"Asia/Bangkok": "אסיה/בנגקוק",
"Asia/Barnaul": "אסיה/ברנאול",
"Asia/Beirut": "אסיה/ביירות",
"Asia/Bishkek": "אסיה/בישקק",
"Asia/Brunei": "אסיה/ברוניי",
"Asia/Calcutta": "אסיה/כלכותה",
"Asia/Chita": "אסיה/צ'יטה",
"Asia/Choibalsan": "אסיה/צ'ויבלסן",
"Asia/Chongqing": "אסיה/צ'ונגצ'ינג",
"Asia/Chungking": "אסיה/צ'ונגקינג",
"Asia/Colombo": "אסיה/קולומבו",
"Asia/Dacca": "אסיה/דאקה",
"Asia/Damascus": "אסיה/דמשק",
"Asia/Dhaka": "אסיה/דאקה",
"Asia/Dili": "אסיה/דילי",
"Asia/Dubai": "אסיה/דובאי",
"Asia/Dushanbe": "אסיה/דושנבה",
"Asia/Famagusta": "אסיה/פמגוסטה",
"Asia/Gaza": "אסיה/עזה",
"Asia/Harbin": "אסיה/חרבין",
"Asia/Hebron": "אסיה/חברון",
"Asia/Ho_Chi_Minh": "אסיה/הו_צ'י_מין",
"Asia/Hong_Kong": "אסיה/הונג קונג",
"Asia/Hovd": "אסיה/הובד",
"Asia/Irkutsk": "אסיה/אירקוטסק",
"Asia/Istanbul": "אסיה/איסטנבול",
"Asia/Jakarta": "אסיה/ג'קרטה",
"Asia/Jayapura": "אסיה/ג'יאפורה",
"Asia/Jerusalem": "אסיה/ירושלים",
"Asia/Kabul": "אסיה/קאבול",
"Asia/Kamchatka": "אסיה/קמצ'טקה",
"Asia/Karachi": "אסיה/קראצ'י",
"Asia/Kashgar": "אסיה/קשגר",
"Asia/Kathmandu": "אסיה/קטמנדו",
"Asia/Katmandu": "אסיה/קטמנדו",
"Asia/Khandyga": "אסיה/חאנדיגה",
"Asia/Kolkata": "אסיה/קולקטה",
"Asia/Krasnoyarsk": "אסיה/קרסנויארסק",
"Asia/Kuala_Lumpur": "אסיה/קואלה_לומפור",
"Asia/Kuching": "אסיה/קוצ'ינג",
"Asia/Kuwait": "אסיה/כווית",
"Asia/Macao": "אסיה/מקאו",
"Asia/Macau": "אסיה/מקאו",
"Asia/Magadan": "אסיה/מגדן",
"Asia/Makassar": "אסיה/מקאסאר",
"Asia/Manila": "אסיה/מנילה",
"Asia/Muscat": "אסיה/מוסקט",
"Asia/Nicosia": "אסיה/ניקוסיה",
"Asia/Novokuznetsk": "אסיה/נובוקוזנצק",
"Asia/Novosibirsk": "אסיה/נובוסיבירסק",
"Asia/Omsk": "אסיה/אומסק",
"Asia/Oral": "אסיה/אוראלי",
"Asia/Phnom_Penh": "אסיה/פנום_פן",
"Asia/Pontianak": "אסיה/פונטיאנק",
"Asia/Pyongyang": "אסיה/פיונגיאנג",
"Asia/Qatar": "אסיה/קטאר",
"Asia/Qostanay": "אסיה/קוסטנאי",
"Asia/Qyzylorda": "אסיה/קיזילורדה",
"Asia/Rangoon": "אסיה/רנגון",
"Asia/Riyadh": "אסיה/ריאד",
"Asia/Saigon": "אסיה/סייגון",
"Asia/Sakhalin": "אסיה/סחלין",
"Asia/Samarkand": "אסיה/סמרקנד",
"Asia/Seoul": "אסיה/סיאול",
"Asia/Shanghai": "אסיה/שנחאי",
"Asia/Singapore": "אסיה/סינגפור",
"Asia/Srednekolymsk": "אסיה/מרכז קולימסק",
"Asia/Taipei": "אסיה/טאיפיי",
"Asia/Tashkent": "אסיה/טשקנט",
"Asia/Tbilisi": "אסיה/טביליסי",
"Asia/Tehran": "אסיה/טהראן",
"Asia/Tel_Aviv": "אסיה/תל אביב",
"Asia/Thimbu": "אסיה/תימבו",
"Asia/Thimphu": "אסיה/תימפו",
"Asia/Tokyo": "אסיה/טוקיו",
"Asia/Tomsk": "אסיה/טומסק",
"Asia/Ujung_Pandang": "אסיה/אוג'ונג_פנדאנג",
"Asia/Ulaanbaatar": "אסיה/אולן בטור",
"Asia/Ulan_Bator": "אסיה/אולן_בטור",
"Asia/Urumqi": "אסיה/אורומקי",
"Asia/Ust-Nera": "אסיה/אוסט-נרה",
"Asia/Vientiane": "אסיה/ויינטיאן",
"Asia/Vladivostok": "אסיה/ולדיווסטוק",
"Asia/Yakutsk": "אסיה/יקוטסק",
"Asia/Yangon": "אסיה/יאנגון",
"Asia/Yekaterinburg": "אסיה/יקטרינבורג",
"Asia/Yerevan": "אסיה/ירוואן",
"Atlantic/Azores": "אטלנטיק/האיים האזוריים",
"Atlantic/Bermuda": "אטלנטיק/ברמודה",
"Atlantic/Canary": "אטלנטיק/קנרית",
"Atlantic/Cape_Verde": "אטלנטיק/קייפ ורדה",
"Atlantic/Faeroe": "אטלנטיק/פארו",
"Atlantic/Faroe": "אטלנטיק/פארו",
"Atlantic/Jan_Mayen": "אטלנטיק/יאנ_מאיין",
"Atlantic/Madeira": "אטלנטיק/מדיירה",
"Atlantic/Reykjavik": "אטלנטיק/רייקיאוויק",
"Atlantic/South_Georgia": "אטלנטיק/דרום_ג'ורג'יה",
"Atlantic/St_Helena": "אטלנטיק/סנט הלנה",
"Atlantic/Stanley": "אטלנטיק/סטנלי",
"Australia/ACT": "אוסטרליה/ACT",
"Australia/Adelaide": "אוסטרליה/אדלייד",
"Australia/Brisbane": "אוסטרליה/בריסביין",
"Australia/Broken_Hill": "אוסטרליה/ברוקנ_היל",
"Australia/Canberra": "אוסטרליה/קנברה",
"Australia/Currie": "אוסטרליה/קארי",
"Australia/Darwin": "אוסטרליה/דרווין",
"Australia/Eucla": "אוסטרליה/אוקלה",
"Australia/Hobart": "אוסטרליה/הובארט",
"Australia/LHI": "אוסטרליה/LHI",
"Australia/Lindeman": "אוסטרליה/לינדמן",
"Australia/Lord_Howe": "אוסטרליה/לורד_האו",
"Australia/Melbourne": "אוסטרליה/מלבורן",
"Australia/NSW": "אוסטרליה/NSW",
"Australia/North": "אוסטרליה/צפון",
"Australia/Perth": "אוסטרליה/פרת'",
"Australia/Queensland": "אוסטרליה/קווינסלנד",
"Australia/South": "אוסטרליה/דרום",
"Australia/Sydney": "אוסטרליה/סידני",
"Australia/Tasmania": "אוסטרליה/טסמניה",
"Australia/Victoria": "אוסטרליה/ויקטוריה",
"Australia/West": "אוסטרליה/מערב",
"Australia/Yancowinna": "אוסטרליה/ינקווינה",
"Brazil/Acre": "ברזיל/עכו",
"Brazil/DeNoronha": "ברזיל/דנורונה",
"Brazil/East": "ברזיל/מזרח",
"Brazil/West": "ברזיל/מערב",
"CET": "זה",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "קנדה/אטלנטי",
"Canada/Central": "קנדה/מרכז",
"Canada/Eastern": "קנדה/מזרח",
"Canada/Mountain": "קנדה/הר",
"Canada/Newfoundland": "קנדה/ניופאונדלנד",
"Canada/Pacific": "קנדה/פסיפיק",
"Canada/Saskatchewan": "קנדה/ססקצ'ואן",
"Canada/Yukon": "קנדה/יוקון",
"Chile/Continental": "צ'ילה/קונטיננטל",
"Chile/EasterIsland": "צ'ילה/אי הפסחא",
"Cuba": "קובה",
"EET": "לאכול",
"EST": "מזרח",
"EST5EDT": "אסט5EDT",
"Egypt": "מצרים",
"Eire": "אייר",
"Etc/GMT": "וכיו/GMT",
"Etc/GMT+0": "וכו\ GMT+0",
"Etc/GMT+1": "וכו '/ GMT + 1",
"Etc/GMT+10": "וכו\ GMT+10",
"Etc/GMT+11": "וכו\ GMT+11",
"Etc/GMT+12": "וכו\ GMT+12",
"Etc/GMT+2": "וכוץ/GMT+2",
"Etc/GMT+3": "וכוץ/GMT+3",
"Etc/GMT+4": "וכוץ/GMT+4",
"Etc/GMT+5": "וכו '/ GMT + 5",
"Etc/GMT+6": "וכוץ/GMT+6",
"Etc/GMT+7": "וכוץ/GMT+7",
"Etc/GMT+8": "וכוץ/GMT+8",
"Etc/GMT+9": "וכו\ GMT+9",
"Etc/GMT-0": "וכו\ GMT-0",
"Etc/GMT-1": "וכוץ/GMT-1",
"Etc/GMT-10": "וכו\ GMT-10",
"Etc/GMT-11": "וכו' גמט-11",
"Etc/GMT-12": "וכו' גמט-12",
"Etc/GMT-13": "וכו\ GMT-13",
"Etc/GMT-14": "וכו' גמט-14",
"Etc/GMT-2": "וכוץ/גמט-2",
"Etc/GMT-3": "וכוץ/GMT-3",
"Etc/GMT-4": "וכוץ/GMT-4",
"Etc/GMT-5": "וכוץ/GMT-5",
"Etc/GMT-6": "וכוץ/GMT-6",
"Etc/GMT-7": "וכוץ/GMT-7",
"Etc/GMT-8": "וכוץ/GMT-8",
"Etc/GMT-9": "וכו\ GMT-9",
"Etc/GMT0": "וכו\ GMT0",
"Etc/Greenwich": "וכוץ/גריניץ'",
"Etc/UCT": "וכוץ/UCT",
"Etc/UTC": "וכו' / UTC",
"Etc/Universal": "וכו' / אוניברסלי",
"Etc/Zulu": "וכוץ/זולו",
"Europe/Amsterdam": "אירופה/אמסטרדם",
"Europe/Andorra": "אירופה/אנדורה",
"Europe/Astrakhan": "אירופה/אסטרחן",
"Europe/Athens": "אירופה/אתונה",
"Europe/Belfast": "אירופה/בלפסט",
"Europe/Belgrade": "אירופה/בלגרד",
"Europe/Berlin": "אירופה/ברלין",
"Europe/Bratislava": "אירופה/ברטיסלבה",
"Europe/Brussels": "אירופה/בריסל",
"Europe/Bucharest": "אירופה/בוקרשט",
"Europe/Budapest": "אירופה/בודפשט",
"Europe/Busingen": "אירופה/אוטוזינגן",
"Europe/Chisinau": "אירופה/קישינב",
"Europe/Copenhagen": "אירופה/קופנהגן",
"Europe/Dublin": "אירופה/דבלין",
"Europe/Gibraltar": "אירופה/גיברלטר",
"Europe/Guernsey": "אירופה/גרנזי",
"Europe/Helsinki": "אירופה/הלסינקי",
"Europe/Isle_of_Man": "אירופה/האי של האדם",
"Europe/Istanbul": "אירופה/איסטנבול",
"Europe/Jersey": "אירופה/ג'רזי",
"Europe/Kaliningrad": "אירופה/קלינינגרד",
"Europe/Kiev": "אירופה/קייב",
"Europe/Kirov": "אירופה/קירוב",
"Europe/Kyiv": "אירופה/קייב",
"Europe/Lisbon": "אירופה/ליסבון",
"Europe/Ljubljana": "אירופה/לובליאנה",
"Europe/London": "אירופה/לונדון",
"Europe/Luxembourg": "אירופה/לוקסמבורג",
"Europe/Madrid": "אירופה/מדריד",
"Europe/Malta": "אירופה/מלטה",
"Europe/Mariehamn": "אירופה/מאריהמן",
"Europe/Minsk": "אירופה/מינסק",
"Europe/Monaco": "אירופה/מונקו",
"Europe/Moscow": "אירופה/מוסקבה",
"Europe/Nicosia": "אירופה/ניקוסיה",
"Europe/Oslo": "אירופה/אוסלו",
"Europe/Paris": "אירופה/פריז",
"Europe/Podgorica": "אירופה/פודגוריצה",
"Europe/Prague": "אירופה/פראג",
"Europe/Riga": "אירופה/ריגה",
"Europe/Rome": "אירופה/רומא",
"Europe/Samara": "אירופה/סמארה",
"Europe/San_Marino": "אירופה/סן_מרינו",
"Europe/Sarajevo": "אירופה/סרייבו",
"Europe/Saratov": "אירופה/סאראטוב",
"Europe/Simferopol": "אירופה/סימפרופול",
"Europe/Skopje": "אירופה/סקופיה",
"Europe/Sofia": "אירופה/סופיה",
"Europe/Stockholm": "אירופה/שטוקהולם",
"Europe/Tallinn": "אירופה/טאלין",
"Europe/Tirane": "אירופה/טיראן",
"Europe/Tiraspol": "אירופה/טירספול",
"Europe/Ulyanovsk": "אירופה/אוליאנובסק",
"Europe/Uzhgorod": "אירופה/אוז'גורוד",
"Europe/Vaduz": "אירופה/ואדוז",
"Europe/Vatican": "אירופה/הוותיקן",
"Europe/Vienna": "אירופה/וינה",
"Europe/Vilnius": "אירופה/וילנה",
"Europe/Volgograd": "אירופה/וולגוגרד",
"Europe/Warsaw": "אירופה/ורשה",
"Europe/Zagreb": "אירופה/זאגרב",
"Europe/Zaporozhye": "אירופה/זפורוז'יה",
"Europe/Zurich": "אירופה/ציריך",
"GB": "ג'יגה-בייט",
"GB-Eire": "GB אייר",
"GMT": "גמט",
"GMT+0": "גמט+0",
"GMT-0": "גמט-0",
"GMT0": "גמט0",
"Greenwich": "גריניץ'",
"HST": "שסט",
"Hongkong": "הונג קונג",
"Iceland": "איסלנד",
"Indian/Antananarivo": "הודי/אנטננריבו",
"Indian/Chagos": "הודי/צ'אגוס",
"Indian/Christmas": "הודי/חג המולד",
"Indian/Cocos": "הודי/קוקוס",
"Indian/Comoro": "הודי/קומורו",
"Indian/Kerguelen": "הודי/קרגלן",
"Indian/Mahe": "הודי/מאהה",
"Indian/Maldives": "הודים/האיים המלדיביים",
"Indian/Mauritius": "הודי/מאוריציוס",
"Indian/Mayotte": "הודי/מיוט",
"Indian/Reunion": "הודי/ראוניון",
"Iran": "איראן",
"Israel": "ישראל",
"Jamaica": "ג'מייקה",
"Japan": "יפן",
"Kwajalein": "קוואג'ליין",
"Libya": "לוב",
"MET": "עם",
"MST": "MST",
"MST7MDT": "מסט7 מדט",
"Mexico/BajaNorte": "מקסיקו/באג'אנורטה",
"Mexico/BajaSur": "מקסיקו/באג'סור",
"Mexico/General": "מקסיקו/כללי",
"NZ": "NZ",
"NZ-CHAT": "נז צ'אט",
"Navajo": "נבאחו",
"PRC": "PRC",
"PST8PDT": "פסט8פדט",
"Pacific/Apia": "פסיפיק/אפיה",
"Pacific/Auckland": "האוקיינוס השקט/אוקלנד",
"Pacific/Bougainville": "פסיפיק/בוגנוויל",
"Pacific/Chatham": "האוקיינוס השקט/צ'את'ם",
"Pacific/Chuuk": "האוקיינוס השקט/צ'וק",
"Pacific/Easter": "האוקיינוס השקט/חג הפסחא",
"Pacific/Efate": "פסיפיק/אפטה",
"Pacific/Enderbury": "פסיפיק/אנדרברי",
"Pacific/Fakaofo": "האוקיינוס השקט/פקאופו",
"Pacific/Fiji": "האוקיינוס השקט/פיג'י",
"Pacific/Funafuti": "האוקיינוס השקט/פונפוטי",
"Pacific/Galapagos": "האוקיינוס השקט/גלפגוס",
"Pacific/Gambier": "פסיפיק/גמביאר",
"Pacific/Guadalcanal": "האוקיינוס השקט/גוודלנקל",
"Pacific/Guam": "האוקיינוס השקט/גואם",
"Pacific/Honolulu": "האוקיינוס השקט/הונולולו",
"Pacific/Johnston": "האוקיינוס השקט/ג'ונסטון",
"Pacific/Kanton": "האוקיינוס השקט/קנטון",
"Pacific/Kiritimati": "פסיפיק/קיריטימאטי",
"Pacific/Kosrae": "האוקיינוס השקט/קוסרה",
"Pacific/Kwajalein": "האוקיינוס השקט/קוואג'ליין",
"Pacific/Majuro": "פסיפיק/מג'ורו",
"Pacific/Marquesas": "האוקיינוס השקט/מרקסאס",
"Pacific/Midway": "פסיפיק/מידוויי",
"Pacific/Nauru": "האוקיינוס השקט/נאורו",
"Pacific/Niue": "האוקיינוס השקט/ניואה",
"Pacific/Norfolk": "האוקיינוס השקט/נורפולק",
"Pacific/Noumea": "האוקיינוס השקט/נומאה",
"Pacific/Pago_Pago": "פסיפיק/פגו_פאגו",
"Pacific/Palau": "האוקיינוס השקט/פלאו",
"Pacific/Pitcairn": "האוקיינוס השקט/פיטקרן",
"Pacific/Pohnpei": "האוקיינוס השקט/פוהנפיי",
"Pacific/Ponape": "פסיפיק/פונאפה",
"Pacific/Port_Moresby": "האוקיינוס השקט/פורט_מורסבי",
"Pacific/Rarotonga": "האוקיינוס השקט/רארוטונגה",
"Pacific/Saipan": "האוקיינוס השקט/סייפן",
"Pacific/Samoa": "האוקיינוס השקט/סמואה",
"Pacific/Tahiti": "האוקיינוס השקט/טהיטי",
"Pacific/Tarawa": "האוקיינוס השקט/טראווה",
"Pacific/Tongatapu": "האוקיינוס השקט/טונגאטאפו",
"Pacific/Truk": "האוקיינוס השקט/טרוק",
"Pacific/Wake": "האוקיינוס השקט/התעוררות",
"Pacific/Wallis": "האוקיינוס השקט/וואליס",
"Pacific/Yap": "האוקיינוס השקט/יאפ",
"Poland": "פולין",
"Portugal": "פורטוגל",
"ROC": "רוק",
"ROK": "שנה",
"Singapore": "סינגפור",
"Turkey": "טורקיה",
"UCT": "אוקט",
"US/Alaska": 'ארה"ב / אלסקה',
"US/Aleutian": 'ארה"ב / Aleutian',
"US/Arizona": 'ארה"ב/אריזונה',
"US/Central": 'ארה"ב/מרכז',
"US/East-Indiana": 'ארה"ב/מזרח אינדיאנה',
"US/Eastern": 'ארה"ב/מזרח',
"US/Hawaii": 'ארה"ב/הוואי',
"US/Indiana-Starke": 'ארה"ב/אינדיאנה סטארק',
"US/Michigan": 'ארה"ב/מישיגן',
"US/Mountain": 'ארה"ב/הר',
"US/Pacific": 'ארה"ב/פסיפיק',
"US/Samoa": 'ארה"ב/סמואה',
"UTC": "UTC",
"Universal": "יוניברסל",
"W-SU": "וו-סו",
"WET": "רטוב",
"Zulu": "זולו",
}