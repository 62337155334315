import {
  Entypo,
  Feather,
  FontAwesome,
  Foundation,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons'
import { createBrowserApp } from '@react-navigation/web'
import React from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { createAppContainer, createSwitchNavigator } from 'react-navigation'
import { createStackNavigator } from 'react-navigation-stack'
import { createMaterialTopTabNavigator } from 'react-navigation-tabs'
import AppLock from '../components/AppLock'
import { ChatIcons } from '../components/ChatIcons'
import OtpVerification from '../components/forgotPassword/OtpVerification'
import ResetPassword from '../components/forgotPassword/ResetPassword'
import PinChange from '../components/securePIN/PinChange'
import PinValidation from '../components/securePIN/PinValidation'
import SideBar from '../components/SideBar'
import Theme from '../components/Theme'
import appConstants from '../constants/appConstants'
import AddChatScreenPage from './AddChatScreenPage'
import BiometricsPage from './BiometricsPage'
import ChangeLanguage from './changeLanguagePage'
import SubjectTimeZone from './timeZonePage'
import PinSetup from './PinSetup'
import UserValidation from './forgotPasswordPage'
import HealthDetailsPage from './healthDetailsPage'
import ChatListPage from './chatListPage'
import Contact from './contactPage'
import ConversationScreen from './conversatonPage'
import Diary from './diaryPage'
import Faq from './faqPage'
import EDiaryForm from './formPage'
import FormPreview from './FormPreview'
import FormPrint from './FormPrint'
import HealthPage from './HealthPage'
import Home from './homePage'
import InitialScreen from './initialScreen'
import Messages from './messagesPage'
import Passcode from '../components/Passcode/index'
import PastCallsScreen from './pastCallsPage'
import CallDetailsPage from './scheduledCalls/callDetailsPage'
import CallHistoryPage from './scheduledCalls/callHistoryPage'
import ParticipantListPage from './scheduledCalls/participantListPage'
import SelectLanguage from './selectLanguagepage'
import StudyListPage from './studyListPage'
import SubjectListPage from './subjectListPage'
import UpcomingCallsScreen from './upcomingCallsPage'
import VersionInfo from './VersionInfo'
import VitalsPage from './VitalsPage'
import Visits from './Visits'
import SessionTimeoutPage from './sessionTimeoutPage'
import SiteStaffHeaderRight from '../components/siteStaffHeaderRight'
import SubjectDetails from './SubjectDetailsPage'
import SubjectHeaderRight from '../components/SubjectHeaderRight'
import PrintFail from '../components/form/print/PrintFail'
import SubjectDataLoaderPage from './subjectDataLoaderPage'
import OfflineDataLoader from './offlineDataLoader'
import Header from '../components/common/header'
import TermsAndConditions from '../components/TermsNConditions/termsAndConditions'
import TermsAndConditionsCancel from '../components/TermsNConditions/TermsAndConditionsCancel'
import { createMaterialBottomTabNavigator } from 'react-navigation-material-bottom-tabs'
import { authorizeTabs } from '../utils/appPrivileges'
import Login from '../components/Login/index'
import AccountSetUp from '../components/Login/components/AccountSetUp'
import SiteStaffProfileHeader from '../components/common/clinroHeader/index'
import VisitsScreenHeader from '../components/common/VisitsScreenHeader'
import HeaderRight from '../components/common/headerRight'

const isWeb = Platform.OS === 'web'
const tintColor = '#212121'
const fontFamily = 'Inter'
const fontWeight = '400'

export const backgroundColor = '#ffffff'
export const buttonBackgroundColor = '#9155FD'

const HomeStack = createStackNavigator(
  {
    Home: {
      screen: Home,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header canShowBackIcon={false} navigation={navigation} title={t('HomeTitle')} headerRight={true}/>
        ),
      }),
    },
    Diary: {
      screen: Diary,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: null,
        gestureEnabled: false,
        headerLeft: () => <Header canShowBackIcon={true} navigation={navigation} headerRight={true}/>,
      }),
    },
    Form: EDiaryForm,
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerVisible: true,
      headerStyle: {
        height: 65,
        backgroundColor,
        borderBottomWidth: 0,
      },

      headerTintColor: tintColor,
      headerTitleStyle: {
        fontWeight,
        fontFamily,
        fontSize: 24,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      gestureEnabled: false,
      headerTitle: null,
      headerRight: () =>
        Platform.OS === 'web' ? (
          <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
        ) : null,
    }),
  }
)

const CallDetailTabs = createMaterialTopTabNavigator(
  {
    CallDetails: {
      screen: CallDetailsPage,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('CallDetails'),
        gestureEnabled: false,
      }),
    },
    Participants: {
      screen: ParticipantListPage,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('Participants'),

        gestureEnabled: false,
      }),
    },
    CallHistory: {
      screen: CallHistoryPage,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('CallHistory'),

        gestureEnabled: false,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarOptions: {
        indicatorStyle: {
          backgroundColor: '#9155fd',
        },
        style: {
          backgroundColor,
        },
        upperCaseLabel: false,
        labelStyle: {
          fontSize: 16,
          fontFamily,
          color: 'black',
        },
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      headerTintColor: '#eceff1',
      headerTitleStyle: {
        fontWeight: 'bold',
        alignSelf: 'center',
      },
      gestureEnabled: false,
      headerRight: () =>
        Platform.OS === 'web' ? (
          <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
        ) : null,
    }),
  },
  {
    initialRouteName: 'CallDetails',
    inactiveTintColor: '#90a4ae',
  }
)

const FormPrintStack = createStackNavigator(
  {
    FormPrint: {
      screen: FormPrint,
      path: '/:formId',
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        header: null,
      }),
    },
    PrintFail: {
      screen: PrintFail,
      navigationOptions: ({ navigation }) => ({
        header: null,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      headerStyle: {
        backgroundColor,
        height: 64,
        borderBottomWidth: 0,
      },
      gestureEnabled: false,
      headerLeft: () => <Header navigation={navigation} canShowBackIcon={false} headerRight={false}/>,
    }),
  }
)

const DiaryStack = createStackNavigator(
  {
    Visits,
    Diary,
    Diary: {
      screen: Diary,
      navigationOptions: ({ navigation, screenProps }) => ({
        headerLeft: () => <Header navigation={navigation} canShowBackIcon={true} headerRight={false}/>,
        headerVisible: true,
        headerStyle: {
          height: Platform.OS === 'web' ? 80 : 110,
          backgroundColor,
          borderBottomWidth: 0,
        },
        headerTintColor: tintColor,
        gestureEnabled: false,
        headerForceInset: {
          top: 'never',
          bottom: 'never',
        },
        headerRight: () => <HeaderRight navigation={navigation} screenProps={screenProps} />,
      }),
    },
    Form: EDiaryForm,
    Visits: {
      screen: Visits,
      navigationOptions: ({ navigation, screenProps }) => ({
        gestureEnabled: false,
        headerTitle: null,
        header: () => <VisitsScreenHeader navigation={navigation} screenProps={screenProps} />
      }),
    },
    CallDetails: {
      screen: CallDetailTabs,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        headerLeft: () => (
          <Header navigation={navigation} canShowBackIcon={true} title={t('CallDetails')} headerRight={true}/>
        ),
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerTitle: null,
      headerStyle: {
        height: 64,
        backgroundColor,
        borderBottomWidth: 0,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      headerTintColor: tintColor,
      headerTitleStyle: {
        fontSize: 24,
        fontWeight,
        fontFamily,
      },
      gestureEnabled: false,
    }),
  }
)

const MoreOptionsStack = createStackNavigator(
  {
    More: {
      screen: SideBar,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Actn_sheetMore')} canShowBackIcon={false} headerRight={true}/>
        ),
      }),
    },
    Messages: {
      screen: Messages,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Notifications')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    ChangeTheme: Theme,
    FAQ: {
      screen: Faq,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('FAQTitle')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    ChangeLanguage,
    Contact: {
      screen: Contact,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Actn_sheetContact')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    // Form: EDiaryForm,
    SubjectTimeZone: {
      screen: SubjectTimeZone,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('SelectTimezone')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    AppLock: {
      screen: AppLock,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('AppLock')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    PinChange: {
      screen: PinChange,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('AppLock')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    PinSetup: {
      screen: PinSetup,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        header: null,
      }),
    },
    Biometrics: {
      screen: BiometricsPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Biometrics')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    Vitals: {
      screen: VitalsPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Vitals')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    Activity: {
      screen: HealthDetailsPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('Activity')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    Health: {
      screen: HealthPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('HealthMenu')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    SubjectDetails: {
      screen: SubjectDetails,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('myProfile')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
    VersionInfo: {
      screen: VersionInfo,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerVisible: true,
        headerStyle: {
          height: 50,
          backgroundColor,
        },
        headerTintColor: tintColor,
        headerTitleStyle: {
          fontWeight: 'bold',
          fontFamily,
          fontSize: 22,
        },
        headerForceInset: {
          top: 'never',
          bottom: 'never',
        },
        gestureEnabled: false,
        headerTitle: null,
        headerLeft: () => (
          <Header navigation={navigation} title={t('VersionInfo')} canShowBackIcon={true} headerRight={true}/>
        ),
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      gestureEnabled: false,
      headerVisible: true,
      headerTitleStyle: {
        fontWeight,
        fontFamily,
        fontSize: 24,
      },
      headerStyle: {
        height: 64,
        backgroundColor,
        borderBottomWidth: 0,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      headerTintColor: tintColor,
      headerRight: () =>
        Platform.OS === 'web' ? (
          <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
        ) : null,
    }),
  }
)

const MeetingScheduleTabs = createMaterialTopTabNavigator(
  {
    UpcomingCalls: {
      screen: UpcomingCallsScreen,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('UpcomingCalls'),
        gestureEnabled: false,
      }),
    },
    PastCalls: {
      screen: PastCallsScreen,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('PastCalls'),
        gestureEnabled: false,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      tabBarOptions: {
        indicatorStyle: {
          backgroundColor: '#9155fd',
        },
        style: {
          backgroundColor,
        },
        upperCaseLabel: false,
        labelStyle: {
          fontSize: 16,
          fontFamily,
          color: 'black',
        },
      },
      headerVisible: true,
      gestureEnabled: false,
      headerTitleStyle: {
        fontWeight: 'bold',
        fontFamily,
        fontSize: 22,
      },
      headerStyle: {
        backgroundColor,
        height: 120,
      },
      headerTintColor: tintColor,
      headerRight: () =>
        Platform.OS === 'web' ? (
          <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
        ) : null,
    }),
  },
  {
    initialRouteName: 'UpcomingCalls',
    inactiveTintColor: '#90a4ae',
  }
)

const MeetingScheduleStack = createStackNavigator(
  {
    MeetingScheduleTabs: {
      screen: MeetingScheduleTabs,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        headerLeft: () => (
          <Header navigation={navigation} canShowBackIcon={false} title={t('MeetingSchedule')} headerRight={true}/>
        ),
      }),
    },
    CallDetails: {
      screen: CallDetailTabs,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        headerLeft: () => (
          <Header navigation={navigation} canShowBackIcon={true} title={t('CallDetails')} headerRight={true}/>
        ),
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerVisible: true,
      headerStyle: {
        height: 64,
        backgroundColor,
        borderBottomWidth: 0,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      gestureEnabled: false,
      headerVisible: true,
      headerTitleStyle: {
        fontWeight,
        fontFamily,
        fontSize: 24,
      },
      headerTintColor: tintColor,
      headerRight: () =>
        Platform.OS === 'web' ? (
          <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
        ) : null,
    }),
  }
)

const ChatStack = createStackNavigator(
  {
    Chat: {
      screen: ChatListPage,
      navigationOptions: ({ navigation, screenProps }) => ({
        gestureEnabled: false,
        headerTitle: null,
        headerLeft: () => <Header navigation={navigation} title={screenProps.t('Chat')} canShowBackIcon={false} headerRight={true}/>,
        headerRight: () =>
          Platform.OS === 'web' ? (
            <SubjectHeaderRight color="#6E41C0" navigation={navigation} screenProps={screenProps} />
          ) : null,
      }),
    },
    Conversation: {
      screen: ConversationScreen,
      navigationOptions: ({ navigation, screenProps }) => ({
        gestureEnabled: false,
        headerLeft: () => <Header navigation={navigation} canShowBackIcon={true} headerRight={false}/>,
      }),
    },
    AddChatScreen: AddChatScreenPage,
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerVisible: true,
      headerStyle: {
        height: 64,
        backgroundColor,
        borderBottomWidth: 0,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      headerTintColor: tintColor,
      headerTitleStyle: {
        fontSize: 24,
        fontWeight,
        fontFamily,
      },
      gestureEnabled: false,
    }),
  }
)

const tabsOrder = () => {
  return authorizeTabs()
}

const ParticipantStack = createMaterialBottomTabNavigator(
  {
    homeTab: {
      screen: HomeStack,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: '',
        gestureEnabled: false,
      }),
    },
    diaryTab: {
      screen: DiaryStack,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('HomePatnt_Diary'),
        gestureEnabled: false,
        tabBarOnPress: ({ navigation }) => navigation.navigate('Visits', { isTraining: false }),
      }),
    },
    meetingScheduleTab: {
      screen: MeetingScheduleStack,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('Calls'),
        gestureEnabled: false,
      }),
    },
    chatTab: {
      screen: ChatStack,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        gestureEnabled: false,
        title: t('Chat'),
      }),
    },
    moreTab: {
      screen: MoreOptionsStack,
      navigationOptions: ({ screenProps: { t } }) => ({
        title: t('Actn_sheetMore'),
        gestureEnabled: false,
      }),
    },
  },
  {
    defaultNavigationOptions: ({
      navigation,
      screenProps: { unreadChats, subjectPrivileges },
    }) => ({
      tabBarButtonComponent: TouchableOpacity,
      tabBarIcon: ({ focused, tintColor }) => {
        const { routeName } = navigation.state
        if (routeName === 'homeTab') {
          return focused ? (
            <Foundation name="home" size={24} color={tintColor} />
          ) : (
            <Feather name="home" size={24} color={tintColor} />
          )
        }
        if (routeName === 'diaryTab') {
          return focused ? (
            <MaterialCommunityIcons name="book-open-page-variant" size={24} color={tintColor} />
          ) : (
            <Entypo name="book" size={24} color={tintColor} />
          )
        }
        if (routeName === 'moreTab') {
          return (
            <View>
              <View style={{ flexDirection: 'row' }}>
                <FontAwesome name="heart" size={12} color={tintColor} />
                <MaterialCommunityIcons name="bell" size={12} color={tintColor} />
              </View>
              <View style={{ flexDirection: 'row' }}>
                <MaterialIcons name="lock" size={12} color={tintColor} />
                <Feather name="globe" size={12} color={tintColor} />
              </View>
            </View>
          )
        }
        if (routeName === 'meetingScheduleTab') {
          return focused ? (
            <MaterialCommunityIcons name="phone" size={26} color={tintColor} />
          ) : (
            <Feather name="phone" size={24} color={tintColor} />
          )
        }
        if (routeName === 'chatTab') {
          return <ChatIcons focused={focused} unreadChats={unreadChats} tintColor={tintColor} />
        }
      },
    }),
    order: tabsOrder(),
    resetOnBlur: true,
    initialRouteName: tabsOrder()[0],
    theme: 'light',
    activeColor: 'black',
    inactiveColor: '#90a4ae',
    fontWeight: 'bold',
    tabBarPosition: 'bottom',
    barStyleLight: {
      backgroundColor,
      borderTopWidth: 1,
      fontWeight: 'bold',
      fontFamily,
      fontSize: 22,
      borderColor: '#90a4ae',
      height: 80,
    },
    animationEnabled: true,
  }
)

const AuthStack = createStackNavigator(
  {
    InitialScreen: {
      screen: InitialScreen,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    Login: {
      screen: Login,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    Passcode: {
      screen: Passcode,
      navigationOptions: ({ navigation }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerStyle: {
          backgroundColor: '#FFFFFF',
          borderBottomWidth: 0,
        },
        headerLeft: () => (
          <Header
            navigation={navigation}
            title=""
            canShowBackIcon={true}
            showHeaderLogo={false}
            headerRight={false}
          />
        ),
      }),
    },
    ResetPassword: {
      screen: ResetPassword,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header
            navigation={navigation}
            title={navigation.state.params.fromLogin ? t('ChangePassword') : t('ResetPassword')}
            canShowBackIcon={false}
            headerRight={false}
          />
        ),
      }),
    },
    UserValidation: {
      screen: UserValidation,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    OtpVerification: {
      screen: OtpVerification,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    VersionInfo: {
      screen: VersionInfo,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerVisible: true,
        headerTitle: null,
        headerLeft: () => (
          <Header navigation={navigation} title={t('VersionInfo')} canShowBackIcon headerRight={false}/>
        ),
        headerStyle: {
          height: 64,
          backgroundColor,
          borderBottomWidth: 0,
        },

        headerTintColor: tintColor,
        headerTitleStyle: {
          fontWeight,
          fontFamily,
          fontSize: 24,
        },
        headerForceInset: {
          top: 'never',
          bottom: 'never',
        },
        gestureEnabled: false,
      }),
    },
    SessionTimeout: {
      screen: SessionTimeoutPage,
      navigationOptions: ({ navigation }) => ({
        headerVisible: true,
        headerTitle: '',
        headerStyle: {
          height: 65,
          backgroundColor,
        },
        headerLeft: () => <Header canShowBackIcon={false} title={null} navigation={navigation} headerRight={false}/>,
        headerTintColor: tintColor,
        gestureEnabled: false,
        headerForceInset: {
          top: 'never',
          bottom: 'never',
        },
      }),
    },
    AccountSetUp: {
      screen: AccountSetUp,
      navigationOptions: ({ navigation, screenProps }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      headerVisible: true,
      gestureEnabled: false,
    }),
  }
)

const ParticipantAppSetupStack = createStackNavigator(
  {
    PinValidate: {
      screen: PinValidation,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    OfflineDataLoader: {
      screen: OfflineDataLoader,
      navigationOptions: ({ navigation }) => ({
        headerVisible: true,
        headerTitle: '',
        headerStyle: {
          height: 65,
          backgroundColor,
        },
        headerLeft: () => <Header canShowBackIcon={false} title={null} navigation={navigation} headerRight={false}/>,
        headerTintColor: tintColor,
        gestureEnabled: false,
        headerForceInset: {
          top: 'never',
          bottom: 'never',
        },
      }),
    },
    ChangeLanguage: {
      screen: ChangeLanguage,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    SubjectTimeZone: {
      screen: SubjectTimeZone,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerTitle: null,
        gestureEnabled: false,
        headerLeft: () => (
          <Header navigation={navigation} title={t('SelectTimezone')} canShowBackIcon={false} headerRight={false}/>
        ),
      }),
    },
    SelectLanguage: {
      screen: SelectLanguage,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    PinSetup: {
      screen: PinSetup,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    PinChange: {
      screen: PinChange,
      navigationOptions: ({ navigation }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    TermsAndConditions: {
      screen: TermsAndConditions,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
    TermsAndConditionsCancel: {
      screen: TermsAndConditionsCancel,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        headerVisible: false,
        gestureEnabled: false,
        header: null,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      headerVisible: true,
      gestureEnabled: false,
    }),
  }
)

const ClinicianContextStack = createStackNavigator(
  {
    StudyListPage: {
      screen: StudyListPage,
      navigationOptions: ({ navigation, screenProps }) => ({
        header: () => (
          <SiteStaffProfileHeader
            title={screenProps.t('StudyList')}
            navigation={navigation}
            canShowBackIcon={false}
            screenProps={screenProps}
          />
        ),
      }),
    },
    SubjectListPage: {
      screen: SubjectListPage,
      navigationOptions: ({ navigation, screenProps }) => ({
        header: () => (
          <SiteStaffProfileHeader
            navigation={navigation}
            title={screenProps.t('SubjectList')}
            canShowBackIcon={true}
            screenProps={screenProps}
          />
        ),
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerVisible: true,
      gestureEnabled: false,
      headerTitleStyle: {
        fontWeight: 'bold',
        fontFamily,
        fontSize: 22,
        borderBottomWidth: 0,
      },
      headerStyle: {
        backgroundColor,
        height: 120,
      },
      headerTintColor: tintColor,
      headerLeft: () => <Header navigation={navigation} canShowBackIcon={false} />,
      headerRight: () => (
        <SiteStaffHeaderRight color={tintColor} navigation={navigation} screenProps={screenProps} />
      ),
    }),
  }
)

const ClinicianStack = createStackNavigator(
  {
    ClinicianDiary: {
      screen: DiaryStack,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        header: null,
        headerShown: false,
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerVisible: true,
      gestureEnabled: false,
      headerTitleStyle: {
        fontWeight: 'bold',
        fontFamily,
        fontSize: 22,
        borderBottomWidth: 0,
      },
      headerStyle: {
        backgroundColor,
        height: 120,
      },
      headerTintColor: tintColor,
      headerLeft: () => <Header navigation={navigation} canShowBackIcon={false} />,
      headerRight: () => (
        <SiteStaffHeaderRight color={tintColor} navigation={navigation} screenProps={screenProps} />
      ),
    }),
  }
)

const FormPreviewStack = createStackNavigator(
  {
    FormPreview: {
      screen: FormPreview,
      path: '/:formId',
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        title: t('FormPreview'),
        headerTitleStyle: {
          color: tintColor,
          fontWeight: 'bold',
        },
        headerStyle: {
          backgroundColor,
        },
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      headerStyle: {
        backgroundColor,
        height: 64,
        borderBottomWidth: 0,
      },
      headerVisible: true,
      gestureEnabled: false,
      headerLeft: () => <Header navigation={navigation} canShowBackIcon={false} headerRight={false}/>,
    }),
  }
)

const CaregiverStatck = createStackNavigator(
  {
    SubjectDataLoaderPage: {
      screen: SubjectDataLoaderPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        header: null,
        headerLeft: () => <Header navigation={navigation} canShowBackIcon={false} headerRight={false}/>,
      }),
    },
  },
  {
    SubjectListPage: {
      screen: SubjectListPage,
      navigationOptions: ({ navigation, screenProps: { t } }) => ({
        header: null,
        headerLeft: () => (
          <Header navigation={navigation} canShowBackIcon={false} title={t('SubjectList')} headerRight={false}/>
        ),
      }),
    },
  },
  {
    defaultNavigationOptions: ({ navigation, screenProps }) => ({
      headerStyle: {
        height: 64,
        backgroundColor,
        borderBottomWidth: 0,
      },
      headerForceInset: {
        top: 'never',
        bottom: 'never',
      },
      headerTintColor: tintColor,
      headerTitleStyle: {
        fontWeight,
        fontFamily,
        fontSize: 24,
      },
      gestureEnabled: false,
    }),
  }
)
const AppRoutes = createSwitchNavigator({
  [`${appConstants.urlPrefix}Auth`]: AuthStack,
  [`${appConstants.urlPrefix}ParticipantAppSetup`]: ParticipantAppSetupStack,
  [`${appConstants.urlPrefix}ClinicianContext`]: ClinicianContextStack,
  [`${appConstants.urlPrefix}RootTabs`]: ParticipantStack,
  [`${appConstants.urlPrefix}SiteStaffRootTabs`]: ClinicianStack,
  [`${appConstants.urlPrefix}FormPrint`]: FormPrintStack,
  [`${appConstants.urlPrefix}FormPreview`]: FormPreviewStack,
  [`${appConstants.urlPrefix}CaregiverRootTabs`]: CaregiverStatck,
})

const container = isWeb ? createBrowserApp(AppRoutes) : createAppContainer(AppRoutes)

export default container
