import _ from 'lodash'
import { createSelector } from 'reselect'
import constants from '../constants/constants'
import { getUserId } from './user'

export const getSyncQueuePool = (state) => state.syncQueue

export const getSubjectSyncQueue = createSelector([getSyncQueuePool, getUserId], (syncQueues, userId) => {
  return _.get(syncQueues?.[userId], 'data', {} )
})


export const isDataExistsToSync = createSelector(
  [getSubjectSyncQueue],
  (syncQueue) => {
    return !_.isEmpty(
      _.filter(
        syncQueue,
        (item) =>
          item.status === constants.OfflineDataStatus.ACTIVE ||
          item.status === constants.OfflineDataStatus.IN_PROGRESS
      )
    )
  }
)


export const getSyncingStatus = createSelector([getSyncQueuePool, getUserId], (syncQueues, userId) => {
  return _.get(syncQueues?.[userId], 'isSyncing', false )
})


export const getOfflineFormsToSync = createSelector([getSubjectSyncQueue], (syncQueueData) => {
  return _.filter(syncQueueData, (ele) => ele.isActive).length
})