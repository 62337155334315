import React from "react"
import { StyleSheet, View } from "react-native"
import { Slider } from '@react-native-assets/slider'
import PropTypes from 'prop-types'
import { Colors } from "../../../common/ColorScheme"
import constants from "../constants"

const { SHOW_MARKS} = constants;
const VerticalScale = (props) => {
    const {  trackComponent, originalStep, showMarks} = props
    return (
        <View style={styles.container}>
            <Slider 
                {...props}
                CustomMark={(obj) => trackComponent({index: obj.value})}
                value={props.value != null ? props.value : props.minimumValue + (Number(originalStep) - 0.2)} // temp solution to make the min value selectable
                thumbSize={props.value != null ? 0 : 5}
                thumbTintColor = {showMarks !== SHOW_MARKS.SHOW_MARKS ? Colors.lightGrey : "transparent"}
                enabled={!props.disabled}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
VerticalScale.defaultProps = {
    trackComponent: ()=> null,
    value: null,
    disabled: false,
  }
  VerticalScale.propTypes = {
    trackComponent: PropTypes.func,
    value: PropTypes.number,
    minimumValue: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    originalStep: PropTypes.number.isRequired,
    showMarks: PropTypes.string.isRequired
  }
export default VerticalScale