import React, { useState, useEffect, useRef } from 'react'
import {
  StyleSheet,
  View,
  Pressable,
  Modal as AppModal,
  Button,
  Text,
  ImageBackground,
  Platform,
} from 'react-native'
import { Camera } from 'expo-camera'
import { uploadImageToBucket } from '../../actions/form'
import _ from 'lodash'
import WebModal from 'modal-react-native-web'
import { AntDesign } from '@expo/vector-icons'
import { MaterialIcons } from '@expo/vector-icons'
import * as VideoThumbnails from 'expo-video-thumbnails'
import VideoModal from './VideoModal'
import { FontAwesome5 } from '@expo/vector-icons'

const Modal = Platform.OS === 'web' ? WebModal : AppModal
export default function RecordVideo(props) {
  const {
    field: { crfData, id },
  } = props
  const [hasAudioPermission, setHasAudioPermission] = useState(null)
  const [hasCameraPermission, setHasCameraPermission] = useState(null)
  const [camera, setCamera] = useState(null)
  const [urls, setUrls] = useState([])
  const [recordsData, setRecordsData] = useState([])
  const [type, setType] = useState(Camera.Constants.Type.back)
  const video = React.useRef(null)
  const [status, setStatus] = React.useState({})
  const [isRecording, setIsRecording] = React.useState(false)
  const [modalContent, setModalContent] = useState({ visible: false, selectedVideo: '' })

  useEffect(() => {
    ;(async () => {
      const cameraStatus = await Camera.requestCameraPermissionsAsync()
      setHasCameraPermission(cameraStatus.status === 'granted')

      const audioStatus = await Camera.requestMicrophonePermissionsAsync()
      setHasAudioPermission(audioStatus.status === 'granted')
    })()
  }, [])

  useEffect(() => {
    if (crfData && crfData.fieldValue && !_.isEmpty(JSON.parse(crfData.fieldValue))) {
      _.forEach(JSON.parse(crfData.fieldValue), (file) => {
        prepareTheRecordData(file)
      })
    }
  }, [])

  useEffect(() => {
    onVideoFileUploaded()
  }, [JSON.stringify(urls)])

  const onVideoFileUploaded = () => {
    const {
      field: { id },
      form,
    } = props
    const { setFieldsValue } = form
    if (!_.isEmpty(urls)) {
      setFieldsValue({
        [id]: JSON.stringify(urls),
      })
    }
  }

  const takeVideo = async () => {
    setIsRecording(true)
    if (camera) {
      const data = await camera.recordAsync()
      uploadFileToBucket(data)
      setIsRecording(false)
    }
  }

  const uploadFileToBucket = async (recording) => {
    const formData = new FormData()
    formData.append('photo', { uri: recording.uri, name: 'photo.mp4', type: 'video/webm' })
    formData.append('extension', recording.uri.split('.').pop())
    const URL = await uploadImageToBucket(formData)
    if (URL) {
      console.log('uploaded successfully! ')
      alert('uploaded to bucket successfully!')
      prepareTheRecordData(URL)
    } else {
      alert('Failed to upload')
      prepareTheRecordData(recording.uri)
    }
  }

  const generateThumbnail = async (file) => {
    try {
      const { uri } = await VideoThumbnails.getThumbnailAsync(file, {
        time: 15000,
      })
      let data = [...recordsData]
      data.push({
        fileUri: file,
        thumbnail: uri,
      })
      console.log(data)
      setRecordsData(data)
    } catch (e) {
      console.warn(e)
    }
  }

  const stopVideo = () => {
    camera.stopRecording()
  }

  if (hasCameraPermission === null || hasAudioPermission === null) {
    return <View />
  }
  if (hasCameraPermission === false || hasAudioPermission === false) {
    return (
      <React.Fragment>
        <Text>No access to camera</Text>
      </React.Fragment>
    )
  }

  const openVideoInBigWindow = (record) => {
    setModalContent({
      ...modalContent,
      visible: true,
      selectedVideo: record.fileUri,
    })
  }

  const onHide = () => {
    setModalContent({
      ...modalContent,
      visible: false,
      selectedVideo: '',
    })
  }

  const handleDelete = (record) => {
    console.log('deleting vidoe', record)
    const newRecordsData = _.filter(recordsData, (video) => video.fileUri !== record.fileUri)
    setRecordsData(newRecordsData)
  }

  function prepareTheRecordData(bucketUrl) {
    setUrls((prevUrls) => [...prevUrls, bucketUrl])
    generateThumbnail(bucketUrl)
  }

  function getCameraButtons() {
    return (
      <View style={{ flex: 1, justifyContent: 'space-around', flexDirection: 'row' }}>
        <Pressable
          onPress={() => {
            setType(
              type === Camera.Constants.Type.back
                ? Camera.Constants.Type.front
                : Camera.Constants.Type.back
            )
          }}
        >
          <MaterialIcons name="flip-camera-android" size={35} color="black" />
        </Pressable>
        <Pressable onPress={isRecording ? () => stopVideo() : () => takeVideo()}>
          <FontAwesome5 name="record-vinyl" size={35} color={isRecording ? 'red' : 'black'} />
        </Pressable>
      </View>
    )
  }

  return (
    <React.Fragment>
      {/* -----------------------Showing Records----------------------- */}
      <React.Fragment>
        {props.form.getFieldDecorator(id, {
          initialValue: null,
        })(
          <React.Fragment>
            {_.chunk(recordsData, 2).map((recordings, index) => (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginTop: 3,
                  justifyContent: 'center',
                }}
              >
                {recordings.map((record, index) => (
                  <React.Fragment key={`thumbnail-${index}`}>
                    <Pressable onPress={() => openVideoInBigWindow(record)}>
                      <ImageBackground
                        source={Platform.OS == 'web' ? record.thumbnail : { uri: record.thumbnail }}
                        style={{ width: 180, height: 180, marginLeft: 3 }}
                      >
                        <Pressable
                          accessible={true}
                          testID={`${id}-video-bigwindow`}
                          onPress={() => openVideoInBigWindow(record)}
                          style={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                          }}
                        >
                          <AntDesign name="caretright" size={40} color="white" />
                        </Pressable>
                        <Pressable
                          onPress={() => handleDelete(record)}
                          accessible={true}
                          testID={`${id}-video-delete`}
                        >
                          <MaterialIcons
                            name="delete-forever"
                            size={30}
                            color="white"
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              backgroundColor: 'red',
                            }}
                          />
                        </Pressable>
                      </ImageBackground>
                    </Pressable>
                  </React.Fragment>
                ))}
              </View>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
      {/* ------------------------Camera Buttons------------------------ */}
      {Platform.OS !== 'web' && (
        <View style={{ flex: 1 }}>
          <View style={styles.cameraContainer} accessible={true} testID={`${id}-camera-click`}>
            <Camera
              ref={(ref) => setCamera(ref)}
              style={styles.fixedRatio}
              type={type}
              ratio={'4:3'}
            />
          </View>
          <React.Fragment>{getCameraButtons()}</React.Fragment>
        </View>
      )}
      {/* ----------------------------Modal------------------------------ */}
      <View>
        <VideoModal
          visible={modalContent.visible}
          selectedFile={modalContent.selectedVideo}
          hideModal={() => onHide()}
          testID={`${id}-video-close`}
        />
      </View>
      {Platform.OS === 'web' && (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'gray', textAlign: 'center', margin: 5 }}>
            Video recording is not currently supported on web. Please use a mobile device to record
            videos
          </Text>
        </View>
      )}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  cameraContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  fixedRatio: {
    flex: 1,
    aspectRatio: 1,
  },
  video: {
    alignSelf: 'stretch',
    width: 350,
    height: 220,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
