import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { backgroundColor } from '../../../../containers/NavigationScreens'
import Header from '../../header'
import applicationConstants from '../../../../constants/constants'
import Banner from '../../../Banner'
import ProfileHeader from '../../Profile/index'
import _ from 'lodash'
import PropTypes from 'prop-types'

const visitsHeader = (props) => {
  const { appType, onMobile, navigation, screenProps, showBanner=true, onBack } = props

  return (
    <View
      style={{
        backgroundColor: backgroundColor,
        height: 120,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginRight: 5,
          backgroundColor:'white'
        }}
      >
        <Header
          navigation={navigation}
          canShowBackIcon={applicationConstants.AppType.SITESTAFF === appType ? true : false}
          title="Visits"
          appType={appType}
          onPress={
            applicationConstants.AppType.SITESTAFF === appType
              ? () => navigation.navigate('SubjectListPage')
              : () => navigation.goBack()
          }
          headerRight={_.isEqual(applicationConstants.AppType.SITESTAFF, appType) || !onMobile}
          onBack={onBack}
        />
        <ProfileHeader navigation={navigation} screenProps={screenProps} />
      </View>
      {showBanner && (
        <View style={{ marginBottom: 10, marginTop: 10, zIndex: -1 }}>
          <Banner />
        </View>
      )}
    </View>
  )
}

const mapStateToProps = (state) => ({
  appType: state.appStatus.appType,
  onMobile: state.appStatus.onMobile
})

visitsHeader.defaultProps = {
  appType: applicationConstants.AppType.SUBJECT, 
  onMobile: false,
  navigation: {
    goBack: () => null,
    navigate: () => null,
  }, 
  screenProps: {}, 
  showBanner: false,
}

visitsHeader.propTypes = {
  appType: PropTypes.string, 
  onMobile: PropTypes.bool,
  navigation: PropTypes.shape({
    goBack: PropTypes.func,
    navigate: PropTypes.func
  }),
  screenProps: PropTypes.instanceOf(Object),
  showBanner: PropTypes.bool,
  onBack: PropTypes.func.isRequired
}

export default connect(mapStateToProps, null)(visitsHeader)
