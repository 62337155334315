import React from 'react'
import { Body, Header, Left, Right, Text } from 'native-base'
import {
  Image,
  View,
  Platform,
  Pressable,
  Modal as AppModal,
  useWindowDimensions,
} from 'react-native'
import _ from 'lodash'
import { MaterialIcons } from '@expo/vector-icons'
import { backgroundColor } from '../../containers/NavigationScreens'
import WebModal from 'modal-react-native-web'
import { Video } from 'expo-av'

const Modal = Platform.OS === 'web' ? WebModal : AppModal

export default function VideoModal(props) {
  const { visible, selectedFile, hideModal, testID } = props
  const { height: winHeight, width: winWidth } = useWindowDimensions()
  const video = React.useRef(null)
  const closeModal = () => {
    video.current.stopAsync()
    hideModal()
  }

  return (
    <React.Fragment>
      <Modal transparent={true} animationType="slide" visible={visible}>
        <Header style={{ backgroundColor: backgroundColor }}>
          <Left style={{ alignContent: 'flex-start', width: 10 }}>
            <Pressable onPress={closeModal} accessible={true} testID={testID}>
              <Text>
                <MaterialIcons name="arrow-back" color="white" size={24} />
              </Text>
            </Pressable>
          </Left>
          <Body style={{ alignContent: 'flex-start' }}></Body>
          <Right></Right>
        </Header>
        <View style={{ flex: 1, backgroundColor: 'white', marginTop: 10 }}>
          <Video
            ref={video}
            style={{ flex: 1, alignSelf: 'stretch' }}
            source={{ uri: selectedFile }}
            useNativeControls
            resizeMode="contain"
            isLooping
            shouldPlay={true}
          />
        </View>
      </Modal>
    </React.Fragment>
  )
}
