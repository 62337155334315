import { StatusBar } from 'expo-status-bar'
import React, { useEffect } from 'react'
import { Button, StyleSheet, Text, View, Platform } from 'react-native'
import { Audio } from 'expo-av'
import { uploadImageToBucket } from '../../actions/form'
import _ from 'lodash'

function UploadAudio(props) {
  const {
    field: { crfData, id },
  } = props

  const [recording, setRecording] = React.useState()
  const [recordings, setRecordings] = React.useState([])
  const [message, setMessage] = React.useState('')
  const [urls, setUrls] = React.useState([])

  useEffect(() => {
    onAudioFileUploaded()
  }, [JSON.stringify(urls)])

  useEffect(() => {
    if (crfData && crfData.fieldValue && !_.isEmpty(JSON.parse(crfData.fieldValue))) {
      _.forEach(JSON.parse(crfData.fieldValue), (file) => prepareTheRecordData(file))
    }
  }, [])

  async function startRecording() {
    try {
      const permission = await Audio.requestPermissionsAsync()
      if (permission.status === 'granted') {
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        })
        const { recording } = await Audio.Recording.createAsync(
          Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY
        )
        setRecording(recording)
      } else {
        setMessage('Please grant permission to app to access microphone')
      }
    } catch (err) {
      console.error('Failed to start recording', err)
    }
  }

  async function stopRecording() {
    await recording.stopAndUnloadAsync()
    uploadFileToBucket(recording)
    setRecording(undefined)
  }

  const setRecordingForUI = (sound, durationMillis, file) => {
    const updatedRecordings = {
      sound: sound,
      duration: getDurationFormatted(durationMillis),
      file: file,
    }
    setRecordings((prevRecordings) => [...prevRecordings, updatedRecordings])
  }

  const uploadFileToBucket = async (recording) => {
    const formData = new FormData()
    formData.append('photo', { uri: recording.getURI(), name: 'photo.m4a', type: 'audio/webm' })
    formData.append('extension', recording.getURI().split('.').pop())
    const URL = await uploadImageToBucket(formData)
    if (URL) {
      console.log('uploaded successfully! ')
      prepareTheRecordData(URL)
    } else {
      alert('Failed to upload')
    }
    return URL
  }

  const prepareTheRecordData = async (file) => {
    setUrls((prevUrls) => [...prevUrls, file])
    const { sound } = await Audio.Sound.createAsync({ uri: file })
    const status = await sound.getStatusAsync()
    setRecordingForUI(sound, status.durationMillis, file)
  }

  function getDurationFormatted(millis) {
    const minutes = millis / 1000 / 60
    const minutesDisplay = Math.floor(minutes)
    const seconds = Math.round((minutes - minutesDisplay) * 60)
    const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds
    return `${minutesDisplay}:${secondsDisplay}`
  }

  // function getRecordingLines() {
  //   return recordings.map((recordingLine, index) => {
  //     return (
  //       <View key={index} style={styles.row}>
  //         <Text style={styles.fill}>
  //           Recording {index + 1} - {recordingLine.duration}
  //         </Text>
  //         <Button
  //           style={styles.button}
  //           onPress={() => recordingLine.sound.playAsync()}
  //           title="Play"
  //         ></Button>
  //       </View>
  //     )
  //   })
  // }

  const onAudioFileUploaded = () => {
    const {
      field: { id },
      form,
    } = props
    const { setFieldsValue } = form
    if (!_.isEmpty(urls)) {
      setFieldsValue({
        [id]: JSON.stringify(urls),
      })
    }
  }

  return (
    <View style={styles.container}>
      <Text>{message}</Text>
      {Platform.OS !== 'web' && (
        <Button
          style={{ textTransform: 'uppercase' }}
          title={recording ? 'Stop Recording' : 'Start Recording'}
          onPress={recording ? stopRecording : startRecording}
        />
      )}
      {props.form.getFieldDecorator(id, {
        initialValue: null,
      })(
        <React.Fragment>
          {recordings.map((recordingLine, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.fill}>
                Recording {index + 1} - {recordingLine.duration}
              </Text>
              <Button
                style={styles.button}
                onPress={() => recordingLine.sound.playAsync()}
                title="Play"
              ></Button>
            </View>
          ))}
        </React.Fragment>
      )}
      {/* 
      getRecordingLines()

      return recordings.map((recordingLine, index) => {
      return (
        <View key={index} style={styles.row}>
          <Text style={styles.fill}>
            Recording {index + 1} - {recordingLine.duration}
          </Text>
          <Button
            style={styles.button}
            onPress={() => recordingLine.sound.playAsync()}
            title="Play"
          ></Button>
        </View>
      )
    })
      
      */}
      <StatusBar style="auto" />
      {Platform.OS === 'web' && (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'gray', textAlign: 'center', margin: 5 }}>
            Audio recording is not currently supported on web. Please use a mobile device to record
            audios
          </Text>
        </View>
      )}
    </View>
  )
}

export default UploadAudio

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fill: {
    flex: 1,
    margin: 16,
  },
  button: {
    margin: 16,
    textTransform: 'uppercase',
  },
})
