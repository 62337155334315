import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'

import AwesomeAlert from 'react-native-awesome-alerts'
import Animated from 'react-native-reanimated'

const CustomModal = (props) => {
  const {
    title,
    message,
    cancelText,
    confirmText,
    onCancel,
    onConfirm,
    confirmButtonColor,
    showCancelButton = true,
    showConfirmButton = true,
    closeOnTouchOutside = true,
    closeOnHardwareBackPress = false,
    cancelButtonColor = confirmButtonColor,
  } = props
  return (
    <AwesomeAlert
      show={true}
      showProgress={false}
      title={title}
      message={message}
      closeOnTouchOutside={closeOnTouchOutside}
      closeOnHardwareBackPress={closeOnHardwareBackPress}
      showCancelButton={showCancelButton}
      showConfirmButton={showConfirmButton}
      cancelText={cancelText}
      confirmText={confirmText}
      confirmButtonColor={confirmButtonColor}
      cancelButtonColor={cancelButtonColor}
      cancelButtonTextStyle={{ textTransform: 'uppercase' }}
      confirmButtonTextStyle={{ textTransform: 'uppercase' }}
      onCancelPressed={() => {
        onCancel()
      }}
      onConfirmPressed={() => {
        onConfirm()
      }}
      overlayStyle={{ backgroundColor: 'transparent' }}
      modalProps={{ animationType: 'slide' }}
      contentContainerStyle={{ borderWidth: 1, borderColor: '#90A4AE', backgroundColor: '#ffffff' }}
      messageStyle={{ fontSize: 16, fontFamily: 'Inter', color: '#000000' }}
      cancelButtonStyle={{ color: 'white', fontFamily: 'Inter', textTransform: 'uppercase' }}
      confirmButtonStyle={{ color: 'white', fontFamily: 'Inter', textTransform: 'uppercase' }}
    />
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
  },
})

export default CustomModal
