import _ from 'lodash'
import { isInSequence } from '../../scales/utils'
import { getLabelsOfSameLabelPlacement } from './VerticalLabelUtils'
import constants from '../../../constants/constants'

const {VERTICAL_LABEL_HEIGHT_LIMIT} = constants
export const getLabelWidth = (options, number, widthOfBox, start, end, step) => {
    const label = _.find(options, label => Number(label.position) === number)
    if(!_.isEmpty(label)){
        return checkSpace(options, label, widthOfBox, start, end, step)
    }else {
      return 0
    }
}

const checkSpace = (options, label, widthOfBox, start, end, step) => {
    const limit = Math.ceil((((end-start)/step)+1)/4) //1/4th of scale
    const number = Number(label.position)
    const labels = getLabelsOfSameLabelPlacement(options, label.labelPlacement)
    if(number === end){
      return handleEndLabel(number, limit, labels, start, end, step, widthOfBox)
    }else{
      return handleNonEndLabel(number, limit, labels, start, end, step, widthOfBox)
    }
  }

  const handleEndLabel = (number, limit, labels, start, end, step, widthOfBox) => {
        let endLimitNum = (end-((2*limit)*step))
        const orderedLabels = _.orderBy(labels, label => Number(label.position), 'desc');
        const previousLabel = _.find(orderedLabels, label => Number(label.position) < number &&  Number(label.position) > endLimitNum)
        if(_.isEmpty(previousLabel)){
          return Math.floor((end-(end-limit*step))/(step))*widthOfBox
        }else if(!_.isEmpty(previousLabel) && isInSequence(Number(previousLabel.position), start, end, step)){
          return Math.floor(((end-Number(previousLabel.position))/step)*widthOfBox)/2
        }
  }

  const handleBeforeEndLabel = (number, limit, labels, start, end, step, widthOfBox) => {
    const endLabel = _.find(labels, label => Number(label.position)===end)
    if(!_.isEmpty(endLabel) && isInSequence(end, start, end, step)){
      return Math.floor(((end-number)/step)*widthOfBox)/2
    }else{
      return proceessNonEndLabel(number, limit, labels, start, end, step, widthOfBox)
    }
  }

  const proceessNonEndLabel = (number, limit, labels, start, end, step, widthOfBox) => {
    const orderedLabels = _.orderBy(labels, label => Number(label.position), 'asc');
    let limitNumber = limit*step+number > end ? end : limit*step+number
    if(number < limitNumber){
      const nextLabel = _.find(orderedLabels, label => Number(label.position) > number && Number(label.position) <= limitNumber)
      if(!_.isEmpty(nextLabel)){
        if(Number(nextLabel.position) === end && isInSequence(end, start, end, step)){
          return Math.floor(((end-number)/step)*widthOfBox)/2
        }
        return Math.floor(((Number(nextLabel.position)-number)/step)*widthOfBox)
      }
      return Math.floor(((limitNumber-number)/step)*widthOfBox)
    }
  }
  const handleNonEndLabel = (number, limit, labels, start, end, step, widthOfBox) => {
    const labelsInRange = _.map(_.orderBy(_.filter(labels, label => isInSequence(Number(label.position), start, end, step)), lab => Number(lab.position), 'asc'), lb => Number(lb.position))
    const secondToLastNo = isInSequence(end, start, end, step) && _.includes(labelsInRange, end)  ? _.nth(labelsInRange, -2) : _.last(labelsInRange)
    if(number === secondToLastNo){
      return handleBeforeEndLabel(number, limit, labels, start, end, step, widthOfBox)
    }else{
      return proceessNonEndLabel(number, limit, labels, start, end, step, widthOfBox)
    }    
  }


export const getWidthArray = (range, step) => {
  let result = [];
  for (let i = step; i <= range; i += step) {
      result.push(i);
  }
  if (result[result.length - 1] !== range) {
      result.push(range);
  }
  return result;
}

export const getHzLabelHeight = (avaWidth, widthOfBox) => {
  if((widthOfBox < 35 && avaWidth < 2*widthOfBox) || ((avaWidth < (widthOfBox/2)) && ((widthOfBox/2) < 60))){
    return 12
  }else{
    return VERTICAL_LABEL_HEIGHT_LIMIT-10
  }
}