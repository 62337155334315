import React, { useMemo } from 'react'
import { View, Platform, Alert } from 'react-native'
import WebAlert from '../utils/WebAlert'
import moment from 'moment-timezone'
import coreConstants from '../constants/constants'
import PropTypes from 'prop-types'

const ConfirmationPopUp =  (props) => useMemo(() => {
    const {t, user, appStatus, alertText,
        ackTimezone, setTimezone, buttons, shouldHandleTimezoneChange, inferredValue, configuredValue, isSubjectRegistered, isAcknowledged}= props
    return (
        !isAcknowledged && shouldHandleTimezoneChange && 
    user?.privacyPolicy?.agreedToTerms &&
     !user?.resetPassword && !(user?.passwordExpired)  && isSubjectRegistered &&
     inferredValue && configuredValue && appStatus?.appType !== coreConstants.AppType.SITESTAFF &&(
        <View>
    {Platform.OS === 'web' && (
        <WebAlert
        headerText={''}
        hideModal={ackTimezone}
        t={t}
        action={setTimezone}
        message={alertText}
        buttons = {buttons}
        />
    )}
    {Platform.OS !== 'web' && (
         Alert.alert(
         '',
         alertText,
         [
             {
             text: t('NO'),
             onPress: async () => ackTimezone(),
             style: 'cancel',
             },
             {
             text: t('YES'),
             onPress: async () =>  setTimezone(),
             },
         ],
         { cancelable: false }
         )
     )
    }
    </View>
    ));
  }, [props?.configuredValue, props?.isMismatched,props?.isAcknowledged,props?.user?.resetPassword,  props?.isSubjectRegistered]);

const TimeZoneMismatchAlert = (props) => {
const { t, setOrAckTimezoneChange, isTimeZoneNoClicked, user, appStatus, isSubjectRegistered, isAcknowledged} = props
const inferredValue = moment.tz.guess(true);
const configuredValue = user?.timeZone;
const inferredOffset = moment.tz(inferredValue).utcOffset()
const configuredOffset = moment.tz(configuredValue).utcOffset()
const isMismatched = inferredValue && configuredOffset !== inferredOffset
const shouldHandleTimezoneChange = !isAcknowledged && isMismatched
var configOffSet = moment.tz(configuredValue).format('Z')
var inferredOffSet = moment.tz(inferredValue).format('Z')
const buttons = [ 
    { 'title': 'NO', 'action': () => ackTimezone() }, 
    { 'title': 'YES', 'action': () => setTimezone() } 
]

const setTimezone = () =>  {
    setOrAckTimezoneChange(true)
}
const ackTimezone = () => {
    isTimeZoneNoClicked(true);
    setOrAckTimezoneChange(false)
}
const alertText = [
    t('DeviceTimezoneMismatchTitle'),
    '',
    t('DeviceTimezoneMismatchSubtitle'),
    '',
    t('DeviceTimezoneMismatchTzConfigured'),
    '(GMT'+configOffSet+') '+t(configuredValue),
    '',
    t('DeviceTimezoneMismatchTzInferred'),
    '(GMT'+inferredOffSet+') '+t(inferredValue),
].join('\n')

return (
<ConfirmationPopUp 
    t = {t}
    setOrAckTimezoneChange = {setOrAckTimezoneChange}
    isTimeZoneNoClicked ={isTimeZoneNoClicked}
    user ={user}
    appStatus ={appStatus}
    alertText={alertText}
    ackTimezone={ackTimezone}
    setTimezone ={setTimezone}
    buttons={buttons}
    shouldHandleTimezoneChange={shouldHandleTimezoneChange}
    inferredValue={inferredValue}
    configuredValue={configuredValue}
    isMismatched={isMismatched}
    isSubjectRegistered={isSubjectRegistered}
    isAcknowledged={isAcknowledged}
    />)
}



TimeZoneMismatchAlert.defaultProps = {
   user: {
    timeZone: null,
   },
   isSubjectRegistered: false,
   isAcknowledged: false,
  }
  
TimeZoneMismatchAlert.propTypes = {
    user: PropTypes.shape({
        timeZone: PropTypes.string,
    }),
    isSubjectRegistered: PropTypes.bool,
    isAcknowledged: PropTypes.bool,
} 

export default TimeZoneMismatchAlert