import React from 'react'
import PhNumberInput from './PhNumberInput'

const LoginPhoneNoComponent = (props) => {
  const {callingCode,  t,  getPhoneValue } = props

  return(
      <PhNumberInput
      callingCode={callingCode}
      t={t}
      getPhoneValue={getPhoneValue}
      />
    )
  
}

export default LoginPhoneNoComponent
