import { createSelector } from 'reselect'
import { getUserId } from './user'
import _ from 'lodash'

export const getCrfDataPool = (state) => state.crfData 

export const getUserCrfData = createSelector([getCrfDataPool, getUserId], (crfDataPool, userId) => {
    return _.get(crfDataPool, userId, {})
})



