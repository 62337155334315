import React from 'react'
import { View, Text } from 'react-native'
import styles from './styles/visitStyles'

function VisitLabel(props) {
  const {
    screenProps: { t },
    title,
    testID,
  } = props
  return (
    <View style={styles.visitLabelContainer} testID={testID} accessible={true}>
      <Text style={styles.visitText}>{title}</Text>
    </View>
  )
}

export default VisitLabel
