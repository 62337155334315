import React from 'react';
import TrackMarksContainer from '../TrackMarksContainer';
import VerticalTrackMarkContainer from '../VerticalTrackMarkContainer';
import PropTypes from 'prop-types';
import { alignments } from '../../../scales/alignments';
import { Platform } from 'react-native';

const ScaleTrack = (props) => {
    const { orientation } = props;
    if(orientation === alignments.VERTICAL && Platform.OS !== 'web'){
        return (<VerticalTrackMarkContainer {...props} orientation={alignments.MOBILE_VERTICAL}/>)
    }
    return (<TrackMarksContainer
        {...props}
    />)
}

ScaleTrack.defaultProps = {
    orientation: alignments.HORIZONTAL,
}
ScaleTrack.propTypes = {
    orientation: PropTypes.string,
}

export default ScaleTrack;