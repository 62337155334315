import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  headerStyle: {
    fontSize: 20,
    fontFamily: 'Inter',
  },
  containerStyle: {
    backgroundColor: '#fff',
    height: '100%',
  },
  textStyle: {
    fontFamily: 'Inter',
    fontSize: 16,
  },
  subTextStyle: {
    fontFamily: 'Inter',
    fontSize: 12,
    opacity: 0.8,
    marginTop: 5,
  },
  inputTextStyle: {
    fontSize: 20,
    fontFamily: 'Inter',
  },
  myAccountLabelStyle: {
    fontSize: 16,
    fontFamily: 'Inter',
  },
  inputItem: {
    marginTop: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#eeeeee',
    color: '#9e9e9e',
    fontSize: 14,
    fontFamily: 'Inter',
  },
  headerTextContainer: {
    flexDirection: 'column',
  },
  statusText: {
    margin: '2px',
    padding: '2px',
    fontFamily: 'Inter',
    fontSize: 13,
  },
  startVersionContainer: {
    borderWidth: 1,
    borderColor: '#9155FD',
    margin: '2%',
    borderRadius: 12,
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#9155FD',
  },
  versionInfoStyle: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'Inter',
    color: 'white',
  },
})
