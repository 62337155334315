import React, { Component } from 'react'
import api from '../utils/api'
import { serializeQuery } from '../utils/paginationUtils'
import showToast from '../utils/toast'
import { connect } from 'react-redux'
import AddChatScreen from '../components/addChatScreen'
import { bindActionCreators } from 'redux'
import { setSelectedChat } from '../actions/session'
import _ from 'lodash'
import constants from '../constants/constants'
import { getSubjectId } from '../selectors/subject'
import { getUserStudySite } from '../selectors/studyMetaData'
import { getUserSession } from '../selectors/commonSelector'
import PropTypes from 'prop-types'

class AddChatScreenPage extends Component {
  state = {
    data: '',
    isModalVisible: '',
    page: 0,
    loading: false,
    loadingMore: false,
    searchValue: '',
    noOfPages: 0,
    isRefresh: false,
    loGetSelectedParticipants: [],
  }

  componentDidMount() {
    this.retrieveData()
    this.refresh()
  }

  fetchMoreData = () => {
    const { searchValue } = this.state
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
        loadingMore: true,
      }),
      () => {
        // if(searchValue!=''){
        //   this.retrieveSearchData(searchValue);
        // } else {
        this.retrieveData()
        // }
      }
    )
  }

  fetchMoreSearchData = () => {
    const { searchValue } = this.state

    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
        loadingMore: true,
      }),
      () => {
        // if(searchValue!=''){
        //   this.retrieveSearchData(searchValue);
        // } else {
        // this.retrieveSearchData(searchValue);
        // }
      }
    )
  }

  retrieveSearchData = async (value) => {
    const { page, loadingMore, data, noOfPages, isRefresh } = this.state
    const { clientID } = this.props
    this.setState({
      searchValue: value,
    })
    if (isRefresh == true) {
      this.setState({
        noOfPages: 0,
        isRefresh: false,
      })
    }

    if (!loadingMore) {
      this.setState({
        loading: true,
      })
    }
    try {
      const paginationParameters = {
        size: 8,
        page: noOfPages,
      }

      const res = await api.get(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users?subjectId=${constants.ContextProperties.SUBJECT_ID}&${serializeQuery(paginationParameters)}&search=${value}`
      )
      console.log(res.data.content)

      const retrievedData = res.data

      if (retrievedData != '') {
        if (noOfPages > 0) {
          this.setState((prevState) => {
            var prevData = prevState.data
            var dataRetrieved = retrievedData.content

            if (dataRetrieved != undefined) {
              var prevDataIds = []
              var filteredData = []
              prevData.map((value) => {
                prevDataIds.push(value.id)
              })
              dataRetrieved.map((value) => {
                if (!prevDataIds.includes(value.id)) {
                  filteredData.push(value)
                }
              })
              var finalData = prevData.concat(filteredData)
              return {
                data: finalData,
                loading: true,
                loadingMore: true,
                noOfPages: noOfPages + 1,
              }
            } else {
              return {
                data: prevState.data,
                loading: false,
                loadingMore: false,
              }
            }
          })
          this.setState({
            noOfPages: noOfPages + 1,
          })
          this.retrieveSearchData(value)
        } else if (noOfPages == 0) {
          this.setState({
            data: retrievedData.content,
          })
          this.setState({
            noOfPages: noOfPages + 1,
          })
          this.setState({
            isRefresh: false,
          })
          this.retrieveSearchData(value)
        }
      } else {
        this.setState({
          loading: false,
          loadingMore: false,
          noOfPages: 0,
        })
      }
    } catch (e) {
      console.log(e)
      showToast('Failed to Retrieve', 'danger', 2000)
      this.setState({
        loading: false,
        loadingMore: false,
      })
    }
  }

  checkData = (val) => {
    console.log(val)
    if (val != '') {
      this.setState({
        data: [],
        loading: true,
        loadingMore: true,
        noOfPages: 0,
      })
      this.setState({
        isRefresh: true,
      })
      this.retrieveSearchData(val)
    } else {
      this.retrieveData(true)
    }
  }

  retrieveData = async (isRefresh) => {
    const { page, loadingMore } = this.state
    console.log('retrieve data')
    if (!loadingMore) {
      this.setState({
        loading: true,
      })
    }
    try {
      const paginationParameters = {
        size: 8,
        page: isRefresh ? 0 : page,
      }
      const res = await api.get(
        `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users?subjectId=${constants.ContextProperties.SUBJECT_ID}&${serializeQuery(paginationParameters)}`
      )

      if (res.data) {
        const retrievedData = res.data
        if (retrievedData.content != '') {
          this.setState((prevState) => ({
            data: isRefresh ? retrievedData.content : [...prevState.data, ...retrievedData.content],
          }))
        } else {
          this.setState({
            data: retrievedData.content,
          })
        }
      }
      this.setState({
        loading: false,
        loadingMore: false,
      })
    } catch (e) {
      console.log(e)
      showToast('Failed to Retrieve', 'danger', 2000)
      this.setState({
        loading: false,
        loadingMore: false,
      })
    }
  }

  setPageInitial = () => {
    this.setState({
      page: 0,
    })
    this.setState({
      noOfPages: 0,
    })
  }

  refresh = () => {
    const { getSelectedParticipants } = this.props

    this.setState({
      page: 0,
      loGetSelectedParticipants: getSelectedParticipants,
    })
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  navigateToConverstation = async (selectedValues, AddParticipants) => {
    const {
      navigation,
      subjectId,
      chatId,
      setSelectedChat: loSetSelectedChat,
      selectedChat,
    } = this.props
    const {
      screenProps: { t },
    } = this.props
    const today = new Date()
    console.log('Chat iD')
    console.log(chatId)
    var getSelectedParticipants = []
    AddParticipants.map((val) => {
      const obj = {
        ezProChat: {
          id: chatId,
        },
        participantPkId: val.id,
        type: 'SITE_STAFF',
        lastSeenDate: today,
        isActive: true,
      }

      getSelectedParticipants.push(obj)
    })
    try {
      const res = await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${chatId}/participants?subjectId=${constants.ContextProperties.SUBJECT_ID}`, getSelectedParticipants)
      if (res.data) {
        if (res.data.isNewChat) {
          const participants = res.data.participants
          var chat = selectedChat
          _.map(participants, (p) => {
            chat.ezProChatParticipants.push(p)
          })
          loSetSelectedChat(chat)
        } else {
          const chat = res.data
          loSetSelectedChat(chat.chat)
        }

        if (selectedChat.isNewChat == false) {
          navigation.navigate('Conversation', {
            selectedChat: selectedChat.chat,
            updateLastSeen: () => this.updateLastSeen(selectedChat.chat.id),
          })
        }
      }
    } catch (e) {
      console.log(e)
      showToast(t('SomethingWrong'), 'danger', 3000)
    }
  }

  updateLastSeen = async (selectedChatId) => {
    const { subjectId} = this.props
    const ezProChatParticipant = {
      ezProChat: {
        id: selectedChatId,
      },
      participantPkId: subjectId,
      lastSeenDate: moment().utc(),
    }

    try {
      await api.put(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${selectedChatId}/participants/${constants.ContextProperties.SUBJECT_ID}`, ezProChatParticipant)
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { data, loading } = this.state
    const { navigation, subjectId, clientID, isModalVisible, hideModal, getSelectedParticipants } =
      this.props
    const { screenProps } = this.props

    console.log(getSelectedParticipants)
    let selectedParticipantsIds = []
    if (getSelectedParticipants != '') {
      var listOfSelectedParticipants = getSelectedParticipants()
      selectedParticipantsIds = listOfSelectedParticipants.map((val) => {
        return val.id
      })
    }
    // const selectedLanguage = navigation.getParam('')
    return (
      <AddChatScreen
        fetchMoreSearchData={this.fetchMoreSearchData}
        getSelectedParticipants={getSelectedParticipants}
        selectedParticipantIds={selectedParticipantsIds || ''}
        data={data}
        navigateToConverstation={this.navigateToConverstation}
        setPageInitial={this.setPageInitial}
        navigation={navigation}
        screenProps={screenProps}
        retrieveData={this.retrieveData}
        fetchMoreData={this.fetchMoreData}
        defaultValues={true}
        checkData={this.checkData}
        retrieveSearchData={this.retrieveSearchData}
        refresh={this.refresh}
        loading={loading}
        noChatsMessage={'No Participants Found.'}
        hideModal={hideModal}
        isModalVisible={isModalVisible}
        subjectId={subjectId}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  clientID: _.get(getUserStudySite(state)?.client, 'id', null),
  subjectId: getSubjectId(state),
  chatId: _.get(getUserSession(state)?.selectedChat, 'id', null),
  selectedChat: _.get(getUserSession(state), 'selectedChat', {})
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedChat,
    },
    dispatch
  )

  

AddChatScreenPage.defaultProps = {
  getSelectedParticipants: () => null,
  subjectId: null,
}

AddChatScreenPage.propTypes = {
  getSelectedParticipants: PropTypes.func,
  subjectId: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddChatScreenPage)
