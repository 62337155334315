import React, { Component } from 'react'
import { Modal } from 'react-native'
import api from '../utils/api'
import { serializeQuery } from '../utils/paginationUtils'
import { connect } from 'react-redux'
import AddChatScreen from '../components/addChatScreen'
import HeaderDialogScreen from '../components/HeaderDialogScreen'
import { bindActionCreators } from 'redux'
import { setSelectedChat } from '../actions/session'
import _ from 'lodash'
import constants from '../constants/constants'
import { getSubjectId } from '../selectors/subject'
import { getUserSession } from '../selectors/commonSelector'
class HeaderDialogPage extends Component {
  state = {
    data: '',
    isModalVisible: '',
    page: 0,
    loading: false,
    loadingMore: false,
    searchValue: '',
    noOfPages: 0,
    loselectedParticipants: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const { selectedChat, getSelectedParticipants } = this.props
    var s = getSelectedParticipants()
    this.setState({
      loselectedParticipants: s,
    })
  }

  hideModal = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  removeParticipant = async (value, id, removeParticipantNow) => {
    const { setSelectedChat: loSetSelectedChat, selectedChat } = this.props
    try {
      const today = new Date()
      const ezProChatParticipant = {
        participantPkId: value.id,
        type: 'SITE_STAFF',
        isActive: true,
        ezProChat: {
          id: id,
        },
      }
      const res = await api.delete(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${id}/participants/${ezProChatParticipant.participantPkId}?subjectId=${constants.ContextProperties.SUBJECT_ID}`, 
      {data: ezProChatParticipant}
      )
      removeParticipantNow(value)
    } catch (e) {
      console.log(e)
    }
  }

  removeSubject = async (id, value, removeItem) => {
    const { subjectId, navigation } = this.props
    try {
      console.log(id)
      const ezProChatParticipant = {
        participantPkId: subjectId,
        type: 'SUBJECT',
        isActive: true,
        ezProChat: {
          id: id,
        },
      }
      console.log(ezProChatParticipant)
      const res = await api.delete(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${id}/participants/${ezProChatParticipant.participantPkId}?subjectId=${constants.ContextProperties.SUBJECT_ID}`, {data:ezProChatParticipant})
      removeItem(value)
      navigation.goBack()
    } catch (e) {
      console.log(e)
    }
  }
  render() {
    const { loselectedParticipants } = this.state
    const { getSelectedParticipants } = this.props
    const {
      navigation,
      data,
      setSelectedChat: loSetSelectedChat,
      screenProps,
      selectedChat,
      subjectId,
      isModalVisible,
      hideModal,
    } = this.props
    console.log('retrieved participants')
    console.log(selectedChat)
    return (
      <HeaderDialogScreen
        chatData={selectedChat != '' ? loselectedParticipants : []}
        data={selectedChat}
        subjectId={subjectId}
        selectedChatId={selectedChat.id}
        navigation={navigation}
        screenProps={screenProps}
        hideModal={hideModal}
        isModalVisible={isModalVisible}
        removeParticipant={this.removeParticipant}
        getData={this.getData}
        removeSubject={this.removeSubject}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  subjectId: getSubjectId(state),
  selectedChats: _.get(getUserSession(state), 'selectedChat', {})
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedChat,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDialogPage)
