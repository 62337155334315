export default {
    "Africa/Abidjan": "Աֆրիկա/Աբիջան",
"Africa/Accra": "Աֆրիկա/Ակրա",
"Africa/Addis_Ababa": "Աֆրիկա/Ադիս_Աբաբա",
"Africa/Algiers": "Աֆրիկա/Ալժիր",
"Africa/Asmara": "Աֆրիկա/Ասմարա",
"Africa/Asmera": "Աֆրիկա/Ասմերա",
"Africa/Bamako": "Աֆրիկա/Բամակո",
"Africa/Bangui": "Աֆրիկա/Բանգուի",
"Africa/Banjul": "Աֆրիկա/Բանջուլ",
"Africa/Bissau": "Աֆրիկա/Բիսաու",
"Africa/Blantyre": "Աֆրիկա/Բլանտյր",
"Africa/Brazzaville": "Աֆրիկա/Բրազավիլ",
"Africa/Bujumbura": "Աֆրիկա/Բուժումբուրա",
"Africa/Cairo": "Աֆրիկա/Կահիրե",
"Africa/Casablanca": "Աֆրիկա/Կազաբլանկա",
"Africa/Ceuta": "Աֆրիկա/Սեուտա",
"Africa/Conakry": "Աֆրիկա/Կոնակրի",
"Africa/Dakar": "Աֆրիկա/Դաքար",
"Africa/Dar_es_Salaam": "Աֆրիկա/Դար_ես_Սալաամ",
"Africa/Djibouti": "Աֆրիկա/Ջիբութի",
"Africa/Douala": "Աֆրիկա/Դուալա",
"Africa/El_Aaiun": "Աֆրիկա/Էլ_Աայուն",
"Africa/Freetown": "Աֆրիկա/Ֆրիտաուն",
"Africa/Gaborone": "Աֆրիկա/Գաբորոն",
"Africa/Harare": "Աֆրիկա/Հարարե",
"Africa/Johannesburg": "Աֆրիկա/Յոհանեսբուրգ",
"Africa/Juba": "Աֆրիկա/Ձյուբա",
"Africa/Kampala": "Աֆրիկա/Կամպալա",
"Africa/Khartoum": "Աֆրիկա/Խարտում",
"Africa/Kigali": "Աֆրիկա/Կիգալի",
"Africa/Kinshasa": "Աֆրիկա/Կինշասա",
"Africa/Lagos": "Աֆրիկա/Լագոս",
"Africa/Libreville": "Աֆրիկա/Լիբրևիլ",
"Africa/Lome": "Աֆրիկա/Լոմե",
"Africa/Luanda": "Աֆրիկա/Լուանդա",
"Africa/Lubumbashi": "Աֆրիկա/Լուբումբաշի",
"Africa/Lusaka": "Աֆրիկա/Լուսակա",
"Africa/Malabo": "Աֆրիկա/Մալաբո",
"Africa/Maputo": "Աֆրիկա/Մապուտո",
"Africa/Maseru": "Աֆրիկա/Մասերու",
"Africa/Mbabane": "Աֆրիկա/Մբաբանե",
"Africa/Mogadishu": "Աֆրիկա/Մոգադիշու",
"Africa/Monrovia": "Աֆրիկա/Մոնրովիա",
"Africa/Nairobi": "Աֆրիկա/Նաիրոբի",
"Africa/Ndjamena": "Աֆրիկա/Նդջամենա",
"Africa/Niamey": "Աֆրիկա/Նիամեյ",
"Africa/Nouakchott": "Աֆրիկա/Նուակչոտ",
"Africa/Ouagadougou": "Աֆրիկա/Ուագադուգու",
"Africa/Porto-Novo": "Աֆրիկա/Պորտո-Նովո",
"Africa/Sao_Tome": "Աֆրիկա/Սաո_Տոմե",
"Africa/Timbuktu": "Աֆրիկա/Տիմբուկտու",
"Africa/Tripoli": "Աֆրիկա/Տրիպոլի",
"Africa/Tunis": "Աֆրիկա/Թունիս",
"Africa/Windhoek": "Աֆրիկա/Վինդհուկ",
"America/Adak": "Ամերիկա/Ադակ",
"America/Anchorage": "Ամերիկա/Անկորիջ",
"America/Anguilla": "Ամերիկա/Անգիլա",
"America/Antigua": "Ամերիկա/Անտիգուա",
"America/Araguaina": "Ամերիկա/Արագուաինա",
"America/Argentina/Buenos_Aires": "Ամերիկա/Արգենտինա/Բուենոս_Այրես",
"America/Argentina/Catamarca": "Ամերիկա/Արգենտինա/Կատամարկա",
"America/Argentina/ComodRivadavia": "Ամերիկա/Արգենտինա/Կոմոդրիվադավիա",
"America/Argentina/Cordoba": "Ամերիկա/Արգենտինա/Կորդոբա",
"America/Argentina/Jujuy": "Ամերիկա/Արգենտինա/Խուջույ",
"America/Argentina/La_Rioja": "Ամերիկա/Արգենտինա/Լա_Ռիոխա",
"America/Argentina/Mendoza": "Ամերիկա/Արգենտինա/Մենդոզա",
"America/Argentina/Rio_Gallegos": "Ամերիկա/Արգենտինա/Ռիո_Գալեգոս",
"America/Argentina/Salta": "Ամերիկա/Արգենտինա/Սալտա",
"America/Argentina/San_Juan": "Ամերիկա/Արգենտինա/Սան_Ժուան",
"America/Argentina/San_Luis": "Ամերիկա/Արգենտինա/Սան_Լուիս",
"America/Argentina/Tucuman": "Ամերիկա/Արգենտինա/Տուկուման",
"America/Argentina/Ushuaia": "Ամերիկա/Արգենտինա/Ուշուայա",
"America/Aruba": "Ամերիկա/Արուբա",
"America/Asuncion": "Ամերիկա/Ասունսիոն",
"America/Atikokan": "Ամերիկա/Ատիկոկան",
"America/Atka": "Ամերիկա/Ատկա",
"America/Bahia": "Ամերիկա/Բահիա",
"America/Bahia_Banderas": "Ամերիկա/Բահիա_Բանդերաս",
"America/Barbados": "Ամերիկա/Բարբադոս",
"America/Belem": "Ամերիկա/Բելեմ",
"America/Belize": "Ամերիկա/Բելիզ",
"America/Blanc-Sablon": "Ամերիկա/Բլանկ-Սաբլոն",
"America/Boa_Vista": "Ամերիկա/Բոա_Վիստա",
"America/Bogota": "Ամերիկա/Բոգոտա",
"America/Boise": "Ամերիկա/Բոյզ",
"America/Buenos_Aires": "Ամերիկա/Բուենոս_Այրես",
"America/Cambridge_Bay": "Ամերիկա/Քեմբրիջ_Բեյ",
"America/Campo_Grande": "Ամերիկա/Կամպո_Գրանդե",
"America/Cancun": "Ամերիկա/Կանկուն",
"America/Caracas": "Ամերիկա/Կարակաս",
"America/Catamarca": "Ամերիկա/Կատամարկա",
"America/Cayenne": "Ամերիկա/Կայեն",
"America/Cayman": "Ամերիկա/Կայման",
"America/Chicago": "Ամերիկա/Չիկագո",
"America/Chihuahua": "Ամերիկա/Չիհուահուա",
"America/Ciudad_Juarez": "Ամերիկա/Սյուդադ_Խուարես",
"America/Coral_Harbour": "Ամերիկա/Կորալ_Հարբոր",
"America/Cordoba": "Ամերիկա/Կորդոբա",
"America/Costa_Rica": "Ամերիկա/Կոստա_Ռիկա",
"America/Creston": "Ամերիկա/Կրեսթոն",
"America/Cuiaba": "Ամերիկա/Կուիաբա",
"America/Curacao": "Ամերիկա/Կուրակաո",
"America/Danmarkshavn": "Ամերիկա/Դանմարքշավն",
"America/Dawson": "Ամերիկա/Դոուսոն",
"America/Dawson_Creek": "Ամերիկա/Դոսոն_Քրիկ",
"America/Denver": "Ամերիկա/Դենվեր",
"America/Detroit": "Ամերիկա/Դեթրոյթ",
"America/Dominica": "Ամերիկա/Դոմինիկա",
"America/Edmonton": "Ամերիկա/Էդմոնտոն",
"America/Eirunepe": "Ամերիկա/Էիրունեպե",
"America/El_Salvador": "Ամերիկա/Էլ_Սալվադոր",
"America/Ensenada": "Ամերիկա/Էնսենադա",
"America/Fort_Nelson": "Ամերիկա/Ֆորտ_Նելսոն",
"America/Fort_Wayne": "Ամերիկա/Ֆորտ_Ուեյն",
"America/Fortaleza": "Ամերիկա/Ֆորտալեզա",
"America/Glace_Bay": "Ամերիկա/Գլեյս_Բեյ",
"America/Godthab": "Ամերիկա/Գոդթաբ",
"America/Goose_Bay": "Ամերիկա/Գոուս_Բեյ",
"America/Grand_Turk": "Ամերիկա/Գրանդ_Թուրք",
"America/Grenada": "Ամերիկա/Գրենադա",
"America/Guadeloupe": "Ամերիկա/Գվադելուպա",
"America/Guatemala": "Ամերիկա/Գվատեմալա",
"America/Guayaquil": "Ամերիկա/Գուայակիլ",
"America/Guyana": "Ամերիկա/Գայանա",
"America/Halifax": "Ամերիկա/Հալիֆաքս",
"America/Havana": "Ամերիկա/Հավանա",
"America/Hermosillo": "Ամերիկա/Հերմոսիլյո",
"America/Indiana/Indianapolis": "Ամերիկա/Ինդիանա/Ինդիանապոլիս",
"America/Indiana/Knox": "Ամերիկա/Ինդիանա/Նոքս",
"America/Indiana/Marengo": "Ամերիկա/Ինդիանա/Մարենգո",
"America/Indiana/Petersburg": "Ամերիկա/Ինդիանա/Պետերբուրգ",
"America/Indiana/Tell_City": "Ամերիկա/Ինդիանա/Թել_Սիթի",
"America/Indiana/Vevay": "Ամերիկա/Ինդիանա/Վեվեյ",
"America/Indiana/Vincennes": "Ամերիկա/Ինդիանա/Վինչեննես",
"America/Indiana/Winamac": "Ամերիկա/Ինդիանա/Վինամակ",
"America/Indianapolis": "Ամերիկա/Ինդիանապոլիս",
"America/Inuvik": "Ամերիկա/Ինուվիկ",
"America/Iqaluit": "Ամերիկա/Իկալուիտ",
"America/Jamaica": "Ամերիկա/Ջամայկա",
"America/Jujuy": "Ամերիկա/Ջուջույ",
"America/Juneau": "Ամերիկա/Ջունո",
"America/Kentucky/Louisville": "Ամերիկա/Կենտուկի/Լուիսվիլ",
"America/Kentucky/Monticello": "Ամերիկա/Կենտուկի/Մոնտիչելո",
"America/Knox_IN": "Ամերիկա/Նոքս_ին",
"America/Kralendijk": "Ամերիկա/Կրալենդիջկ",
"America/La_Paz": "Ամերիկա/Լա_Պազ",
"America/Lima": "Ամերիկա/Լիմա",
"America/Los_Angeles": "Ամերիկա/Լոս_Անջելես",
"America/Louisville": "Ամերիկա/Լուիսվիլ",
"America/Lower_Princes": "Ամերիկա/Ստորին արքայազներ",
"America/Maceio": "Ամերիկա/Մակեիո",
"America/Managua": "Ամերիկա/Մանագուա",
"America/Manaus": "Ամերիկա/Մանաուս",
"America/Marigot": "Ամերիկա/Մարիգոտ",
"America/Martinique": "Ամերիկա/Մարտինիկա",
"America/Matamoros": "Ամերիկա/Մատամորոս",
"America/Mazatlan": "Ամերիկա/Մազաթլան",
"America/Mendoza": "Ամերիկա/Մենդոզա",
"America/Menominee": "Ամերիկա/Մենոմինի",
"America/Merida": "Ամերիկա/Մերիդա",
"America/Metlakatla": "Ամերիկա/Մետլակաթլա",
"America/Mexico_City": "Ամերիկա/Մեքսիկո_Սիթի",
"America/Miquelon": "Ամերիկա/Միքելոն",
"America/Moncton": "Ամերիկա/Մոնկտոն",
"America/Monterrey": "Ամերիկա/Մոնտերեյ",
"America/Montevideo": "Ամերիկա/Մոնտեվիդեո",
"America/Montreal": "Ամերիկա/Մոնրեալ",
"America/Montserrat": "Ամերիկա/Մոնսերատ",
"America/Nassau": "Ամերիկա/Նասաու",
"America/New_York": "Ամերիկա/Նյու Յորք",
"America/Nipigon": "Ամերիկա/Նիպիգոն",
"America/Nome": "Ամերիկա/Անուն",
"America/Noronha": "Ամերիկա/Նորոնհա",
"America/North_Dakota/Beulah": "Ամերիկա/Հյուսիս_Դակոտա/Բեուլա",
"America/North_Dakota/Center": "Ամերիկա/Հյուսիս_Դակոտա/Կենտրոն",
"America/North_Dakota/New_Salem": "Ամերիկա/Հյուսիս_Դակոտա/Նյու_Սալեմ",
"America/Nuuk": "Ամերիկա/Նուուկ",
"America/Ojinaga": "Ամերիկա/Օջինագա",
"America/Panama": "Ամերիկա/Պանամա",
"America/Pangnirtung": "Ամերիկա/Պանգնիրթունգ",
"America/Paramaribo": "Ամերիկա/Պարամարիբո",
"America/Phoenix": "Ամերիկա/Ֆենիքս",
"America/Port-au-Prince": "Ամերիկա/Պորտ-օ-Պրինս",
"America/Port_of_Spain": "Ամերիկա/Իսպանիայի նավահանգիստ",
"America/Porto_Acre": "Ամերիկա/Պորտո_Ակրե",
"America/Porto_Velho": "Ամերիկա/Պորտո_Վելյո",
"America/Puerto_Rico": "Ամերիկա/Պուերտո_Ռիկո",
"America/Punta_Arenas": "Ամերիկա/Պունտա_Արենաս",
"America/Rainy_River": "Ամերիկա/Ռեյնի_Ռիվեր",
"America/Rankin_Inlet": "Ամերիկա/Ռանկին_Ինլետ",
"America/Recife": "Ամերիկա/Ռեսիֆե",
"America/Regina": "Ամերիկա/Ռեգինա",
"America/Resolute": "Ամերիկա/Վճռական",
"America/Rio_Branco": "Ամերիկա/Ռիո_Բրանկո",
"America/Rosario": "Ամերիկա/Ռոսարիո",
"America/Santa_Isabel": "Ամերիկա/Սանտա_Իզաբել",
"America/Santarem": "Ամերիկա/Սանտարեմ",
"America/Santiago": "Ամերիկա/Սանտյագո",
"America/Santo_Domingo": "Ամերիկա/Սանտո_Դոմինգո",
"America/Sao_Paulo": "Ամերիկա/Սաո_Պաուլո",
"America/Scoresbysund": "Ամերիկա/Սկորեսբիսունդ",
"America/Shiprock": "Ամերիկա/Շիպրոկ",
"America/Sitka": "Ամերիկա/Սիտկա",
"America/St_Barthelemy": "Ամերիկա/Սթ_Բարթելեմի",
"America/St_Johns": "Ամերիկա/Սթ_Ջոնս",
"America/St_Kitts": "Ամերիկա/Սթ_Քիթս",
"America/St_Lucia": "Ամերիկա/Սթ_Լյուսիա",
"America/St_Thomas": "Ամերիկա/Սթ_Թոմաս",
"America/St_Vincent": "Ամերիկա/Սթ_Վինսենթ",
"America/Swift_Current": "Ամերիկա/Swift_Current",
"America/Tegucigalpa": "Ամերիկա/Տեգուչիգալպա",
"America/Thule": "Ամերիկա/Թուլե",
"America/Thunder_Bay": "Ամերիկա/Թանդեր_Բեյ",
"America/Tijuana": "Ամերիկա/Տիժուանա",
"America/Toronto": "Ամերիկա/Տորոնտո",
"America/Tortola": "Ամերիկա/Տորտոլա",
"America/Vancouver": "Ամերիկա/Վանկուվեր",
"America/Virgin": "Ամերիկա/Վիրջին",
"America/Whitehorse": "Ամերիկա/Ուայթհորս",
"America/Winnipeg": "Ամերիկա/Վինիփեգ",
"America/Yakutat": "Ամերիկա/Յակուտատ",
"America/Yellowknife": "Ամերիկա/Յելլոունքայֆ",
"Antarctica/Casey": "Անտարկտիկա/Քեյսի",
"Antarctica/Davis": "Անտարկտիկա/Դևիս",
"Antarctica/DumontDUrville": "Անտարկտիկա/Դյումոնտդուրվիլ",
"Antarctica/Macquarie": "Անտարկտիկա/Մակուարի",
"Antarctica/Mawson": "Անտարկտիկա/Մավսոն",
"Antarctica/McMurdo": "Անտարկտիկա/Մաքմուրդո",
"Antarctica/Palmer": "Անտարկտիկա/Պալմեր",
"Antarctica/Rothera": "Անտարկտիկա/Ռոթերա",
"Antarctica/South_Pole": "Անտարկտիկա/Սաութ_Պոլ",
"Antarctica/Syowa": "Անտարկտիկա/Սյովա",
"Antarctica/Troll": "Անտարկտիկա/Տրոլ",
"Antarctica/Vostok": "Անտարկտիկա/Վոստոկ",
"Arctic/Longyearbyen": "Արկտիկա/Լոնգյարբեն",
"Asia/Aden": "Ասիա/Ադեն",
"Asia/Almaty": "Ասիա/Ալմաթի",
"Asia/Amman": "Ասիա/Ամման",
"Asia/Anadyr": "Ասիա/Անադիր",
"Asia/Aqtau": "Ասիա/Ակթաու",
"Asia/Aqtobe": "Ասիա/Ակտոբե",
"Asia/Ashgabat": "Ասիա/Աշխաբադ",
"Asia/Ashkhabad": "Ասիա/Աշխաբադ",
"Asia/Atyrau": "Ասիա/Ատիրաու",
"Asia/Baghdad": "Ասիա/Բաղդադ",
"Asia/Bahrain": "Ասիա/Բահրեյն",
"Asia/Baku": "Ասիա/Բաքու",
"Asia/Bangkok": "Ասիա/Բանգկոկ",
"Asia/Barnaul": "Ասիա/Բարնաուլ",
"Asia/Beirut": "Ասիա/Բեյրութ",
"Asia/Bishkek": "Ասիա/Բիշքեկ",
"Asia/Brunei": "Ասիա/Բրունեյ",
"Asia/Calcutta": "Ասիա/Կալկոտա",
"Asia/Chita": "Ասիա/Չիտա",
"Asia/Choibalsan": "Ասիա/Չոյբալսան",
"Asia/Chongqing": "Ասիա/Չոնգինգ",
"Asia/Chungking": "Ասիա/Չունգինգ",
"Asia/Colombo": "Ասիա/Կոլոմբո",
"Asia/Dacca": "Ասիա/Դակկա",
"Asia/Damascus": "Ասիա/Դամասկոս",
"Asia/Dhaka": "Ասիա/Դաքա",
"Asia/Dili": "Ասիա/Դիլի",
"Asia/Dubai": "Ասիա/Դուբայ",
"Asia/Dushanbe": "Ասիա/Դուշանբե",
"Asia/Famagusta": "Ասիա/Ֆամագուստա",
"Asia/Gaza": "Ասիա/Գազա",
"Asia/Harbin": "Ասիա/Հարբին",
"Asia/Hebron": "Ասիա/Հեբրոն",
"Asia/Ho_Chi_Minh": "Ասիա/Հո_Չի_Մին",
"Asia/Hong_Kong": "Ասիա/Հոնգ_Կոնգ",
"Asia/Hovd": "Ասիա/Հովդ",
"Asia/Irkutsk": "Ասիա/Իրկուտսկ",
"Asia/Istanbul": "Ասիա/Ստամբուլ",
"Asia/Jakarta": "Ասիա/Ջակարտա",
"Asia/Jayapura": "Ասիա/Ջայապուրա",
"Asia/Jerusalem": "Ասիա/Երուսաղեմ",
"Asia/Kabul": "Ասիա/Քաբուլ",
"Asia/Kamchatka": "Ասիա/Կամչատկա",
"Asia/Karachi": "Ասիա/Կարաչի",
"Asia/Kashgar": "Ասիա/Կաշգար",
"Asia/Kathmandu": "Ասիա/Կաթմանդու",
"Asia/Katmandu": "Ասիա/Կաթմանդու",
"Asia/Khandyga": "Ասիա/Խանդիգա",
"Asia/Kolkata": "Ասիա/Կալկաթա",
"Asia/Krasnoyarsk": "Ասիա/Կրասնոյարսկ",
"Asia/Kuala_Lumpur": "Ասիա/Կուալա_Լումպուր",
"Asia/Kuching": "Ասիա/Կուչինգ",
"Asia/Kuwait": "Ասիա/Քուվեյթ",
"Asia/Macao": "Ասիա/Մակաո",
"Asia/Macau": "Ասիա/Մակաո",
"Asia/Magadan": "Ասիա/Մագադան",
"Asia/Makassar": "Ասիա/Մակասսար",
"Asia/Manila": "Ասիա/Մանիլա",
"Asia/Muscat": "Ասիա/Մուսկատ",
"Asia/Nicosia": "Ասիա/Նիկոսիա",
"Asia/Novokuznetsk": "Ասիա/Նովոկուզնեցկ",
"Asia/Novosibirsk": "Ասիա/Նովոսիբիրսկ",
"Asia/Omsk": "Ասիա/Օմսկ",
"Asia/Oral": "Ասիա/Օրալ",
"Asia/Phnom_Penh": "Ասիա/Փնոմ_Պեն",
"Asia/Pontianak": "Ասիա/Պոնտիանակ",
"Asia/Pyongyang": "Ասիա/Փհենյան",
"Asia/Qatar": "Ասիա/Կատար",
"Asia/Qostanay": "Ասիա/Կոստանայ",
"Asia/Qyzylorda": "Ասիա/Քիզիլորդա",
"Asia/Rangoon": "Ասիա/Ռանգուն",
"Asia/Riyadh": "Ասիա/Ռիադ",
"Asia/Saigon": "Ասիա/Սեյգոն",
"Asia/Sakhalin": "Ասիա/Սախալին",
"Asia/Samarkand": "Ասիա/Սամարկանդ",
"Asia/Seoul": "Ասիա/Սեուլ",
"Asia/Shanghai": "Ասիա/Շանհայ",
"Asia/Singapore": "Ասիա/Սինգապուր",
"Asia/Srednekolymsk": "Ասիա/Սրեդնեկոլիմսկ",
"Asia/Taipei": "Ասիա/Թայփեյ",
"Asia/Tashkent": "Ասիա/Տաշքենդ",
"Asia/Tbilisi": "Ասիա/Թբիլիսի",
"Asia/Tehran": "Ասիա/Թեհրան",
"Asia/Tel_Aviv": "Ասիա/Թել_Ավիվ",
"Asia/Thimbu": "Ասիա/Թիմբու",
"Asia/Thimphu": "Ասիա/Թիմֆու",
"Asia/Tokyo": "Ասիա/Տոկիո",
"Asia/Tomsk": "Ասիա/Տոմսկ",
"Asia/Ujung_Pandang": "Ասիա/Ույունգ_Պանդանգ",
"Asia/Ulaanbaatar": "Ասիա/Ուլանբատոր",
"Asia/Ulan_Bator": "Ասիա/Ուլան_Բատոր",
"Asia/Urumqi": "Ասիա/Ուրումքի",
"Asia/Ust-Nera": "Ասիա/Ուստ-Ներա",
"Asia/Vientiane": "Ասիա/Վյենտյան",
"Asia/Vladivostok": "Ասիա/Վլադիվոստոկ",
"Asia/Yakutsk": "Ասիա/Յակուտսկ",
"Asia/Yangon": "Ասիա/Յանգոն",
"Asia/Yekaterinburg": "Ասիա/Եկատերինբուրգ",
"Asia/Yerevan": "Ասիա/Երևան",
"Atlantic/Azores": "Ատլանտիկա/Ազորյան կղզիներ",
"Atlantic/Bermuda": "Ատլանտիկ/Բերմուդյան կղզիներ",
"Atlantic/Canary": "Ատլանտիկ/Կանարյան",
"Atlantic/Cape_Verde": "Ատլանտիկ/Կապե_Վերդե",
"Atlantic/Faeroe": "Ատլանտիկ/Ֆաերո",
"Atlantic/Faroe": "Ատլանտիկ/Ֆարերյան",
"Atlantic/Jan_Mayen": "Ատլանտիկ/Յան_Մայեն",
"Atlantic/Madeira": "Ատլանտիկ/Մադեյրա",
"Atlantic/Reykjavik": "Ատլանտիկ/Ռեյկյավիկ",
"Atlantic/South_Georgia": "Ատլանտիկ/Հարավ_Ջորջիա",
"Atlantic/St_Helena": "Ատլանտիկ/Սուրբ Հելենա",
"Atlantic/Stanley": "Ատլանտիկ/Սթենլի",
"Australia/ACT": "Ավստրալիա/ACT",
"Australia/Adelaide": "Ավստրալիա/Ադելաիդա",
"Australia/Brisbane": "Ավստրալիա/Բրիսբեն",
"Australia/Broken_Hill": "Ավստրալիա/Բրոքեն_Հիլ",
"Australia/Canberra": "Ավստրալիա/Կանբերա",
"Australia/Currie": "Ավստրալիա/Կարի",
"Australia/Darwin": "Ավստրալիա/Դարվին",
"Australia/Eucla": "Ավստրալիա/Էյկլա",
"Australia/Hobart": "Ավստրալիա/Հոբարտ",
"Australia/LHI": "Ավստրալիա/LHI",
"Australia/Lindeman": "Ավստրալիա/Լինդեման",
"Australia/Lord_Howe": "Ավստրալիա/Լորդ_Հոու",
"Australia/Melbourne": "Ավստրալիա/Մելբուրն",
"Australia/NSW": "Ավստրալիա/NSW",
"Australia/North": "Ավստրալիա/Հյուսիս",
"Australia/Perth": "Ավստրալիա/Պերտ",
"Australia/Queensland": "Ավստրալիա/Քվինսլենդ",
"Australia/South": "Ավստրալիա/Հարավ",
"Australia/Sydney": "Ավստրալիա/Սիդնեյ",
"Australia/Tasmania": "Ավստրալիա/Թասմանիա",
"Australia/Victoria": "Ավստրալիա/Վիկտորիա",
"Australia/West": "Ավստրալիա/Արևմուտք",
"Australia/Yancowinna": "Ավստրալիա/Յանկովինա",
"Brazil/Acre": "Բրազիլիա/Ակրե",
"Brazil/DeNoronha": "Բրազիլիա/Դենորոնհա",
"Brazil/East": "Բրազիլիա/Արևելք",
"Brazil/West": "Բրազիլիա/Արևմուտք",
"CET": "ՍԱ",
"CST6CDT": "ՍԹ6 ՍԴՏ",
"Canada/Atlantic": "Կանադա/Ատլանտիկ",
"Canada/Central": "Կանադա/Սենթրալ",
"Canada/Eastern": "Կանադա/Արևելյան",
"Canada/Mountain": "Կանադա/լեռ",
"Canada/Newfoundland": "Կանադա/Նյուֆաունդլենդ",
"Canada/Pacific": "Կանադա/Խաղաղ օվկիանոս",
"Canada/Saskatchewan": "Կանադա/Սասկաչևան",
"Canada/Yukon": "Կանադա/Յուկոն",
"Chile/Continental": "Չիլի/Կոնտինենտալ",
"Chile/EasterIsland": "Չիլի/Արևելյան կղզի",
"Cuba": "Կուբա",
"EET": "ՈՒՏԵԼ",
"EST": "Է",
"EST5EDT": "ԷՍՏ5ԵԴ",
"Egypt": "Եգիպտոս",
"Eire": "Էյր",
"Etc/GMT": "և այլն/GMT",
"Etc/GMT+0": "և այլն/GMT+10",
"Etc/GMT+1": "և այլն/GMT+1",
"Etc/GMT+10": "և այլն/ԳՄՏ+10",
"Etc/GMT+11": "և այլն/ԳՄՏ+11",
"Etc/GMT+12": "և այլն/ԳՄՏ+12",
"Etc/GMT+2": "և այլն/GMT+2",
"Etc/GMT+3": "և այլն/GMT+3",
"Etc/GMT+4": "և այլն/GMT+4",
"Etc/GMT+5": "և այլն/GMT+5",
"Etc/GMT+6": "և այլն/GMT+6",
"Etc/GMT+7": "և այլն/GMT+7",
"Etc/GMT+8": "և այլն/GMT+3",
"Etc/GMT+9": "և այլն/GMT+9",
"Etc/GMT-0": "և այլն/ԳՄՏ-0",
"Etc/GMT-1": "և այլն/ԳՄՏ-1",
"Etc/GMT-10": "և այլն/ԳՄՏ-10",
"Etc/GMT-11": "և այլն/ԳՄՏ-11",
"Etc/GMT-12": "և այլն/ԳՄՏ-12",
"Etc/GMT-13": "և այլն/ԳՄՏ-13",
"Etc/GMT-14": "և այլն/ԳՄՏ-14",
"Etc/GMT-2": "և այլն/ԳՄՏ-2",
"Etc/GMT-3": "և այլն/ԳՄՏ-3",
"Etc/GMT-4": "և այլն/ԳՄՏ-4",
"Etc/GMT-5": "և այլն/ԳՄՏ-5",
"Etc/GMT-6": "և այլն/GMT-6",
"Etc/GMT-7": "և այլն/ԳՄՏ-7",
"Etc/GMT-8": "և այլն/ԳՄՏ -8",
"Etc/GMT-9": "և այլն/ԳՄՏ-9",
"Etc/GMT0": "և այլն/GMT0",
"Etc/Greenwich": "Եվ այլն/Գրինվիչ",
"Etc/UCT": "և այլն/UCT",
"Etc/UTC": "և այլն/UTC",
"Etc/Universal": "Եվ այլն/Ունիվերսալ",
"Etc/Zulu": "Եվ այլն/Զուլու",
"Europe/Amsterdam": "Եվրոպա/Ամստերդամ",
"Europe/Andorra": "Եվրոպա/Անդորրա",
"Europe/Astrakhan": "Եվրոպա/Աստրախան",
"Europe/Athens": "Եվրոպա/Աթենք",
"Europe/Belfast": "Եվրոպա/Բելֆաստ",
"Europe/Belgrade": "Եվրոպա/Բելգրադ",
"Europe/Berlin": "Եվրոպա/Բեռլին",
"Europe/Bratislava": "Եվրոպա/Բրատիսլավա",
"Europe/Brussels": "Եվրոպա/Բրյուսել",
"Europe/Bucharest": "Եվրոպա/Բուխարեստ",
"Europe/Budapest": "Եվրոպա/Բուդապեշտ",
"Europe/Busingen": "Եվրոպա/Բուզինգեն",
"Europe/Chisinau": "Եվրոպա/Քիշնև",
"Europe/Copenhagen": "Եվրոպա/Կոպենհագեն",
"Europe/Dublin": "Եվրոպա/Դուբլին",
"Europe/Gibraltar": "Եվրոպա/Ջիբրալթար",
"Europe/Guernsey": "Եվրոպա/Գերնսի",
"Europe/Helsinki": "Եվրոպա/Հելսինկի",
"Europe/Isle_of_Man": "Եվրոպա/Մեն կղզի",
"Europe/Istanbul": "Եվրոպա/Ստամբուլ",
"Europe/Jersey": "Եվրոպա/Ջերսի",
"Europe/Kaliningrad": "Եվրոպա/Կալինինգրադ",
"Europe/Kiev": "Եվրոպա/Կիև",
"Europe/Kirov": "Եվրոպա/Կիրով",
"Europe/Kyiv": "Եվրոպա/Կիև",
"Europe/Lisbon": "Եվրոպա/Լիսաբոն",
"Europe/Ljubljana": "Եվրոպա/Լյուբլյանա",
"Europe/London": "Եվրոպա/Լոնդոն",
"Europe/Luxembourg": "Եվրոպա/Լյուքսեմբուրգ",
"Europe/Madrid": "Եվրոպա/Մադրիդ",
"Europe/Malta": "Եվրոպա/Մալթա",
"Europe/Mariehamn": "Եվրոպա/Մարիհեմն",
"Europe/Minsk": "Եվրոպա/Մինսկ",
"Europe/Monaco": "Եվրոպա/Մոնակո",
"Europe/Moscow": "Եվրոպա/Մոսկվա",
"Europe/Nicosia": "Եվրոպա/Նիկոսիա",
"Europe/Oslo": "Եվրոպա/Օսլո",
"Europe/Paris": "Եվրոպա/Փարիզ",
"Europe/Podgorica": "Եվրոպա/Պոդգորիցա",
"Europe/Prague": "Եվրոպա/Պրահա",
"Europe/Riga": "Եվրոպա/Ռիգա",
"Europe/Rome": "Եվրոպա/Հռոմ",
"Europe/Samara": "Եվրոպա/Սամարա",
"Europe/San_Marino": "Եվրոպա/Սան_Մարինո",
"Europe/Sarajevo": "Եվրոպա/Սարաևո",
"Europe/Saratov": "Եվրոպա/Սարատով",
"Europe/Simferopol": "Եվրոպա/Սիմֆերոպոլ",
"Europe/Skopje": "Եվրոպա/Սկոպյե",
"Europe/Sofia": "Եվրոպա/Սոֆիա",
"Europe/Stockholm": "Եվրոպա/Ստոկհոլմ",
"Europe/Tallinn": "Եվրոպա/Տալլին",
"Europe/Tirane": "Եվրոպա/Տիրան",
"Europe/Tiraspol": "Եվրոպա/Տիրասպոլ",
"Europe/Ulyanovsk": "Եվրոպա/Ուլյանովսկ",
"Europe/Uzhgorod": "Եվրոպա/Ուժգորոդ",
"Europe/Vaduz": "Եվրոպա/Վադուզ",
"Europe/Vatican": "Եվրոպա/Վատիկան",
"Europe/Vienna": "Եվրոպա/Վիեննա",
"Europe/Vilnius": "Եվրոպա/Վիլնյուս",
"Europe/Volgograd": "Եվրոպա/Վոլգոգրադ",
"Europe/Warsaw": "Եվրոպա/Վարշավա",
"Europe/Zagreb": "Եվրոպա/Զագրեբ",
"Europe/Zaporozhye": "Եվրոպա/Զապորոժիե",
"Europe/Zurich": "Եվրոպա/Ցյուրիխ",
"GB": "ԳԲ",
"GB-Eire": "ԳԲ-Էյր",
"GMT": "ԳՄՏ",
"GMT+0": "ԳՄԹ+0",
"GMT-0": "ԳՄՏ 0",
"GMT0": "ԳՄԹ0",
"Greenwich": "Գրինվիչ",
"HST": "HST",
"Hongkong": "Հոնկոնգ",
"Iceland": "Իսլանդիա",
"Indian/Antananarivo": "Հնդկական/Անտանանարիվո",
"Indian/Chagos": "Հնդկական/Չագոս",
"Indian/Christmas": "Հնդկական/Սուրբ Ծնունդ",
"Indian/Cocos": "Հնդկական/Կոկոս",
"Indian/Comoro": "Հնդկական/Կոմորո",
"Indian/Kerguelen": "Հնդկական/Կերգելեն",
"Indian/Mahe": "Հնդկական/Մահե",
"Indian/Maldives": "Հնդկական/Մալդիվներ",
"Indian/Mauritius": "Հնդկական/Մավրիկիոս",
"Indian/Mayotte": "Հնդկական/Մայոտտ",
"Indian/Reunion": "Հնդկական/Ռեյունիոն",
"Iran": "Իրան",
"Israel": "Իսրայել",
"Jamaica": "Ջամայկա",
"Japan": "Ճապոնիա",
"Kwajalein": "Կվաջալեյն",
"Libya": "Լիբիա",
"MET": "ՀԱՆԴԻՊԵԼ",
"MST": "ՄՍԹ",
"MST7MDT": "ՄՍԹ7 ՄԴՏ",
"Mexico/BajaNorte": "Մեքսիկո/Բաջանորտե",
"Mexico/BajaSur": "Մեքսիկո/Բաջասուր",
"Mexico/General": "Մեքսիկո/Գեներալ",
"NZ": "ՆԶ",
"NZ-CHAT": "ՆԶ-ՉԱՏ",
"Navajo": "Նավաջո",
"PRC": "ՓՐԿ",
"PST8PDT": "ՊՍՏ8ՊԴՏ",
"Pacific/Apia": "Խաղաղ օվկիանոս/Ապիա",
"Pacific/Auckland": "Խաղաղ օվկիանոս/Օքլենդ",
"Pacific/Bougainville": "Խաղաղ օվկիանոս/Բուգենվիլ",
"Pacific/Chatham": "Խաղաղ օվկիանոս/Չաթամ",
"Pacific/Chuuk": "Խաղաղ օվկիանոս/Չուկ",
"Pacific/Easter": "Խաղաղօվկիանոս/Զատիկ",
"Pacific/Efate": "Խաղաղ օվկիանոս/Էֆեյթ",
"Pacific/Enderbury": "Խաղաղ օվկիանոս/Էնդերբերի",
"Pacific/Fakaofo": "Խաղաղ օվկիանոս/Ֆակաոֆո",
"Pacific/Fiji": "Խաղաղ օվկիանոս/Ֆիջի",
"Pacific/Funafuti": "Խաղաղ օվկիանոս/Ֆունաֆուտի",
"Pacific/Galapagos": "Խաղաղ օվկիանոս/Գալապագոս",
"Pacific/Gambier": "Խաղաղօվկիանոս/Գամբիեր",
"Pacific/Guadalcanal": "Խաղաղ օվկիանոս/Գվադալկանալ",
"Pacific/Guam": "Խաղաղ օվկիանոս/Գուամ",
"Pacific/Honolulu": "Խաղաղ օվկիանոս/Հոնոլուլու",
"Pacific/Johnston": "Խաղաղ օվկիանոս/Ջոնսթոն",
"Pacific/Kanton": "Խաղաղ օվկիանոս/Կանտոն",
"Pacific/Kiritimati": "Խաղաղօվկիանոս/Կիրիտիմատի",
"Pacific/Kosrae": "Խաղաղ օվկիանոս/Կոսրաե",
"Pacific/Kwajalein": "Խաղաղ օվկիանոս/Կվաջալեյն",
"Pacific/Majuro": "Խաղաղ օվկիանոս/Մաջուրո",
"Pacific/Marquesas": "Խաղաղ օվկիանոս/Մարկեսաս",
"Pacific/Midway": "Խաղաղ օվկիանոս/Միդուեյ",
"Pacific/Nauru": "Խաղաղ օվկիանոս/Նաուրու",
"Pacific/Niue": "Խաղաղ օվկիանոս/Նիուե",
"Pacific/Norfolk": "Խաղաղ օվկիանոս/Նորֆոլկ",
"Pacific/Noumea": "Խաղաղ օվկիանոս/Նումեա",
"Pacific/Pago_Pago": "Խաղաղ օվկիանոս/Պագո_Պագո",
"Pacific/Palau": "Խաղաղ օվկիանոս/Պալաու",
"Pacific/Pitcairn": "Խաղաղ օվկիանոս/Պիտկերն",
"Pacific/Pohnpei": "Խաղաղ օվկիանոս/Պոհնպեյ",
"Pacific/Ponape": "Խաղաղ օվկիանոս/Պոնապե",
"Pacific/Port_Moresby": "Խաղաղ օվկիանոս/Պորտ_Մորեսբի",
"Pacific/Rarotonga": "Խաղաղ օվկիանոս/Ռարոտոնգա",
"Pacific/Saipan": "Խաղաղ օվկիանոս/Սաիպան",
"Pacific/Samoa": "Խաղաղ օվկիանոս/Սամոա",
"Pacific/Tahiti": "Խաղաղ օվկիանոս/Տահիտի",
"Pacific/Tarawa": "Խաղաղ օվկիանոս/Տարավա",
"Pacific/Tongatapu": "Խաղաղ օվկիանոս/Տոնգատապու",
"Pacific/Truk": "Խաղաղ օվկիանոս/Տրուկ",
"Pacific/Wake": "Խաղաղօվկիանոս/Արթնացում",
"Pacific/Wallis": "Խաղաղ օվկիանոս/Ուոլիս",
"Pacific/Yap": "Խաղաղ օվկիանոս/Յապ",
"Poland": "Լեհաստան",
"Portugal": "Պորտուգալիա",
"ROC": "ՌՈԿ",
"ROK": "ՌՈՔ",
"Singapore": "Սինգապուր",
"Turkey": "Թուրքիա",
"UCT": "UCT",
"US/Alaska": "ԱՄՆ/Ալյասկա",
"US/Aleutian": "ԱՄՆ/Ալեուտական",
"US/Arizona": "ԱՄՆ/Արիզոնա",
"US/Central": "ԱՄՆ/Կենտրոնական",
"US/East-Indiana": "ԱՄՆ/Արևելյան Ինդիանա",
"US/Eastern": "ԱՄՆ/Արևելյան",
"US/Hawaii": "ԱՄՆ/Հավայան կղզիներ",
"US/Indiana-Starke": "ԱՄՆ/Ինդիանա-ստարկե",
"US/Michigan": "ԱՄՆ/Միչիգան",
"US/Mountain": "ԱՄՆ/լեռ",
"US/Pacific": "ԱՄՆ/Խաղաղ օվկիանոս",
"US/Samoa": "ԱՄՆ/Սամոա",
"UTC": "UTC",
"Universal": "Ունիվերսալ",
"W-SU": "Վ-ՍՈՒ",
"WET": "ԹԱՑ",
"Zulu": "Զուլու",
}