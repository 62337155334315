// import * as Localization from 'expo-localization';
import i18n from 'i18n-js'
import en from './locale_en.json'
import ru from './locale_ru.json'
import fr from './locale_fr.json'
import ja from './locale_ja.json'
import es from './locale_es.json'
import German from './locale_de.json'
import Italian from './locale_it.json'
import Polish from './locale_pl.json'
import Portuguese from './locale_pt-PT.json'
import Dutch from './locale_nl.json'
import Hindi from './locale_hi.json'
import Czech from './locale_cs.json'
import Danish from './locale_da.json'
import Korean from './locale_ko.json'
import Chinese from './locale_zh.json'
import SpanishMexico from './locale_es-MX.json'
import FrenchCanada from './locale_fr-CA.json'
import PortugueseBrazil from './locale_pt.json'
import Swedish from './locale_sv.json'
import Norwegian from './locale_no.json'
import Finnish from './locale_fi.json'
import Greek from './locale_el.json'
import Turkish from './locale_tr.json'

import timezoneLocaleAlbanian from './timezoneLocale-albanian'
import timezoneLocaleArabic from './timezoneLocale-arabic'
import timezoneLocaleArmenian from './timezoneLocale-armenian'
import timezoneLocaleBosnian from './timezoneLocale-bosnian'
import timezoneLocaleBulgarian from './timezoneLocale-bulgarian'
import timezoneLocaleCroatian from './timezoneLocale-croatian'
import timezoneLocaleEstonian from './timezoneLocale-estonian'
import timezoneLocaleHebrew from './timezoneLocale-hebrew'
import timezoneLocaleSlovak from './timezoneLocale-slovak'
import timezoneLocaleSlovenian from './timezoneLocale-slovenian'


i18n.fallbacks = true
i18n.translations = {
  'en-us': en,
  ru: ru,
  fr: fr,
  ja: ja,
  es: es,
  pl: Polish,
  'zh-cn': Chinese,
  de: German,
  pt: Portuguese,
  it: Italian,
  sq_AL: timezoneLocaleAlbanian,
  ar_SA: timezoneLocaleArabic,
  hy_AM: timezoneLocaleArmenian,
  bs_BA: timezoneLocaleBosnian,
  bg_BG: timezoneLocaleBulgarian,
  hr_HR: timezoneLocaleCroatian,
  cs: Czech,
  da: Danish,
  nl: Dutch,
  et_EE: timezoneLocaleEstonian,
  fi: Finnish,
  'fr-ca': FrenchCanada,
  el: Greek,
  he_IL: timezoneLocaleHebrew,
  hi: Hindi,
  ko: Korean,
  nb: Norwegian,
  sk_SK: timezoneLocaleSlovak,
  sl_SI: timezoneLocaleSlovenian,
  'es-mx': SpanishMexico,
  sv: Swedish,
  tr: Turkish,
  'pt-br': PortugueseBrazil,
}

export default i18n
