import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  trainingLabelContainer: {
    borderWidth: 1,
    borderColor: '#9155FD',
    backgroundColor: '#9155FD',
    flex: 0.1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
  },
  trainingText: {
    fontSize: 16,
    color: '#ffffff',
    fontFamily: 'Inter',
  },
  startTrainingContainer: {
    borderWidth: 1,
    borderColor: '#9155FD',
    margin: '2%',
    marginTop: '15%',
    borderRadius: 20,
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    // justifyContent:'flex-end',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#9155FD',
    margin: 'auto',
    marginBottom: 10,
    marginTop: 20,
    textTransform: 'uppercase',
  },
  trainingInfoStyle: {
    padding: 10,
    fontSize: 16,
    fontFamily: 'Inter',
  },
  completeTrainingContainer: {
    borderWidth: 1,
    borderColor: '#9155FD',
    margin: '5%',
    borderRadius: 10,
    backgroundColor: '#9155FD',
    padding: 5,
    // flex: 1,
    // flex: 5,
  },
  sessionTimeoutContainer: {
    width: '100%',
    height: '100%',
    opacity: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 150,
  },
  sessionText: {
    fontSize: 34,
    fontFamily: 'Inter',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // flexDirection: 'column',
    width: '90%',
    marginTop: 20,
    marginBottom: 17,
  },
  sessionTextColor: {
    color: 'rgba(58,53,65,.87)',
  },
  sessionLoginText: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: '400',
    margin: 0,
  },
})
