import React from "react"
import { buttonBackgroundColor } from "../../containers/NavigationScreens"
import {View, ActivityIndicator, StyleSheet } from 'react-native'

const LoaderStyles = StyleSheet.create({
    loaderContainer: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        zIndex: 2,
    },
})

const Loader = (props) => {
    const {loading} = props
    return(
        loading ? (
            <View
              style={LoaderStyles.loaderContainer}
            >
              <ActivityIndicator
                size="large"
                color={buttonBackgroundColor}
                animating={true}
                key={loading ? 'loading' : 'not-loading'}
              />
            </View>
          ) : <></>
    )

}
export default Loader