import React from "react"
import _ from 'lodash'
import VASComponent from "./VASComponent"

const VASContainer = (props) => {
    const {
        field: {
          dictionary: { options, min, max, step, orientation, isPercentage, showMarks, showValue, labelText, scaleVerticalAlignment, indicationNumbers, indicationMultiple},
          id,
          isRequired,
          uiSchema: fdUISchema,
        },
        form,
        t,
        disabled,
        fieldAnswer,
    } = props
    const { setFieldsValue } = form
    const percentageSymbol = isPercentage ? '%' : ''
   const onValueChange = (value) => { 
        let loValue = value
        if((value - min) % step !== 0 ){
            const elements = _.range(min, Number(max)+1, step);
            const closest = elements.reduce((prev, curr) => {
                const currentDifference = Math.abs(loValue - curr);
                const nearestDifference = Math.abs(loValue - prev);
                return currentDifference < nearestDifference ? curr : prev;
            });
            loValue = closest
        }
        setFieldsValue({
          [id]: loValue+percentageSymbol,
        })
    }

    const crfData = { ...fieldAnswer }

    return form.getFieldDecorator(id, {
        type: 'number',
        initialValue: !_.isEmpty(crfData) ? crfData?.fieldValue : null,
        rules: [
            {
              required: isRequired,
              message: t('BScaleValMsg'),
            },
          ],
      })(
        <VASComponent
          orientation={orientation}
          min={Number(min)}
          max={Number(max)}
          options={options}
          step={Number(step)}
          showMarks={showMarks}
          onValueChange={onValueChange}
          disabled={disabled}
          fdUISchema={fdUISchema}
          id={id}
          isPercentage={isPercentage}
          showValueConfig= {{
            showValue: showValue,
            labelText: labelText
          }}
          scaleVerticalAlignment={scaleVerticalAlignment}
          indicationConfig={{
            indicationMultiple: indicationMultiple,
            indicationNumbers: indicationNumbers
          }}
        />
      )
}

export default VASContainer

