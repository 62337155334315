export const activities = {
  steps: {
    key: 'steps',
    iconName: 'shoe-prints',
    viewProp: 'viewSteps',
    units: 'StepUnits',
    title: 'Steps',
    backgroundColor: '#00B3D8',
    progressColor: '#00B3D8',
    iconColor: '#00B3D8',
    textColor: '#e0f7fa',
    progressBackgroundColor: '#004654',
  },
  distance: {
    key: 'distance',
    iconName: 'walking',
    viewProp: 'viewDistance',
    units: 'DistanceUnits',
    title: 'Distance',
    backgroundColor: '#4A58A2',
    progressColor: '#4A58A2',
    iconColor: '#4A58A2',
    textColor: '#e1f5fe',
    progressBackgroundColor: '#1c223f',
  },
  floors: {
    key: 'floors',
    iconName: 'stairs',
    viewProp: 'viewFloors',
    units: 'FloorUnits',
    title: 'FlightsClimbed',
    backgroundColor: '#E978B8',
    progressColor: '#E978B8',
    iconColor: '#E978B8',
    textColor: '#e0f2f1',
    progressBackgroundColor: '#76134b',
  },
  activeEnergyBurned: {
    key: 'activeEnergyBurned',
    iconName: 'flash',
    viewProp: 'viewActiveEnergyBurned',
    units: 'ActiveEnergyUnits',
    title: 'ActiveEnergy',
    backgroundColor: '#F76D7D',
    progressColor: '#F76D7D',
    iconColor: '#F76D7D',
    textColor: '#e8eaf6',
    progressBackgroundColor: '#840715',
  },
  sleepingDuration: {
    key: 'sleepingDuration',
    iconName: 'bed',
    viewProp: 'viewSleepingDuration',
    units: 'SleepAnalysisUnits',
    title: 'SleepAnalysis',
    backgroundColor: '#FFBB3B',
    progressColor: '#FFBB3B',
    iconColor: '#FFBB3B',
    textColor: '#ede7f6',
    progressBackgroundColor: '#7a5000',
  },
}
