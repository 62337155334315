export default {
    "Africa/Abidjan": "أفريقيا/أبيدجان",
"Africa/Accra": "أفريقيا/أكرا",
"Africa/Addis_Ababa": "أفريقيا/أديس أبابا",
"Africa/Algiers": "أفريقيا/الجزائر",
"Africa/Asmara": "أفريقيا/أسمرة",
"Africa/Asmera": "أفريقيا/أسميرا",
"Africa/Bamako": "أفريقيا/باماكو",
"Africa/Bangui": "أفريقيا/بانغي",
"Africa/Banjul": "أفريقيا/بانجول",
"Africa/Bissau": "أفريقيا/بيساو",
"Africa/Blantyre": "أفريقيا/بلانتير",
"Africa/Brazzaville": "أفريقيا/برازافيل",
"Africa/Bujumbura": "أفريقيا/بوجمبورا",
"Africa/Cairo": "أفريقيا/القاهرة",
"Africa/Casablanca": "أفريقيا/الدار البيضاء",
"Africa/Ceuta": "أفريقيا/سبتة",
"Africa/Conakry": "أفريقيا/كوناكري",
"Africa/Dakar": "أفريقيا/داكار",
"Africa/Dar_es_Salaam": "أفريقيا/دار السلام",
"Africa/Djibouti": "أفريقيا/جيبوتي",
"Africa/Douala": "أفريقيا/دوالا",
"Africa/El_Aaiun": "أفريقيا/العيون",
"Africa/Freetown": "أفريقيا/فريتاون",
"Africa/Gaborone": "أفريقيا/غابورون",
"Africa/Harare": "أفريقيا/هراري",
"Africa/Johannesburg": "أفريقيا/جوهانسبرغ",
"Africa/Juba": "أفريقيا/جوبا",
"Africa/Kampala": "أفريقيا/كمبالا",
"Africa/Khartoum": "أفريقيا/الخرطوم",
"Africa/Kigali": "أفريقيا/كيغالي",
"Africa/Kinshasa": "أفريقيا/كينشاسا",
"Africa/Lagos": "أفريقيا/لاغوس",
"Africa/Libreville": "أفريقيا/ليبرفيل",
"Africa/Lome": "أفريقيا/لومي",
"Africa/Luanda": "أفريقيا/لواندا",
"Africa/Lubumbashi": "أفريقيا/لوبومباشي",
"Africa/Lusaka": "أفريقيا/لوساكا",
"Africa/Malabo": "أفريقيا/مالابو",
"Africa/Maputo": "أفريقيا/مابوتو",
"Africa/Maseru": "أفريقيا/ماسيرو",
"Africa/Mbabane": "أفريقيا/مبابان",
"Africa/Mogadishu": "أفريقيا/مقديشو",
"Africa/Monrovia": "أفريقيا/مونروفيا",
"Africa/Nairobi": "أفريقيا/نيروبي",
"Africa/Ndjamena": "أفريقيا/نجامينا",
"Africa/Niamey": "أفريقيا/نيامي",
"Africa/Nouakchott": "أفريقيا/نواكشوط",
"Africa/Ouagadougou": "أفريقيا/واغادوغو",
"Africa/Porto-Novo": "أفريقيا/بورتو نوفو",
"Africa/Sao_Tome": "أفريقيا/ساو تومي",
"Africa/Timbuktu": "أفريقيا/تمبكتو",
"Africa/Tripoli": "أفريقيا/طرابلس",
"Africa/Tunis": "أفريقيا/تونس",
"Africa/Windhoek": "أفريقيا/ويندهوك",
"America/Adak": "أمريكا/أداك",
"America/Anchorage": "أمريكا/أنكوراج",
"America/Anguilla": "أمريكا/أنغيلا",
"America/Antigua": "أمريكا/أنتيغوا",
"America/Araguaina": "أمريكا/أراجواينا",
"America/Argentina/Buenos_Aires": "أمريكا/الأرجنتين/بوينس آيرس",
"America/Argentina/Catamarca": "أمريكا/الأرجنتين/كاتاماركا",
"America/Argentina/ComodRivadavia": "أمريكا/الأرجنتين/كومودريفادافيا",
"America/Argentina/Cordoba": "أمريكا/الأرجنتين/قرطبة",
"America/Argentina/Jujuy": "أمريكا/الأرجنتين/خوخوي",
"America/Argentina/La_Rioja": "أمريكا/الأرجنتين/La_Rioja",
"America/Argentina/Mendoza": "أمريكا/الأرجنتين/ميندوزا",
"America/Argentina/Rio_Gallegos": "أمريكا/الأرجنتين/ريو_غاليغوس",
"America/Argentina/Salta": "أمريكا/الأرجنتين/سالتا",
"America/Argentina/San_Juan": "أمريكا/الأرجنتين/San_Juan",
"America/Argentina/San_Luis": "أمريكا/الأرجنتين/San_Luis",
"America/Argentina/Tucuman": "أمريكا/الأرجنتين/توكومان",
"America/Argentina/Ushuaia": "أمريكا/الأرجنتين/أوشوايا",
"America/Aruba": "أمريكا/أروبا",
"America/Asuncion": "أمريكا/أسونسيون",
"America/Atikokan": "أمريكا/أتيكوكان",
"America/Atka": "أمريكا/أتكا",
"America/Bahia": "أمريكا/باهيا",
"America/Bahia_Banderas": "أمريكا/باهيا_بانديراس",
"America/Barbados": "أمريكا/بربادوس",
"America/Belem": "أمريكا/بيليم",
"America/Belize": "أمريكا/بليز",
"America/Blanc-Sablon": "أمريكا/بلانك سابلون",
"America/Boa_Vista": "أمريكا/بوا_فيستا",
"America/Bogota": "أمريكا/بوغوتا",
"America/Boise": "أمريكا/بويز",
"America/Buenos_Aires": "أمريكا/بوينوس آيرس",
"America/Cambridge_Bay": "أمريكا/كامبردج_باي",
"America/Campo_Grande": "أمريكا/كامبو_غراندي",
"America/Cancun": "أمريكا/كانكون",
"America/Caracas": "أمريكا/كاراكاس",
"America/Catamarca": "أمريكا/كاتاماركا",
"America/Cayenne": "أمريكا/كايين",
"America/Cayman": "أمريكا/كايمان",
"America/Chicago": "أمريكا/شيكاغو",
"America/Chihuahua": "أمريكا/تشيهواهوا",
"America/Ciudad_Juarez": "أمريكا/سيوداد خواريز",
"America/Coral_Harbour": "أمريكا/كورال هاربور",
"America/Cordoba": "أمريكا/قرطبة",
"America/Costa_Rica": "أمريكا/كوستا_ريكا",
"America/Creston": "أمريكا/كريستون",
"America/Cuiaba": "أمريكا/كويابا",
"America/Curacao": "أمريكا/كوراكاو",
"America/Danmarkshavn": "أمريكا/دانماركشافن",
"America/Dawson": "أمريكا/داوسون",
"America/Dawson_Creek": "أمريكا/داوسون كريك",
"America/Denver": "أمريكا/دنفر",
"America/Detroit": "أمريكا/ديترويت",
"America/Dominica": "أمريكا/دومينيكا",
"America/Edmonton": "أمريكا/إدمونتون",
"America/Eirunepe": "أمريكا/إيرونيبي",
"America/El_Salvador": "أمريكا/إل_سلفادور",
"America/Ensenada": "أمريكا/إنسينادا",
"America/Fort_Nelson": "أمريكا/فورت نيلسون",
"America/Fort_Wayne": "أمريكا/فورت واين",
"America/Fortaleza": "أمريكا/فورتاليزا",
"America/Glace_Bay": "أمريكا/غلاس_باي",
"America/Godthab": "أمريكا/جودثاب",
"America/Goose_Bay": "أمريكا/Goose_Bay",
"America/Grand_Turk": "أمريكا/غراند تورك",
"America/Grenada": "أمريكا/غرينادا",
"America/Guadeloupe": "أمريكا/غوادلوب",
"America/Guatemala": "أمريكا/غواتيمالا",
"America/Guayaquil": "أمريكا/غواياكيل",
"America/Guyana": "أمريكا/غيانا",
"America/Halifax": "أمريكا/هاليفاكس",
"America/Havana": "أمريكا/هافانا",
"America/Hermosillo": "أمريكا/هيرموسيلو",
"America/Indiana/Indianapolis": "أمريكا/إنديانا/إنديانابوليس",
"America/Indiana/Knox": "أمريكا/إنديانا/نوكس",
"America/Indiana/Marengo": "أمريكا/إنديانا/مارينغو",
"America/Indiana/Petersburg": "أمريكا/إنديانا/بطرسبورغ",
"America/Indiana/Tell_City": "أمريكا/إنديانا/Tell_City",
"America/Indiana/Vevay": "أمريكا/إنديانا/فيفاي",
"America/Indiana/Vincennes": "أمريكا/إنديانا/فينسين",
"America/Indiana/Winamac": "أمريكا/إنديانا/ويناماك",
"America/Indianapolis": "أمريكا/إنديانابوليس",
"America/Inuvik": "أمريكا/إينوفيك",
"America/Iqaluit": "أمريكا/إيكالويت",
"America/Jamaica": "أمريكا/جامايكا",
"America/Jujuy": "أمريكا/جوجوي",
"America/Juneau": "أمريكا/جونو",
"America/Kentucky/Louisville": "أمريكا/كنتاكي/لويزفيل",
"America/Kentucky/Monticello": "أمريكا/كنتاكي/مونتايسلو",
"America/Knox_IN": "أمريكا/نوكس_إن",
"America/Kralendijk": "أمريكا/كرالينديك",
"America/La_Paz": "أمريكا/لا_باز",
"America/Lima": "أمريكا/ليما",
"America/Los_Angeles": "أمريكا/لوس أنجلوس",
"America/Louisville": "أمريكا/لويزفيل",
"America/Lower_Princes": "أمريكا/الأمراء السفليين",
"America/Maceio": "أمريكا/ماسيو",
"America/Managua": "أمريكا/ماناغوا",
"America/Manaus": "أمريكا/ماناوس",
"America/Marigot": "أمريكا/ماريجوت",
"America/Martinique": "أمريكا/مارتينيكي",
"America/Matamoros": "أمريكا/ماتاموروس",
"America/Mazatlan": "أمريكا/مازاتلان",
"America/Mendoza": "أمريكا/ميندوزا",
"America/Menominee": "أمريكا/مينوميني",
"America/Merida": "أمريكا/ميريدا",
"America/Metlakatla": "أمريكا/ميتلاكاتلا",
"America/Mexico_City": "أمريكا/مكسيكو سيتي",
"America/Miquelon": "أمريكا/ميكلون",
"America/Moncton": "أمريكا/مونكتون",
"America/Monterrey": "أمريكا/مونتيري",
"America/Montevideo": "أمريكا/مونتيفيديو",
"America/Montreal": "أمريكا/مونتريال",
"America/Montserrat": "أمريكا/مونتسيرات",
"America/Nassau": "أمريكا/ناساو",
"America/New_York": "أمريكا/نيويورك",
"America/Nipigon": "أمريكا/نيبيجون",
"America/Nome": "أمريكا/نومي",
"America/Noronha": "أمريكا/نورونها",
"America/North_Dakota/Beulah": "أمريكا/نورث داكوتا/بيولا",
"America/North_Dakota/Center": "أمريكا/نورث داكوتا/سنتر",
"America/North_Dakota/New_Salem": "أمريكا/نورث داكوتا/نيو_سالم",
"America/Nuuk": "أمريكا/نوك",
"America/Ojinaga": "أمريكا/أوجيناغا",
"America/Panama": "أمريكا/بنما",
"America/Pangnirtung": "أمريكا/بانجنيرتونج",
"America/Paramaribo": "أمريكا/باراماريبو",
"America/Phoenix": "أمريكا/فينيكس",
"America/Port-au-Prince": "أمريكا/بورت أو برنس",
"America/Port_of_Spain": "أمريكا/بورت_أوف سبين",
"America/Porto_Acre": "أمريكا/بورتو_أكري",
"America/Porto_Velho": "أمريكا/بورتو_فيلهو",
"America/Puerto_Rico": "أمريكا/بورتو_ريكو",
"America/Punta_Arenas": "أمريكا/بونتا أريناس",
"America/Rainy_River": "أمريكا/ريني_ريفر",
"America/Rankin_Inlet": "أمريكا/رانكين_إنليت",
"America/Recife": "أمريكا/ريسيفي",
"America/Regina": "أمريكا/ريجينا",
"America/Resolute": "أمريكا/ريسولوت",
"America/Rio_Branco": "أمريكا/ريو_برانكو",
"America/Rosario": "أمريكا/روزاريو",
"America/Santa_Isabel": "أمريكا/سانتا_إيزابيل",
"America/Santarem": "أمريكا/سانتاريم",
"America/Santiago": "أمريكا/سانتياغو",
"America/Santo_Domingo": "أمريكا/سانتو_دومينغو",
"America/Sao_Paulo": "أمريكا/ساو باولو",
"America/Scoresbysund": "أمريكا/سكوريسبيسوند",
"America/Shiprock": "أمريكا/شيبروك",
"America/Sitka": "أمريكا/سيتكا",
"America/St_Barthelemy": "أمريكا/سانت بارتيليمي",
"America/St_Johns": "أمريكا/سانت جونز",
"America/St_Kitts": "أمريكا/سانت كيتس",
"America/St_Lucia": "أمريكا/سانت لوسيا",
"America/St_Thomas": "أمريكا/سانت توماس",
"America/St_Vincent": "أمريكا/سانت فنسنت",
"America/Swift_Current": "أمريكا/Swift_Current",
"America/Tegucigalpa": "أمريكا/تيغوسيغالبا",
"America/Thule": "أمريكا/ثول",
"America/Thunder_Bay": "أمريكا/ثاندر_باي",
"America/Tijuana": "أمريكا/تيخوانا",
"America/Toronto": "أمريكا/تورنتو",
"America/Tortola": "أمريكا/تورتولا",
"America/Vancouver": "أمريكا/فانكوفر",
"America/Virgin": "أمريكا/فيرجن",
"America/Whitehorse": "أمريكا/وايت هورس",
"America/Winnipeg": "أمريكا/وينيبيغ",
"America/Yakutat": "أمريكا/ياكوتات",
"America/Yellowknife": "أمريكا/يلونايف",
"Antarctica/Casey": "أنتاركتيكا/كيسي",
"Antarctica/Davis": "أنتاركتيكا/ديفيس",
"Antarctica/DumontDUrville": "أنتاركتيكا/دومونتدورفيل",
"Antarctica/Macquarie": "أنتاركتيكا/ماكواري",
"Antarctica/Mawson": "أنتاركتيكا/ماوسون",
"Antarctica/McMurdo": "أنتاركتيكا/ماكموردو",
"Antarctica/Palmer": "أنتاركتيكا/بالمر",
"Antarctica/Rothera": "أنتاركتيكا/روثيرا",
"Antarctica/South_Pole": "أنتاركتيكا/القطب الجنوبي",
"Antarctica/Syowa": "أنتاركتيكا/سيوا",
"Antarctica/Troll": "أنتاركتيكا/ترول",
"Antarctica/Vostok": "أنتاركتيكا/فوستوك",
"Arctic/Longyearbyen": "القطب الشمالي/لونجييربين",
"Asia/Aden": "آسيا/عدن",
"Asia/Almaty": "آسيا/ألماتي",
"Asia/Amman": "آسيا/عمان",
"Asia/Anadyr": "آسيا/أنادير",
"Asia/Aqtau": "آسيا/أكتاو",
"Asia/Aqtobe": "آسيا/أكتوبي",
"Asia/Ashgabat": "آسيا/عشق أباد",
"Asia/Ashkhabad": "آسيا/عشق أباد",
"Asia/Atyrau": "آسيا/أتيراو",
"Asia/Baghdad": "آسيا/بغداد",
"Asia/Bahrain": "آسيا/البحرين",
"Asia/Baku": "آسيا/باكو",
"Asia/Bangkok": "آسيا/بانكوك",
"Asia/Barnaul": "آسيا/بارناول",
"Asia/Beirut": "آسيا/بيروت",
"Asia/Bishkek": "آسيا/بيشكيك",
"Asia/Brunei": "آسيا/بروناي",
"Asia/Calcutta": "آسيا/كالكوتا",
"Asia/Chita": "آسيا/تشيتا",
"Asia/Choibalsan": "آسيا/شويبالسان",
"Asia/Chongqing": "آسيا/تشونغتشينغ",
"Asia/Chungking": "آسيا/تشونغكينغ",
"Asia/Colombo": "آسيا/كولومبو",
"Asia/Dacca": "آسيا/دكا",
"Asia/Damascus": "آسيا/دمشق",
"Asia/Dhaka": "آسيا/دكا",
"Asia/Dili": "آسيا/ديلي",
"Asia/Dubai": "آسيا/دبي",
"Asia/Dushanbe": "آسيا/دوشانبي",
"Asia/Famagusta": "آسيا/فاماغوستا",
"Asia/Gaza": "آسيا/غزة",
"Asia/Harbin": "آسيا/هاربين",
"Asia/Hebron": "آسيا/الخليل",
"Asia/Ho_Chi_Minh": "آسيا/هو_تشي_مينه",
"Asia/Hong_Kong": "آسيا/هونغ كونغ",
"Asia/Hovd": "آسيا/هوفد",
"Asia/Irkutsk": "آسيا/إيركوتسك",
"Asia/Istanbul": "آسيا/اسطنبول",
"Asia/Jakarta": "آسيا/جاكرتا",
"Asia/Jayapura": "آسيا/جايابورا",
"Asia/Jerusalem": "آسيا/القدس",
"Asia/Kabul": "آسيا/كابول",
"Asia/Kamchatka": "آسيا/كامتشاتكا",
"Asia/Karachi": "آسيا/كراتشي",
"Asia/Kashgar": "آسيا/كاشغار",
"Asia/Kathmandu": "آسيا/كاتماندو",
"Asia/Katmandu": "آسيا/كاتماندو",
"Asia/Khandyga": "آسيا/خانديجا",
"Asia/Kolkata": "آسيا/كولكاتا",
"Asia/Krasnoyarsk": "آسيا/كراسنويارسك",
"Asia/Kuala_Lumpur": "آسيا/كوالالمبور",
"Asia/Kuching": "آسيا/كوتشينغ",
"Asia/Kuwait": "آسيا/الكويت",
"Asia/Macao": "آسيا/ماكاو",
"Asia/Macau": "آسيا/ماكاو",
"Asia/Magadan": "آسيا/ماجادان",
"Asia/Makassar": "آسيا/ماكاسار",
"Asia/Manila": "آسيا/مانيلا",
"Asia/Muscat": "آسيا/مسقط",
"Asia/Nicosia": "آسيا/نيقوسيا",
"Asia/Novokuznetsk": "آسيا/نوفوكوزنيتسك",
"Asia/Novosibirsk": "آسيا/نوفوسيبيرسك",
"Asia/Omsk": "آسيا/أومسك",
"Asia/Oral": "آسيا/أورال",
"Asia/Phnom_Penh": "آسيا/بنوم بنه",
"Asia/Pontianak": "آسيا/بونتياناك",
"Asia/Pyongyang": "آسيا/بيونغ يانغ",
"Asia/Qatar": "آسيا/قطر",
"Asia/Qostanay": "آسيا/كوستاناي",
"Asia/Qyzylorda": "آسيا/كيزيلوردا",
"Asia/Rangoon": "آسيا/رانجون",
"Asia/Riyadh": "آسيا/الرياض",
"Asia/Saigon": "آسيا/سايجون",
"Asia/Sakhalin": "آسيا/سخالين",
"Asia/Samarkand": "آسيا/سمرقند",
"Asia/Seoul": "آسيا/سيول",
"Asia/Shanghai": "آسيا/شنغهاي",
"Asia/Singapore": "آسيا/سنغافورة",
"Asia/Srednekolymsk": "آسيا/سريدنيكوليمسك",
"Asia/Taipei": "آسيا/تايبيه",
"Asia/Tashkent": "آسيا/طشقند",
"Asia/Tbilisi": "آسيا/تبليسي",
"Asia/Tehran": "آسيا/طهران",
"Asia/Tel_Aviv": "آسيا/تل_أبيب",
"Asia/Thimbu": "آسيا/ثيمبو",
"Asia/Thimphu": "آسيا/ثيمفو",
"Asia/Tokyo": "آسيا/طوكيو",
"Asia/Tomsk": "آسيا/تومسك",
"Asia/Ujung_Pandang": "آسيا/أوجونغ_باندانغ",
"Asia/Ulaanbaatar": "آسيا/أولان باتور",
"Asia/Ulan_Bator": "آسيا/أولان_باتور",
"Asia/Urumqi": "آسيا/أورومتشي",
"Asia/Ust-Nera": "آسيا/أوست نيرا",
"Asia/Vientiane": "آسيا/فيينتيان",
"Asia/Vladivostok": "آسيا/فلاديفوستوك",
"Asia/Yakutsk": "آسيا/ياكوتسك",
"Asia/Yangon": "آسيا/يانغون",
"Asia/Yekaterinburg": "آسيا/يكاترينبورغ",
"Asia/Yerevan": "آسيا/يريفان",
"Atlantic/Azores": "أتلانتيك/أزوريس",
"Atlantic/Bermuda": "المحيط الأطلسي/برمودا",
"Atlantic/Canary": "أتلانتيك/كناري",
"Atlantic/Cape_Verde": "أتلانتيك/كيب فيردي",
"Atlantic/Faeroe": "أتلانتيك/فايرو",
"Atlantic/Faroe": "المحيط الأطلسي/فارو",
"Atlantic/Jan_Mayen": "أتلانتيك/جان_ماين",
"Atlantic/Madeira": "أتلانتيك/ماديرا",
"Atlantic/Reykjavik": "أتلانتيك/ريكيافيك",
"Atlantic/South_Georgia": "المحيط الأطلسي/جنوب جورجيا",
"Atlantic/St_Helena": "أتلانتيك/سانت هيلينا",
"Atlantic/Stanley": "أتلانتيك/ستانلي",
"Australia/ACT": "أستراليا/ACT",
"Australia/Adelaide": "أستراليا/أديليد",
"Australia/Brisbane": "أستراليا/بريسبان",
"Australia/Broken_Hill": "أستراليا/بروكين_هيل",
"Australia/Canberra": "أستراليا/كانبرا",
"Australia/Currie": "أستراليا/كوري",
"Australia/Darwin": "أستراليا/داروين",
"Australia/Eucla": "أستراليا/أوكلا",
"Australia/Hobart": "أستراليا/هوبارت",
"Australia/LHI": "أستراليا/LHI",
"Australia/Lindeman": "أستراليا/ليندمان",
"Australia/Lord_Howe": "أستراليا/لورد_هاو",
"Australia/Melbourne": "أستراليا/ملبورن",
"Australia/NSW": "أستراليا/نيو ساوث ويلز",
"Australia/North": "أستراليا/الشمال",
"Australia/Perth": "أستراليا/بيرث",
"Australia/Queensland": "أستراليا/كوينزلاند",
"Australia/South": "أستراليا/الجنوب",
"Australia/Sydney": "أستراليا/سيدني",
"Australia/Tasmania": "أستراليا/تسمانيا",
"Australia/Victoria": "أستراليا/فيكتوريا",
"Australia/West": "أستراليا/الغرب",
"Australia/Yancowinna": "أستراليا/يانكوينا",
"Brazil/Acre": "البرازيل/فدان",
"Brazil/DeNoronha": "البرازيل/دينورونها",
"Brazil/East": "البرازيل/الشرق",
"Brazil/West": "البرازيل/الغرب",
"CET": "سيت",
"CST6CDT": "CST6CDT",
"Canada/Atlantic": "كندا/أتلانتيك",
"Canada/Central": "كندا/وسط",
"Canada/Eastern": "كندا/الشرقية",
"Canada/Mountain": "كندا/جبل",
"Canada/Newfoundland": "كندا/نيوفاوندلاند",
"Canada/Pacific": "كندا/المحيط الهادئ",
"Canada/Saskatchewan": "كندا/ساسكاتشوان",
"Canada/Yukon": "كندا/يوكون",
"Chile/Continental": "تشيلي/كونتيننتال",
"Chile/EasterIsland": "تشيلي/جزيرة الفصح",
"Cuba": "كوبا",
"EET": "لقاء",
"EST": "الأفضل",
"EST5EDT": "EST5EDT",
"Egypt": "مصر",
"Eire": "سلك",
"Etc/GMT": "ETC/بتوقيت جرينتش",
"Etc/GMT+0": "إلخ/جي إم تي 0",
"Etc/GMT+1": "إلخ/جي إم تي +1",
"Etc/GMT+10": "إلخ/جي إم تي +10",
"Etc/GMT+11": "إلخ/جي إم تي +11",
"Etc/GMT+12": "إلخ/جي إم تي +12",
"Etc/GMT+2": "إلخ/جي إم تي +2",
"Etc/GMT+3": "إلخ/جي إم تي +3",
"Etc/GMT+4": "إلخ/جي إم تي +4",
"Etc/GMT+5": "إلخ/جي إم تي +5",
"Etc/GMT+6": "إلخ/جي إم تي +6",
"Etc/GMT+7": "إلخ/جي إم تي +7",
"Etc/GMT+8": "إلخ/جي إم تي +8",
"Etc/GMT+9": "إلخ/جي إم تي +9",
"Etc/GMT-0": "إتك/جي إم تي -0",
"Etc/GMT-1": "إتك/جي إم تي -1",
"Etc/GMT-10": "إتك/جي إم تي -10",
"Etc/GMT-11": "إتك/جي إم تي-11",
"Etc/GMT-12": "إتك/جي إم تي -12",
"Etc/GMT-13": "إتك/جي إم تي -13",
"Etc/GMT-14": "إتك/جي إم تي -14",
"Etc/GMT-2": "إتك/جي إم تي -2",
"Etc/GMT-3": "إتك/جي إم تي -3",
"Etc/GMT-4": "إتك/جي إم تي -4",
"Etc/GMT-5": "إتك/جي إم تي -5",
"Etc/GMT-6": "إتك/جي إم تي -6",
"Etc/GMT-7": "إتك/جي إم تي -7",
"Etc/GMT-8": "إتك/جي إم تي -8",
"Etc/GMT-9": "إتك/جي إم تي -9",
"Etc/GMT0": "ETC/GMT0",
"Etc/Greenwich": "إتك/غرينتش",
"Etc/UCT": "وما إلى ذلك/قطع",
"Etc/UTC": "ETC/UTC",
"Etc/Universal": "إتك/يونيفرسال",
"Etc/Zulu": "إتك/زولو",
"Europe/Amsterdam": "أوروبا/أمستردام",
"Europe/Andorra": "أوروبا/أندورا",
"Europe/Astrakhan": "أوروبا/أستراخان",
"Europe/Athens": "أوروبا/أثينا",
"Europe/Belfast": "أوروبا/بلفاست",
"Europe/Belgrade": "أوروبا/بلغراد",
"Europe/Berlin": "أوروبا/برلين",
"Europe/Bratislava": "أوروبا/براتيسلافا",
"Europe/Brussels": "أوروبا/بروكسل",
"Europe/Bucharest": "أوروبا/بوخارست",
"Europe/Budapest": "أوروبا/بودابست",
"Europe/Busingen": "أوروبا/بوسينجن",
"Europe/Chisinau": "أوروبا/تشيسيناو",
"Europe/Copenhagen": "أوروبا/كوبنهاغن",
"Europe/Dublin": "أوروبا/دبلن",
"Europe/Gibraltar": "أوروبا/جبل طارق",
"Europe/Guernsey": "أوروبا/غيرنسي",
"Europe/Helsinki": "أوروبا/هلسنكي",
"Europe/Isle_of_Man": "أوروبا/جزيرة آيل أوف مان",
"Europe/Istanbul": "أوروبا/اسطنبول",
"Europe/Jersey": "أوروبا/جيرسي",
"Europe/Kaliningrad": "أوروبا/كالينينغراد",
"Europe/Kiev": "أوروبا/كييف",
"Europe/Kirov": "أوروبا/كيروف",
"Europe/Kyiv": "أوروبا/كييف",
"Europe/Lisbon": "أوروبا/لشبونة",
"Europe/Ljubljana": "أوروبا/ليوبليانا",
"Europe/London": "أوروبا/لندن",
"Europe/Luxembourg": "أوروبا/لوكسمبورغ",
"Europe/Madrid": "أوروبا/مدريد",
"Europe/Malta": "أوروبا/مالطا",
"Europe/Mariehamn": "أوروبا/ماريهامن",
"Europe/Minsk": "أوروبا/مينسك",
"Europe/Monaco": "أوروبا/موناكو",
"Europe/Moscow": "أوروبا/موسكو",
"Europe/Nicosia": "أوروبا/نيقوسيا",
"Europe/Oslo": "أوروبا/أوسلو",
"Europe/Paris": "أوروبا/باريس",
"Europe/Podgorica": "أوروبا/بودغوريتشا",
"Europe/Prague": "أوروبا/براغ",
"Europe/Riga": "أوروبا/ريغا",
"Europe/Rome": "أوروبا/روما",
"Europe/Samara": "أوروبا/سامارا",
"Europe/San_Marino": "أوروبا/سان مارينو",
"Europe/Sarajevo": "أوروبا/سراييفو",
"Europe/Saratov": "أوروبا/ساراتوف",
"Europe/Simferopol": "أوروبا/سيمفيروبول",
"Europe/Skopje": "أوروبا/سكوبي",
"Europe/Sofia": "أوروبا/صوفيا",
"Europe/Stockholm": "أوروبا/ستوكهولم",
"Europe/Tallinn": "أوروبا/تالين",
"Europe/Tirane": "أوروبا/تيرانا",
"Europe/Tiraspol": "أوروبا/تيراسبول",
"Europe/Ulyanovsk": "أوروبا/أوليانوفسك",
"Europe/Uzhgorod": "أوروبا/أوزجورود",
"Europe/Vaduz": "أوروبا/فادوز",
"Europe/Vatican": "أوروبا/الفاتيكان",
"Europe/Vienna": "أوروبا/فيينا",
"Europe/Vilnius": "أوروبا/فيلنيوس",
"Europe/Volgograd": "أوروبا/فولغوغراد",
"Europe/Warsaw": "أوروبا/وارسو",
"Europe/Zagreb": "أوروبا/زغرب",
"Europe/Zaporozhye": "أوروبا/زابوروجي",
"Europe/Zurich": "أوروبا/زيورخ",
"GB": "جيجابايت",
"GB-Eire": "GB-Eire",
"GMT": "بتوقيت غرينيتش",
"GMT+0": "جي إم تي +0",
"GMT-0": "جي إم تي -0",
"GMT0": "GMT0",
"Greenwich": "غرينتش",
"HST": "المضيف",
"Hongkong": "هونغ كونغ",
"Iceland": "أيسلندا",
"Indian/Antananarivo": "هندي/أنتاناناريفو",
"Indian/Chagos": "هندي/تشاغوس",
"Indian/Christmas": "هندي/عيد الميلاد",
"Indian/Cocos": "إنديان/كوكوس",
"Indian/Comoro": "الهندي/جزر القمر",
"Indian/Kerguelen": "إنديان/كيرغولين",
"Indian/Mahe": "هندي/ماهي",
"Indian/Maldives": "الهندية/المالديف",
"Indian/Mauritius": "هندي/موريشيوس",
"Indian/Mayotte": "هندي/مايوت",
"Indian/Reunion": "إنديان/ريونيون",
"Iran": "إيران",
"Israel": "إسرائيل",
"Jamaica": "جامايكا",
"Japan": "اليابان",
"Kwajalein": "كواجالين",
"Libya": "ليبيا",
"MET": "التقى",
"MST": "معظم",
"MST7MDT": "MST7MDT",
"Mexico/BajaNorte": "المكسيك/باجانورتي",
"Mexico/BajaSur": "المكسيك/باجاسور",
"Mexico/General": "المكسيك/عام",
"NZ": "نيوزيلندا",
"NZ-CHAT": "دردشة نيوزيلندا",
"Navajo": "نافاجو",
"PRC": "جمهورية الصين الشعبية",
"PST8PDT": "PST8PDT",
"Pacific/Apia": "المحيط الهادئ/أبيا",
"Pacific/Auckland": "المحيط الهادئ/أوكلاند",
"Pacific/Bougainville": "المحيط الهادئ/بوغانفيل",
"Pacific/Chatham": "المحيط الهادئ/تشاتام",
"Pacific/Chuuk": "المحيط الهادئ/تشوك",
"Pacific/Easter": "المحيط الهادئ/عيد الفصح",
"Pacific/Efate": "المحيط الهادئ/إيفات",
"Pacific/Enderbury": "باسيفيك/إندربوري",
"Pacific/Fakaofo": "المحيط الهادئ/فاكاوفو",
"Pacific/Fiji": "المحيط الهادئ/فيجي",
"Pacific/Funafuti": "المحيط الهادئ/فونافوتي",
"Pacific/Galapagos": "المحيط الهادئ/غالاباغوس",
"Pacific/Gambier": "المحيط الهادئ/جامبير",
"Pacific/Guadalcanal": "المحيط الهادئ/غوادالكانال",
"Pacific/Guam": "المحيط الهادئ/غوام",
"Pacific/Honolulu": "المحيط الهادئ/هونولولو",
"Pacific/Johnston": "باسيفيك/جونستون",
"Pacific/Kanton": "المحيط الهادئ/كانتون",
"Pacific/Kiritimati": "المحيط الهادئ/كيريتيماتي",
"Pacific/Kosrae": "المحيط الهادئ/كوسراي",
"Pacific/Kwajalein": "المحيط الهادئ/كواجالين",
"Pacific/Majuro": "المحيط الهادئ/ماجورو",
"Pacific/Marquesas": "المحيط الهادئ/ماركيساس",
"Pacific/Midway": "المحيط الهادئ/ميدواي",
"Pacific/Nauru": "المحيط الهادئ/ناورو",
"Pacific/Niue": "المحيط الهادئ/نيوي",
"Pacific/Norfolk": "المحيط الهادئ/نورفولك",
"Pacific/Noumea": "المحيط الهادئ/نوميا",
"Pacific/Pago_Pago": "المحيط الهادئ/باغو_باجو",
"Pacific/Palau": "المحيط الهادئ/بالاو",
"Pacific/Pitcairn": "المحيط الهادئ/بيتكيرن",
"Pacific/Pohnpei": "المحيط الهادئ/بوهنباي",
"Pacific/Ponape": "المحيط الهادئ/بونابي",
"Pacific/Port_Moresby": "المحيط الهادئ/بورت_مورسبي",
"Pacific/Rarotonga": "المحيط الهادئ/راروتونغا",
"Pacific/Saipan": "المحيط الهادئ/سايبان",
"Pacific/Samoa": "المحيط الهادئ/ساموا",
"Pacific/Tahiti": "المحيط الهادئ/تاهيتي",
"Pacific/Tarawa": "المحيط الهادئ/تاراوا",
"Pacific/Tongatapu": "المحيط الهادئ/تونغاتابو",
"Pacific/Truk": "المحيط الهادئ/الشاحنة",
"Pacific/Wake": "باسيفيك/ويك",
"Pacific/Wallis": "باسيفيك/واليس",
"Pacific/Yap": "المحيط الهادئ/ياب",
"Poland": "بولندا",
"Portugal": "البرتغال",
"ROC": "روك",
"ROK": "روك",
"Singapore": "سنغافورة",
"Turkey": "تركيا",
"UCT": "قص",
"US/Alaska": "الولايات المتحدة/ألاسكا",
"US/Aleutian": "الولايات المتحدة/ألوتيان",
"US/Arizona": "الولايات المتحدة/أريزونا",
"US/Central": "الولايات المتحدة/الوسطى",
"US/East-Indiana": "الولايات المتحدة/شرق إنديانا",
"US/Eastern": "الولايات المتحدة/الشرقية",
"US/Hawaii": "الولايات المتحدة/هاواي",
"US/Indiana-Starke": "الولايات المتحدة/ولاية إنديانا",
"US/Michigan": "الولايات المتحدة/ميشيغان",
"US/Mountain": "الولايات المتحدة/الجبل",
"US/Pacific": "الولايات المتحدة/المحيط الهادئ",
"US/Samoa": "الولايات المتحدة/ساموا",
"UTC": "التوقيت العالمي",
"Universal": "عالمي",
"W-SU": "W-SU",
"WET": "مبتل",
"Zulu": "الزولو",
}