import React from "react";
import { StyleSheet, View } from "react-native";
import NRSVerticalScale from "./NRSVerticalScale";
import LabelWrapper from "./VerticalLabels";
import { getScalePosition, getScaleProperties, isInSequence } from "../utils";
import _ from 'lodash';
import constants from "../../../constants/constants";
import PropTypes from 'prop-types';
import VerticalEdgeLabel from "./VerticalEdgeLabel";
import { alignments } from "../alignments";
import { fontScale } from "../../styles/fontResizer";
import { useSelector } from 'react-redux';

const {SCALE_POSITION} = constants

const VerticalScaleWrapper = (props) => {
    const {scaleVerticalAlignment, start, end, step, value, disabled, id, isPercentage, onNumberChange, options, orientation, fontSize, labelsFlex, labelsData, minimumFontSize, isCalculated} = props
    const scalePosition = getScalePosition(scaleVerticalAlignment)
    let noFlexStyle = {}
    if((labelsFlex.left=== 0 || labelsFlex.right === 0) && scaleVerticalAlignment!== SCALE_POSITION.CENTRE_OF_THE_PAGE ){
      noFlexStyle = {width:0}
    }
    const { onMobile } = useSelector(state => state?.appStatus);

    return (
        <View style={[styles.wrapperContainer, {justifyContent: scalePosition}]}>
          {isCalculated && (
              <VerticalEdgeLabel 
              labelsFlex={labelsFlex}
              value={_.filter(options, label => label.labelPlacement === 'TOP' && isInSequence(Number(label.position), start, end, step))?.[0]?.value}
              isTopLabel={true}
              scalePosition={scaleVerticalAlignment}
              options={options}
              transformedFontSize={minimumFontSize}
              disabled={disabled}
              id={id}
              labelsData={labelsData}
              isVAS={false}
              />
          )}
        <View style={[styles.container, {justifyContent: scalePosition}]}>
           <View style={[noFlexStyle, { flex: labelsFlex.left }]}>
           {isCalculated && (
            <LabelWrapper 
            labels = {_.filter(options, op => op.labelPlacement === 'LEFT')}
            start={start}
            end={end}
            step={step}
            labelFlex={labelsFlex.left}
            isPercentage={isPercentage}
            orientation={orientation}
            disabled={disabled}
            id={id}
            transformedFontSize={minimumFontSize}
            labelsData={labelsData}
        />
          )}
                
          </View> 
          <View>
            <NRSVerticalScale 
                start={start}
                end={end}
                step={step}
                value={value}
                disabled={disabled}
                id={id}
                isPercentage={isPercentage}
                onNumberChange={onNumberChange}
                orientation={orientation}
                fontSize={fontSize}
            />
          </View>
        <View style={[noFlexStyle, {flex: labelsFlex.right}]}>
        {isCalculated && (
            <LabelWrapper 
            labels = {_.filter(options, op => op.labelPlacement === 'RIGHT')}
            start={start}
            end={end}
            step={step}
            labelFlex={labelsFlex.right}
            isPercentage={isPercentage}
            orientation={orientation}
            disabled={disabled}
            id={id}
            transformedFontSize={minimumFontSize}
            labelsData={labelsData}
        />
          )}
            
        </View>
        </View>
        {isCalculated && (
            <VerticalEdgeLabel 
            labelsFlex={labelsFlex}
            value={_.filter(options, label => label.labelPlacement === 'BOTTOM' && isInSequence(Number(label.position), start, end, step))?.[0]?.value}
            isTopLabel={false}
            scalePosition={scaleVerticalAlignment}
            options={options}
            transformedFontSize={minimumFontSize}
            startValueMarginBtm = {getScaleProperties(start, end, step, orientation, onMobile).margin}
            disabled={disabled}
            id={id}
            labelsData={labelsData}
            isVAS={false}
          />
          )}
        
        </View>
      );
}
const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    wrapperContainer: {
      flex: 1,
      flexDirection: 'column',
      paddingBottom: 50,
    }
   
});

VerticalScaleWrapper.defaultProps = {
  isPercentage: false,
  start: 0,
  end: 10,
  step: 1,
  value: null,
  onNumberChange: () => null,
  disabled: false,
  options: [],
  id: null,
  scaleVerticalAlignment: SCALE_POSITION.CENTRE_OF_THE_PAGE,
  orientation: alignments.VERTICAL,
  fontSize: 10,
  labelsFlex: {
    left: 0,
    right: 0
  },
  labelsData: {},
  minimumFontSize: fontScale(6),
  isCalculated: false
}

VerticalScaleWrapper.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onNumberChange: PropTypes.func,
  options: PropTypes.arrayOf(Object),
  isPercentage: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  scaleVerticalAlignment: PropTypes.string,
  orientation: PropTypes.string,
  fontSize: PropTypes.number,
  labelsFlex: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number
  }),
  labelsData: PropTypes.instanceOf(Object),
  minimumFontSize: PropTypes.number,
  isCalculated: PropTypes.bool,

}
  
export default VerticalScaleWrapper