import {
  ADD_MESSAGE,
  DELETE_MESSAGE,
  REDUCE_UNREAD_CHATS_BY_1,
  SET_UNREAD_CHATS,
  ADD_MESSAGE_TO_DELETE,
  REMOVE_MESSAGES_TO_DELETE,
  CLEAR_SELECTED_CHAT,
  CLEAR_UNREAD_COUNT,
} from '../actions/chat'
import initialState from '../store/initialState'
import _ from 'lodash'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import { UPDATE_SELECTEDCHAT } from '../actions/session'

export default (state = initialState.chat, action) => {
  switch (action.type) {
    case UPDATE_SELECTEDCHAT:
      return {
        ...state,
        [action.userId]: {
            ...state?.[action.userId],
            recentUnReadMessages: action.isUnreadMsgsPresent ? state?.[action.userId]?.recentUnReadMessages : [],
        }
      }
    case ADD_MESSAGE: {
      let messages = state?.[action?.userId]?.recentUnReadMessages || []
      messages = [...messages,action.message ]
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          recentUnReadMessages: messages,
        }
      }
    }
    case DELETE_MESSAGE: {
      _.remove(
        state?.[action.userId]?.recentUnReadMessages,
        (msg) =>
          _.filter(action.messages, (md) => _.isEqual(_.toUpper(msg.id), _.toUpper(md.id))).length
      )
      return {
        ...state,
      }
    }
    case SET_UNREAD_CHATS:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          unreadChats: {
            ...action.unreadChats,
          },
        }
       
      }
    case REDUCE_UNREAD_CHATS_BY_1:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          unreadChats: { ...action.unreadChats}
        }
      }
    case ADD_MESSAGE_TO_DELETE: {
      let msgsToDelete = state?.[action?.userId]?.messagesToDelete || []
      msgsToDelete = [...msgsToDelete, action.message]
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          messagesToDelete: msgsToDelete,
        }
      }
    }
    case REMOVE_MESSAGES_TO_DELETE: {
      _.remove(state?.[action.userId]?.messagesToDelete,
        (msg) =>
          _.filter(action.messages, (md) => _.isEqual(_.toUpper(msg.id), _.toUpper(md.id))).length
      )
      return {
        ...state,
      }
    }
    case CLEAR_SELECTED_CHAT: {
      return {
        ...state,
        selectedChat: {},
      }
    }
    case CLEAR_UNREAD_COUNT: {
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          canClearUnreadCountOfSelectedChat: action.canClear,
        }
      }
    }
    case CLEAR_USER_STORAGE: {
      return {
        ...state,
        [action.userId]: {}
      }
    }
    default:
      return state
  }
}
