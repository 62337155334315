import {
  UPDATE_TRAINING_STATUS,
} from '../actions/studyMetaData'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import initialState from '../store/initialState'

export default (state = initialState.trainingForms, action) => {
  switch (action.type) {
    case UPDATE_TRAINING_STATUS: {
      return {
        ...state,
        trainingCompleted: action.status,
      }
    }
    case CLEAR_USER_STORAGE: {
        return {
            ...state,
            [action.userId]: {}
        }
    }
    default:
      return state
  }
}
