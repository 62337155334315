import _ from 'lodash'
import { Card } from 'native-base'
import React from 'react'
import { Text, View } from 'react-native'
import styles from '../components/styles/faqStyles'

const iconColor = '#000'
const bgColor = '#b3e5fc'

export const buildFaqHeader = (data, index) => {
  return (
    <Text style={styles.faqText}>
      {index + 1}
      {'. '}
      {data.question}
    </Text>
  )
}

export const buildFaqContent = (data) => {
  return (
    <View style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Card style={[styles.ansContainer]}>
        <Text style={[styles.faqAnsText]}>{data.answer}</Text>
      </Card>
    </View>
  )
}

export const buildData = (faqs) => {
  const data = _.map(faqs, (faq, index) => ({
    title: faq.question,
    content: faq.answer,
    sno: index + 1,
  }))
  return data
}
