import { View } from 'react-native'
import React from 'react'
import TimeZoneMismatchAlert from './TimeZoneMismatchAlert'
import TimeZoneNoAlert from './TimeZoneNoAlert'
import NoEnoughDiskSpaceAlert from './NoEnoughDiskSpaceAlert'
import PropTypes from 'prop-types'
import _ from 'lodash'

const AppAlerts = (props) => {
    const {t, navigation, appStatus, user, setOrAckTimezoneChange, isTimeZoneNoClicked, configuredValue, isTimeZoneNoClickedProp, isStorageAvailable,
    isSpaceAlertAcknowledged, currentScreen, isAcknowledged}  = props
  return (
    <View style={{ marginTop: 5 }}>
        {/* ----------When subject's timezone is different from selected timezone----------  */}
        <TimeZoneMismatchAlert
            t={t}
            navigation={navigation}
            appStatus ={appStatus}
            user={user}
            setOrAckTimezoneChange ={setOrAckTimezoneChange}
            isTimeZoneNoClicked ={isTimeZoneNoClicked}
            isSubjectRegistered= {_.get(user, 'isSubjectRegistered', false)}
            isAcknowledged={isAcknowledged}
          />
        
        { isTimeZoneNoClickedProp &&
                (<TimeZoneNoAlert
                t={t}
                navigation={navigation}
                configuredValue ={configuredValue}
                appStatus ={appStatus}
                setOrAckTimezoneChange ={setOrAckTimezoneChange}
                isTimeZoneNoClicked = {isTimeZoneNoClicked}
                />)}

        {/* ----------When there is no enough room to download diary images in background---------- 
          Restricting the popup to display in PIN screen
        */}
        <NoEnoughDiskSpaceAlert 
        t={t}
        isStorageAvailable={isStorageAvailable}
        isSpaceAlertAcknowledged={isSpaceAlertAcknowledged}
        currentScreen ={currentScreen}
        />
    </View>
  )
}

AppAlerts.defaultProps = {
  t: ()=> null,
  navigation: {}, 
  appStatus: {},
  user: {},
  setOrAckTimezoneChange: ()=> null,
  isTimeZoneNoClicked: ()=> null,
  configuredValue: null, 
  isTimeZoneNoClickedProp: ()=> null,
  isStorageAvailable: true,
  isSpaceAlertAcknowledged: false,
  currentScreen: ''
}

AppAlerts.propTypes = {
  t: PropTypes.func,
  navigation: PropTypes.instanceOf(Object), 
  appStatus: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  setOrAckTimezoneChange: PropTypes.func,
  isTimeZoneNoClicked: PropTypes.func,
  configuredValue: PropTypes.string, 
  isTimeZoneNoClickedProp: PropTypes.func,
  isStorageAvailable: PropTypes.bool,
  isSpaceAlertAcknowledged: PropTypes.bool,
  currentScreen: PropTypes.string,
  isAcknowledged: PropTypes.bool
}


export default AppAlerts