import React from 'react';
import { Platform, Text, View } from 'react-native';
import PropTypes from 'prop-types'
import { fontScale } from '../../styles/fontResizer';
import { alignments } from '../../scales/alignments';
import _ from 'lodash'

const ClippedText = ({ text, width, height, fontSize, style, containerStyle, orientation }) => {

  const getTruncatedText = (text, maxChars) => {
    if (text.length > maxChars) {
      return text.slice(0, maxChars - 3) + '...';
    }
    return text;
  };

  const numberOfLines = Math.floor(height / (fontSize * 1.4));
  const maxCharsPerLine = Math.floor(width / (fontSize * 0.6)); // Estimating character width
  const maxChars = numberOfLines * maxCharsPerLine;

  const truncatedText = getTruncatedText(text, maxChars);

  const getNumberOfLines = () => {
    if(checkEachWordFit()){
      return 1
    }else{
      return Math.floor(height / (fontSize * 1.4))
    }
  }

  const checkEachWordFit = () => {
    const avgCharWidth = fontSize * 0.7; 
    const charsPerWidth = Math.floor(width / avgCharWidth) //SingleLine
    const charsPerWidthWithOutSpaces= calculate(charsPerWidth)
    const valueArray = text.split(' ')
    return !_.isEmpty(_.filter(valueArray, ele => (_.size(ele)>charsPerWidthWithOutSpaces)))
 }


const calculate = (charsPerWidth) => {
  return charsPerWidth-getSpaces()
}

const getSpaces = () => {
  if(orientation === alignments.VERTICAL){
    return Platform.OS === 'web' ? 4 : 10
  }else{
    return Platform.OS === 'web' ? 4 : 2
  }
}

  return (
    <View style={containerStyle}>
      <Text
        style={[style, { fontSize }]}
        numberOfLines={getNumberOfLines()}
      >
        {style.textAlign !=='right' ?  text : truncatedText}
      </Text>
    </View>
  );
};

ClippedText.defaultProps = {
  text: null,
  width: 0,
  height: 0,
  fontSize: fontScale(10),
  style: {
    textAlign: 'center'
  },
  containerStyle: {},
  orientation: null,
}

ClippedText.propTypes = {
  text: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  style: PropTypes.shape({
    textAlign: PropTypes.string,
  }),
  containerStyle: PropTypes.instanceOf(Object),
  orientation: PropTypes.string,
}

export default ClippedText;
