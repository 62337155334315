import React, { Component } from 'react'
import { ActivityIndicator, Platform, View } from 'react-native'
import { NavigationEvents } from 'react-navigation'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOfflineData } from '../../actions/studyMetaData'
import { backgroundColor } from '../../containers/NavigationScreens'
import { getPin } from '../../utils/secureStorageUtils'
import PIN from './PIN'
import {clearStorageAndStoreSyncQueue} from '../../actions/syncQueue'
import { setCurrentScreen , updateIsAcknowledged} from '../../actions/session'
import { getCurrentScreen } from '../../selectors/session'
import { getUserStudySite } from '../../selectors/studyMetaData'
import { getSubject, getUser } from '../../selectors/commonSelector'
import _ from 'lodash'
import { updatePinValidated } from '../../actions/storeAppStatus'
import PropTypes from 'prop-types'

class PinValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appPin: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { setCurrentScreen } = this.props
    setCurrentScreen('PIN')
    if (Platform.OS === 'ios') {
      // setupInitialHealthKit();
    }
  }

  retrieveAppPin = async () => {
    const { loggedInUserId } = this.props;
    const appPin = getPin(loggedInUserId)
    this.setState({
      appPin,
      loading: false,
    })
  }

  login = async () => {
    const {
      navigation,
      screenProps: { t },
      retrieveOfflineData,
      updateIsAcknowledged,
      updatePinValidated: updatePinValidationStatus
    } = this.props
    updateIsAcknowledged(false)
    updatePinValidationStatus(true)
    retrieveOfflineData(navigation)
  }

  render() {
    const {
      navigation,
      screenProps,
      subject,
      clientID,
      loggedInUserId,
      clearStorageAndStoreSyncQueue
    } = this.props
    const { loading, appPin } = this.state
    return (
      <View style={{ flex: 1 }}>
        {loading && (
          <View
            style={{ flex: 1, position: 'absolute', alignItems: 'center', alignSelf: 'center' }}
          >
            <ActivityIndicator
              size="large"
              color={backgroundColor}
              overlayColor="rgba(0, 0, 0, 0.07)"
            />
          </View>
        )}
        <NavigationEvents
          onWillFocus={() => {
            this.retrieveAppPin()
          }}
        />
        {!loading && (
          <PIN
            login={this.login}
            navigation={navigation}
            mode="validate"
            appPin={appPin}
            screenProps={screenProps}
            subject={subject}
            clientID={clientID}
            loggedInUserId = {loggedInUserId}
            clearStorageAndStoreSyncQueue={clearStorageAndStoreSyncQueue}
          />
        )}
      </View>
    )
  }
}
PinValidation.defaultProps = {
  subject: {},
  clientID: null,
  loggedInUserId: null,
}
PinValidation.propTypes = {
  navigation: PropTypes.object.isRequired,
  screenProps: PropTypes.object.isRequired,
  retrieveOfflineData: PropTypes.func.isRequired,
  setCurrentScreen: PropTypes.func.isRequired,
  clearStorageAndStoreSyncQueue: PropTypes.func.isRequired,
  updateIsAcknowledged: PropTypes.func.isRequired,
  updatePinValidated: PropTypes.func.isRequired,
  subject: PropTypes.object,
  clientID: PropTypes.string,
  loggedInUserId: PropTypes.string,
}
const mapStateToProps = (state) => ({
  loading: state?.loading,
  currentScreen: getCurrentScreen(state),
  subject: getSubject(state),
  clientID: _.get(getUserStudySite(state)?.client, 'id', null ),
  loggedInUserId: getUser(state)?.phoneNo
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveOfflineData: getOfflineData,
      setCurrentScreen,
      clearStorageAndStoreSyncQueue,
      updateIsAcknowledged,
      updatePinValidated,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PinValidation)
