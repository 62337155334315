import constants from '../constants/constants'
import api from '../utils/api'
import showToast from '../utils/toast'


export const retrieveFAQs = (studyId, subject, t) => async (dispatch) => {
  try {
    // api.defaults.headers.common.Authorization = generateBasicAuthToken(subject.phoneNo, subject.password);
    // api.defaults.headers.common['Accept-Language'] = locale;
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/faqs`)
    return res.data
  } catch (error) {
    console.log(error)
    // showToast(localeStore.FailedRetrieve, 'danger', 2000);
    const message = t('FailedRetrieveFaqs')
    showToast(message, 'danger', 3000)
  }
}
