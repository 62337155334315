import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { clearSelectedSubject, retrieveSubjects, updateSubject } from '../actions/subject'
import { updateSelectedFormType } from '../actions/session'
import { retrieveSubjectsOfCaregiver, retrieveSubjectById } from '../actions/caregiver'
import SubjectList from '../components/subject/list'
import constants from '../constants/constants'
import { NavigationEvents } from 'react-navigation'
import moment from 'moment-timezone'
import { getUser } from '../selectors/commonSelector'
import { getUserStudySite } from '../selectors/studyMetaData'
import _ from 'lodash'

class SubjectListPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subjects: [],
      list: [],
      loading: false,
      searchTerm: ''
    }
  }

  componentDidMount() {
    this.getSubjects()
  }

  getSubjects = async () => {
    const {
      retrieveSubjects: retrieveSubjectsOfSite,
      siteId,
      currentUser,
      retrieveSubjectsOfCaregiver,
    } = this.props
    this.setState({loading: true})
    try {
      let subjects = []
      if (currentUser && currentUser.role === 'CAREGIVER') {
        subjects = await retrieveSubjectsOfCaregiver(currentUser.id)
      } else {
        subjects = await retrieveSubjectsOfSite(siteId)
      }
      this.setState({loading: false, subjects, list: subjects})
    } catch (error) {
      this.setState({loading: false})
      console.log(error)
    }
  }

  clearSubject = async () => {
    const { clearSelectedSubject} = this.props
    this.setState({searchTerm: ''})
    clearSelectedSubject()
  }

  onSubjectSelect = async (subject) => {
    const {
      updateSubject: updateSelectedSubjectInStore,
      navigation,
      retrieveSubjectById,
      currentUser,
    } = this.props
    const { formCategory } = constants
    if (currentUser && currentUser.role === 'CAREGIVER') {
      await retrieveSubjectById(subject.id)
      navigation.navigate('Visits')
    } else {
      updateSelectedSubjectInStore({ ...subject, lastUpdatedDateOn: null }, currentUser.id)
      updateSelectedFormType(formCategory.RATER_ASSESSMENT, currentUser.id)
      moment.tz.setDefault(currentUser?.userData?.data?.timezone)
      navigation.navigate('ClinicianDiary', { screen: 'Visits' })
    }
  }

  onSearchTerm = (searchTerm) => {
    const {subjects} = this.state
    this.setState({searchTerm})
    if(!_.isEmpty(searchTerm)){
      const filteredSubjects = subjects.filter((subject) => _.includes(_.lowerCase(subject?.subjectNo), _.lowerCase(searchTerm)))
      this.setState({list: filteredSubjects})
    }else{
      this.setState({list: subjects})
    }
  }

  render() {
    const {  appType } = this.props
    const {list, loading, searchTerm} = this.state
    return (
      <View style={{ flex: 1 }}>
        <NavigationEvents
          onDidFocus={() => {
            appType === constants.AppType.SITESTAFF && this.clearSubject()
          }}
        />
        <SubjectList list={list} onSubjectSelect={this.onSubjectSelect} loading={loading} onSearchTerm={this.onSearchTerm} searchTerm={searchTerm}/>
      </View>
    )
  }
}

SubjectListPage.defaultProps = {
  siteId: '',
  updateSubject: () => null,
  appType: constants.AppType.SUBJECT,
  

}

SubjectListPage.propTypes = {
  siteId: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  updateSubject: PropTypes.func,
  appType: PropTypes.string,
  clearSelectedSubject: PropTypes.func,
}
const mapStateToProps = (state) => {
  return {
    siteId: getUserStudySite(state)?.id,
    currentUser: getUser(state),
    appType: state.appStatus.appType,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      retrieveSubjects,
      updateSubject,
      retrieveSubjectsOfCaregiver,
      retrieveSubjectById,
      updateSelectedFormType,
      clearSelectedSubject,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SubjectListPage)
