import _ from 'lodash'
import React, { Component } from 'react'
import { Text, View } from 'react-native'
import constants from '../../constants/constants'
import SubjectTimezone from '../../utils/dateFormatUtils'
import styles from './styles'

export default class CallHistory extends Component {
  render() {
    const {
      call,
      screenProps: { t },
      timeZone,
      subjectId,
    } = this.props
    const subject = _.find(call.participants, (p) =>
      _.isEqual(_.toUpper(p.participantPkId), _.toUpper(subjectId))
    )
    if (
      constants.CALL_STATUS.COMPLETED == call.status &&
      subject.status == constants.PARTICIPANT_STATUS.NOT_ATTENDED
    ) {
      return (
        <View
          style={{
            backgroundColor: '#ffffff',
            borderRadius: 10,
            paddingTop: 10,
            alignItems: 'center',
            flex: 1,
            height: 400,
          }}
          testID={'call-history-tab-click'} accessible = {true}
        >
          <Text style={{ color: '#546e7a', fontSize: 14, fontFamily: 'Inter' }}>
            {t('MissedCall')}
          </Text>
        </View>
      )
    }
    return (
      <View style={styles.callDetailsView}>
        {constants.CALL_STATUS.STARTED == call.status && (
          <Text style={styles.callDetailLabelText}>{t('CallInProgress')}</Text>
        )}
        {constants.CALL_STATUS.SCHEDULED == call.status && (
          <Text style={styles.callDetailLabelText}>{t('CallNotStarted')}</Text>
        )}
        {(subject.status == constants.PARTICIPANT_STATUS.JOINED ||
          subject.status == constants.PARTICIPANT_STATUS.LEFT) && (
          <Text style={styles.callDetailLabelText}>
            {t('JoinedOn')}:{' '}
            <Text style={styles.callDetailText}>
              <SubjectTimezone dateTime={subject.joinedAt} format="DD-MMM-YYYY hh:mm:ss A z" />
            </Text>{' '}
          </Text>
        )}
        {subject.status == constants.PARTICIPANT_STATUS.LEFT && (
          <Text style={styles.callDetailLabelText}>
            {t('LeftOn')}:{' '}
            <Text style={styles.callDetailText}>
              <SubjectTimezone dateTime={subject.leftAt} format="DD-MMM-YYYY hh:mm:ss A z" />
            </Text>{' '}
          </Text>
        )}
      </View>
    )
  }
}
