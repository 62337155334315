// import {
//   STORE_SUBJECT_SELECTED_LANGUAGE,
// } from '../actions/login'
import { UPDATE_SELECTED_SUBJECT, CLEAR_SELECTED_SUBJECT } from '../actions/subject'
import initialState from '../store/initialState'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import { GET_META_DATA_SUCCESS, UPDATE_SUBJECT_CURRENT_VERSION } from '../actions/studyMetaData'

export default (state = initialState.subject, action) => {
  switch (action.type) {
    case CLEAR_SELECTED_SUBJECT: 
    case CLEAR_USER_STORAGE: 
      return {
        ...state,
        [action.userId]: {}
      }
    case UPDATE_SELECTED_SUBJECT: 
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          ...action.subject
        }
      }
    case GET_META_DATA_SUCCESS:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          lastUpdatedDateOn: action.metaData?.lastUpdatedDateOn,
        } 
      }
    case UPDATE_SUBJECT_CURRENT_VERSION:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          currentCrfVersion: action.crfVersion,
        } 
      }
    default:
      return state
  }
}
