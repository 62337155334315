import constants from '../constants/constants'
import api from '../utils/api'
import { healthkitData } from '../utils/healthKit/Healthkit'


export const saveHealthkitData = (subjectId, date) => async (dispatch) => {
  // console.log("Sync called");
  try {
    const hkData = {
      subjectHealthData: {
        subject: {
          id: subjectId,
        },
        date,
        bodyTemp: await healthkitData.subjectHealthData.bodyTemp(),
        bloodGlucose: await healthkitData.subjectHealthData.bloodGlucose(),
        systolicBp: await healthkitData.subjectHealthData.systolicBp('bloodPressureSystolicValue'),
        diastolicBp: await healthkitData.subjectHealthData.diastolicBp(
          'bloodPressureDiastolicValue'
        ),
        respiratoryRate: await healthkitData.subjectHealthData.respiratoryRate(),
        bmi: await healthkitData.subjectHealthData.bmi(),
        heartRate: await healthkitData.subjectHealthData.heartRate(),
        height: await healthkitData.subjectHealthData.height(),
        weight: await healthkitData.subjectHealthData.weight(),
        bodyFat: await healthkitData.subjectHealthData.bodyFat(),
        leanBodyMass: await healthkitData.subjectHealthData.leanBodyMass(),
        sex: await healthkitData.subjectHealthData.sex(),
      },
      subjectActivity: {
        subject: {
          id: subjectId,
        },
        date,
        activeEnergyBurned: await healthkitData.subjectActivity.activeEnergyBurned(),
        restingEnergyBurned: await healthkitData.subjectActivity.restingEnergyBurned(),
        distance: await healthkitData.subjectActivity.distance(),
        floors: await healthkitData.subjectActivity.floors(),
        steps: await healthkitData.subjectActivity.steps(),
        sleepingDuration: await healthkitData.subjectActivity.sleepDuration(),
      },
    }
    // console.log("hkData: ", hkData);
    const res = await api.post(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}/healthData`, hkData)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const retrieveHealthkitData = (subjectId, date) => async (dispatch) => {
  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}/healthData?date=${date}`)
    return res.data
  } catch (error) {
    console.log(error)
  }
}
